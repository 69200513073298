import { assertNever } from "@msys/common";
import { Modal } from "@msys/ui";
import { Unit } from "@msys/units";
import { Alert, Stack } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { Form, Formik } from "formik";
import { capitalize, omit, uniqueId } from "lodash";
import React from "react";
import * as Yup from "yup";
import { AutocompleteField } from "../../../commons/form-fields/AutocompleteField";
import { CheckboxField } from "../../../commons/form-fields/CheckboxField";
import { FormattedFloatOptionalField } from "../../../commons/form-fields/FormattedFloatOptionalField";
import { SelectField } from "../../../commons/form-fields/SelectField";
import { TextField } from "../../../commons/form-fields/TextField";
import {
  DefineItemProps2Entry,
  DefineItemProps2EntryBool,
  DefineItemProps2EntryNumber,
  DefineItemProps2EntryNumberArray,
  DefineItemProps2EntryText,
  DefineItemProps2EntryTextArray,
  DocType,
} from "../../../../clients/graphqlTypes";
import {
  AllowedValuesField,
  AllowedValuesFormValues,
  useAllowedValuesFieldValidationSchema,
} from "../form-fields/PropertyAllowedValuesField";
import {
  defaultQuestionControlValues,
  QuestionControlSection,
  QuestionControlSectionFormValues,
} from "../QuestionControlSection";
import { usePropertyUnits } from "../usePropertyUnits";
import { v4 } from "uuid";

type PropType = "TEXT" | "NUMBER" | "BOOLEAN";

const PROP_VALUES: PropType[] = ["TEXT", "NUMBER", "BOOLEAN"];

interface TypeFields {
  type: PropType;
  isArray: boolean;
}

interface FormValues
  extends TypeFields,
    AllowedValuesFormValues,
    QuestionControlSectionFormValues {
  key: string;
  unit: Unit | null;
  setRange: boolean;
  range: {
    min: number | null;
    max: number | null;
  };
  essential: boolean;
  group: string;
  clientVisibility: boolean;
}

interface Props {
  handleClose: () => void;
  handleComplete: (newProperty: DefineItemProps2Entry) => Promise<void>;
  docType: DocType | null;
  showQuestionControl?: boolean;
}

export function PropertyAddModal({
  handleClose,
  handleComplete,
  docType,
  showQuestionControl = true,
}: Props) {
  const { t } = useTranslate(["Global", "QuoteItem"]);
  const { getUnits } = usePropertyUnits();
  const unitsOptions = React.useMemo(() => getUnits(), [getUnits]);
  const [error, setError] = React.useState<Error | null>(null);

  const initialValues: FormValues = React.useMemo(
    () => ({
      type: "TEXT",
      isArray: false,
      key: "",
      defineAllowedValues: false,
      allowedValues: [
        {
          key: v4(),
          value: null,
          media: null,
        },
      ],
      setRange: false,
      range: {
        min: null,
        max: null,
      },
      essential: false,
      unit: null,
      group: "",
      clientVisibility: false,
      ...defaultQuestionControlValues,
    }),
    []
  );

  const allowedValuesSchema = useAllowedValuesFieldValidationSchema();

  const validationSchema = React.useMemo(
    () =>
      Yup.object().shape({
        type: Yup.string().oneOf(PROP_VALUES).required(),
        isArray: Yup.boolean().required(),
        key: Yup.string()
          .required()
          .min(1)
          .label(t("Name", { ns: "Global" })),
        setRange: Yup.boolean(),
        range: Yup.object()
          .shape({
            min: Yup.number().nullable(),
            max: Yup.number().nullable(),
          })
          .when("setRange", {
            is: true,
            then: Yup.object().shape({
              min: Yup.number().nullable(),
              max: Yup.number()
                .nullable()
                .when("min", (min: number | null, schema: any) => {
                  return schema
                    .test({
                      test: (max: number | null) => {
                        return !(min === null && max === null);
                      },
                      message: t("Min or max should be defined", {
                        ns: "Global",
                      }),
                    })
                    .test({
                      test: (max: number | null) =>
                        !(min !== null && max !== null && min > max),
                      message: t("Max should be greater or equal than min", {
                        ns: "Global",
                      }),
                    });
                }),
            }),
          }),
        essential: Yup.boolean().required(),
        unit: Yup.object().shape({ key: Yup.string().required() }).nullable(),
        group: Yup.string(),
        prompt: Yup.string(),
        askWhen: Yup.array(Yup.string()),
        askWhom: Yup.array(Yup.string()),
        ...allowedValuesSchema,
      }),
    [t, allowedValuesSchema]
  );

  const onSubmit = async (values: FormValues) => {
    const newProperty: DefineItemProps2Entry = createPropertyEntry(values);
    try {
      await handleComplete(newProperty);
      handleClose();
    } catch (err) {
      const error = err instanceof Error ? err : new Error(JSON.stringify(err));
      setError(error);
    }
  };

  const formId = React.useMemo(() => uniqueId(), []);

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnMount
      onSubmit={onSubmit}
    >
      {formikProps => (
        <Modal
          title={t("Add property", {
            ns: "QuoteItem",
          })}
          handleClose={handleClose}
          actionButtons={[
            {
              label: t("Cancel", {
                ns: "Global",
              }),
              handleClick: handleClose,
              buttonProps: {
                variant: "text",
                disabled: formikProps.isSubmitting,
              },
            },
            {
              label: t("Confirm", {
                ns: "Global",
              }),
              buttonProps: {
                form: formId,
                type: "submit",
                loading: formikProps.isSubmitting,
                disabled: !formikProps.isValid,
              },
            },
          ]}
        >
          <Form id={formId}>
            <Stack spacing={1}>
              {error && <Alert severity="error">{error.message}</Alert>}
              <TextField
                name={"key"}
                label={t("Label", {
                  ns: "Global",
                })}
                autoFocus
              />
              <SelectField
                name={"type"}
                label={t("Type", {
                  ns: "QuoteItem",
                })}
                options={PROP_VALUES.map(type => ({
                  label: capitalize(type), // TODO: translate ?
                  value: type,
                }))}
                onChange={e => {
                  if (!e.target.value) return;
                  formikProps.setValues({
                    ...formikProps.values,
                    type: e.target.value as PropType,
                    allowedValues: [
                      {
                        key: v4(),
                        value: null,
                        media: null,
                      },
                    ],
                    defineAllowedValues: false,
                  });
                }}
              />
              {formikProps.values.type === "NUMBER" && (
                <AutocompleteField
                  name="unit"
                  inputLabel={t("Unit", {
                    ns: "QuoteItem",
                  })}
                  options={unitsOptions}
                  getOptionLabel={option => option.value}
                  renderOption={(props, option) => (
                    <li key={option.key} {...props}>
                      {option.value}
                    </li>
                  )}
                />
              )}
              {formikProps.values.type !== "BOOLEAN" && (
                <CheckboxField
                  name={"isArray"}
                  label={t("Allow multiple values", {
                    ns: "QuoteItem",
                  })}
                />
              )}
              {formikProps.values.type !== "BOOLEAN" && (
                <AllowedValuesField
                  type={formikProps.values.type}
                  disabled={
                    (formikProps.values.type === "NUMBER" &&
                      formikProps.values.setRange) ||
                    formikProps.isSubmitting
                  }
                />
              )}
              {formikProps.values.type === "NUMBER" && (
                <CheckboxField
                  name={"setRange"}
                  label={t("Set min and/or max", {
                    ns: "QuoteItem",
                  })}
                  disabled={
                    formikProps.values.defineAllowedValues ||
                    formikProps.isSubmitting
                  }
                />
              )}
              {formikProps.values.type === "NUMBER" &&
                formikProps.values.setRange && (
                  <Stack direction={"row"} spacing={1} paddingLeft={4} flex={1}>
                    <FormattedFloatOptionalField
                      name={"range.min"}
                      label={t("Min", {
                        ns: "QuoteItem",
                      })}
                    />
                    <FormattedFloatOptionalField
                      name={"range.max"}
                      label={t("Max", {
                        ns: "QuoteItem",
                      })}
                    />
                  </Stack>
                )}
              <TextField name={"group"} label={"Group"} />
              <CheckboxField
                name={`clientVisibility`}
                label={t("Visibility", { ns: "Global" })}
              />
              {docType && showQuestionControl && (
                <QuestionControlSection isInitiallyExpanded />
              )}
            </Stack>
          </Form>
        </Modal>
      )}
    </Formik>
  );
}

function createPropertyEntry({
  type,
  isArray,
  ...values
}: FormValues): DefineItemProps2Entry {
  if (!type) throw new Error("Prop type is missing!");

  if (type === "BOOLEAN") {
    return { bool: createBooleanPropertyEntry(values) };
  } else if (type === "NUMBER") {
    if (isArray) {
      return { numberArray: createNumberArrayPropertyEntry(values) };
    }

    return { number: createNumberPropertyEntry(values) };
  } else if (type === "TEXT") {
    if (isArray) {
      return { textArray: createTextArrayPropertyEntry(values) };
    }

    return { text: createTextPropertyEntry(values) };
  } else {
    assertNever(type);
  }
}

type EntryInputValues = Omit<FormValues, keyof TypeFields>;

function createBooleanPropertyEntry(
  values: EntryInputValues
): DefineItemProps2EntryBool {
  return {
    key: values.key,
    label: values.key,
    group: values.group,
    essential: values.essential,
    valueBool: undefined,
    clientVisibility: values.clientVisibility,
    prompt: values.prompt,
    askWhen: values.askWhen,
    askWhom: values.askWhom,
  };
}
function createNumberPropertyEntry(
  values: EntryInputValues
): DefineItemProps2EntryNumber {
  return {
    key: values.key,
    label: values.key,
    group: values.group,
    essential: values.essential,
    allowedValuesNumber: values.defineAllowedValues
      ? values.allowedValues.map(v => ({
          allowedNumber: v.value as number,
          media: v.media ? omit(v.media, "id", "__type") : null,
        }))
      : [],
    range: values.setRange ? values.range : null,
    valueNumber: undefined,
    unit: values.unit?.key,
    clientVisibility: values.clientVisibility,
    prompt: values.prompt,
    askWhen: values.askWhen,
    askWhom: values.askWhom,
  };
}
function createNumberArrayPropertyEntry(
  values: EntryInputValues
): DefineItemProps2EntryNumberArray {
  return {
    key: values.key,
    label: values.key,
    group: values.group,
    essential: values.essential,
    allowedValuesNumber: values.defineAllowedValues
      ? values.allowedValues.map(v => ({
          allowedNumber: v.value as number,
          media: v.media ? omit(v.media, "id", "__type") : null,
        }))
      : [],
    range: values.setRange ? values.range : null,
    valueNumberArray: undefined,
    unit: values.unit?.key,
    clientVisibility: values.clientVisibility,
    prompt: values.prompt,
    askWhen: values.askWhen,
    askWhom: values.askWhom,
  };
}
function createTextPropertyEntry(
  values: EntryInputValues
): DefineItemProps2EntryText {
  return {
    key: values.key,
    label: values.key,
    group: values.group,
    essential: values.essential,
    allowedValuesText: values.defineAllowedValues
      ? values.allowedValues.map(v => ({
          allowedText: v.value as string,
          media: v.media ? omit(v.media, "id", "__type") : null,
        }))
      : [],
    valueText: undefined,
    clientVisibility: values.clientVisibility,
    prompt: values.prompt,
    askWhen: values.askWhen,
    askWhom: values.askWhom,
  };
}
function createTextArrayPropertyEntry(
  values: EntryInputValues
): DefineItemProps2EntryTextArray {
  return {
    key: values.key,
    label: values.key,
    group: values.group,
    essential: values.essential,
    allowedValuesText: values.defineAllowedValues
      ? values.allowedValues.map(v => ({
          allowedText: v.value as string,
          media: v.media ? omit(v.media, "id", "__type") : null,
        }))
      : [],
    valueTextArray: undefined,
    clientVisibility: values.clientVisibility,
    prompt: values.prompt,
    askWhen: values.askWhen,
    askWhom: values.askWhom,
  };
}
