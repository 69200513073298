import { CollapseSection } from "@msys/ui";
import { Box, Stack } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import React from "react";
import {
  OrganisationProjectsSorting,
  ProjectFilterKind,
  ProjectStateMachineStatus,
} from "../../../clients/graphqlTypes";
import { RestrictedByCapabilityWithDebug } from "../../auth/RestrictedByCapability";
import {
  FilterByOrganisationUsers,
  FilterCheckbox,
} from "../../commons/filters";
import { FilterModal } from "../../commons/filters/FilterModal";
import { CrmCompaniesOrPersonsSelect } from "../crm-companies/CrmCompaniesOrPersonsSelect";
import { CrmCompanySelect } from "../crm-companies/CrmCompanySelect";
import { CustomFieldConfigFragment } from "../custom-fields/customFieldConfigs.generated";
import { FilterCustomFields } from "../custom-fields/filters";
import {
  FilterBuildingZip,
  FilterPlanned,
  FilterProjectCategory,
  FilterProjectState,
  FilterProjectType,
  FilterRelevantToMe,
  FilterWithinRadiusKm,
  ProjectsSorting,
} from "./filters";
import { FilterAssignees } from "./filters/ProjectAssignees";
import { FilterProjectPhases } from "./filters/ProjectPhases";
import { FilterSources } from "./filters/ProjectSources";
import { DEFAULT_SORTING, Filters } from "./ProjectsList";

export function ProjectsFilterModal<F extends Filters>({
  handleClose,
  sorting,
  setSorting,
  filters,
  setFilters,
  resetFilters,
  customFieldConfigs,
  allowedStates,
  onKindChange,
  onPlanningChange,
  onStateChange,
  showSorting,
  showPhasesFilter,
  showRelevantToMe,
}: {
  handleClose: () => void;
  sorting: OrganisationProjectsSorting[];
  setSorting: (sorting: OrganisationProjectsSorting[]) => void;
  filters: F;
  setFilters: React.Dispatch<React.SetStateAction<F>>;
  resetFilters: () => void;
  customFieldConfigs: CustomFieldConfigFragment[];
  allowedStates: ProjectStateMachineStatus[];
  onKindChange?: (value: ProjectFilterKind | null | undefined) => Partial<F>;
  onPlanningChange?: (value: boolean | null | undefined) => Partial<F>;
  onStateChange?: (
    value: ProjectStateMachineStatus[] | null | undefined
  ) => Partial<F>;
  showSorting: boolean;
  showPhasesFilter: boolean;
  showRelevantToMe: boolean;
}) {
  const { t } = useTranslate(["Projects", "Tickets", "Global"]);

  const [filtersState, setFiltersState] = React.useState(filters);
  const [sortingState, setSortingState] = React.useState(sorting);

  return (
    <FilterModal
      title={t("Project Filters", {
        ns: "Projects",
      })}
      handleApply={() => {
        setSorting(sortingState);
        setFilters(filtersState);
      }}
      handleClose={handleClose}
      handleReset={() => resetFilters()}
      spacing={2}
    >
      {showSorting && (
        <ProjectsSorting
          sorting={sortingState[0]}
          setSorting={setSortingState}
          defaultSorting={DEFAULT_SORTING}
        />
      )}

      <CollapseSection
        title={t("Scope", { ns: "Projects" })}
        isInitiallyExpanded={true}
      >
        <Stack direction="column" spacing={1}>
          <FilterProjectCategory
            value={filtersState.filterCategories ?? []}
            setValue={newValue =>
              setFiltersState(state => ({
                ...state,
                filterCategories: newValue,
              }))
            }
          />
          <FilterProjectType
            value={filtersState.filterType ?? []}
            setValue={newValue =>
              setFiltersState(filters => ({
                ...filters,
                filterType: newValue,
              }))
            }
          />
          <FilterSources
            value={filtersState.sources ?? []}
            setValue={newValue =>
              setFiltersState(filters => ({
                ...filters,
                sources: newValue,
              }))
            }
          />
          <FilterProjectState
            allowedStates={allowedStates}
            value={filtersState.filterIncludeState ?? []}
            setValue={newValue =>
              setFiltersState(filters => ({
                ...filters,
                ...(onStateChange?.(newValue) ?? {
                  filterIncludeState: newValue,
                }),
              }))
            }
          />
        </Stack>
      </CollapseSection>

      <CollapseSection
        title={t("Location", { ns: "Projects" })}
        isInitiallyExpanded={true}
      >
        <Stack direction="column" spacing={1}>
          <FilterBuildingZip
            value={filtersState.filterBuildingZip ?? ""}
            setValue={newValue =>
              setFiltersState(state => ({
                ...state,
                filterBuildingZip: newValue,
              }))
            }
          />
          <FilterWithinRadiusKm
            value={filtersState.filterWithinRadiusKm ?? 0}
            setValue={newValue =>
              setFiltersState(state => ({
                ...state,
                filterWithinRadiusKm: newValue,
              }))
            }
          />
        </Stack>
      </CollapseSection>

      <CollapseSection
        title={t("People", { ns: "Projects" })}
        isInitiallyExpanded={true}
      >
        <Stack direction="column" spacing={1}>
          <RestrictedByCapabilityWithDebug capability="QUOTING">
            <CrmCompanySelect
              crmCompanyId={filtersState.filterByCrmOrganisationId ?? undefined}
              onChange={crmCompany =>
                setFiltersState(state => ({
                  ...state,
                  filterByCrmOrganisationId: crmCompany?.id,
                }))
              }
              inputLabel={t("Client", { ns: "Projects" })}
              required={false}
              canCreateNew={false}
              disableBlockedBusinessPartners={false}
            />
          </RestrictedByCapabilityWithDebug>
          <CrmCompaniesOrPersonsSelect
            crmCompaniesOrPersonsIds={filtersState.filterByCrmLinkIds ?? []}
            onChange={crmCompaniesOrPersonsIds =>
              setFiltersState(state => ({
                ...state,
                filterByCrmLinkIds: crmCompaniesOrPersonsIds,
              }))
            }
            inputLabel={t("Other contacts", { ns: "Projects" })}
            required={false}
          />
          <FilterByOrganisationUsers
            label={t("Created by", { ns: "Projects" })}
            placeholder=""
            value={filtersState.filterBySourceUserIds ?? []}
            setValue={newValue =>
              setFiltersState(filters => ({
                ...filters,
                filterBySourceUserIds: newValue,
              }))
            }
          />
        </Stack>
      </CollapseSection>

      <CollapseSection
        title={t("Operations", { ns: "Projects" })}
        isInitiallyExpanded={true}
      >
        <Stack direction="column" spacing={1}>
          <Stack direction="row" spacing={1}>
            <Box>
              <FilterCheckbox
                label={t("Urgent", { ns: "Tickets" })}
                value={filtersState.filterUrgent ?? undefined}
                setValue={newValue =>
                  setFiltersState(state => ({
                    ...state,
                    filterUrgent: newValue,
                  }))
                }
              />
            </Box>
            <Box>
              <FilterCheckbox
                label={t("Overdue", { ns: "Tickets" })}
                value={filtersState.filterOverdue ?? undefined}
                setValue={newValue =>
                  setFiltersState(state => ({
                    ...state,
                    filterOverdue: newValue,
                  }))
                }
              />
            </Box>
          </Stack>
          {showPhasesFilter && (
            <FilterProjectPhases
              allowedStates={allowedStates}
              value={filtersState.filterPhaseIds ?? []}
              setValue={newValue =>
                setFiltersState(filters => ({
                  ...filters,
                  filterPhaseIds: newValue,
                }))
              }
            />
          )}
          <FilterAssignees
            value={filtersState.filterAssigneeIds ?? []}
            setValue={newValue =>
              setFiltersState(filters => ({
                ...filters,
                filterAssigneeIds: newValue,
              }))
            }
          />
          <RestrictedByCapabilityWithDebug capability="PLANNING">
            <FilterPlanned
              value={filtersState.filterAssigned ?? undefined}
              setValue={newValue =>
                setFiltersState(state => ({
                  ...state,
                  ...(onPlanningChange?.(newValue) ?? {
                    filterAssigned: newValue,
                  }),
                }))
              }
            />
          </RestrictedByCapabilityWithDebug>
        </Stack>
      </CollapseSection>

      {/*<FilterMinBudget
        value={filtersState.filterMinBudget ?? 0}
        setValue={newValue =>
          setFiltersState(state => ({
            ...state,
            filterMinBudget: newValue,
          }))
        }
      />*/}

      {customFieldConfigs.length > 0 && (
        <FilterCustomFields
          fieldConfigs={customFieldConfigs}
          values={filtersState.filterByCustomFields ?? []}
          setValues={newValues =>
            setFiltersState(state => ({
              ...state,
              filterByCustomFields: newValues,
            }))
          }
        />
      )}

      {showRelevantToMe && (
        <Box>
          <FilterRelevantToMe
            filters={filtersState}
            setFilters={setFiltersState}
          />
        </Box>
      )}
    </FilterModal>
  );
}
