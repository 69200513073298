import {
  DebouncedSearchInput,
  ListHeader,
  ModalOpenButton,
  useFormatting,
  useScreenWidth,
} from "@msys/ui";
import { Box } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import React from "react";
import {
  OrganisationProjectsSorting,
  ProjectStateMachineStatus,
} from "../../../clients/graphqlTypes";
import { useUserData } from "../../auth/useUserData";
import { SwitchCollectionViewButton } from "../../commons/button/SwitchCollectionViewButton";
import { FilterButton } from "../../commons/filters/FilterButton";
import { useFiltersAndPagination } from "../../commons/filters/useFiltersAndPagination";
import {
  CollectionView,
  useCollectionViewWithMobile,
} from "../../commons/hooks/useCollectionView";
import { Page, PageTopbarItem } from "../../commons/layout/Page";
import { PageContainer } from "../../commons/layout/PageContainer";
import { useCustomFieldConfig } from "../../features/custom-fields/useCustomFieldConfig";
import { FilterRelevantToMe } from "../../features/projects/filters";
import { ProjectsFilterModal } from "../../features/projects/ProjectsFilterModal";
import {
  ProjectsList,
  type Filters,
} from "../../features/projects/ProjectsList";
import { ProjectListItemDisplayConfig } from "../../features/projects/components/ProjectListItem";
import { defaultListItemDisplayConfig } from "../../features/projects/components/ProjectListItemVisibilitySettingsModal";
import { renderProjectsFilterChips } from "../../features/projects/renderProjectsFilterChips";
import { PROJECT_FILTER_MAP } from "../../features/projects/useProjectSources";
import { useDataGridStateStore } from "../../features/users/useDataGridStateStore";
import { useUserPreference } from "../../features/users/useUserPreference";

const DEFAULT_ITEMS_PER_PAGE = 25;
const DEFAULT_SORTING: OrganisationProjectsSorting[] = [
  {
    column: "createdAt",
    direction: "desc",
  },
];

const ALLOWED_VIEWS: CollectionView[] = ["table", "list"];

interface Props {
  submenuItems: PageTopbarItem[];
}

export const ProjectsPast = ({ submenuItems }: Props) => {
  const { t } = useTranslate(["Projects", "Tickets", "Global"]);
  const viewer = useUserData().currentUser!;
  const { getFormattedPrice, getFormattedFloat } = useFormatting();
  const { isMinTablet } = useScreenWidth();

  const [activeView, setActiveView] =
    useCollectionViewWithMobile<CollectionView>(
      "projects-past",
      "table",
      "list"
    );

  const {
    offset,
    limit,
    paginationModel,
    setPaginationModel,
    filters,
    setFilters,
    resetFilters,
    sorting,
    setSorting,
    toRemoveParams,
  } = useFiltersAndPagination<OrganisationProjectsSorting, Filters>(
    {},
    DEFAULT_SORTING,
    DEFAULT_ITEMS_PER_PAGE
  );

  const baseVariables = React.useMemo(
    () => ({
      offset,
      limit,
      sorting: DEFAULT_SORTING,
    }),
    [limit, offset]
  );

  const allowedStates: ProjectStateMachineStatus[] = React.useMemo(
    () =>
      viewer.organisation.isClientOrganisation
        ? ["completed", "cancelled", "contractee_declined", "legacy_closed"]
        : ["completed", "cancelled", "legacy_closed"],
    [viewer.organisation.isClientOrganisation]
  );

  const variables = React.useMemo(
    () => ({
      ...baseVariables,
      ...filters,
      filterBySources: filters.sources
        ? filters.sources.map(s => PROJECT_FILTER_MAP[s]).flat(1)
        : undefined,
      filterIncludeState: filters.filterIncludeState?.length
        ? filters.filterIncludeState
        : allowedStates,
      sorting,
    }),
    [allowedStates, baseVariables, filters, sorting]
  );

  const { customFieldConfig: customFieldConfigs } =
    useCustomFieldConfig("Project");

  const stateStore = useDataGridStateStore("ProjectsPast");
  const listItemDisplayConfigStore =
    useUserPreference<ProjectListItemDisplayConfig>(
      `DisplayConfig-ProjectsCurrent`,
      defaultListItemDisplayConfig
    );

  return (
    <Page
      title={t("Past", { ns: "Projects" })}
      subtitle={t("Projects", { ns: "Projects" })}
      submenuItems={submenuItems}
    >
      <PageContainer>
        <ListHeader
          SwitchViewButton={
            <SwitchCollectionViewButton
              allowedViews={ALLOWED_VIEWS}
              activeView={activeView}
              setActiveView={setActiveView}
            />
          }
          FilterButton={
            <ModalOpenButton
              Modal={ProjectsFilterModal}
              modalProps={{
                sorting,
                setSorting,
                filters,
                setFilters,
                resetFilters,
                customFieldConfigs,
                allowedStates,
                showPhasesFilter: activeView !== "kanban",
                showSorting: activeView !== "kanban",
                showRelevantToMe: !isMinTablet,
              }}
            >
              <FilterButton />
            </ModalOpenButton>
          }
          FilterFields={
            isMinTablet ? (
              <Box display="flex" alignItems="center" height={0} pl={1.5}>
                <FilterRelevantToMe filters={filters} setFilters={setFilters} />
              </Box>
            ) : undefined
          }
          SearchField={
            <DebouncedSearchInput
              placeholder={t("Search", { ns: "Global" })}
              defaultValue={filters.filterSearchTerm}
              onChangeSearchTerm={(newValue: string) => {
                setFilters(filters => ({
                  ...filters,
                  filterSearchTerm: newValue,
                }));
              }}
            />
          }
          FilterChips={renderProjectsFilterChips({
            viewer,
            t,
            filters,
            setFilters,
            getFormattedPrice,
            getFormattedFloat,
            allowedStates,
            showRelevantToMe: !isMinTablet,
            showPhasesChip: true,
          })}
          mb={2}
        />
        <ProjectsList
          sorting={sorting}
          setSorting={setSorting}
          activeView={activeView}
          pathToPage={"/projects/past"}
          paginationModel={paginationModel}
          setPaginationModel={setPaginationModel}
          stateStore={stateStore}
          variables={variables}
          displayConfig={listItemDisplayConfigStore.value}
        />
      </PageContainer>
    </Page>
  );
};
