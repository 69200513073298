import { SelectMultiple } from "@msys/ui";
import { useTranslate } from "@tolgee/react";
import { FilterProps } from "../../../commons/filters";
import {
  FilterChipGroup,
  FilterChipGroupProps,
} from "../../../commons/filters/FilterChip";
import {
  ProjectSourceFilterType,
  useProjectSources,
} from "../useProjectSources";

export function FilterSourcesChips(
  props: Omit<
    FilterChipGroupProps<ProjectSourceFilterType>,
    "label" | "getValueLabel"
  >
) {
  const { t } = useTranslate("Projects");
  const { projectSourceFilterLabels } = useProjectSources();
  return (
    <FilterChipGroup
      {...props}
      getValueLabel={value => projectSourceFilterLabels[value]}
      label={t("Lead source")}
    />
  );
}

export const FilterSources = (
  props: FilterProps<ProjectSourceFilterType[]>
) => {
  const { t } = useTranslate("Projects");
  const { projectSourceFilterOptions } = useProjectSources();
  return (
    <SelectMultiple
      label={t("Lead source")}
      options={projectSourceFilterOptions}
      value={props.value}
      onChange={value => {
        props.setValue(value);
      }}
    />
  );
};
