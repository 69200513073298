import { gql } from "@apollo/client";
import {
  CollapseButton,
  CollapseSection,
  isImageOr3dModel,
  isPdfOrFile,
  processAttachment,
  TextWithOverflow,
} from "@msys/ui";
import { GalleryRow } from "../../../commons/images/GalleryRow";
import { Button, List, Stack, Typography } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { useTranslate } from "@tolgee/react";
import React from "react";
import { TIME_AND_MATERIAL_CONTRACT_TYPES } from "../../../constants";
import { ContractType } from "../../../../clients/graphqlTypes";
import {
  ALL_KEY,
  ExpandedStore,
} from "../../../trees-virtual/hooks/useExpandedStore";
import { FileRow } from "../../attachments/FileRow";
import { DecisionModalButton } from "../../doc-items/buttons/DecisionModalButton";
import { ContingencyCheckbox } from "../../doc-items/fields/ContingencyCheckbox";
import { DecisionOptionRadioOrCheckbox } from "../../doc-items/fields/DecisionOptionRadioOrCheckbox";
import { SubitemsOptionsNoneSection } from "../../doc-items/fields/SubitemsOptionsNoneSection";
import { hasAnyQuestions } from "../../doc-items/helpers";
import { useDecisionItemMutations } from "../../doc-items/hooks/useDecisionItemMutations";
import { DocumentPreviewItems } from "../../documents/DocumentPreviewItems";
import { Fields } from "../../documents/DocumentPreviewItemsTypes";
import {
  templateQuoteItemHasDescription,
  TemplateQuotePreviewItemDescription,
} from "./TemplateQuotePreviewItemDescription";
import { TemplateQuotePreviewItems_ItemFragment } from "./TemplateQuotePreviewItems.generated";

export const TemplatesQuotePreviewItems = ({
  docId,
  canEdit,
  navigateToItem,
  selectedItemId,
  allDocItems,
  contractType,
  isBinding,
  container,
  expandedStore,
}: {
  docId: string;
  canEdit: boolean;
  navigateToItem?: (item: TemplateQuotePreviewItems_ItemFragment) => void;
  selectedItemId?: string | null;
  allDocItems: TemplateQuotePreviewItems_ItemFragment[];
  contractType: ContractType;
  isBinding: boolean;
  container?: HTMLElement | null;
  expandedStore: ExpandedStore;
}) => {
  const { t } = useTranslate(["Quote", "Global", "Decisions"]);

  const isTimeAndMaterialContract =
    TIME_AND_MATERIAL_CONTRACT_TYPES.includes(contractType);

  const {
    handleDecisionPreselectionChange,
    handleContingencyPreselectionChange,
    handleFinalizeContingencyDecision,
    handleFinalizeSubitemDecision,
    canForceFinalizeSubitemDecision,
    canFinalizeContingencyDecision,
    loading,
  } = useDecisionItemMutations<TemplateQuotePreviewItems_ItemFragment>(
    null,
    docId,
    false,
    expandedStore.expandedItemIds
  );

  const collapseItemAndChildrenRecursively = React.useCallback(
    (item: TemplateQuotePreviewItems_ItemFragment) => {
      const toCollapseIds = allDocItems
        .filter(i => i.pathForPdf.startsWith(item.pathForPdf))
        .map(i => i.id);
      const toExpandItemIds = expandedStore.areAllItemsExpanded
        ? allDocItems
            .filter(i => i.hasChildren && !toCollapseIds.includes(i.id))
            .map(i => i.id)
        : [];
      expandedStore.setExpandedState(
        Object.fromEntries([
          [ALL_KEY, false],
          ...toExpandItemIds.map(id => [id, true]),
          ...toCollapseIds.map(id => [id, false]),
        ])
      );
    },
    [expandedStore, allDocItems]
  );

  const fields = React.useMemo(
    (): Fields<TemplateQuotePreviewItems_ItemFragment> => ({
      CollapseButton: {
        render: item => {
          const expanded = expandedStore.isItemExpanded(item.id);
          return item.hasChildren ? (
            <CollapseButton
              aria-label={expanded ? "Collapse" : "Expand"}
              isExpanded={expanded}
              setIsExpanded={newExpanded => {
                if (newExpanded) {
                  expandedStore.setItemExpanded(item.id, newExpanded);
                } else {
                  collapseItemAndChildrenRecursively(item);
                }
              }}
            />
          ) : null;
        },
      },
      NonBindingCaption: {
        render: item => {
          if (item.type === "paid" && item.binding === "non_binding") {
            return t("Non-Binding item", {
              ns: "Quote",
            });
          }

          return null;
        },
      },
      ContingencyOptionalCaption: {
        render: item => {
          if (item.decisionIsContingentItem) {
            const captions: string[] = [];
            captions.push(
              t("Optional item", {
                ns: "Quote",
              })
            );

            if (item.decisionContingentItemPreselection) {
              captions.push(
                t("Recommended", {
                  ns: "Quote",
                })
              );
            }

            return captions.join(" • ");
          }

          return null;
        },
      },
      ContingencyOptionsButtons: {
        render: item => {
          return item.decisionIsContingentItem ? (
            <ContingencyCheckbox
              item={item}
              loading={loading}
              disabled={item.originDocId !== docId}
              canEdit={canEdit}
              handleContingencyPreselectionChange={
                handleContingencyPreselectionChange
              }
            />
          ) : null;
        },
      },
      ContingencyFinalizeButton: {
        render: item => {
          return item.decisionIsContingentItem ? (
            <Button
              disabled={true}
              size="extra-small"
              variant="contained"
              color="primary"
              onClick={async e => {
                e.preventDefault();
                e.stopPropagation();
                await handleFinalizeContingencyDecision(item.id);
              }}
            >
              {t("Resolve", { ns: "Quote" })}
            </Button>
          ) : null;
        },
      },
      DecisionOptionButton: {
        render: (item, parent) => {
          return parent?.type === "decision" &&
            !item.decisionOptionElimination ? (
            <DecisionOptionRadioOrCheckbox
              docId={docId}
              projectId={null}
              item={item}
              disabled={!canEdit || loading || parent.originDocId !== docId}
              expandedItemIds={expandedStore.expandedItemIds}
            />
          ) : null;
        },
      },
      DecisionNoneOptionButton: {
        render: item => {
          return item.decisionBehaviorOfSubitems === "SELECT_ONE" ? (
            <SubitemsOptionsNoneSection
              item={item}
              loading={loading}
              disabled={item.originDocId !== docId}
              canEdit={canEdit}
              handleDecisionPreselectionChange={
                handleDecisionPreselectionChange
              }
            />
          ) : null;
        },
      },
      DecisionButton: {
        render: item =>
          hasAnyQuestions(item, "TEMPLATE", undefined) ? (
            <DecisionModalButton
              projectId={null}
              docType="TEMPLATE"
              docId={docId}
              itemId={item.id}
              canFinalize={false}
              viewerDecisionRole={undefined}
              expandedItemIds={expandedStore.expandedItemIds}
              type="icon"
              disabled={true}
              isOptionHidden={item => item.isHidden}
            />
          ) : null,
      },
      DecisionFinalizeButton: {
        render: item => {
          return item.type === "decision" ? (
            <Stack direction="row" spacing={0.5} flexWrap="wrap">
              <Button
                disabled={true}
                size="extra-small"
                variant="contained"
                color="primary"
                onClick={async e => {
                  e.preventDefault();
                  e.stopPropagation();

                  if (item.decisionIsContingentItem) {
                    if (item.decisionContingentItemPreselection !== true) {
                      await handleContingencyPreselectionChange(item.id, true);
                    }
                    await handleFinalizeContingencyDecision(item.id);
                  }

                  await handleFinalizeSubitemDecision(item.id);
                }}
              >
                {t("Finalize decision", { ns: "Quote" })}
              </Button>
            </Stack>
          ) : null;
        },
      },
      Pos: {
        render: item => <TextWithOverflow>{item.pathForPdf}</TextWithOverflow>,
      },
      Title: {
        render: item => (
          <Typography variant="h3" sx={{ wordBreak: "break-word" }}>
            {item.title}
          </Typography>
        ),
      },
      Description: {
        render: item => {
          if (!templateQuoteItemHasDescription(item)) return null;
          return (
            <TemplateQuotePreviewItemDescription item={item} columns={3} />
          );
        },
      },
      Images: {
        render: item => {
          const images = item.attachments
            .filter(a => a.clientVisibility)
            .map(processAttachment)
            .filter(isImageOr3dModel);
          return images.length > 0 ? (
            <GalleryRow
              images={images}
              maxItems={4}
              style={item.level > 2 ? { maxWidth: "300px" } : undefined}
            />
          ) : null;
        },
      },
      Attachments: {
        render: item => {
          const files = item.attachments
            .filter(a => a.clientVisibility)
            .map(processAttachment)
            .filter(isPdfOrFile);
          return files.length > 0 ? (
            <CollapseSection
              title={t("Attachments", { ns: "Quote" })}
              titleVariant="body2"
              titleColor="secondary"
              iconButtonColor="secondary"
              minHeight="auto"
              isInitiallyExpanded={false}
              collapseTimeout={0}
            >
              <List
                disablePadding
                dense
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  flexWrap: "wrap",
                }}
              >
                {files.map(attachment => (
                  <FileRow
                    key={attachment.id}
                    file={attachment}
                    button={false}
                    divider={false}
                    sx={{ minWidth: "200px", maxWidth: "320px", width: "auto" }}
                  />
                ))}
              </List>
            </CollapseSection>
          ) : null;
        },
      },
      Quantity: {
        render: (item, _, priceData) =>
          priceData.rows &&
          priceData.rows.length > 0 &&
          priceData.rows.some(row => row.quantity?.proposed !== undefined) ? (
            <>
              {priceData.rows?.map((row, index) => (
                <Stack key={index}>{row.quantity?.proposed}</Stack>
              ))}
            </>
          ) : null,
      },
      UnitPrice: {
        render: (item, _, priceData) =>
          priceData.rows &&
          priceData.rows.length > 0 &&
          priceData.rows.some(row => row.unitPrice?.proposed !== undefined) ? (
            <>
              {priceData.rows?.map((row, index) => (
                <Stack key={index}>{row.unitPrice?.proposed}</Stack>
              ))}
            </>
          ) : null,
      },
      Subtotal: {
        render: (item, _, priceData) =>
          priceData.rows &&
          priceData.rows.length > 0 &&
          priceData.rows.some(row => row.subtotal?.proposed !== undefined) ? (
            <>
              {priceData.rows?.map((row, index) => (
                <Stack key={index}>{row.subtotal?.proposed}</Stack>
              ))}
            </>
          ) : null,
      },
      TotalPrice: {
        render: (item, _, priceData) => {
          return <>{priceData.total?.proposed}</>;
        },
      },
    }),
    [
      t,
      loading,
      docId,
      handleContingencyPreselectionChange,
      handleFinalizeContingencyDecision,
      expandedStore,
      collapseItemAndChildrenRecursively,
      handleDecisionPreselectionChange,
      canEdit,
      handleFinalizeSubitemDecision,
    ]
  );

  return (
    <DocumentPreviewItems<TemplateQuotePreviewItems_ItemFragment>
      doc={{
        id: docId,
        contractType,
        isBinding,
        agreement: "NONE",
      }}
      fields={fields}
      allDocItems={allDocItems}
      navigateToItem={navigateToItem}
      selectedItemId={selectedItemId}
      isTimeAndMaterialContract={isTimeAndMaterialContract}
      getItemStyle={getTemplateQuoteItemStyle}
      shouldRenderItem={shouldRenderTemplateQuoteItem}
      container={container}
      showCollapseButton={false}
      expandedStore={undefined}
    />
  );
};

const getTemplateQuoteItemStyle = (
  theme: Theme,
  item: TemplateQuotePreviewItems_ItemFragment
): React.CSSProperties | undefined => ({
  color:
    item.decisionOptionElimination ||
    item.isParentDecisionNotPreselected ||
    item.isParentContingencyNotPreselected ||
    (item.decisionIsContingentItem &&
      item.decisionContingentItemPreselection !== true)
      ? theme.palette.grey[600]
      : item.type === "paid" && item.binding === "non_binding"
        ? theme.palette.secondary.main
        : undefined,
  fontStyle:
    item.type === "paid" && item.binding === "non_binding"
      ? "italic"
      : undefined,
  textDecoration: item.deletedAt ? "line-through" : undefined,
});

function shouldRenderTemplateQuoteItem(
  item: TemplateQuotePreviewItems_ItemFragment
) {
  return !item.isItemEliminated && !item.isAnyParentItemEliminated;
}
