import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import {
  Box,
  Button,
  ButtonBase,
  ButtonProps,
  FilledInput,
  Popover,
  Stack,
  TextField,
} from "@mui/material";
import throttle from "lodash.throttle";
import {
  bindPopover,
  bindTrigger,
  usePopupState,
} from "material-ui-popup-state/es/hooks";
import React from "react";
import { HexColorPicker } from "react-colorful";
import { useLatest } from "react-use";
import {
  PopoverOpenButton,
  PopoverOpenButtonRef,
} from "../popover/PopoverOpenButton";
import { withStyles } from "../styles";

const isValid = (color: string) => color.match(/^#[a-f\d]{6}$/i);

const transparent = {
  backgroundImage: `linear-gradient(45deg, #999 25%, transparent 25%),
      linear-gradient(45deg, transparent 75%, #999 75%),
      linear-gradient(45deg, transparent 75%, #999 75%),
      linear-gradient(45deg, #999 25%, #fff 25%)`,
  backgroundSize: "6px 6px",
  backgroundPosition: "0 0, 0 0, -3px -3px, 3px 3px",
};

const ColorfulPopover = withStyles(Popover, {
  paper: {
    "& .react-colorful__saturation": {
      borderRadius: "0 !important",
    },
    "& .react-colorful__last-control": {
      borderRadius: "0 !important",
    },
  },
});

const ColorButtonBase = withStyles(
  ({
    $color,
    ...props
  }: React.ComponentProps<typeof ButtonBase> & { $color: string }) => (
    <ButtonBase {...props} />
  ),
  (theme, props) => ({
    root: {
      height: 18,
      width: 18,
      position: "relative",
      top: 8,
      marginRight: 8,
      flexGrow: 0,
      flexShrink: 0,
      borderRadius: `${theme.shape.borderRadius}px`,
      backgroundColor: isValid(props.$color) ? props.$color : "transparent",
      ...(!isValid(props.$color) ? transparent : undefined),
    },
  })
);

interface Props {
  id?: string;
  name?: string;
  error?: boolean;
  required?: boolean;
  disabled?: boolean;
  placeholder?: string;
  helperText?: string;
  label: string;
  value: string;
  onChange: (value: string) => void;
  fullWidth?: boolean;
}

export const ColorPicker = ({
  id,
  name,
  error,
  required,
  disabled,
  placeholder,
  helperText,
  label,
  value,
  onChange,
  fullWidth = true,
}: Props) => {
  const popupState = usePopupState({
    variant: "popover",
    popupId: "colorPickerPopover",
  });

  const onChangeLatest = useLatest(onChange);

  // We need to slightly throttle change callback from react-colorful, since it updates values too frequently
  const handleChange = React.useCallback(
    throttle((value: string) => {
      onChangeLatest.current(value);
    }, 100),
    [onChangeLatest]
  );

  return (
    <>
      <TextField
        id={id}
        name={name}
        error={error}
        helperText={helperText}
        required={required}
        disabled={disabled}
        fullWidth={fullWidth}
        label={label}
        placeholder={placeholder}
        value={value}
        onChange={event => {
          onChange(event.target.value);
        }}
        InputProps={{
          startAdornment: (
            <ColorButtonBase
              disabled={disabled}
              $color={value}
              {...bindTrigger(popupState)}
            ></ColorButtonBase>
          ),
        }}
      />
      <ColorfulPopover
        {...bindPopover(popupState)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <HexColorPicker color={value} onChange={handleChange} />
      </ColorfulPopover>
    </>
  );
};

export const ColorButtonPicker = ({
  value,
  onChange,
  buttonProps,
}: {
  value: string;
  onChange: (value: string) => void;
  buttonProps: ButtonProps;
}) => {
  const [open, setOpen] = React.useState<boolean>(false);
  const popoverOpenButtonRef = React.useRef<PopoverOpenButtonRef>();

  const [internalValue, setInternalValue] = React.useState<string>(value);

  const stateRef = useLatest({
    internalValue,
    value,
    onChange,
    setInternalValue,
  });

  // react on outside change
  React.useEffect(() => {
    if (stateRef.current.internalValue !== value) {
      setInternalValue(value);
    }
  }, [stateRef, value]);

  // We need to slightly throttle change callback from react-colorful, since it updates values too frequently
  const handleChange = React.useCallback(
    throttle((value: string) => {
      stateRef.current.setInternalValue(value);
    }, 100),
    [stateRef]
  );

  return (
    <PopoverOpenButton
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      popoverRef={popoverOpenButtonRef}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      content={
        <Stack
          spacing={1}
          p={1}
          sx={{
            "& .react-colorful__saturation": {
              borderRadius: "0 !important",
            },
            "& .react-colorful__last-control": {
              borderRadius: "0 !important",
            },
          }}
        >
          <FilledInput
            size="extra-small"
            placeholder="HEX"
            value={internalValue}
            onChange={e => {
              handleChange(e.target.value || "");
            }}
            startAdornment={
              <Box
                display="flex"
                width={"18px"}
                height={"18px"}
                sx={theme => ({
                  flexGrow: 0,
                  flexShrink: 0,
                  borderRadius: `${theme.shape.borderRadius}px`,
                  backgroundColor: isValid(internalValue)
                    ? internalValue
                    : "transparent",
                  ...(!isValid(internalValue) ? transparent : undefined),
                })}
              />
            }
          />
          <HexColorPicker color={internalValue} onChange={handleChange} />
          <Stack direction="row" justifyContent="flex-end" spacing={1}>
            <Button
              size="extra-small"
              color="primary"
              variant="text"
              onClick={() => popoverOpenButtonRef.current?.handleClose()}
            >
              <CloseIcon />
            </Button>
            <Button
              size="extra-small"
              color="primary"
              variant="contained"
              disabled={Boolean(!internalValue || !isValid(internalValue))}
              onClick={() => {
                onChange(internalValue);
                popoverOpenButtonRef.current?.handleClose();
              }}
            >
              <CheckIcon />
            </Button>
          </Stack>
        </Stack>
      }
    >
      <Button
        {...buttonProps}
        endIcon={
          <KeyboardArrowDown
            sx={{
              transition: "transform 0.2s ease-out",
              ...(open ? { transform: `rotate(-180deg)` } : undefined),
            }}
          />
        }
      >
        <Box
          display="flex"
          width={"18px"}
          height={"18px"}
          sx={theme => ({
            flexGrow: 0,
            flexShrink: 0,
            borderRadius: `${theme.shape.borderRadius}px`,
            backgroundColor: isValid(value) ? value : "transparent",
            ...(!isValid(value) ? transparent : undefined),
          })}
        />
      </Button>
    </PopoverOpenButton>
  );
};
