import { useElementSize, useScreenWidth } from "@msys/ui";
import React from "react";

const MIN_CARD_WIDTH = 220;

export type Columns = {
  xs: number;
  md: number;
  lg: number;
  xl: number;
};

export const useCardsNumber = () => {
  const { isMinTablet } = useScreenWidth();
  const sizeMeasureRef = React.useRef<HTMLDivElement | null>(null);
  const listSize = useElementSize(sizeMeasureRef);

  const spacing = isMinTablet ? 2 : 1;
  const gutterSize = spacing * 8;
  const minCardWidth = MIN_CARD_WIDTH;
  const cardsInRowNumber = listSize.width
    ? Math.floor((listSize.width + gutterSize) / (minCardWidth + gutterSize))
    : null;
  const cardsColumnsNumber = cardsInRowNumber
    ? Math.min(Math.max(Math.ceil(12 / cardsInRowNumber), 1), 12)
    : null;

  const columns = {
    xs: 6,
    ...(cardsColumnsNumber
      ? {
          md: cardsColumnsNumber,
          lg: cardsColumnsNumber,
          xl: cardsColumnsNumber,
        }
      : { md: 4, lg: 3, xl: 3 }),
  } as Columns;

  return { sizeMeasureRef, spacing, columns };
};
