import { notNull } from "@msys/common";
import {
  DebouncedSearchInput,
  ListHeader,
  MenuButton,
  MenuItemWithIcon,
  ModalOpenButton,
  useFormatting,
  useScreenWidth,
} from "@msys/ui";
import ChecklistIcon from "@mui/icons-material/Checklist";
import SettingsIcon from "@mui/icons-material/Settings";
import ViewKanbanIcon from "@mui/icons-material/ViewKanban";
import { Box, Stack } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import React from "react";
import {
  namedOperations,
  OrganisationProjectsSorting,
  ProjectFilterKind,
  ProjectStateMachineStatus,
} from "../../../clients/graphqlTypes";
import { RestrictedByOrganisationPermissionWithDebug } from "../../auth/RestrictedByOrganisationPermission";
import { useUserData } from "../../auth/useUserData";
import { SwitchCollectionViewButton } from "../../commons/button/SwitchCollectionViewButton";
import { FilterButton } from "../../commons/filters/FilterButton";
import { useFiltersAndPagination } from "../../commons/filters/useFiltersAndPagination";
import {
  CollectionView,
  useCollectionViewWithMobile,
} from "../../commons/hooks/useCollectionView";
import { Page, PageTopbarItem } from "../../commons/layout/Page";
import { PageContainer } from "../../commons/layout/PageContainer";
import { useCustomFieldConfig } from "../../features/custom-fields/useCustomFieldConfig";
import { ImportCustomGaebP84Button } from "../../features/importExport/gaebImport/ImportCustomGaebP84Button";
import { ImportXiopdButton } from "../../features/importExport/xiopdImport/ImportXiopdButton";
import { CreateProjectOrTicketButton } from "../../features/projects/buttons/CreateProjectOrTicketButton";
import { ProjectListItemDisplayConfig } from "../../features/projects/components/ProjectListItem";
import {
  defaultListItemDisplayConfig,
  ProjectListItemVisibilitySettingsModal,
} from "../../features/projects/components/ProjectListItemVisibilitySettingsModal";
import { FilterRelevantToMe } from "../../features/projects/filters";
import { FilterProjectAssigneesButton } from "../../features/projects/filters/ProjectAssignees";
import { FilterProjectBudgetButton } from "../../features/projects/filters/ProjectBudget";
import { FilterProjectKindButton } from "../../features/projects/filters/ProjectKind";
import { ProjectsFilterModal } from "../../features/projects/ProjectsFilterModal";
import { ProjectsKanban } from "../../features/projects/ProjectsKanban";
import {
  DEFAULT_SORTING,
  type Filters,
  ProjectsList,
} from "../../features/projects/ProjectsList";
import { renderProjectsFilterChips } from "../../features/projects/renderProjectsFilterChips";
import { PROJECT_FILTER_MAP } from "../../features/projects/useProjectSources";
import { ImportS4HanaSalesQuotationButton } from "../../features/sap-s4-hana/ImportS4HanaSalesQuotationButton";
import { useDataGridStateStore } from "../../features/users/useDataGridStateStore";
import { useUserPreference } from "../../features/users/useUserPreference";
import { useVisibilityStore } from "../../features/users/useVisibilityStore";

type ImportVisibilityKeys = "gaeb" | "xiopd";

const DEFAULT_ITEMS_PER_PAGE = 25;
const ALLOWED_VIEWS: CollectionView[] = ["table", "list", "kanban"];

interface Props {
  submenuItems: PageTopbarItem[];
}

export const ProjectsCurrent = ({ submenuItems }: Props) => {
  const { t } = useTranslate([
    "Projects",
    "Tickets",
    "Opportunities",
    "Global",
  ]);
  const viewer = useUserData().currentUser!;
  const { getFormattedPrice, getFormattedFloat } = useFormatting();
  const { isMinTablet } = useScreenWidth();

  const [isConfiguring, setIsConfiguring] = React.useState<boolean>(false);

  const [activeView, setActiveView] =
    useCollectionViewWithMobile<CollectionView>(
      "projects-current",
      "table",
      "list"
    );

  const {
    offset,
    limit,
    paginationModel,
    setPaginationModel,
    filters,
    setFilters,
    resetFilters,
    sorting,
    setSorting,
    toRemoveParams,
  } = useFiltersAndPagination<OrganisationProjectsSorting, Filters>(
    { kind: ["DEFAULT", "TICKET"] },
    DEFAULT_SORTING,
    DEFAULT_ITEMS_PER_PAGE
  );

  const baseVariables = React.useMemo(
    () => ({
      offset,
      limit,
      sorting: DEFAULT_SORTING,
    }),
    [limit, offset]
  );

  const allowedStates: ProjectStateMachineStatus[] = React.useMemo(
    () =>
      viewer.organisation.isClientOrganisation
        ? ["contracted", "opportunity"]
        : ["contracted"],
    [viewer.organisation.isClientOrganisation]
  );

  const variables = React.useMemo(
    () => ({
      ...baseVariables,
      ...filters,
      filterBySources: filters.sources
        ? filters.sources.map(s => PROJECT_FILTER_MAP[s]).flat(1)
        : undefined,
      filterIncludeState: filters.filterIncludeState?.length
        ? filters.filterIncludeState
        : allowedStates,
      sorting,
    }),
    [allowedStates, baseVariables, filters, sorting]
  );

  const { customFieldConfig: customFieldConfigs } =
    useCustomFieldConfig("Project");

  const stateStore = useDataGridStateStore("ProjectsCurrent");

  const importVisibilityStore = useVisibilityStore<ImportVisibilityKeys>(
    "ProjectsCurrent-Import"
  );
  const listItemDisplayConfigStore =
    useUserPreference<ProjectListItemDisplayConfig>(
      `DisplayConfig-ProjectsCurrent`,
      defaultListItemDisplayConfig
    );

  const menuItems = !importVisibilityStore.loading
    ? [
        <RestrictedByOrganisationPermissionWithDebug
          permission="CREATE_PROJECT"
          key="import-gaeb"
        >
          {importVisibilityStore.value.gaeb !== false ? (
            <ImportCustomGaebP84Button
              projectParams={{ isTentative: false }}
              refetchQueries={[namedOperations.Query.ProjectsList]}
            />
          ) : null}
        </RestrictedByOrganisationPermissionWithDebug>,
        <RestrictedByOrganisationPermissionWithDebug
          permission="CREATE_PROJECT"
          key="import-xiopd"
        >
          {importVisibilityStore.value.xiopd !== false ? (
            <ImportXiopdButton
              projectParams={{ isTentative: false }}
              refetchQueries={[namedOperations.Query.ProjectsList]}
            />
          ) : null}
        </RestrictedByOrganisationPermissionWithDebug>,
        viewer.organisation.enabledIntegrationIds.includes("sap_s4hana") ? (
          <RestrictedByOrganisationPermissionWithDebug
            permission="CREATE_PROJECT"
            key="import-s4-hana-salesquoation"
          >
            <ImportS4HanaSalesQuotationButton
              projectId={null}
              refetchQueries={[namedOperations.Query.ProjectsList]}
            />
          </RestrictedByOrganisationPermissionWithDebug>
        ) : null,
      ].filter(notNull)
    : [];

  const onKindChange = (
    value: ProjectFilterKind | null | undefined
  ): Partial<Filters> => ({
    kind: !value ? ["DEFAULT", "TICKET"] : [value],
    filterKind: value,
  });

  return (
    <Page
      title={t("Current", { ns: "Projects" })}
      subtitle={t("Projects", { ns: "Projects" })}
      submenuItems={submenuItems}
    >
      <PageContainer $fullHeight>
        <ListHeader
          // hideTitleOnMobile
          // title={t("My Projects", { ns: "Projects" })}
          QuickFilter={
            <Stack
              direction="row"
              spacing={1}
              alignItems="center"
              flexShrink={1}
              flexGrow={0}
            >
              {viewer.organisation.isCraftsmanOrganisation ? (
                <FilterProjectKindButton
                  value={filters.kind ?? []}
                  setValue={newValue =>
                    setFilters(state => ({
                      ...state,
                      kind: newValue,
                      filterKind:
                        newValue.length === 0 || newValue.length === 2
                          ? null
                          : newValue[0],
                    }))
                  }
                />
              ) : undefined}
              <FilterProjectBudgetButton
                filters={filters}
                setFilters={setFilters}
                variables={variables}
              />
              <FilterProjectAssigneesButton
                value={filters.filterAssigneeIds ?? []}
                setValue={(newValue: string[]) => {
                  setFilters(filters => ({
                    ...filters,
                    filterAssigneeIds: newValue,
                  }));
                }}
              />
            </Stack>
          }
          SwitchViewButton={
            <SwitchCollectionViewButton
              allowedViews={ALLOWED_VIEWS.filter(view => {
                if (
                  viewer.organisation.isClientOrganisation &&
                  view === "kanban"
                )
                  return false;
                return true;
              })}
              activeView={activeView}
              setActiveView={setActiveView}
            />
          }
          CreateButton={
            <RestrictedByOrganisationPermissionWithDebug permission="CREATE_PROJECT">
              <CreateProjectOrTicketButton
                pageName="Projects"
                type="button"
                initialCreateType={
                  filters.kind &&
                  filters.kind.length === 1 &&
                  filters.kind.at(0)! === "TICKET"
                    ? "ticket"
                    : "project"
                }
              />
            </RestrictedByOrganisationPermissionWithDebug>
          }
          FilterButton={
            <ModalOpenButton
              Modal={ProjectsFilterModal}
              modalProps={{
                sorting,
                setSorting,
                filters,
                setFilters,
                resetFilters,
                customFieldConfigs,
                allowedStates,
                onKindChange,
                showPhasesFilter: activeView !== "kanban",
                showSorting: activeView !== "kanban",
                showRelevantToMe: !isMinTablet,
              }}
            >
              <FilterButton />
            </ModalOpenButton>
          }
          FilterFields={
            isMinTablet ? (
              <Box display="flex" alignItems="center" height={0} pl={1.5}>
                <FilterRelevantToMe filters={filters} setFilters={setFilters} />
              </Box>
            ) : undefined
          }
          SearchField={
            <DebouncedSearchInput
              placeholder={t("Search", { ns: "Global" })}
              defaultValue={filters.filterSearchTerm}
              onChangeSearchTerm={(newValue: string) => {
                setFilters(filters => ({
                  ...filters,
                  filterSearchTerm: newValue,
                }));
              }}
            />
          }
          FilterChips={renderProjectsFilterChips({
            viewer,
            t,
            filters,
            setFilters,
            getFormattedPrice,
            getFormattedFloat,
            allowedStates,
            onKindChange,
            showRelevantToMe: !isMinTablet,
            showPhasesChip: activeView !== "kanban",
          })}
          ActionButtons={
            <MenuButton Icon={<SettingsIcon />}>
              <ModalOpenButton
                Modal={ProjectListItemVisibilitySettingsModal}
                modalProps={{
                  importVisibilityStore,
                  listItemDisplayConfigStore,
                  labels: {
                    xiopd: t("{fileFormat} files", {
                      ns: "Global",
                      fileFormat: "xi:opd",
                    }),
                    gaeb: t("{fileFormat} files", {
                      ns: "Global",
                      fileFormat: "GAEB",
                    }),
                  },
                }}
              >
                <MenuItemWithIcon icon={<ChecklistIcon />}>
                  {t("Projects view settings", { ns: "Projects" })}
                </MenuItemWithIcon>
              </ModalOpenButton>
              {activeView === "kanban" && (
                <RestrictedByOrganisationPermissionWithDebug permission="MANAGE_ORG">
                  <MenuItemWithIcon
                    disabled={isConfiguring}
                    icon={<ViewKanbanIcon />}
                    onClick={() => {
                      setIsConfiguring(true);
                    }}
                  >
                    {t("Configure pipeline", { ns: "Opportunities" })}
                  </MenuItemWithIcon>
                </RestrictedByOrganisationPermissionWithDebug>
              )}
            </MenuButton>
          }
          MenuButton={
            menuItems.length > 0 ? (
              <MenuButton>{menuItems}</MenuButton>
            ) : undefined
          }
          mb={2}
        />
        {!listItemDisplayConfigStore.loading &&
          (activeView === "kanban" ? (
            <ProjectsKanban
              variables={variables}
              displayConfig={listItemDisplayConfigStore.value}
              isConfiguring={isConfiguring}
              setIsConfiguring={setIsConfiguring}
            />
          ) : (
            <ProjectsList
              sorting={sorting}
              setSorting={setSorting}
              activeView={activeView}
              pathToPage={"/projects/current"}
              paginationModel={paginationModel}
              setPaginationModel={setPaginationModel}
              showStatus={allowedStates.length > 1}
              showPhase={true}
              stateStore={stateStore}
              variables={variables}
              displayConfig={listItemDisplayConfigStore.value}
            />
          ))}
      </PageContainer>
    </Page>
  );
};
