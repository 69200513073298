/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import { Props2NonComputedKeyLabelFragmentDoc } from '../../doc-items/properties.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TemplatesQuoteFilterChips_TemplateTypesQueryVariables = Types.Exact<{
  ids: Array<Types.Scalars['ID']['input']>;
}>;


export type TemplatesQuoteFilterChips_TemplateTypesQuery = { templateTypes: { __typename: 'MissingCapabilities' } | { __typename: 'MissingPermissions' } | { __typename: 'TemplateTypesResult', templateTypes: Array<{ __typename: 'TemplateType', id: string, title: string, props2: Array<{ __typename: 'Props2Bool', key: string, label: string } | { __typename: 'Props2Number', key: string, label: string } | { __typename: 'Props2NumberArray', key: string, label: string } | { __typename: 'Props2Text', key: string, label: string } | { __typename: 'Props2TextArray', key: string, label: string }> }> } };


export const TemplatesQuoteFilterChips_TemplateTypesDocument = gql`
    query TemplatesQuoteFilterChips_TemplateTypes($ids: [ID!]!) {
  templateTypes(ids: $ids) {
    ... on TemplateTypesResult {
      templateTypes {
        id
        title
        props2 {
          ...Props2NonComputedKeyLabel
        }
      }
    }
  }
}
    ${Props2NonComputedKeyLabelFragmentDoc}`;

/**
 * __useTemplatesQuoteFilterChips_TemplateTypesQuery__
 *
 * To run a query within a React component, call `useTemplatesQuoteFilterChips_TemplateTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTemplatesQuoteFilterChips_TemplateTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTemplatesQuoteFilterChips_TemplateTypesQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useTemplatesQuoteFilterChips_TemplateTypesQuery(baseOptions: Apollo.QueryHookOptions<TemplatesQuoteFilterChips_TemplateTypesQuery, TemplatesQuoteFilterChips_TemplateTypesQueryVariables> & ({ variables: TemplatesQuoteFilterChips_TemplateTypesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TemplatesQuoteFilterChips_TemplateTypesQuery, TemplatesQuoteFilterChips_TemplateTypesQueryVariables>(TemplatesQuoteFilterChips_TemplateTypesDocument, options);
      }
export function useTemplatesQuoteFilterChips_TemplateTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TemplatesQuoteFilterChips_TemplateTypesQuery, TemplatesQuoteFilterChips_TemplateTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TemplatesQuoteFilterChips_TemplateTypesQuery, TemplatesQuoteFilterChips_TemplateTypesQueryVariables>(TemplatesQuoteFilterChips_TemplateTypesDocument, options);
        }
export function useTemplatesQuoteFilterChips_TemplateTypesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<TemplatesQuoteFilterChips_TemplateTypesQuery, TemplatesQuoteFilterChips_TemplateTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TemplatesQuoteFilterChips_TemplateTypesQuery, TemplatesQuoteFilterChips_TemplateTypesQueryVariables>(TemplatesQuoteFilterChips_TemplateTypesDocument, options);
        }
export type TemplatesQuoteFilterChips_TemplateTypesQueryHookResult = ReturnType<typeof useTemplatesQuoteFilterChips_TemplateTypesQuery>;
export type TemplatesQuoteFilterChips_TemplateTypesLazyQueryHookResult = ReturnType<typeof useTemplatesQuoteFilterChips_TemplateTypesLazyQuery>;
export type TemplatesQuoteFilterChips_TemplateTypesSuspenseQueryHookResult = ReturnType<typeof useTemplatesQuoteFilterChips_TemplateTypesSuspenseQuery>;
export type TemplatesQuoteFilterChips_TemplateTypesQueryResult = Apollo.QueryResult<TemplatesQuoteFilterChips_TemplateTypesQuery, TemplatesQuoteFilterChips_TemplateTypesQueryVariables>;