import { ModalOpenButton, useScreenWidth } from "@msys/ui";
import LogoutIcon from "@mui/icons-material/ExitToApp";
import { Button, ButtonProps, IconButton } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { ConfirmModal } from "../commons/modals/ConfirmModal";
import { assertNever } from "../utils";
import { useAuth } from "./useAuth";

export const LogoutButton = ({
  color = "warning",
  type: _type,
}: {
  color?: ButtonProps["color"];
  type?: "icon" | "button";
}) => {
  const { auth } = useAuth();
  const { t } = useTranslate("SidebarItems");
  const { isMaxPhone } = useScreenWidth();
  const type = _type ?? (isMaxPhone ? "icon" : "button");

  return (
    <ModalOpenButton
      Modal={ConfirmModal}
      modalProps={{
        title: t("Are you sure you want to log out?"),
        handleConfirm: async () => {
          await auth.logout();
        },
      }}
    >
      {type === "icon" ? (
        <IconButton color={color}>
          <LogoutIcon />
        </IconButton>
      ) : type === "button" ? (
        <Button
          fullWidth
          startIcon={<LogoutIcon />}
          variant="outlined"
          color={color}
        >
          {t("Log out")}
        </Button>
      ) : (
        assertNever(type)
      )}
    </ModalOpenButton>
  );
};
