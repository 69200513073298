import { CollapseSection, Select } from "@msys/ui";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Box,
  Button,
  IconButton,
  MenuItem,
  Select as SelectMui,
  Stack,
  Switch,
} from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { isEqual } from "lodash";
import moment from "moment";
import React from "react";
import {
  CustomFieldConfig,
  CustomFieldFilter,
} from "../../../../clients/graphqlTypes";
import {
  FilterByDate,
  FilterByNumber,
  FilterByText,
} from "../../../commons/filters";
import { CustomFieldConfigFragment } from "../customFieldConfigs.generated";
import { getExistingOperator, getHumanReadableOperator } from "../helpers";

export const FilterCustomFields: React.FC<{
  fieldConfigs: CustomFieldConfigFragment[];
  values: CustomFieldFilter[] | CustomFieldFilter;
  setValues: (values: CustomFieldFilter[]) => void;
}> = ({ fieldConfigs, values, setValues }) => {
  const { t } = useTranslate("CrmOrganisations");

  const [currentValues, setCurrentValues] = React.useState(
    Array.isArray(values) ? values : [values]
  );

  const availableFieldConfigs = fieldConfigs;

  function setCurrentValueItem(
    index: number,
    key: string,
    operator: Operator,
    value: string | boolean | null | undefined
  ) {
    const newItem = {
      key,
      [operator]: value,
    };

    const newValues = [
      ...currentValues.slice(0, index),
      newItem,
      ...currentValues.slice(index + 1),
    ];

    setCurrentValues(newValues);

    const validNewValues = newValues.filter(
      value =>
        value.key &&
        value[getExistingOperator(value)] !== undefined &&
        value[getExistingOperator(value)] !== ""
    );

    if (!isEqual(validNewValues, values)) {
      setValues(validNewValues);
    }
  }

  const valuesDefinedCount = currentValues.filter((value, index) =>
    Boolean(
      availableFieldConfigs.find(fieldConfig => fieldConfig.key === value.key)
    )
  ).length;

  return availableFieldConfigs.length > 0 ? (
    <CollapseSection
      title={t("Custom fields")}
      isInitiallyExpanded={valuesDefinedCount > 0}
      itemCount={valuesDefinedCount}
    >
      <Stack direction="column" spacing={1}>
        {currentValues.map((value, index) => {
          const operator = getExistingOperator(value);
          const cfg = availableFieldConfigs.find(
            fieldConfig => fieldConfig.key === value.key
          );
          if (!cfg) return null;
          return (
            <Stack
              key={`${index}-${value.key}`}
              direction="row"
              spacing={1}
              alignItems="center"
            >
              <Box flex={1} minWidth={0}>
                <Select
                  label={t("Key")}
                  value={value.key}
                  onChange={v => {
                    setCurrentValueItem(index, v, operator, value[operator]);
                  }}
                  options={availableFieldConfigs.map(fieldConfig => ({
                    label: fieldConfig.key,
                    value: fieldConfig.key,
                  }))}
                />
              </Box>
              <Box flex={1} minWidth={0}>
                <Select
                  label={t("Operator")}
                  value={operator}
                  onChange={v => {
                    setCurrentValueItem(index, value.key, v, value[operator]);
                  }}
                  options={getPossibleOperators(
                    value,
                    availableFieldConfigs
                  ).map(operator => ({
                    label: getHumanReadableOperator(operator),
                    value: operator,
                  }))}
                />
              </Box>
              <Box flex={1} minWidth={0}>
                <FilterCustomField
                  cfg={cfg}
                  dataType={cfg.dataType ?? "t_text"}
                  value={value[operator]}
                  setValue={(newValue: string | boolean | null | undefined) =>
                    setCurrentValueItem(index, value.key, operator, newValue)
                  }
                />
              </Box>
              <IconButton
                size="small"
                color="primary"
                onClick={() => {
                  const newValues = currentValues.filter((v, i) => i !== index);
                  setCurrentValues(newValues);
                  setValues(newValues);
                }}
              >
                <DeleteIcon />
              </IconButton>
            </Stack>
          );
        })}
        <Box>
          <Button
            onClick={() =>
              setCurrentValues(values => [
                ...values,
                { key: availableFieldConfigs[0].key },
              ])
            }
            startIcon={<AddIcon />}
            size="extra-small"
            color="secondary"
          >
            {t("Add")}
          </Button>
        </Box>
      </Stack>
    </CollapseSection>
  ) : null;
};

function getPossibleOperators(
  value: any,
  fieldConfigs: Pick<CustomFieldConfig, "key" | "dataType">[]
): Operator[] {
  if (!value.key) return [];

  const dataType = fieldConfigs.find(
    fieldConfig => fieldConfig.key === value.key
  )?.dataType;
  if (!dataType) return [];

  if (dataType === "t_boolean") {
    return ["eq"];
  }

  if (
    dataType === "t_text" ||
    dataType === "t_external_url" ||
    dataType === "t_youtube_video_id"
  ) {
    return ["eq", "ilike"];
  }

  return ["eq", "gt", "gte", "lt", "lte"];
}

type FilterCustomFieldProps =
  | ({
      dataType: "t_boolean";
    } & FilterCustomFieldItemProps<boolean>)
  | ({
      dataType:
        | "t_text"
        | "t_date"
        | "t_external_url"
        | "t_youtube_video_id"
        | "t_number"
        | "t_m"
        | "t_cm"
        | "t_mm";
    } & FilterCustomFieldItemProps<string>);

type Operator = Extract<
  "eq" | "ilike" | "gt" | "gte" | "lt" | "lte",
  keyof CustomFieldFilter
>;

const FilterCustomField = (props: FilterCustomFieldProps) => {
  switch (props.dataType) {
    case "t_boolean":
      return <FilterCustomFieldBoolean {...props} />;
    case "t_m":
    case "t_cm":
    case "t_mm":
    case "t_number":
      return <FilterCustomFieldNumber {...props} />;
    case "t_date":
      return <FilterCustomFieldDate {...props} />;
    case "t_external_url":
    case "t_youtube_video_id":
    case "t_text":
      return <FilterCustomFieldText {...props} />;
    default:
      // @ts-ignore FIXME: weird typescript issue with "Spread types may only be created from object types"
      return <FilterCustomFieldText {...props} />;
  }
};

interface FilterCustomFieldItemProps<T> {
  cfg: CustomFieldConfigFragment;
  value: T | null | undefined;
  setValue: (value: T | null | undefined) => void;
}

const FilterCustomFieldNumber = (props: FilterCustomFieldItemProps<string>) => {
  const { t } = useTranslate("CrmOrganisations");

  const { value, setValue, ...rest } = props;

  return (
    <FilterByNumber
      label={t("value")}
      placeholder={t("value")}
      value={value ? JSON.parse(value) : 0}
      setValue={value => setValue(JSON.stringify(value))}
      {...rest}
    />
  );
};

const FilterCustomFieldDate = (props: FilterCustomFieldItemProps<string>) => {
  const { t } = useTranslate("CrmOrganisations");

  const { value, setValue, ...rest } = props;

  return (
    <FilterByDate
      label={t("value")}
      value={value ? moment(JSON.parse(value)) : null}
      setValue={value =>
        setValue(value && JSON.stringify(value.format("YYYY-MM-DD")))
      }
      {...rest}
    />
  );
};

const FilterCustomFieldBoolean = (
  props: FilterCustomFieldItemProps<boolean>
) => {
  const { value, setValue, ...rest } = props;

  return (
    <Switch
      value={value}
      onChange={(event, checked) => {
        setValue(checked);
      }}
      {...rest}
    />
  );
};

const FilterCustomFieldText = (props: FilterCustomFieldItemProps<string>) => {
  const { t } = useTranslate("CrmOrganisations");

  const { cfg, value, setValue, ...rest } = props;

  if (cfg.dataType === "t_text" && cfg.allowedValues) {
    return (
      <SelectMui
        fullWidth
        value={value ? JSON.parse(value) : ""}
        onChange={e => {
          setValue(e.target.value ? JSON.stringify(e.target.value) : undefined);
        }}
      >
        <MenuItem
          key={"--empty--"}
          value={""}
          style={{ minHeight: "36px" }}
        ></MenuItem>
        {cfg.allowedValues.split("\n").map(allowedValue => (
          <MenuItem
            key={allowedValue}
            value={allowedValue}
            style={{ minHeight: "36px" }}
          >
            {allowedValue}
          </MenuItem>
        ))}
      </SelectMui>
    );
  }
  return (
    <FilterByText
      label={t("value")}
      placeholder={t("value")}
      value={value ? JSON.parse(value) : undefined}
      setValue={value => setValue(value ? JSON.stringify(value) : undefined)}
      {...rest}
    />
  );
};
