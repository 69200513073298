import { notNull } from "@msys/common";
import { FormattedPrice, useFormatting } from "@msys/ui";
import PersonIcon from "@mui/icons-material/Person";
import PersonPinIcon from "@mui/icons-material/PersonPin";
import { Box, Stack, Tooltip, Typography, useTheme } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import React from "react";
import { OrganisationProjectPhase } from "../../../../clients/graphqlTypes";
import { RestrictedByProjectPermissionWithDebug } from "../../../auth/RestrictedByProjectPermission";
import { useUserData } from "../../../auth/useUserData";
import { ActionLine } from "../../../commons/DataItem";
import { getAddressLabel } from "../../addresses/helpers";
import {
  RelationshipRole,
  useRelationshipRoles,
} from "../../contact-links/useRelationshipRoles";
import { ProjectStatusBadge } from "../../projects/badges/ProjectStatusBadge";
import { ProjectAssigneeEditButton } from "../../projects/ProjectAssigneeEditButton";
import { ProjectIcon } from "../../projects/ProjectIcon";
import { ProjectPhaseChip } from "../../projects/ProjectPhaseChip";
import { TicketUrgentBadge } from "../../projects/TicketStatusBadge";
import { useProjectTypes } from "../../projects/useProjectTypes";
import { useCategories } from "../../skill-categories/useCategories";
import { getPersonLabel } from "../../users/helpers";
import { useQuotingStatus } from "../useQuotingStatus";
import { OpportunityListItemFragment } from "./OpportunityListItem.generated";

export interface OpportunityListItemDisplayConfig {
  clientOrganisation?: boolean;
  clientMainContact?: boolean;
  clientEmail?: boolean;
  clientPhone?: boolean;
  projectNumber?: boolean;
  projectCreatedDate?: boolean;
  projectDueDate?: boolean;
  projectAddress?: boolean;
  projectBudget?: boolean;
  projectCategories?: boolean;
  projectType?: boolean;
  projectLeadId?: boolean;
  projectEarliestStart?: boolean;
  projectEarliestPlanSessionDate?: boolean;
  projectAssignees?: boolean;
  projectQuotingStatus?: boolean;
  crmRoles?: RelationshipRole[];
}

interface Props {
  project: OpportunityListItemFragment;
  Action?: React.ReactElement;
  showAssignee?: boolean;
  showStatus?: boolean;
  showPhase?: boolean;
  availablePhases?: OrganisationProjectPhase[];
  displayConfig?: OpportunityListItemDisplayConfig;
}

export const OpportunityListItem = ({
  project,
  Action,
  showAssignee = false,
  showStatus = false,
  showPhase = false,
  availablePhases = [],
  displayConfig,
}: Props) => {
  const { t } = useTranslate(["DataItem", "Projects"]);
  const viewer = useUserData().currentUser!;

  const theme = useTheme();
  const { getFormattedDate, getFormattedDateTime } = useFormatting();

  const { projectTypeLabels } = useProjectTypes();
  const { categoryLabels } = useCategories();
  const { getRoleLabel } = useRelationshipRoles();
  const { quotingStatusLabels } = useQuotingStatus();

  const clientOrganisation = project.crmOrganisation?.title;
  const clientMainContact = project.crmOrganisation?.contactPerson
    ? getPersonLabel(project.crmOrganisation?.contactPerson, true) || undefined
    : undefined;
  const clientEmail = project.crmOrganisation?.email;
  const clientPhone = project.crmOrganisation?.phones?.[0]?.number;

  const projectAssignees =
    project.assignees && project.assignees?.length
      ? getPersonLabel(project.assignees[0]!, true) +
        (project.assignees.length > 1
          ? ` +${project.assignees.length - 1}`
          : "")
      : undefined;
  const projectNumber = project.number;
  const projectDueDate = project.deadline
    ? getFormattedDate(project.deadline)
    : undefined;
  const projectEarliestStart = project.earliestStart
    ? getFormattedDate(project.earliestStart)
    : undefined;
  const projectEarliestPlanSessionDate = project.earliestPlanSessionDate
    ? getFormattedDate(project.earliestPlanSessionDate)
    : undefined;
  const projectCreatedDate = project.createdAt
    ? getFormattedDateTime(project.createdAt)
    : "-";
  const projectAddress = project.buildingInfo?.buildingAddress
    ? getAddressLabel(project.buildingInfo?.buildingAddress, ", ")
    : undefined;
  const projectBudget = project.budget ? (
    <FormattedPrice value={project.budget} />
  ) : undefined;
  const projectType = project.type
    ? projectTypeLabels[project.type]
    : undefined;
  const projectCategories =
    project.categories && project.categories.length
      ? project.categories.map(c => categoryLabels[c]).join(", ")
      : undefined;

  const ClientLine = [
    (displayConfig?.clientOrganisation ?? true) && clientOrganisation ? (
      <Tooltip
        title={t("Company", { ns: "DataItem" })}
        key="clientOrganisation"
        arrow
        placement="bottom"
      >
        <span>{clientOrganisation}</span>
      </Tooltip>
    ) : null,
    (displayConfig?.clientMainContact ?? false) && clientMainContact ? (
      <Tooltip
        title={t("Main contact", { ns: "DataItem" })}
        key="clientMainContact"
        arrow
        placement="bottom"
      >
        <span>{clientMainContact}</span>
      </Tooltip>
    ) : null,
    (displayConfig?.clientEmail ?? false) && clientEmail ? (
      <Tooltip
        title={t("Email", { ns: "DataItem" })}
        key="clientEmail"
        arrow
        placement="bottom"
      >
        <span>{clientEmail}</span>
      </Tooltip>
    ) : null,
    (displayConfig?.clientPhone ?? false) && clientPhone ? (
      <Tooltip
        title={t("Phone", { ns: "DataItem" })}
        key="clientPhone"
        arrow
        placement="bottom"
      >
        <span>{clientPhone}</span>
      </Tooltip>
    ) : null,
  ].filter(notNull);

  const ProjectLine = [
    displayConfig?.projectNumber ?? false ? (
      <Tooltip
        title={t("Project number", { ns: "Projects" })}
        key="projectNumber"
        arrow
        placement="bottom"
      >
        <span>{projectNumber}</span>
      </Tooltip>
    ) : null,
    viewer.organisation.capabilities.includes("QUOTING") &&
    (displayConfig?.projectLeadId ?? false) &&
    project.leadId ? (
      <Tooltip
        title={t("Lead ID", { ns: "Projects" })}
        key="projectLeadId"
        arrow
        placement="bottom"
      >
        <span>{project.leadId}</span>
      </Tooltip>
    ) : null,
    (displayConfig?.projectAddress ?? true) && projectAddress ? (
      <Tooltip
        title={t("Address", { ns: "DataItem" })}
        key="projectAddress"
        arrow
        placement="bottom"
      >
        <span>{projectAddress}</span>
      </Tooltip>
    ) : null,
    (displayConfig?.projectBudget ?? false) && projectBudget ? (
      <RestrictedByProjectPermissionWithDebug
        permission="READ_QUOTES"
        project={project}
        key="projectBudget"
      >
        <Tooltip
          title={t("Budget", { ns: "DataItem" })}
          arrow
          placement="bottom"
        >
          <span>{projectBudget}</span>
        </Tooltip>
      </RestrictedByProjectPermissionWithDebug>
    ) : null,
    displayConfig?.projectCreatedDate ?? false ? (
      <Tooltip
        title={t("Created date", { ns: "DataItem" })}
        key="projectCreatedDate"
        arrow
        placement="bottom"
      >
        <span>{projectCreatedDate}</span>
      </Tooltip>
    ) : null,
    (displayConfig?.projectEarliestStart ?? true) && projectEarliestStart ? (
      <Tooltip
        title={t("Earliest start", { ns: "DataItem" })}
        key="projectEarliestStart"
        arrow
        placement="bottom"
      >
        <span>{projectEarliestStart}</span>
      </Tooltip>
    ) : null,
    (displayConfig?.projectDueDate ?? true) && projectDueDate ? (
      <Tooltip
        title={t("Deadline", { ns: "DataItem" })}
        key="projectDueDate"
        arrow
        placement="bottom"
      >
        <span
          style={{
            color: project.overdue ? theme.palette.error.main : undefined,
          }}
        >
          {projectDueDate}
        </span>
      </Tooltip>
    ) : null,
    (displayConfig?.projectCategories ?? true) && projectCategories ? (
      <Tooltip
        title={t("Categories", { ns: "DataItem" })}
        key="projectCategories"
        arrow
        placement="bottom"
      >
        <span>{projectCategories}</span>
      </Tooltip>
    ) : null,
    (displayConfig?.projectType ?? true) && projectType ? (
      <Tooltip
        title={t("Type", { ns: "DataItem" })}
        key="projectType"
        arrow
        placement="bottom"
      >
        <span>{projectType}</span>
      </Tooltip>
    ) : null,
    viewer.organisation.capabilities.includes("PLANNING") &&
    (displayConfig?.projectEarliestPlanSessionDate ?? false) &&
    projectEarliestPlanSessionDate ? (
      <Tooltip
        title={t("Earliest planned work session", { ns: "DataItem" })}
        key="projectEarliestPlanSessionDate"
        arrow
        placement="bottom"
      >
        <span>{projectEarliestPlanSessionDate}</span>
      </Tooltip>
    ) : null,
    viewer.organisation.capabilities.includes("PLANNING") &&
    (displayConfig?.projectAssignees ?? false) &&
    projectAssignees ? (
      <Tooltip
        title={t("Assigned workers", { ns: "DataItem" })}
        arrow
        placement="bottom"
        key="projectAssignees"
      >
        <span>{projectAssignees}</span>
      </Tooltip>
    ) : null,
    viewer.organisation.capabilities.includes("QUOTING") &&
    (displayConfig?.projectQuotingStatus ?? false) ? (
      <Tooltip
        title={t("Quoting status", { ns: "Opportunities" })}
        arrow
        placement="bottom"
        key="projectQuotingStatus"
      >
        <span>{quotingStatusLabels[project.quotingStatus]}</span>
      </Tooltip>
    ) : null,
  ].filter(notNull);

  const ContactsLine =
    displayConfig?.crmRoles && displayConfig?.crmRoles.length > 0
      ? project.crmLinks
          .filter(contact =>
            displayConfig?.crmRoles?.includes(
              contact.linkAs as RelationshipRole
            )
          )
          .map(contact =>
            contact.__typename === "ProjectLinkCrmUser" ? (
              <Tooltip
                title={getRoleLabel(contact.linkAs) ?? contact.linkAs}
                key={`contact-${contact.crmUser.id}-${contact.linkAs}`}
                arrow
                placement="bottom"
              >
                <span>{contact.crmUser.fullname}</span>
              </Tooltip>
            ) : (
              <Tooltip
                title={getRoleLabel(contact.linkAs) ?? contact.linkAs}
                key={`contact-${contact.crmOrganisation.id}-${contact.linkAs}`}
                arrow
                placement="bottom"
              >
                <span>{contact.crmOrganisation.title}</span>
              </Tooltip>
            )
          )
      : [];

  const AssigneeButton = showAssignee ? (
    <RestrictedByProjectPermissionWithDebug
      permission="MANAGE_PROJECT"
      project={project}
      otherwise={
        <ProjectAssigneeEditButton
          assignee={project.assignee ?? null}
          projectId={project.id}
          projectTitle={project.title}
          readOnly={true}
          type="icon"
        />
      }
    >
      <ProjectAssigneeEditButton
        assignee={project.assignee ?? null}
        projectId={project.id}
        projectTitle={project.title}
        type="icon"
      />
    </RestrictedByProjectPermissionWithDebug>
  ) : null;

  return (
    <Stack direction="column" spacing={0.5} minWidth={0}>
      <Stack
        direction="row"
        spacing={1}
        minWidth={0}
        justifyContent="space-between"
      >
        <Typography
          variant={"h4"}
          sx={{ overflowWrap: "break-word", minWidth: "40px" }}
        >
          <span>{project.title}</span>
          {project.urgent && (
            <span
              style={{
                display: "inline-flex",
                marginLeft: "8px",
                verticalAlign: "middle",
              }}
            >
              <TicketUrgentBadge small />
            </span>
          )}
          {showStatus && (
            <span
              style={{
                display: "inline-flex",
                marginLeft: "8px",
                verticalAlign: "middle",
              }}
            >
              <ProjectStatusBadge projectState={project.state} small />
            </span>
          )}
          {showPhase && (
            <span
              style={{
                display: "inline-flex",
                marginLeft: "8px",
                verticalAlign: "middle",
              }}
            >
              <ProjectPhaseChip
                projectId={project.id}
                currentState={project.state}
                currentPhase={project.phase}
                availablePhases={availablePhases}
              />
            </span>
          )}
        </Typography>
        {Action || AssigneeButton ? (
          <ActionLine>
            {AssigneeButton}
            {Action}
          </ActionLine>
        ) : null}
      </Stack>

      {ClientLine.length > 0 && (
        <Typography variant="caption" component="div" color="textSecondary">
          <Stack direction="row" alignItems="center" spacing={0.5}>
            <Box
              sx={theme => ({
                display: "flex",
                flexGrow: 0,
                flexShrink: 0,
                alignSelf: "flex-start",
                height: `calc(${theme.typography.caption.fontSize} * ${theme.typography.caption.lineHeight})`,
              })}
            >
              <PersonPinIcon style={{ fontSize: "15px", flexShrink: 0 }} />
            </Box>
            <Box minWidth={0} sx={{ overflowWrap: "break-word" }}>
              {ClientLine.map((part, index) => (
                <React.Fragment key={index}>
                  {index > 0 ? " • " : ""}
                  {part}
                </React.Fragment>
              ))}
            </Box>
          </Stack>
        </Typography>
      )}

      {ProjectLine.length > 0 && (
        <Typography variant="caption" component="div" color="textSecondary">
          <Stack direction="row" alignItems="center" spacing={0.5}>
            <Box
              sx={theme => ({
                display: "flex",
                flexGrow: 0,
                flexShrink: 0,
                alignSelf: "flex-start",
                height: `calc(${theme.typography.caption.fontSize} * ${theme.typography.caption.lineHeight})`,
              })}
            >
              <ProjectIcon style={{ fontSize: "15px", flexShrink: 0 }} />
            </Box>
            <Box minWidth={0} sx={{ overflowWrap: "break-word" }}>
              {ProjectLine.map((part, index) => (
                <React.Fragment key={index}>
                  {index > 0 ? " • " : ""}
                  {part}
                </React.Fragment>
              ))}
            </Box>
          </Stack>
        </Typography>
      )}

      {ContactsLine.length > 0 && (
        <Typography variant="caption" component="div" color="textSecondary">
          <Stack direction="row" alignItems="center" spacing={0.5}>
            <Box
              sx={theme => ({
                display: "flex",
                flexGrow: 0,
                flexShrink: 0,
                alignSelf: "flex-start",
                height: `calc(${theme.typography.caption.fontSize} * ${theme.typography.caption.lineHeight})`,
              })}
            >
              <PersonIcon style={{ fontSize: "15px", flexShrink: 0 }} />
            </Box>
            <Box minWidth={0} sx={{ overflowWrap: "break-word" }}>
              {ContactsLine.map((part, index) => (
                <React.Fragment key={index}>
                  {index > 0 ? " • " : ""}
                  {part}
                </React.Fragment>
              ))}
            </Box>
          </Stack>
        </Typography>
      )}
    </Stack>
  );
};
