import { SelectMultiple } from "@msys/ui";
import { FilterProps } from "../../../commons/filters";
import {
  FilterChipGroup,
  FilterChipGroupProps,
} from "../../../commons/filters/FilterChip";
import { Skill } from "../../../../clients/graphqlTypes";
import { useTranslate } from "@tolgee/react";
import { useCategories } from "../../skill-categories/useCategories";

export function FilterProjectCategory({
  value,
  setValue,
  ...props
}: FilterProps<Skill[]>) {
  const { t } = useTranslate("Categories");
  const { allCategories } = useCategories();

  return (
    <SelectMultiple
      label={t("Categories")}
      options={allCategories.map(({ value, name }) => ({ label: name, value }))}
      value={Array.isArray(value) ? value : [value]}
      onChange={setValue}
      {...props}
    />
  );
}

export function FilterProjectCategoryChips(
  props: Omit<FilterChipGroupProps<Skill>, "label" | "getValueLabel">
) {
  const { t } = useTranslate("Categories");
  const { categoryLabels } = useCategories();

  return (
    <FilterChipGroup
      {...props}
      getValueLabel={value => categoryLabels[value]}
      label={t("Category")}
    />
  );
}
