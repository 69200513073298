import { useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import { TitleWithPathForPdf, useScreenWidth } from "@msys/ui";
import React from "react";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import {
  AskWhom,
  ItemType,
  namedOperations,
} from "../../../clients/graphqlTypes";
import { ViewMode } from "../../commons/ViewModeMenuItem";
import { usePageWidth } from "../../commons/hooks/usePageWidth";
import { PageFullScreenModal } from "../../commons/layout/PageFullScreenModal";
import { PageMiddleColumn } from "../../commons/layout/PageMiddleColumn";
import { QUOTE_ITEM_TYPES } from "../../constants";
import { EmptyStateItem } from "../../features/doc-items/items/EmptyStateItem";
import { QuoteItem } from "../../features/quotes/QuoteItem";
import { QuoteItemActions } from "../../features/quotes/QuoteItemActions";
import { useProjectQuoteEditItemQuery } from "./ProjectQuoteEditItem.generated";
import {
  useHasSapS4HanaIntegration,
  useQuoteItemIsSyncedWithSapS4Hana,
} from "../../features/sap-s4-hana/hooks";

const REFETCH_QUERIES = [
  namedOperations.Query.ProjectQuoteEdit,
  namedOperations.Query.ProjectQuoteEditItem,
];
const REFETCH_QUERY = [namedOperations.Query.ProjectQuoteEditItem];

export function ProjectQuoteEditItem({
  projectId,
  quoteId,
  pathToDocList,
  pathToDocPage,
  isAbsolute,
}: {
  projectId: string;
  quoteId: string;
  pathToDocList: string;
  pathToDocPage: string;
  isAbsolute?: boolean;
}) {
  const { itemId } = useParams();
  if (!itemId) throw new Error("Quote item id is missing");
  const navigate = useNavigate();

  const { isMinTablet } = useScreenWidth();
  const { isMinTwoColumnsWithPreview } = usePageWidth();

  const {
    expandedItemIds,
    pasteItem,
    isAllowedToPasteItem,
    viewerDecisionRole,
  } = useOutletContext<{
    expandedItemIds: string[] | undefined;
    pasteItem: ((docId: string, parentItemId: string) => Promise<void>) | null;
    isAllowedToPasteItem: (parentItem: { type: ItemType }) => boolean;
    viewerDecisionRole: AskWhom;
  }>();

  const client = useApolloClient();
  const query = useProjectQuoteEditItemQuery({
    client,
    variables: {
      projectId,
      quoteId,
      itemId,
    },
  });
  const project = getDataOrNull(query.data?.project)?.project;
  const quote = getDataOrNull(query.data?.quote)?.quote;
  const item = quote?.item[0];
  const hasSapS4HanaIntegration = useHasSapS4HanaIntegration();
  const itemIsSyncedWithSap = useQuoteItemIsSyncedWithSapS4Hana({
    projectId,
    quoteId,
    itemId,
  });

  const isAgreed =
    (quote?.isPublished && quote?.isBinding && quote?.agreement === "YES") ??
    false;

  const [viewMode, setViewMode] = React.useState<ViewMode>(
    isAgreed ? null : "edit"
  );

  React.useEffect(() => {
    setViewMode(isAgreed ? null : "edit");
  }, [isAgreed, item?.id]);

  if (!project || !quote || !item)
    return (
      <EmptyStateItem
        isAbsolute={isAbsolute ?? !isMinTwoColumnsWithPreview}
        loading={query.loading}
        error={query.error}
        onCloseButtonClick={() => navigate(pathToDocPage, { replace: true })}
      />
    );

  const canEdit = !item.deletedAt;

  const quoteItem = (
    <QuoteItem
      key={itemId} // force reset forms
      project={project}
      quote={quote}
      item={item}
      pathToDocList={pathToDocList}
      pathToDocPage={pathToDocPage}
      onChangeCustomFieldsRefetchQueries={REFETCH_QUERIES}
      onSetContentRefetchQueries={REFETCH_QUERIES}
      onUpdateDataRefetchQueries={REFETCH_QUERY}
      isManualSave={isAgreed}
      viewMode={viewMode}
      setViewMode={setViewMode}
      canEdit={canEdit}
      expandedItemIds={expandedItemIds}
      hasSapS4HanaIntegration={hasSapS4HanaIntegration}
    />
  );

  const title = (
    <TitleWithPathForPdf
      title={item.pendingChangeAttributes?.title ?? item.title}
      pathForPdf={item.pendingChangeAttributes?.pathForPdf ?? item.pathForPdf}
    />
  );

  const headerActions = (
    <QuoteItemActions
      project={project}
      quote={quote}
      item={item}
      pathToDocList={pathToDocList}
      pathToDocPage={pathToDocPage}
      onSetContentRefetchQueries={REFETCH_QUERIES}
      viewMode={viewMode}
      setViewMode={setViewMode}
      documentItemTypes={QUOTE_ITEM_TYPES}
      isManualSave={isAgreed}
      canEdit={canEdit}
      expandedItemIds={expandedItemIds}
      pasteItem={isAllowedToPasteItem(item) ? pasteItem : null}
      viewerDecisionRole={viewerDecisionRole}
      disabledActions={
        itemIsSyncedWithSap !== false ? ["CHANGHE_ITEM_TYPE"] : []
      }
    />
  );

  if (isMinTablet) {
    return (
      <PageMiddleColumn
        key={itemId}
        hasRightBorder
        hasBackground
        isSelected
        isAbsolute={isAbsolute ?? !isMinTwoColumnsWithPreview}
        title={title}
        onCloseButtonClick={() => navigate(pathToDocPage, { replace: true })}
        headerActions={headerActions}
      >
        {quoteItem}
      </PageMiddleColumn>
    );
  }

  return (
    <PageFullScreenModal
      key={itemId}
      title={title}
      onCloseButtonClick={() => navigate(pathToDocPage, { replace: true })}
      headerActions={headerActions}
    >
      {quoteItem}
    </PageFullScreenModal>
  );
}
