import { gql, useApolloClient } from "@apollo/client";
import { Select, TextWithBreaks } from "@msys/ui";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { Grid, MenuItem, Typography } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { intersection } from "lodash";
import { useSnackbar } from "notistack";
import React from "react";
import { Stack } from "../../../commons/layout/Stack";
import {
  ConfirmModalProps,
  ConfirmProcess,
  ConfirmProcessRef,
} from "../../../commons/modals/ConfirmProcess";
import { ItemType, namedOperations } from "../../../../clients/graphqlTypes";
import { useItemTypes } from "../hooks/useItemTypes";
import {
  useItemTypeMenuItem_ChangeItemTypeMutation,
  useItemTypeMenuItem_PossibleItemTypesQuery,
} from "./ItemTypeMenuItem.generated";

interface Props {
  projectId: string | null;
  docId: string;
  itemId: string;
  itemType: ItemType;
  allowedTypes?: ItemType[];
  expandedItemIds: string[] | undefined;
  disabled?: boolean;
}

export const ItemTypeMenuItem = ({
  projectId,
  docId,
  itemId,
  itemType,
  allowedTypes,
  expandedItemIds,
  disabled,
}: Props) => {
  const { t } = useTranslate("QuoteItem");
  const { itemTypeOptions } = useItemTypes();

  const confirmProcessRef = React.useRef<ConfirmProcessRef>(null);
  const startConfirmProcess = React.useCallback((props: ConfirmModalProps) => {
    return confirmProcessRef.current!.startConfirmProcess(props);
  }, []);

  const { enqueueSnackbar } = useSnackbar();

  const client = useApolloClient();

  const query = useItemTypeMenuItem_PossibleItemTypesQuery({
    client,
    variables: {
      projectId,
      docId,
      itemId,
    },
    fetchPolicy: "no-cache",
  });

  const types = query.data?.itemPossibleItemTypes?.types ?? [];
  const warnings = query.data?.itemPossibleItemTypes?.warnings ?? [];
  const possibleTypes = allowedTypes
    ? intersection(types, allowedTypes)
    : types;

  const [changeItemType, { loading: changeItemTypeLoading }] =
    useItemTypeMenuItem_ChangeItemTypeMutation({
      client,
    });

  const modifyItemType = async (values: { type: ItemType }) => {
    if (warnings.includes(values.type)) {
      const isConfirmed = await startConfirmProcess({
        title: t("Attention"),
        content: () => (
          <Stack spacing={2} alignItems="center">
            <WarningAmberIcon color="warning" fontSize="large" />
            <Typography>
              <TextWithBreaks
                text={t(
                  "You are changing the item type.\nSome data may get lost."
                )}
              />
            </Typography>
          </Stack>
        ),
      });
      if (!isConfirmed) return;
    }

    try {
      await changeItemType({
        variables: {
          input: {
            projectId,
            docId,
            itemId,
            newType: values.type,
            expandedItemIds,
          },
        },
        refetchQueries: [
          namedOperations.Query.ItemTypeMenuItem_PossibleItemTypes,
        ],
        awaitRefetchQueries: true,
      });
    } catch (e) {
      if (e instanceof Error) enqueueSnackbar(e.message, { variant: "error" });
    }
  };

  if (query.loading && !query.data) return null;

  return (
    <MenuItem
      disableRipple
      onClick={(event: React.MouseEvent<HTMLLIElement>) => {
        event.stopPropagation();
      }}
      disabled={disabled}
    >
      <Grid container alignItems="center" minWidth={"310px"}>
        <Grid item xs={5}>
          {t("Item type")}
        </Grid>
        <Grid item xs={7} minWidth={"180px"}>
          <Select
            autoWidth
            fullWidth
            disabled={changeItemTypeLoading}
            value={itemType}
            label={t("Item type")}
            size="extra-small"
            onChange={async (value: ItemType) => {
              await modifyItemType({ type: value });
            }}
            options={itemTypeOptions.filter(
              o => o.value === itemType || possibleTypes.includes(o.value)
            )}
          />
        </Grid>
      </Grid>
      <ConfirmProcess ref={confirmProcessRef} />
    </MenuItem>
  );
};
