import { useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import {
  CardItem,
  DataGrid,
  DebouncedSearchInput,
  ErrorMessage,
  FormattedPrice,
  GridColDef,
  ListHeader,
  LoadingSpinner,
  ModalOpenButton,
  Tabs,
  useFormatting,
} from "@msys/ui";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import {
  Chip,
  Divider,
  Link as MuiLink,
  Stack,
  TablePagination,
  Typography,
} from "@mui/material";
import {
  GridLogicOperator,
  getGridNumericOperators,
} from "@mui/x-data-grid-premium";
import { useTranslate } from "@tolgee/react";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  IncomingQuotesSortBy,
  IncomingQuotesSorting,
  OutgoingQuotesSortBy,
  OutgoingQuotesSorting,
  SortDirection,
} from "../../../clients/graphqlTypes";
import { useRestrictionFilter } from "../../auth/useRestrictionFilter";
import { EntityNumber } from "../../commons/EntityNumber";
import { TabsWithRestriction } from "../../commons/TabsWithRestriction";
import { SwitchCollectionViewButton } from "../../commons/button/SwitchCollectionViewButton";
import { useFiltersAndPagination } from "../../commons/filters/useFiltersAndPagination";
import {
  CollectionView,
  useCollectionViewWithMobile,
} from "../../commons/hooks/useCollectionView";
import { useStateWithUrlParam } from "../../commons/hooks/useStateWithUrlParam";
import { Page, PageTopbarItem } from "../../commons/layout/Page";
import { PageContainer } from "../../commons/layout/PageContainer";
import {
  PAGE_LIST_RESULTS_PER_PAGE,
  RESULTS_PER_PAGE_OPTIONS,
} from "../../constants";
import { CrmCompanyFilterChip } from "../../features/crm-companies/CrmCompanyFilterChip";
import { QuoteListItem } from "../../features/quotes/QuoteListItem";
import {
  IncomingQuoteStatusBadge,
  QuoteStatusBadge,
} from "../../features/quotes/QuoteStatusBadge";
import { RequestStatusBadge } from "../../features/requests/RequestStatusBadge";
import { RequestOverviewProjectOutgoingQuoteModal } from "../../features/requests/modals/RequestOverviewModal";
import { useDataGridStateStore } from "../../features/users/useDataGridStateStore";
import {
  DocumentsIncomingQuotesQueryVariables,
  DocumentsOutgoingQuotesQueryVariables,
  IncomingQuoteFragment,
  OutgoingQuoteFragment,
  useDocumentsIncomingQuotesQuery,
  useDocumentsOutgoingQuotesQuery,
} from "./Quotes.generated";

const ALLOWED_VIEWS: CollectionView[] = ["table", "list"];

type Tab = "INCOMING" | "OUTGOING";

interface Props {
  submenuItems: PageTopbarItem[];
}

export function Quotes({ submenuItems }: Props) {
  const { t } = useTranslate(["Quotes", "SidebarItems", "PageNotFound"]);

  const initialOptions = [
    {
      value: "OUTGOING" as Tab,
      label: t("Outgoing", { ns: "Quotes" }),
      capability: "QUOTING" as const,
    },
    {
      value: "INCOMING" as Tab,
      label: t("Incoming", { ns: "Quotes" }),
    },
  ];
  const restrictionFilter = useRestrictionFilter();
  const options = initialOptions.filter(restrictionFilter);

  const [tab, setTab] = useStateWithUrlParam<Tab>(
    "tab",
    options[0].value,
    true
  );

  const SectionTabs =
    initialOptions.length > 1 ? (
      <TabsWithRestriction
        options={initialOptions}
        value={tab}
        onChange={newQuickFilter => {
          setTab(newQuickFilter);
        }}
        condensed
        useSelectOnMobile
      />
    ) : undefined;

  return (
    <Page
      subtitle={t("Documents", {
        ns: "SidebarItems",
      })}
      title={t("Quotes", {
        ns: "Quotes",
      })}
      submenuItems={submenuItems}
    >
      <PageContainer>
        {tab === "OUTGOING" &&
          (options.some(o => o.value === "OUTGOING") ? (
            <OutgoingQuotesTable SectionTabs={SectionTabs} />
          ) : (
            <ErrorMessage
              message={t("Page Not Found", {
                ns: "PageNotFound",
              })}
            />
          ))}
        {tab === "INCOMING" && (
          <IncomingQuotesTable SectionTabs={SectionTabs} />
        )}
      </PageContainer>
    </Page>
  );
}

type IncomingQuotesQuickFilterValue = "RECEIVED" | "ACCEPTED" | "DECLINED";
type IncomingQuotesFilters = Omit<
  DocumentsIncomingQuotesQueryVariables,
  "limit" | "offset" | "sorting"
>;

const INCOMING_QUOTES_DEFAULT_SORTING: IncomingQuotesSorting[] = [
  {
    column: "createdAt",
    direction: "desc",
  },
];

function IncomingQuotesTable({
  SectionTabs,
}: {
  SectionTabs?: React.ReactNode;
}) {
  const navigate = useNavigate();
  const { t } = useTranslate(["Global", "QuoteStateBadge", "Quotes"]);
  const { getFormattedDate } = useFormatting();

  const [activeView, setActiveView] =
    useCollectionViewWithMobile<CollectionView>("quotes", "table", "list");

  const quickFilterSettings: Record<
    IncomingQuotesQuickFilterValue,
    {
      label: string;
      disabled?: boolean;
      values: IncomingQuotesFilters;
    }
  > = React.useMemo(
    () => ({
      RECEIVED: {
        label: t("Received", {
          ns: "QuoteStateBadge",
        }),
        values: {
          filterAgreement: "NONE",
        },
      },
      ACCEPTED: {
        label: t("Accepted", {
          ns: "QuoteStateBadge",
        }),
        values: {
          filterAgreement: "YES",
        },
      },
      DECLINED: {
        label: t("Declined", {
          ns: "QuoteStateBadge",
        }),
        values: {
          filterAgreement: "NO",
        },
      },
    }),
    [t]
  );

  const {
    offset,
    limit,
    paginationModel,
    setPaginationModel,
    filters,
    setFilters,
    sorting,
    setSorting,
    toRemoveParams,
  } = useFiltersAndPagination<IncomingQuotesSorting, IncomingQuotesFilters>(
    {},
    INCOMING_QUOTES_DEFAULT_SORTING,
    PAGE_LIST_RESULTS_PER_PAGE
  );

  const [quickFilter, setQuickFilter] = useStateWithUrlParam<
    IncomingQuotesQuickFilterValue | undefined
  >("quickfilter", undefined, toRemoveParams);

  const QUERY_BASE_VARIABLES = {
    limit,
    offset,
    sorting: INCOMING_QUOTES_DEFAULT_SORTING,
  };

  const client = useApolloClient();
  const query = useDocumentsIncomingQuotesQuery({
    client,
    variables: {
      ...QUERY_BASE_VARIABLES,
      sorting,
      ...(quickFilter ? quickFilterSettings[quickFilter]?.values : undefined),
      ...filters,
    },
  });
  const quotes =
    getDataOrNull(
      query.data?.incomingQuotes ?? query.previousData?.incomingQuotes
    )?.edges?.map(e => e.node) ?? [];
  const totalCount =
    getDataOrNull(
      query.data?.incomingQuotes ?? query.previousData?.incomingQuotes
    )?.totalCount ?? 0;

  const columns: GridColDef<IncomingQuoteFragment>[] = [
    {
      field: "number",
      headerName: t("No.", {
        ns: "Global",
      }),
      editable: false,
      filterable: false,
      minWidth: 80,
      flex: 1,
      renderCell: ({ row: quote }) => (
        <EntityNumber noLabel number={quote.number} />
      ),
    },
    {
      field: "docActorContractorName",
      headerName: t("Contractor", {
        ns: "Global",
      }),
      editable: false,
      filterable: false,
      sortable: false,
      flex: 2,
      minWidth: 100,
      valueGetter: ({ row: quote }) => quote.docActorContractorName,
    },
    {
      field: "title",
      headerName: t("Title", {
        ns: "Quotes",
      }),
      editable: false,
      filterable: false,
      flex: 3,
      minWidth: 120,
      renderCell: ({ row: quote }) => quote.title,
    },
    {
      field: "projectNumber",
      headerName: t("Project", {
        ns: "Quotes",
      }),
      editable: false,
      filterable: false,
      flex: 1,
      minWidth: 80,
      valueGetter: ({ row: quote }) => quote?.projectNumber ?? "",
      renderCell: ({ row: quote }) => (
        <Stack direction="row" alignItems="center" spacing={1}>
          <MuiLink
            component={Link}
            to={`/projects/${quote.projectId}`}
            onClick={e => {
              e.stopPropagation();
            }}
          >
            #{quote.projectNumber}
          </MuiLink>
        </Stack>
      ),
    },
    {
      field: "requestTitle",
      headerName: t("Related request", {
        ns: "Quotes",
      }),
      editable: false,
      filterable: false,
      flex: 3,
      minWidth: 120,
      valueGetter: ({ row: quote }) => quote.request?.title ?? "",
      renderCell: ({ row: quote }) =>
        quote.request ? (
          <Stack direction="row" alignItems="center" spacing={1}>
            <ModalOpenButton
              Modal={RequestOverviewProjectOutgoingQuoteModal}
              modalProps={{
                requestId: quote.request.id,
                projectId: quote.projectId,
              }}
            >
              <MuiLink
                onClick={e => {
                  e.stopPropagation();
                }}
              >
                {quote.request.title}
              </MuiLink>
            </ModalOpenButton>
            <RequestStatusBadge request={quote.request} small />
          </Stack>
        ) : (
          "-"
        ),
    },
    {
      field: "isBinding",
      headerName: t("Binding", {
        ns: "Quotes",
      }),
      editable: false,
      sortable: false,
      filterable: false,
      type: "boolean",
      flex: 1,
      renderCell: ({ row: quote }) =>
        quote.isBinding ? (
          <DoneIcon fontSize="small" />
        ) : (
          <CloseIcon fontSize="small" color="secondary" />
        ),
    },
    {
      field: "proposedTotalPrice",
      headerName: t("Proposed Price", {
        ns: "Quotes",
      }),
      editable: false,
      filterable: false,
      sortable: true,
      type: "number",
      flex: 1,
      align: "right",
      headerAlign: "right",
      minWidth: 80,
      valueGetter: ({ row: quote }) => quote.proposedTotalPrice || 0,
      renderCell: ({ row: quote }) => {
        return (
          <Typography
            component="span"
            align="right"
            variant="body2"
            style={{ fontWeight: 500 }}
          >
            <FormattedPrice value={quote.proposedTotalPrice || 0} />
          </Typography>
        );
      },
      filterOperators: getGridNumericOperators().filter(operator =>
        [">", "<", ">=", "<="].includes(operator.value)
      ),
    },
    {
      field: "agreedTotalPrice",
      headerName: t("Agreed Price", {
        ns: "Quotes",
      }),
      editable: false,
      filterable: false,
      sortable: false,
      type: "number",
      flex: 1,
      align: "right",
      headerAlign: "right",
      minWidth: 80,
      valueGetter: ({ row: quote }) => quote.agreedTotalPrice || 0,
      renderCell: ({ row: quote }) => {
        return quote.agreedTotalPrice === null ? (
          "–"
        ) : (
          <Typography
            component="span"
            align="right"
            variant="body2"
            style={{ fontWeight: 500 }}
          >
            <FormattedPrice value={quote.agreedTotalPrice || 0} />
          </Typography>
        );
      },
      filterOperators: getGridNumericOperators().filter(operator =>
        [">", "<", ">=", "<="].includes(operator.value)
      ),
    },
    {
      field: "status",
      headerName: t("Status", {
        ns: "Quotes",
      }),
      editable: false,
      sortable: false,
      filterable: false,
      flex: 1,
      minWidth: 80,
      renderCell: ({ row: quote }) => (
        <IncomingQuoteStatusBadge small quote={quote} />
      ),
    },
    {
      field: "publishedAt",
      headerName: t("Received", {
        ns: "Quotes",
      }),
      editable: false,
      sortable: true,
      filterable: false,
      type: "date",
      flex: 1,
      minWidth: 80,
      valueGetter: ({ row: quote }) =>
        quote.publishedAt ? new Date(quote.publishedAt) : null,
      renderCell: ({ row: quote }) =>
        quote.publishedAt ? getFormattedDate(quote.publishedAt) : "–",
    },
    {
      field: "acceptedAt",
      headerName: t("Accepted", {
        ns: "Quotes",
      }),
      editable: false,
      sortable: true,
      filterable: false,
      type: "date",
      flex: 1,
      minWidth: 80,
      valueGetter: ({ row: quote }) =>
        quote.acceptedAt ? new Date(quote.acceptedAt) : null,
      renderCell: ({ row: quote }) =>
        quote.acceptedAt ? getFormattedDate(quote.acceptedAt) : "–",
    },
  ];

  const getToPath = (item: { projectId: string; id: string }) =>
    `/projects/${item.projectId}/quotes/${item.id}`;

  const stateStore = useDataGridStateStore("IncomingQuotes");

  return (
    <>
      <ListHeader
        // title={null}
        SwitchViewButton={
          <SwitchCollectionViewButton
            allowedViews={ALLOWED_VIEWS}
            activeView={activeView}
            setActiveView={setActiveView}
          />
        }
        QuickFilter={
          <Stack direction="row" spacing={1}>
            {SectionTabs && (
              <>
                {SectionTabs}
                <Divider orientation="vertical" flexItem />
              </>
            )}
            <Tabs
              condensed
              useSelectOnMobile
              options={Object.entries(quickFilterSettings).map(
                ([key, value]) => {
                  return {
                    value: key as IncomingQuotesQuickFilterValue,
                    label: value.label,
                    disabled: value.disabled,
                  };
                }
              )}
              value={quickFilter}
              onChange={newQuickFilter => {
                setQuickFilter(newQuickFilter);
              }}
              onDeselect={() => {
                setQuickFilter(undefined);
              }}
              notSelectedTitle={t("Not selected", { ns: "Global" })}
            />
          </Stack>
        }
        SearchField={
          <DebouncedSearchInput
            placeholder={t("Search", {
              ns: "Global",
            })}
            defaultValue={filters.searchTerm}
            onChangeSearchTerm={(newValue: string) => {
              setFilters(filters => ({
                ...filters,
                searchTerm: newValue,
              }));
            }}
          />
        }
        FilterButton={undefined}
        FilterChips={
          filters?.filterByDocActors ? (
            <Stack direction="row" spacing={1} flexWrap="wrap">
              {filters.filterByDocActors.map(actorFilter => (
                <CrmCompanyFilterChip
                  label={actorFilter.type} // FIXME: translate
                  value={actorFilter.crmOrganisationId}
                  setValue={() =>
                    setFilters(filterVariables => ({
                      ...filterVariables,
                      filterByDocActors:
                        filterVariables.filterByDocActors?.filter(
                          filter =>
                            filter.crmOrganisationId !==
                            actorFilter.crmOrganisationId
                        ),
                    }))
                  }
                />
              ))}
            </Stack>
          ) : undefined
        }
        marginBottom={2}
      />
      {(() => {
        switch (activeView) {
          case "table":
            return (
              <DataGrid
                stateStore={stateStore}
                loading={query.loading}
                hideFooter={totalCount === 0}
                columns={columns}
                rows={quotes}
                onRowClick={({ row }) => {
                  navigate(getToPath(row as IncomingQuoteFragment));
                }}
                disableColumnFilter={false}
                sortModel={sorting.map(s => ({
                  field: s.column,
                  sort: s.direction,
                }))}
                onSortModelChange={newModel => {
                  setSorting(
                    newModel.map(({ field, sort }) => ({
                      column: field as IncomingQuotesSortBy,
                      direction: (sort ?? "asc") as SortDirection,
                    }))
                  );
                }}
                paginationModel={paginationModel}
                onPaginationModelChange={newPaginationModel => {
                  setPaginationModel(newPaginationModel);
                }}
                pageSizeOptions={RESULTS_PER_PAGE_OPTIONS}
                rowCount={totalCount}
              />
            );
          case "list":
            if (query.loading) return <LoadingSpinner />;
            if (totalCount === 0)
              return (
                <ErrorMessage
                  message={t("There are no items to display", {
                    ns: "Global",
                  })}
                />
              );
            return (
              <Stack width="100%" direction="column" spacing={1}>
                {quotes.map(quote => (
                  <CardItem
                    key={quote.id}
                    //@ts-ignore
                    component={Link}
                    to={getToPath(quote)}
                  >
                    <QuoteListItem quote={quote} />
                  </CardItem>
                ))}
                <TablePagination
                  component="div"
                  count={totalCount}
                  page={paginationModel.page}
                  onPageChange={(event, newPage) => {
                    setPaginationModel({
                      pageSize: paginationModel.pageSize,
                      page: newPage,
                    });
                  }}
                  rowsPerPage={paginationModel.pageSize}
                  onRowsPerPageChange={event => {
                    setPaginationModel({
                      pageSize: parseInt(event.target.value, 10),
                      page: 0,
                    });
                  }}
                  labelRowsPerPage={t("Per page:", { ns: "Global" })}
                />
              </Stack>
            );
        }
      })()}
    </>
  );
}

type OutgoingQuotesQuickFilterValue =
  | "DRAFT"
  | "BINDING_QUOTE"
  | "ACCEPTED"
  | "DECLINED";
type OutgoingQuotesFilters = Omit<
  DocumentsOutgoingQuotesQueryVariables,
  "limit" | "offset" | "sorting" | "quotingCapability"
>;

const OUTGOING_QUOTES_DEFAULT_SORTING: OutgoingQuotesSorting[] = [
  {
    column: "createdAt",
    direction: "desc",
  },
];

function OutgoingQuotesTable({
  SectionTabs,
}: {
  SectionTabs?: React.ReactNode;
}) {
  const navigate = useNavigate();
  const { t } = useTranslate(["Global", "QuoteStateBadge", "Quotes"]);
  const { getFormattedDate } = useFormatting();

  const [activeView, setActiveView] =
    useCollectionViewWithMobile<CollectionView>("quotes", "table", "list");

  const quickFilterSettings: Record<
    OutgoingQuotesQuickFilterValue,
    {
      label: string;
      disabled?: boolean;
      values: OutgoingQuotesFilters;
    }
  > = React.useMemo(
    () => ({
      DRAFT: {
        label: t("Draft", {
          ns: "QuoteStateBadge",
        }),
        values: {
          filterIsPublished: false,
        },
      },
      BINDING_QUOTE: {
        label: t("Binding Quote", {
          ns: "QuoteStateBadge",
        }),
        values: {
          filterIsPublished: true,
          filterIsBinding: true,
          filterAgreement: "NONE",
        },
      },
      ACCEPTED: {
        label: t("Accepted", {
          ns: "QuoteStateBadge",
        }),
        values: {
          filterAgreement: "YES",
        },
      },
      DECLINED: {
        label: t("Declined", {
          ns: "QuoteStateBadge",
        }),
        values: {
          filterAgreement: "NO",
        },
      },
      // CHANGE_PENDING: {
      //   label: t("QuoteStateBadge::Change Pending"),
      //   disabled: true,
      //   values: {
      //     filterisChangePending: true,
      //   },
      // },
    }),
    [t]
  );

  const {
    offset,
    limit,
    paginationModel,
    setPaginationModel,
    filters,
    setFilters,
    sorting,
    setSorting,
    toRemoveParams,
  } = useFiltersAndPagination<OutgoingQuotesSorting, OutgoingQuotesFilters>(
    {},
    OUTGOING_QUOTES_DEFAULT_SORTING,
    PAGE_LIST_RESULTS_PER_PAGE
  );

  const [quickFilter, setQuickFilter] = useStateWithUrlParam<
    OutgoingQuotesQuickFilterValue | undefined
  >("quickfilter", undefined, toRemoveParams);

  const QUERY_BASE_VARIABLES = {
    limit,
    offset,
    sorting: OUTGOING_QUOTES_DEFAULT_SORTING,
  };

  const client = useApolloClient();
  const query = useDocumentsOutgoingQuotesQuery({
    client,
    variables: {
      ...QUERY_BASE_VARIABLES,
      sorting,
      ...(quickFilter ? quickFilterSettings[quickFilter]?.values : undefined),
      ...filters,
    },
  });
  const quotes = React.useMemo(
    () =>
      getDataOrNull(
        (query.data ?? query.previousData)?.outgoingQuotes
      )?.edges?.map(e => e.node) ?? [],
    [query.data, query.previousData]
  );
  const totalCount =
    getDataOrNull((query.data ?? query.previousData)?.outgoingQuotes)
      ?.totalCount ?? 0;

  const columns: GridColDef<OutgoingQuoteFragment>[] = [
    {
      field: "number",
      headerName: t("No.", {
        ns: "Global",
      }),
      editable: false,
      filterable: false,
      minWidth: 80,
      flex: 1,
      renderCell: ({ row: quote }) => (
        <EntityNumber noLabel number={quote.number} />
      ),
    },
    {
      field: "createdBy",
      headerName: t("Created by", {
        ns: "Quotes",
      }),
      editable: false,
      filterable: false,
      flex: 2,
      minWidth: 100,
      valueGetter: ({ row: quote }) => quote.createdBy,
    },
    {
      field: "title",
      headerName: t("Title", {
        ns: "Quotes",
      }),
      editable: false,
      filterable: false,
      flex: 3,
      minWidth: 120,
      renderCell: ({ row: quote }) => quote.title,
    },
    {
      field: "projectNumber",
      headerName: t("Project", {
        ns: "Quotes",
      }),
      editable: false,
      filterable: false,
      flex: 1,
      minWidth: 80,
      valueGetter: ({ row: quote }) => quote.projectNumber,
      renderCell: ({ row: quote }) => (
        <Stack direction="row" alignItems="center" spacing={1}>
          <MuiLink
            component={Link}
            to={`/projects/${quote.projectId}`}
            onClick={e => {
              e.stopPropagation();
            }}
          >
            #{quote.projectNumber}
          </MuiLink>
        </Stack>
      ),
    },
    {
      field: "docActorContracteeName",
      headerName: t("Contractee", {
        ns: "Global",
      }),
      editable: false,
      filterable: false,
      sortable: false,
      flex: 2,
      minWidth: 100,
      valueGetter: ({ row: quote }) => quote.docActorContracteeName,
    },
    {
      field: "docActorClientName",
      headerName: t("End client", {
        ns: "Global",
      }),
      editable: false,
      filterable: false,
      sortable: false,
      flex: 2,
      minWidth: 100,
      valueGetter: ({ row: quote }) => quote.docActorClientName,
    },
    {
      field: "isBinding",
      headerName: t("Binding", {
        ns: "Quotes",
      }),
      editable: false,
      sortable: false,
      filterable: false,
      type: "boolean",
      flex: 1,
      renderCell: ({ row: quote }) =>
        quote.isBinding ? (
          <DoneIcon fontSize="small" />
        ) : (
          <CloseIcon fontSize="small" color="secondary" />
        ),
    },
    {
      field: "proposedTotalPrice",
      headerName: t("Proposed Price", {
        ns: "Quotes",
      }),
      editable: false,
      filterable: false,
      sortable: true,
      type: "number",
      flex: 1,
      align: "right",
      headerAlign: "right",
      minWidth: 80,
      valueGetter: ({ row: quote }) => quote.proposedTotalPrice || 0,
      renderCell: ({ row: quote }) => {
        return (
          <Typography
            component="span"
            align="right"
            variant="body2"
            style={{ fontWeight: 500 }}
          >
            <FormattedPrice value={quote.proposedTotalPrice ?? 0} />
          </Typography>
        );
      },
      filterOperators: getGridNumericOperators().filter(operator =>
        [">", "<", ">=", "<="].includes(operator.value)
      ),
    },
    {
      field: "agreedTotalPrice",
      headerName: t("Agreed Price", {
        ns: "Quotes",
      }),
      editable: false,
      filterable: false,
      sortable: false,
      type: "number",
      flex: 1,
      align: "right",
      headerAlign: "right",
      minWidth: 80,
      valueGetter: ({ row: quote }) => quote.agreedTotalPrice || 0,
      renderCell: ({ row: quote }) => {
        return quote.agreedTotalPrice === null ? (
          "–"
        ) : (
          <Typography
            component="span"
            align="right"
            variant="body2"
            style={{ fontWeight: 500 }}
          >
            <FormattedPrice value={quote.agreedTotalPrice || 0} />
          </Typography>
        );
      },
      filterOperators: getGridNumericOperators().filter(operator =>
        [">", "<", ">=", "<="].includes(operator.value)
      ),
    },
    {
      field: "status",
      headerName: t("Status", {
        ns: "Quotes",
      }),
      editable: false,
      sortable: false,
      filterable: false,
      flex: 1,
      minWidth: 80,
      valueGetter: ({ row: quote }) =>
        `${quote.isPublished}-${quote.isBinding}-${quote.needsAgreement}-${quote.agreement}`,
      renderCell: ({ row: quote }) => <QuoteStatusBadge small quote={quote} />,
    },
    {
      field: "createdAt",
      headerName: t("Created", {
        ns: "Quotes",
      }),
      editable: false,
      filterable: false,
      sortable: true,
      flex: 1,
      valueGetter: ({ row: quote }) => quote.createdAt ?? null,
      renderCell: ({ row: quote }) =>
        quote.createdAt ? getFormattedDate(quote.createdAt) : "-",
    },
    {
      field: "publishedAt",
      headerName: t("Sent", {
        ns: "Quotes",
      }),
      editable: false,
      sortable: true,
      filterable: false,
      type: "date",
      flex: 1,
      minWidth: 80,
      valueGetter: ({ row: quote }) =>
        quote.publishedAt ? new Date(quote.publishedAt) : null,
      renderCell: ({ row: quote }) =>
        quote.publishedAt ? getFormattedDate(quote.publishedAt) : "-",
    },
    {
      field: "acceptedAt",
      headerName: t("Accepted", {
        ns: "Quotes",
      }),
      editable: false,
      sortable: true,
      filterable: false,
      type: "date",
      flex: 1,
      minWidth: 80,
      valueGetter: ({ row: quote }) =>
        quote.acceptedAt ? new Date(quote.acceptedAt) : null,
      renderCell: ({ row: quote }) =>
        quote.acceptedAt ? getFormattedDate(quote.acceptedAt) : "-",
    },
    {
      field: "xState",
      headerName: t("External status", { ns: "Quotes" }),
      editable: false,
      filterable: false,
      sortable: false,
      flex: 1,
      minWidth: 80,
      valueGetter: ({ row: quote }) => quote.xStatus,
      renderCell: ({ row: quote }) =>
        quote.xStatus ? (
          <Chip label={quote.xStatus} size="small" variant="filled" />
        ) : (
          "-"
        ),
    },
  ];

  const getToPath = (item: { projectId: string; id: string }) =>
    `/projects/${item.projectId}/quotes/${item.id}`;

  const stateStore = useDataGridStateStore("OutgoingQuotes");

  return (
    <>
      <ListHeader
        // title={null}
        SwitchViewButton={
          <SwitchCollectionViewButton
            allowedViews={ALLOWED_VIEWS}
            activeView={activeView}
            setActiveView={setActiveView}
          />
        }
        QuickFilter={
          <Stack direction="row" spacing={1}>
            {SectionTabs && (
              <>
                {SectionTabs}
                <Divider orientation="vertical" flexItem />
              </>
            )}
            <Tabs
              condensed
              useSelectOnMobile
              options={Object.entries(quickFilterSettings).map(
                ([key, value]) => {
                  return {
                    value: key as OutgoingQuotesQuickFilterValue,
                    label: value.label,
                    disabled: value.disabled,
                  };
                }
              )}
              value={quickFilter}
              onChange={(newQuickFilter: OutgoingQuotesQuickFilterValue) => {
                setQuickFilter(newQuickFilter);
              }}
              onDeselect={() => {
                setQuickFilter(undefined);
              }}
              notSelectedTitle={t("Not selected", { ns: "Global" })}
            />
          </Stack>
        }
        FilterButton={undefined}
        SearchField={
          <DebouncedSearchInput
            placeholder={t("Search", {
              ns: "Global",
            })}
            defaultValue={filters.searchTerm}
            onChangeSearchTerm={(newValue: string) => {
              setFilters(filters => ({
                ...filters,
                searchTerm: newValue,
              }));
            }}
          />
        }
        FilterChips={
          filters?.filterByDocActors ? (
            <Stack direction="row" spacing={1} flexWrap="wrap">
              {filters.filterByDocActors.map(actorFilter => (
                <CrmCompanyFilterChip
                  label={actorFilter.type} // FIXME: translate
                  value={actorFilter.crmOrganisationId}
                  setValue={() =>
                    setFilters(filterVariables => ({
                      ...filterVariables,
                      filterByDocActors:
                        filterVariables.filterByDocActors?.filter(
                          filter =>
                            filter.crmOrganisationId !==
                            actorFilter.crmOrganisationId
                        ),
                    }))
                  }
                />
              ))}
            </Stack>
          ) : undefined
        }
        marginBottom={2}
      />
      {(() => {
        switch (activeView) {
          case "table":
            return (
              <DataGrid
                stateStore={stateStore}
                loading={query.loading}
                hideFooter={totalCount === 0}
                columns={columns}
                rows={quotes}
                onRowClick={({ row }) => {
                  navigate(getToPath(row as OutgoingQuoteFragment));
                }}
                disableColumnFilter={false}
                sortModel={sorting.map(s => ({
                  field: s.column,
                  sort: s.direction,
                }))}
                onSortModelChange={newModel => {
                  setSorting(
                    newModel.map(({ field, sort }) => ({
                      column: field as OutgoingQuotesSortBy,
                      direction: (sort ?? "asc") as SortDirection,
                    }))
                  );
                }}
                paginationModel={paginationModel}
                onPaginationModelChange={newPaginationModel => {
                  setPaginationModel(newPaginationModel);
                }}
                pageSizeOptions={RESULTS_PER_PAGE_OPTIONS}
                rowCount={totalCount}
              />
            );

          case "list":
            if (query.loading) return <LoadingSpinner />;
            if (totalCount === 0)
              return (
                <ErrorMessage
                  message={t("There are no items to display", {
                    ns: "Global",
                  })}
                />
              );
            return (
              <Stack width="100%" direction="column" spacing={1}>
                {quotes.map(quote => (
                  <CardItem
                    key={quote.id}
                    //@ts-ignore
                    component={Link}
                    to={getToPath(quote)}
                  >
                    <QuoteListItem quote={quote} />
                  </CardItem>
                ))}
                <TablePagination
                  component="div"
                  count={totalCount}
                  page={paginationModel.page}
                  onPageChange={(event, newPage) => {
                    setPaginationModel({
                      pageSize: paginationModel.pageSize,
                      page: newPage,
                    });
                  }}
                  rowsPerPage={paginationModel.pageSize}
                  onRowsPerPageChange={event => {
                    setPaginationModel({
                      pageSize: parseInt(event.target.value, 10),
                      page: 0,
                    });
                  }}
                  labelRowsPerPage={t("Per page:", { ns: "Global" })}
                />
              </Stack>
            );
        }
      })()}
    </>
  );
}
