import {
  ellipsisStyle,
  PopoverOpenButton,
  PopoverOpenButtonRef,
} from "@msys/ui";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import { Box, Button, IconButton, Tooltip } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import React from "react";
import { Avatar, getAvatarSize } from "../../commons/Avatar";
import { getPersonLabel } from "../users/helpers";
import { UserAvatar } from "../users/UserAvatar";
import { UserAvatarFragment } from "../users/UserAvatar.generated";
import { ProjectAssigneeEditPopover } from "./ProjectAssigneeEditPopover";

interface Props {
  assignee: {
    id: string;
    user: {
      id: string;
      firstname: string;
      familyname: string;
    } & UserAvatarFragment;
  } | null;
  projectId: string;
  projectTitle?: string;
  refetchQueries?: string[];
  readOnly?: boolean;
  type: "button" | "icon";
}

export const ProjectAssigneeEditButton = ({
  assignee,
  projectId,
  projectTitle,
  refetchQueries,
  readOnly,
  type,
}: Props) => {
  const { t } = useTranslate(["Global", "Projects", "ProjectMembers"]);
  const [tooltipOpen, setTooltipOpen] = React.useState<boolean>(false);

  const popoverOpenButtonRef = React.useRef<PopoverOpenButtonRef>();

  switch (type) {
    case "button":
      return (
        <PopoverOpenButton
          popoverRef={popoverOpenButtonRef}
          content={
            <ProjectAssigneeEditPopover
              projectId={projectId}
              refetchQueries={refetchQueries}
              readOnly={readOnly}
              disabled={readOnly}
              onAssigneeChange={assigneeUserId => {
                if (assigneeUserId) {
                  setTimeout(
                    () => popoverOpenButtonRef.current?.handleClose(),
                    200
                  );
                }
              }}
            />
          }
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <Button
            variant="text"
            color="primary"
            size="extra-small"
            startIcon={
              assignee ? (
                <UserAvatar
                  size="xs"
                  userAvatar={assignee.user}
                  sx={{ pointerEvents: "none" }}
                />
              ) : (
                <Avatar
                  sx={{
                    bgcolor: "grey.300",
                    color: "grey.600",
                    pointerEvents: "none",
                  }}
                  size="xs"
                  type="circle"
                  icon={<PersonOutlineIcon />}
                />
              )
            }
            onClick={e => {
              e.stopPropagation();
              e.preventDefault();
            }}
            sx={{ textTransform: "unset", ...ellipsisStyle }}
            disabled={readOnly}
          >
            <span style={ellipsisStyle}>
              {assignee
                ? getPersonLabel(assignee.user, false)
                : t("Unassigned", { ns: "Projects" })}
            </span>
          </Button>
        </PopoverOpenButton>
      );
    case "icon":
      return (
        <Tooltip
          title={
            assignee
              ? `${t("Assignee", { ns: "Global" })}: ${getPersonLabel(assignee.user, false)}`
              : t("Unassigned", { ns: "Projects" })
          }
          arrow
          placement="top"
          open={tooltipOpen}
        >
          <Box
            display="flex"
            flexGrow={0}
            flexShrink={0}
            onMouseEnter={() => setTooltipOpen(true)}
            onMouseLeave={() => setTooltipOpen(false)}
          >
            <PopoverOpenButton
              popoverRef={popoverOpenButtonRef}
              content={
                <ProjectAssigneeEditPopover
                  projectId={projectId}
                  refetchQueries={refetchQueries}
                  readOnly={readOnly}
                  disabled={readOnly}
                  onAssigneeChanged={assigneeUserId => {
                    if (assigneeUserId) {
                      setTimeout(
                        () => popoverOpenButtonRef.current?.handleClose(),
                        200
                      );
                    }
                  }}
                />
              }
              onOpen={() => setTooltipOpen(false)}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <IconButton
                size="small"
                sx={{
                  width: `${getAvatarSize("xs")}px`,
                  height: `${getAvatarSize("xs")}px`,
                }}
                onClick={e => {
                  e.stopPropagation();
                  e.preventDefault();
                }}
                disabled={readOnly}
              >
                {assignee ? (
                  <UserAvatar
                    size="xs"
                    userAvatar={assignee.user}
                    sx={{ pointerEvents: "none" }}
                  />
                ) : (
                  <Avatar
                    sx={{
                      bgcolor: "grey.300",
                      color: "grey.600",
                      pointerEvents: "none",
                    }}
                    size="xs"
                    type="circle"
                    icon={<PersonOutlineIcon />}
                  />
                )}
              </IconButton>
            </PopoverOpenButton>
          </Box>
        </Tooltip>
      );
    default:
      throw new Error(`Wrong type: ${type}`);
  }
};
