import { gql } from "@apollo/client";
import { RichTextValue } from "@msys/ui";
import { Typography, Stack } from "@mui/material";
import { PropertiesGridLabeledValue } from "../../../commons/PropertiesGridLabeledValue";
import {
  itemHasProperties,
  useQuoteItemProperties,
} from "../../item-properties/itemProperties";
import { RequirementPreviewItemDescription_ItemFragment } from "./RequirementPreviewItemDescription.generated";

interface Props {
  item: RequirementPreviewItemDescription_ItemFragment;
  columns?: number;
}

export const RequirementPreviewItemDescription = ({
  item,
  columns = 2,
}: Props) => {
  const [properties, productProperties] = useQuoteItemProperties(item);

  const ItemDescription =
    item.description || properties.length > 0 ? (
      <Typography
        variant="body2"
        component="div"
        sx={{ wordBreak: "break-word" }}
      >
        {item.description ? (
          <RichTextValue
            key={item.description}
            htmlContent={item.description}
            style={{ marginBottom: properties.length > 0 ? 8 : 0 }}
          />
        ) : null}
        {properties.length > 0 ? (
          <PropertiesGridLabeledValue
            properties={properties}
            columns={columns}
          />
        ) : null}
      </Typography>
    ) : null;

  const ProductTitle =
    item.product &&
    item.product.title &&
    item.product.titleClientVisibility !== "hide" ? (
      <Typography
        variant="h4"
        style={{ fontWeight: "normal", wordBreak: "break-word" }}
      >
        {item.product.title}
      </Typography>
    ) : null;

  const ProductDescription =
    (item.product &&
      item.product.description &&
      item.product.descriptionClientVisibility !== "hide") ||
    productProperties.length > 0 ? (
      <Typography
        variant="body2"
        component="div"
        sx={{ wordBreak: "break-word" }}
      >
        {item.product &&
        item.product.description &&
        item.product.descriptionClientVisibility !== "hide" ? (
          <div>
            <RichTextValue
              key={item.product.description}
              htmlContent={item.product.description}
              style={{ marginBottom: productProperties.length > 0 ? 8 : 0 }}
            />
          </div>
        ) : null}
        {productProperties.length > 0 ? (
          <PropertiesGridLabeledValue
            properties={properties}
            columns={columns}
          />
        ) : null}
      </Typography>
    ) : null;

  return ItemDescription || ProductTitle || ProductDescription ? (
    <Stack direction="column" spacing={1} width="100%" minWidth={0}>
      {ItemDescription}
      {ProductTitle}
      {ProductDescription}
    </Stack>
  ) : null;
};

export const requirementItemHasDescription = (
  item: RequirementPreviewItemDescription_ItemFragment
): boolean => {
  return Boolean(
    itemHasProperties(item) ||
      item.description ||
      (item.product &&
        item.product.title &&
        item.product.titleClientVisibility !== "hide") ||
      (item.product &&
        item.product.description &&
        item.product.descriptionClientVisibility !== "hide")
  );
};
