import React from "react";
import { useLatest } from "react-use";

type AnyFunction = (...args: Array<any>) => any;
type AnyAsyncFunction = (...args: Array<any>) => Promise<any>;

export const useRunWithFlag = (): [
  flag: boolean,
  withFlagUpdate: <F extends AnyFunction | AnyAsyncFunction>(
    fn: F
  ) => Promise<Awaited<ReturnType<F>> | undefined>,
] => {
  const [flag, setFlag] = React.useState<boolean>(false);
  const flagRef = useLatest(flag);

  const runWithFlag = React.useCallback(
    async <F extends AnyFunction | AnyAsyncFunction>(
      fn: F
    ): Promise<Awaited<ReturnType<F>> | undefined> => {
      // prevent overrun
      if (flagRef.current) return;

      setFlag(true);

      let error: any;
      try {
        return await fn();
      } catch (e) {
        error = e;
      } finally {
        setFlag(false);
      }
      if (error) throw error;
    },
    [flagRef]
  );

  return [flag, runWithFlag];
};
