import { useApolloClient } from "@apollo/client";
import { getDataOrNull, notNull } from "@msys/common";
import { CollapseSection, Modal } from "@msys/ui";
import {
  Box,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
} from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import { uniqueId } from "lodash";
import { useSnackbar } from "notistack";
import React from "react";
import * as Yup from "yup";
import { ContractType, Meister1Flow } from "../../../../clients/graphqlTypes";
import { useUserData } from "../../../auth/useUserData";
import { AutocompleteField } from "../../../commons/form-fields/AutocompleteField";
import { CheckboxField } from "../../../commons/form-fields/CheckboxField";
import { FormattedPercentageField } from "../../../commons/form-fields/FormattedPercentageField";
import { RadioGroupField } from "../../../commons/form-fields/RadioGroupField";
import {
  getVatValues,
  useVatFieldValidationSchema,
  VatField,
  VatFieldFormValues,
} from "../../../vat/form-fields/VatField";
import {
  Meister1Process,
  Meister1ProcessRef,
} from "../../m1-calculators/Meister1Process";
import {
  M1Calculator,
  useM1Calculators,
} from "../../m1-calculators/useM1Calculators";
import { useM1Flows } from "../../m1-calculators/useM1Integration";
import { useHasSapS4HanaIntegration } from "../../sap-s4-hana/hooks";
import { S4HanaQuoteCopyModal } from "../../sap-s4-hana/S4HanaQuoteCopyModal";
import {
  TemplateBase,
  TemplatesDisplay,
} from "../../templates/quote/TemplatesDisplay";
import { WizardResults } from "../QuoteCreateWizardSection";
import {
  Meister1PollForQuoteDocOfLeadIdDocument,
  Meister1PollForQuoteDocOfLeadIdQuery,
  Meister1PollForQuoteDocOfLeadIdQueryVariables,
  useCreateQuoteMutation,
  useQuoteCreateModal_RequirementQuery,
  useQuoteCreateModalQuery,
  useSapQuotesQuery,
} from "./QuoteCreateModal.generated";
import { QuoteCreateWizardModal } from "./QuoteCreateWizardModal";
import { ContractTypeField } from "../../../commons/form-fields/ContractTypeField";

const DELIMITER = "///";

type CreateQuoteSource = "empty" | "template" | "m1" | "sap-quote";

interface FormValues extends VatFieldFormValues {
  title: string;
  template: TemplateBase | null;
  integration: Meister1Flow | null;
  request: { id: string; title: string } | null;
  importRequirement: boolean;
  sapQuote: { id: string; title: string } | null;
  source: CreateQuoteSource;
  contractType: ContractType;
  discountPercentage: number;
}

interface Props {
  projectId: string;
  requestId?: string;
  requirementDocId?: string;
  title?: string;
  handleClose: () => void;
  handleComplete?: (
    quoteId: string | undefined,
    handleClose: () => void
  ) => Promise<void> | void;
  refetchQueries?: string[];
}

export const QuoteCreateModal = ({
  requestId,
  projectId,
  requirementDocId,
  title,
  handleClose,
  handleComplete,
  refetchQueries,
}: Props) => {
  const { enqueueSnackbar } = useSnackbar();

  const viewer = useUserData().currentUser!;
  const { t } = useTranslate(["QuoteCreate", "Global"]);
  const {
    hasMeister1Integration,
    meister1Flows,
    isLoading: meister1FlowsIsLoading,
  } = useM1Flows();
  const { calculators, fallback } = useM1Calculators();

  const meister1ProcessRef = React.useRef<Meister1ProcessRef>(null);
  const [templateId, setTemplateId] = React.useState<string | null>(null);
  const [wizardAnswersResolver, setWizardAnswersResolver] = React.useState<
    ((result: WizardResults | null) => void) | null
  >(null);
  const [fromSapQuoteId, setFromSapQuoteId] = React.useState<string | null>(
    null
  );

  const client = useApolloClient();
  const query = useQuoteCreateModalQuery({
    client,
    variables: {
      projectId: projectId,
    },
  });
  const project = getDataOrNull(query.data?.project)?.project;
  const organisationDefaults = getDataOrNull(query.data?.organisationDefaults);

  const hasSapS4HanaIntegration = useHasSapS4HanaIntegration();

  const sapQuotesQuery = useSapQuotesQuery({
    client,
    skip: !hasSapS4HanaIntegration,
    variables: {
      projectId,
    },
  });
  const sapQuotes =
    getDataOrNull(sapQuotesQuery.data?.projectOutgoingQuotes)?.edges.map(
      edge => edge.node
    ) ?? [];

  const requirementQuery = useQuoteCreateModal_RequirementQuery({
    client,
    variables: {
      projectId,
      requirementId: requirementDocId!,
    },
    skip: !requirementDocId,
  });
  const requirement = getDataOrNull(
    requirementQuery.data?.requirement
  )?.requirement;

  // const [meister1ImportMultiBrandLead, { loading: m1Loading }] =
  //   useMeister1ImportMultiBrandLeadMutation({ client, refetchQueries });

  // TODO: use dedicated mutation for creating quote for opportunity?
  const [createQuote] = useCreateQuoteMutation({
    client,
    refetchQueries,
    awaitRefetchQueries: true,
  });

  const integrationOptions = React.useMemo(() => {
    meister1Flows.forEach(flow => {
      // filter out flow for calculators not defined on frontend. log error
      if (!calculators[flow.label as M1Calculator]) {
        // eslint-disable-next-line no-console
        console.error(`Calculator definition missing for ${flow.label}`);
      }
    });

    return meister1Flows.map(flow => ({
      ...flow,
      // create a unique ID to not duplicate options
      id: [flow.id, flow.identName, flow.label].join(DELIMITER),
    }));
  }, [meister1Flows, calculators]);

  const requestOptions = React.useMemo(
    () =>
      project?.incomingQuoteRequests.filter(r => r.status === "PUBLISHED") ??
      [],
    [project?.incomingQuoteRequests]
  );

  const hasMeister1Options =
    hasMeister1Integration && integrationOptions.length > 0;
  const hasSapOptions = hasSapS4HanaIntegration && sapQuotes.length > 0;

  const canImportRequirement = Boolean(
    requestId || requirementDocId || requestOptions.length > 0
  );

  const handleSubmit = async (values: FormValues) => {
    if (values.source === "m1") {
      if (!values.integration) return;
      meister1ProcessRef.current?.startProcess({
        identName: values.integration.identName,
        completeBeforeConfirmPage: true,
        meister1State: {
          type: "existing-project",
          projectId,
          requestId:
            requestId ??
            (requestOptions.length === 1
              ? requestOptions[0].id ?? null
              : values.request?.id ?? null),
          userId: viewer.id,
        },
        inputs: project?.building?.buildingAddress?.postalCode
          ? {
              zip: project?.building?.buildingAddress?.postalCode,
            }
          : undefined,
        onComplete: async leadId => {
          let quoteId;

          while (true) {
            await new Promise(resolve => setTimeout(() => resolve(null), 2000));

            const res = await client.query<
              Meister1PollForQuoteDocOfLeadIdQuery,
              Meister1PollForQuoteDocOfLeadIdQueryVariables
            >({
              query: Meister1PollForQuoteDocOfLeadIdDocument,
              variables: {
                input: {
                  m1LeadId: leadId,
                  projectId,
                },
              },
            });

            if (
              res.data &&
              res.data.meister1PollForQuoteDocOfLeadId &&
              res.data.meister1PollForQuoteDocOfLeadId.length > 0
            ) {
              if (res.data.meister1PollForQuoteDocOfLeadId.length === 1) {
                quoteId = res.data.meister1PollForQuoteDocOfLeadId[0];
              }
              break;
            }
          }

          if (refetchQueries && refetchQueries.length > 0) {
            await client.refetchQueries({
              include: refetchQueries,
            });
          }

          if (handleComplete) {
            await handleComplete(quoteId, handleClose);
          } else {
            handleClose();
          }

          enqueueSnackbar(t("Quotes created", { ns: "QuoteCreate" }));
        },
      });
      return;
    }

    if (values.source === "sap-quote") {
      if (!values.sapQuote) throw new Error("No SAP quote selected");
      setFromSapQuoteId(values.sapQuote?.id);
      return;
    }

    if (values.source === "template") {
      if (!values.template) {
        throw new Error("No template selected");
      }

      const wizardResult = await new Promise<WizardResults | null>(
        (resolve, reject) => {
          if (values.template) {
            setWizardAnswersResolver(() => resolve);
            setTemplateId(values.template.id);
          } else {
            reject("No template selected");
          }
        }
      );

      const title =
        !requirement?.title && values.template?.title
          ? `${values.template.title} - ${t("Quote", {
              ns: "QuoteCreate",
            })}`
          : values.title;

      const newQuote = await createQuote({
        variables: {
          input: {
            projectId: projectId,
            title: title,
            discountPercentage: 0,
            quoteForRequirementId: requirementDocId,
            templateQuoteId: values.template.id,
            importRequirement: false,
            requestId:
              requestId ??
              (requestOptions.length === 1
                ? requestOptions[0].id ?? null
                : values.request?.id ?? null),
            itemUuidSeed: wizardResult?.itemUuidSeed,
            applyItemActions: wizardResult?.answers,
          },
        },
      });

      const docId = newQuote.data?.createQuote.quote.id;

      if (!docId) {
        throw new Error(
          t("Error while creating quote", {
            ns: "QuoteCreate",
          })
        );
      }

      enqueueSnackbar(t("Quote created", { ns: "QuoteCreate" }));

      if (handleComplete) {
        await handleComplete(docId, handleClose);
      } else {
        handleClose();
      }
      return;
    }

    if (values.source === "empty") {
      const newQuote = await createQuote({
        variables: {
          input: {
            projectId: projectId,
            title: values.title,
            discountPercentage: values.discountPercentage || 0,
            contractType: values.contractType,
            quoteForRequirementId: requirementDocId,
            templateQuoteId: undefined,
            importRequirement: canImportRequirement && values.importRequirement,
            requestId:
              requestId ??
              (requestOptions.length === 1
                ? requestOptions[0].id ?? null
                : values.request?.id ?? null),
            vatRate: values.vatRate,
            vatInfo: {
              countryCode: values.vatInfo.countryCode,
              rateType: values.vatInfo.rateType,
            },
          },
        },
      });

      const docId = newQuote.data?.createQuote.quote.id;

      if (!docId) {
        throw new Error(
          t("Error while creating quote", {
            ns: "QuoteCreate",
          })
        );
      }

      enqueueSnackbar(t("Quote created", { ns: "QuoteCreate" }));

      if (handleComplete) {
        await handleComplete?.(docId, handleClose);
      } else {
        handleClose();
      }
      return;
    }
  };

  const validationSchema = Yup.object().shape({
    title: Yup.string()
      .label(t("Title", { ns: "Global" }))
      .when("source", {
        is: "empty",
        then: Yup.string().required(),
      }),
    importRequirement: Yup.boolean(),
    template: Yup.object()
      .shape({
        id: Yup.string().required(),
        title: Yup.string().required(),
        resolvedAsReadModelVersionNumber: Yup.number().optional().nullable(),
      })
      .when("source", {
        is: "template",
        then: Yup.object().required(
          t("Template must be selected", {
            ns: "RequirementCreate",
          })
        ),
      })
      .nullable(),
    discountPercentage: Yup.number()
      .label(t("Discount", { ns: "QuoteCreate" }))
      .when("source", {
        is: "empty",
        then: Yup.number().min(0).max(1).required(),
      }),
    contractType: Yup.string()
      .oneOf(["fmbp_fr", "fmsp_fr", "fup", "lumpsum"])
      .label(t("Contract Type", { ns: "QuoteCreate" }))
      .when("source", {
        is: "empty",
        then: Yup.string().required(),
      }),
    source: Yup.string()
      .oneOf(["empty", "template", "m1", "sap-quote"])
      .required(),
    integration: Yup.object()
      .when("source", {
        is: "m1",
        then: Yup.object().required(
          t("Calculator must be selected", {
            ns: "QuoteCreate",
          })
        ),
      })
      .nullable(),
    ...(!requestId && requestOptions.length > 1
      ? {
          request: Yup.object()
            .shape({
              id: Yup.string().required(),
              title: Yup.string().required(),
            })
            .required(
              t("Request must be selected", {
                ns: "QuoteCreate",
              })
            )
            .nullable(),
        }
      : {}),
    sapQuote: Yup.object()
      .when("source", {
        is: "sap-quote",
        then: Yup.object().required(
          t("SAP quote must be selected", { ns: "QuoteCreate" })
        ),
      })
      .nullable(),
    ...useVatFieldValidationSchema(),
  });

  const initialValues: FormValues = React.useMemo(
    () => ({
      title: `${requirement?.title ?? project?.title} - ${t("Quote", {
        ns: "QuoteCreate",
      })}`,
      discountPercentage: 0,
      contractType: organisationDefaults?.defaultContractType ?? "fup",
      source: "template",
      template: null,
      integration:
        integrationOptions.length === 1 ? integrationOptions[0] : null,
      request: null,
      importRequirement: canImportRequirement,
      sapQuote: null,
      ...getVatValues(viewer.organisation.billingAddress?.countryCode),
    }),
    [
      t,
      requirement?.title,
      project?.title,
      viewer.organisation.billingAddress?.countryCode,
      organisationDefaults?.defaultContractType,
      integrationOptions,
      canImportRequirement,
    ]
  );

  const formId = React.useMemo(() => uniqueId(), []);

  return (
    <Formik<FormValues>
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      enableReinitialize
      initialValues={initialValues}
      validateOnMount
    >
      {formikProps => (
        <Modal
          id={"quote-create-modal"}
          title={
            title ??
            (project
              ? t("Create quote for {recipient}", {
                  ns: "QuoteCreate",
                  recipient: project?.crmOrganisation?.title ?? "",
                })
              : undefined)
          }
          handleClose={handleClose}
          actionButtons={[
            {
              label: t("Cancel", {
                ns: "Global",
              }),
              handleClick: handleClose,
              buttonProps: {
                variant: "text",
                disabled: formikProps.isSubmitting,
              },
            },
            {
              label: t("Create Quote", {
                ns: "QuoteCreate",
              }),
              buttonProps: {
                form: formId,
                type: "submit",
                disabled: !formikProps.isValid,
                loading: formikProps.isSubmitting,
              },
            },
          ]}
          isLoading={
            query.loading || meister1FlowsIsLoading || sapQuotesQuery.loading
          }
          maxWidth={formikProps.values.source === "template" ? "xl" : "sm"}
          fixedHeight={formikProps.values.source === "template"}
        >
          <Form id={formId} style={{ height: "100%" }}>
            <Stack direction="column" spacing={1} height="100%" minHeight={0}>
              {!requestId && requestOptions.length > 1 && (
                <AutocompleteField
                  name="request"
                  inputLabel={t("Quote request", { ns: "QuoteCreate" })}
                  options={requestOptions}
                  getOptionLabel={option => option.title}
                  noOptionsText={t("No existing entry", { ns: "Global" })}
                />
              )}
              <RadioGroupField
                name="source"
                options={[
                  {
                    value: "template",
                    label: t("Select template", {
                      ns: "QuoteCreate",
                    }),
                  },
                  hasMeister1Options
                    ? {
                        value: "m1",
                        label: t("Select M1 calculator", {
                          ns: "QuoteCreate",
                        }),
                      }
                    : null,
                  hasSapOptions
                    ? {
                        value: "sap-quote",
                        label: t("Select SAP quote", {
                          ns: "QuoteCreate",
                        }),
                      }
                    : null,
                  {
                    value: "empty",
                    label: t("Create from scratch", {
                      ns: "QuoteCreate",
                    }),
                  },
                ].filter(notNull)}
                inline
              />

              {formikProps.values.source === "m1" && (
                <AutocompleteField
                  name="integration"
                  inputLabel={t("Meister1 calculator", {
                    ns: "QuoteCreate",
                  })}
                  options={integrationOptions}
                  getOptionLabel={option =>
                    calculators[option.label as M1Calculator]?.displayName ??
                    fallback(option.label).displayName
                  }
                  renderOption={(props, option) => (
                    <ListItem {...props}>
                      <ListItemIcon>
                        {calculators[option.label as M1Calculator]?.icon ??
                          fallback(option.label).icon}
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          calculators[option.label as M1Calculator]
                            ?.displayName ?? fallback(option.label).displayName
                        }
                      />
                    </ListItem>
                  )}
                  noOptionsText={t("No existing entry", {
                    ns: "Global",
                  })}
                />
              )}

              {hasMeister1Options && (
                <Meister1Process ref={meister1ProcessRef} />
              )}

              {formikProps.values.source === "template" && (
                <TemplatesDisplay
                  fixedFilters={{ applicableFor: ["QUOTE"] }}
                  selectedTemplate={formikProps.values.template}
                  setSelectedTemplate={template =>
                    formikProps.setFieldValue("template", template)
                  }
                  onInspectedTemplateClick={template => {
                    formikProps.setFieldValue("template", template);
                    setTimeout(async () => {
                      await formikProps.submitForm();
                    });
                  }}
                />
              )}

              {templateId && wizardAnswersResolver && (
                <QuoteCreateWizardModal
                  organisationId={viewer.organisation.id}
                  templateId={templateId}
                  onComplete={wizardAnswersResolver}
                />
              )}

              {formikProps.values.source === "sap-quote" && (
                <AutocompleteField
                  name="sapQuote"
                  inputLabel={t("SAP quote", {
                    ns: "QuoteCreate",
                  })}
                  options={sapQuotes}
                  getOptionLabel={option => option.title}
                  renderOption={(props, option) => (
                    <ListItem {...props} key={option.id}>
                      <ListItemText primary={option.title} />
                    </ListItem>
                  )}
                  noOptionsText={t("No existing entry", {
                    ns: "Global",
                  })}
                />
              )}

              {formikProps.values.source === "empty" && (
                <>
                  <Field
                    autoFocus
                    label={t("Title", {
                      ns: "Global",
                    })}
                    name="title"
                    component={TextField}
                    required
                  />
                  {canImportRequirement && (
                    <CheckboxField
                      label={t("Import client’s requirements", {
                        ns: "QuoteCreate",
                      })}
                      name="importRequirement"
                    />
                  )}
                  <CollapseSection
                    title={t("Further Options", { ns: "QuoteCreate" })}
                    isInitiallyExpanded={false}
                  >
                    <Stack direction="column" spacing={1}>
                      <Stack direction="row" spacing={1}>
                        <ContractTypeField
                          name="contractType"
                          label={t("Contract Type", {
                            ns: "QuoteCreate",
                          })}
                          required
                        />
                        <FormattedPercentageField
                          label={t("Discount", {
                            ns: "QuoteCreate",
                          })}
                          name="discountPercentage"
                          min={0}
                          max={100}
                        />
                      </Stack>
                      <VatField />
                    </Stack>
                  </CollapseSection>
                </>
              )}
            </Stack>
          </Form>
          {fromSapQuoteId && (
            <S4HanaQuoteCopyModal
              projectId={projectId}
              docId={fromSapQuoteId}
              handleClose={handleClose}
            />
          )}
        </Modal>
      )}
    </Formik>
  );
};
