import { Modal } from "@msys/ui";
import {
  DialogContentText,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { useQuoteTextReplacements } from "../../quotes/useQuoteTextReplacements";

export function AvailableContractingPdfReplacementsModal({
  handleClose,
}: {
  handleClose(): void;
}) {
  const { t } = useTranslate(["Global", "OrganisationSettings"]);
  const replacements = useQuoteTextReplacements();

  return (
    <Modal
      title={t("Using placeholders in texts", {
        ns: "OrganisationSettings",
      })}
      handleClose={handleClose}
      actionButtons={[
        {
          label: t("Close", {
            ns: "Global",
          }),
          handleClick: handleClose,
        },
      ]}
    >
      <DialogContentText>
        {t(
          "The following placeholders are available for use in every text replacements are listed below:",
          {
            ns: "OrganisationSettings",
          }
        )}
      </DialogContentText>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>
              {t("Placeholder", {
                ns: "OrganisationSettings",
              })}
            </TableCell>
            <TableCell>
              {t("Description", {
                ns: "OrganisationSettings",
              })}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.entries(replacements)
            .sort((a, b) => a[0].localeCompare(b[0]))
            .map(([name, description], index) => (
              <TableRow key={index}>
                <TableCell>{name}</TableCell>
                <TableCell>{description}</TableCell>
              </TableRow>
            ))}
          <TableRow>
            <TableCell>{'<prop("@root", "Key")>'}</TableCell>
            <TableCell>
              {t("The value of property from the quote root item", {
                ns: "OrganisationSettings",
              })}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Modal>
  );
}
