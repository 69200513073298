import { useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import {
  CardItem,
  DebouncedSearchInput,
  ModalOpenButton,
  useFormatting,
  useScreenWidth,
} from "@msys/ui";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Button,
  IconButton,
  Stack,
  TablePagination,
  Tooltip,
} from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { isNil, uniqBy } from "lodash";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import {
  OrganisationProjectsSorting,
  ProjectFilterKind,
  ProjectStateMachineStatus,
  namedOperations,
} from "../../../clients/graphqlTypes";
import { RestrictedByOrganisationPermissionWithDebug } from "../../auth/RestrictedByOrganisationPermission";
import { RestrictedByProjectPermissionWithDebug } from "../../auth/RestrictedByProjectPermission";
import { useUserData } from "../../auth/useUserData";
import { FilterButton } from "../../commons/filters/FilterButton";
import { useFiltersAndPagination } from "../../commons/filters/useFiltersAndPagination";
import { useStateWithUrlParam } from "../../commons/hooks/useStateWithUrlParam";
import { Page, PageTopbarItem } from "../../commons/layout/Page";
import { PageContainer } from "../../commons/layout/PageContainer";
import { useCustomFieldConfig } from "../../features/custom-fields/useCustomFieldConfig";
import {
  EditorView,
  EditorViewSwitch,
} from "../../features/planning/EditorViewSwitch";
import {
  PlanningMap,
  PlanningMapRef,
} from "../../features/planning/PlanningMap";
import { PlanningSchedule } from "../../features/planning/PlanningSchedule";
import { ProjectsFilterModal } from "../../features/projects/ProjectsFilterModal";
import { CreateProjectOrTicketButton } from "../../features/projects/buttons/CreateProjectOrTicketButton";
import { ProjectListItem } from "../../features/projects/components/ProjectListItem";
import { FilterProjectKindButton } from "../../features/projects/filters/ProjectKind";
import {
  FilterProjectPlanningButton,
  ProjectFilterPlanning,
} from "../../features/projects/filters/ProjectPlanning";
import { FilterProjectStateButton } from "../../features/projects/filters/ProjectState";
import { renderProjectsFilterChips } from "../../features/projects/renderProjectsFilterChips";
import {
  PROJECT_FILTER_MAP,
  ProjectSourceFilterType,
} from "../../features/projects/useProjectSources";
import { CalendarViewLayout } from "../../features/schedule/CalendarViewLayout";
import {
  MapProjectFragment,
  SchedulePlanSessionFragment,
} from "../../features/schedule/Fragments.generated";
import {
  PlanningProjectsQueryVariables,
  usePlanningProjectsQuery,
  usePlanningProjects_OrganisationQuery,
} from "./PlanningProjects.generated";
import { useSelectedDate } from "./useSelectedDate";

const DEFAULT_ITEMS_PER_PAGE = 25;
const DEFAULT_SORTING: OrganisationProjectsSorting[] = [
  {
    column: "createdAt",
    direction: "desc",
  },
];

type Filters = Omit<
  PlanningProjectsQueryVariables,
  "offset" | "limit" | "sorting" | "filterBySources"
> & {
  kind?: ProjectFilterKind[];
  state?: ProjectStateMachineStatus[];
  planning?: ProjectFilterPlanning[];
  sources?: ProjectSourceFilterType[] | undefined | null;
};

interface Props {
  submenuItems: PageTopbarItem[];
}

export const PlanningProjects = ({ submenuItems }: Props) => {
  const viewer = useUserData().currentUser!;
  const { t } = useTranslate(["Global", "SidebarItems", "Projects", "Tickets"]);
  const { isMaxPhone, isMinTablet } = useScreenWidth();
  const location = useLocation();

  const [editorView, setEditorView] = React.useState<EditorView>(
    EditorView.List
  );

  const [projectsFromSchedule, setProjectsFromSchedule] = React.useState<
    MapProjectFragment[]
  >([]);

  const { getFormattedPrice, getFormattedFloat } = useFormatting();

  const allowedStates: ProjectStateMachineStatus[] = [
    "contracted",
    "opportunity",
  ];

  const {
    offset,
    limit,
    paginationModel,
    setPaginationModel,
    filters,
    setFilters,
    resetFilters,
    sorting,
    setSorting,
    toRemoveParams,
  } = useFiltersAndPagination<OrganisationProjectsSorting, Filters>(
    {
      kind: ["DEFAULT", "TICKET"],
      state: allowedStates,
      planning: ["PLANNED", "UNPLANNED"],
    },
    DEFAULT_SORTING,
    DEFAULT_ITEMS_PER_PAGE
  );

  const searchRef = React.useRef<HTMLInputElement>(null);
  const [searchVisible, setSearchVisible] = React.useState<boolean>(
    Boolean(filters?.filterSearchTerm)
  );

  const toggleSearchVisible = () => {
    if (!searchVisible) {
      setSearchVisible(true);
      setTimeout(() => {
        searchRef.current?.focus();
      });
    } else {
      setSearchVisible(false);
    }
  };

  const client = useApolloClient();

  const projectsQuery = usePlanningProjectsQuery({
    client,
    fetchPolicy: "cache-and-network",
    variables: {
      limit,
      offset,
      ...filters,
      filterBySources: filters.sources
        ? filters.sources.map(s => PROJECT_FILTER_MAP[s]).flat(1)
        : undefined,
      filterIncludeState: filters.filterIncludeState?.length
        ? filters.filterIncludeState
        : allowedStates,
      sorting,
    },
  });

  const organisationQuery = usePlanningProjects_OrganisationQuery({
    client,
    fetchPolicy: "cache-and-network",
  });

  const organisationMemberships =
    organisationQuery.data?.organisationMemberships;

  const projects = React.useMemo(
    () =>
      getDataOrNull(
        (projectsQuery.data ?? projectsQuery.previousData)?.projects
      )?.edges.map(e => e.node) ?? [],
    [projectsQuery.data, projectsQuery.previousData]
  );

  const totalCount =
    getDataOrNull((projectsQuery.data ?? projectsQuery.previousData)?.projects)
      ?.totalCount ?? 0;

  const resources = React.useMemo(
    () =>
      (organisationMemberships ?? [])
        .slice()
        .sort((a, b) => a.familyname.localeCompare(b.familyname)),
    [organisationMemberships]
  );

  const roles = React.useMemo(
    () => organisationQuery.data?.roles ?? [],
    [organisationQuery.data?.roles]
  );

  const projectsForMap = React.useMemo(
    () => uniqBy([...projects, ...projectsFromSchedule], p => p.id),
    [projects, projectsFromSchedule]
  );

  const mapRef = React.useRef<PlanningMapRef>(null);

  const [selectedProjectId, setSelectedProjectId] = useStateWithUrlParam<
    string | null
  >("selectedId", null);

  const selectedProject = React.useMemo(
    () => projects.find(item => item.id === selectedProjectId),
    [projects, selectedProjectId]
  );

  const { customFieldConfig: customFieldConfigs } =
    useCustomFieldConfig("Project");

  React.useEffect(() => {
    const project = projects?.find(p => p.id === selectedProjectId);
    if (project && project.buildingInfo?.id)
      mapRef.current?.setSelectedBuildingId(project.buildingInfo.id);
  }, [projects, selectedProjectId]);

  React.useEffect(() => {
    if (projectsQuery.loading || projectsQuery.error) return;
    if (selectedProjectId && !selectedProject) {
      setSelectedProjectId(null);
    }
  }, [
    projectsQuery.loading,
    projectsQuery.error,
    selectedProject,
    selectedProjectId,
    setSelectedProjectId,
  ]);

  const selectedDates = useSelectedDate(selectedProject);

  const handlePlanSessionClick = React.useCallback(
    (planSession: SchedulePlanSessionFragment) => {
      mapRef.current?.setSelectedBuildingId(
        planSession.project.buildingInfo?.id
      );
    },
    []
  );

  const onKindChange = (
    value: ProjectFilterKind | null | undefined
  ): Partial<Filters> => ({
    kind: !value ? ["DEFAULT", "TICKET"] : [value],
    filterKind: value,
  });
  const onPlanningChange = (
    value: boolean | null | undefined
  ): Partial<Filters> => ({
    planning: isNil(value)
      ? ["PLANNED", "UNPLANNED"]
      : value
        ? ["PLANNED"]
        : ["UNPLANNED"],
    filterAssigned: value,
  });
  const onStateChange = (
    value: ProjectStateMachineStatus[] | null | undefined
  ): Partial<Filters> => ({
    state: !value || value.length === 0 ? allowedStates : value,
    filterIncludeState: value,
  });

  const List = (
    <Stack
      direction="column"
      flex={1}
      width="100%"
      height="100%"
      spacing={1}
      overflow="hidden"
    >
      <Stack direction="column" flexGrow={0} flexShrink={0} spacing={1}>
        <Stack
          direction="row"
          spacing={1}
          width="100%"
          justifyContent="space-between"
          alignItems="center"
          minWidth={0}
        >
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            flexShrink={1}
            flexGrow={1}
          >
            <FilterProjectKindButton
              value={filters.kind ?? []}
              setValue={newValue =>
                setFilters(state => ({
                  ...state,
                  kind: newValue,
                  filterKind:
                    newValue.length === 0 || newValue.length === 2
                      ? null
                      : newValue[0],
                }))
              }
            />
            <FilterProjectStateButton
              value={filters.state ?? []}
              setValue={newValue =>
                setFilters(state => ({
                  ...state,
                  state: newValue,
                  filterIncludeState: newValue,
                }))
              }
              allowedStates={allowedStates}
            />
            <FilterProjectPlanningButton
              value={filters.planning ?? []}
              setValue={newValue =>
                setFilters(state => ({
                  ...state,
                  planning: newValue,
                  filterAssigned:
                    newValue.length === 0 || newValue.length === 2
                      ? undefined
                      : newValue.includes("PLANNED")
                        ? true
                        : newValue.includes("UNPLANNED")
                          ? false
                          : undefined,
                }))
              }
            />
            <ModalOpenButton
              Modal={ProjectsFilterModal}
              modalProps={{
                sorting,
                setSorting,
                filters,
                setFilters,
                resetFilters,
                customFieldConfigs,
                allowedStates,
                onStateChange,
                onKindChange,
                onPlanningChange,
                showPhasesFilter: true,
                showSorting: true,
                showRelevantToMe: true,
              }}
            >
              <FilterButton />
            </ModalOpenButton>

            <IconButton
              style={{
                flexShrink: 0,
                flexGrow: 0,
                width: 30,
                height: 30,
              }}
              size="small"
              color="primary"
              onClick={toggleSearchVisible}
            >
              <SearchIcon />
            </IconButton>
          </Stack>

          <Stack direction="row" spacing={1} alignItems="center">
            {isMaxPhone && (
              <EditorViewSwitch value={editorView} setValue={setEditorView} />
            )}
            <RestrictedByOrganisationPermissionWithDebug permission="CREATE_PROJECT">
              <CreateProjectOrTicketButton
                type="button"
                pageName="PlanningProjects"
                refetchQueries={[namedOperations.Query.PlanningProjects]}
              />
            </RestrictedByOrganisationPermissionWithDebug>
          </Stack>
        </Stack>
        {searchVisible && (
          <Box>
            <DebouncedSearchInput
              inputRef={searchRef}
              fullWidth={isMaxPhone}
              placeholder={t("Search", { ns: "Global" })}
              defaultValue={filters?.filterSearchTerm ?? ""}
              onChangeSearchTerm={newValue =>
                setFilters(filterVariables => ({
                  ...filterVariables,
                  filterSearchTerm: newValue,
                }))
              }
            />
          </Box>
        )}
        {renderProjectsFilterChips({
          viewer,
          t,
          filters,
          setFilters,
          getFormattedPrice,
          getFormattedFloat,
          allowedStates,
          onStateChange,
          onKindChange,
          onPlanningChange,
          showRelevantToMe: !isMinTablet,
          showPhasesChip: true,
        })}
      </Stack>

      <Stack
        direction="column"
        spacing={0.5}
        flexGrow={1}
        flexShrink={1}
        overflow="auto"
      >
        {projects.map(project => (
          <CardItem
            key={project.id}
            onClick={() => {
              setSelectedProjectId(project.id);
            }}
            selected={project === selectedProject}
          >
            <ProjectListItem
              project={project}
              Action={
                <Stack direction="row" spacing={0.5} alignItems="center">
                  <IconButton
                    size="small"
                    color="primary"
                    style={{ flexGrow: 0, flexShrink: 0 }}
                    onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
                      e.stopPropagation();
                    }}
                    component={Link}
                    to={`/projects/${project.id}`}
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    <Tooltip
                      title={t("Open project", {
                        ns: "Projects",
                      })}
                      placement="top"
                      arrow
                    >
                      <OpenInNewIcon fontSize="small" />
                    </Tooltip>
                  </IconButton>

                  {project.assignees.length > 0 && (
                    <RestrictedByProjectPermissionWithDebug
                      permission="MANAGE_PROJECT"
                      project={project}
                    >
                      {viewer.organisation.id ===
                        project.owningSystemOrganisationId && (
                        <Button
                          size="extra-small"
                          color="primary"
                          variant="outlined"
                          onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
                            e.stopPropagation();
                          }}
                          component={Link}
                          to={{
                            pathname: `/planning/projects/${project.id}`,
                            search: new URLSearchParams({
                              r: location.pathname + location.search,
                            }).toString(),
                          }}
                        >
                          {t("Re-schedule", {
                            ns: "Projects",
                          })}
                        </Button>
                      )}
                    </RestrictedByProjectPermissionWithDebug>
                  )}

                  {project.assignees.length === 0 && (
                    <RestrictedByProjectPermissionWithDebug
                      permission="MANAGE_PROJECT"
                      project={project}
                    >
                      {viewer.organisation.id ===
                        project.owningSystemOrganisationId && (
                        <Button
                          size="extra-small"
                          color="primary"
                          variant="outlined"
                          onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
                            e.stopPropagation();
                          }}
                          component={Link}
                          to={{
                            pathname: `/planning/projects/${project.id}`,
                            search: new URLSearchParams({
                              r: location.pathname + location.search,
                            }).toString(),
                          }}
                        >
                          {t("Schedule", {
                            ns: "Projects",
                          })}
                        </Button>
                      )}
                    </RestrictedByProjectPermissionWithDebug>
                  )}
                </Stack>
              }
            />
          </CardItem>
        ))}
      </Stack>

      <TablePagination
        component="div"
        count={totalCount}
        page={paginationModel.page}
        onPageChange={(event, newPage) => {
          setPaginationModel({
            pageSize: paginationModel.pageSize,
            page: newPage,
          });
        }}
        rowsPerPage={paginationModel.pageSize}
        onRowsPerPageChange={event => {
          setPaginationModel({
            pageSize: parseInt(event.target.value, 10),
            page: 0,
          });
        }}
        labelRowsPerPage={t("Per page:", { ns: "Global" })}
      />
    </Stack>
  );

  const [resourceMaxDistance, setResourceMaxDistance] =
    React.useState<number>(0);

  const Map = (
    <PlanningMap
      ref={mapRef}
      projects={projectsForMap}
      resources={resources}
      projectAddress={
        selectedProject?.buildingInfo?.buildingAddress ?? undefined
      }
      resourceMaxDistance={resourceMaxDistance}
      maxWidth={9999}
      RightButtons={
        isMaxPhone ? (
          <>
            <EditorViewSwitch value={editorView} setValue={setEditorView} />
            <RestrictedByOrganisationPermissionWithDebug permission="CREATE_PROJECT">
              <CreateProjectOrTicketButton
                type="button"
                pageName="PlanningProjects"
                refetchQueries={[
                  namedOperations.Query.ProjectsList,
                  namedOperations.Query.PlanningProjects,
                ]}
              />
            </RestrictedByOrganisationPermissionWithDebug>
          </>
        ) : undefined
      }
    />
  );

  const Calendar = (
    <PlanningSchedule
      key={`planning-schedule-${selectedProject?.id ?? "all"}`}
      usePinnedResources={false}
      roles={roles}
      resources={resources}
      view={"TimelineWeek"}
      selectedProjectId={selectedProject?.id}
      projectAddress={
        selectedProject?.buildingInfo?.buildingAddress ?? undefined
      }
      useMaxDistance={Boolean(
        selectedProject?.id && selectedProject?.buildingInfo?.buildingAddress
      )}
      activeDates={selectedDates}
      onPlanSessionClick={handlePlanSessionClick}
      onResourceMaxDistanceChange={setResourceMaxDistance}
      onProjectsChange={setProjectsFromSchedule}
      RightButtons={
        isMaxPhone ? (
          <>
            <EditorViewSwitch value={editorView} setValue={setEditorView} />
            <RestrictedByOrganisationPermissionWithDebug permission="CREATE_PROJECT">
              <CreateProjectOrTicketButton
                type="button"
                pageName="PlanningProjects"
                refetchQueries={[
                  namedOperations.Query.ProjectsList,
                  namedOperations.Query.PlanningProjects,
                ]}
              />
            </RestrictedByOrganisationPermissionWithDebug>
          </>
        ) : undefined
      }
    />
  );

  return (
    <Page
      subtitle={t("Planning and Resources", {
        ns: "SidebarItems",
      })}
      title={t("Projects", {
        ns: "Projects",
      })}
      submenuItems={submenuItems}
    >
      <PageContainer style={{ minHeight: "100%", height: "unset" }}>
        {isMinTablet ? (
          <CalendarViewLayout
            name="projects"
            List={
              <Stack
                spacing={1}
                height="100%"
                flexGrow={1}
                flexShrink={1}
                direction="row"
                justifyContent="space-between"
                alignItems="stretch"
              >
                <Box
                  height="100%"
                  flexShrink={0}
                  flexGrow={0}
                  overflow="auto"
                  sx={{ width: { xs: "620px", lg: "720px" } }}
                >
                  {List}
                </Box>
                <Box height="100%" flexGrow={1} flexShrink={1}>
                  {Map}
                </Box>
              </Stack>
            }
            Calendar={Calendar}
          />
        ) : (
          <Stack
            flex={1}
            spacing={1}
            direction="column"
            justifyContent="stretch"
            alignItems="stretch"
          >
            {editorView === EditorView.List && List}
            {editorView === EditorView.Map && Map}
            {editorView === EditorView.Calendar && Calendar}
          </Stack>
        )}
      </PageContainer>
    </Page>
  );
};
