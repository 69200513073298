export type DeployEnv = "local" | "dev" | "stg" | "partner" | "prod";

const {
  VITE_DEPLOY_ENV,
  VITE_GRAPHQL_URL,
  VITE_GRAPHQL_LAMBDA_URL,
  VITE_CAPACITOR_GRAPHQL_URL,
  VITE_VERSION,
  VITE_KEYCLOAK_AUTH_SERVER,
  VITE_KEYCLOAK_REALM,
  VITE_KEYCLOAK_CLIENT,
} = import.meta.env;

if (!VITE_DEPLOY_ENV)
  throw new Error(`environment variable VITE_DEPLOY_ENV not set`);

// TODO check VITE_DEPLOY_ENV value is actually a valid value of ServerEnvironment

export const DEPLOY_ENV = VITE_DEPLOY_ENV as DeployEnv;

export const GRAPHQL_ENDPOINT_URI = VITE_GRAPHQL_URL;
export const LAMBDA_GRAPHQL_ENDPOINT_URI = VITE_GRAPHQL_LAMBDA_URL;
export const CAPACITOR_GRAPHQL_ENDPOINT_URL = VITE_CAPACITOR_GRAPHQL_URL;

export const KEYCLOAK_AUTH_SERVER = VITE_KEYCLOAK_AUTH_SERVER;
export const KEYCLOAK_REALM = VITE_KEYCLOAK_REALM;
export const KEYCLOAK_CLIENT = VITE_KEYCLOAK_CLIENT;

export const VERSION = VITE_VERSION;

export const THREED_PLANNER_URL = import.meta.env.VITE_THREED_PLANNER_URL;
export const THREED_PLANNER_SHOW = import.meta.env.VITE_THREED_PLANNER_SHOW;

export const ICAL_GENERATOR_ENDPOINT = import.meta.env
  .VITE_ICAL_GENERATOR_ENDPOINT;
export const TERMS_AND_CONDITIONS_URL = import.meta.env
  .VITE_TERMS_AND_CONDITIONS_URL;

export const GOOGLE_MAPS_API_KEY = import.meta.env.VITE_GOOGLE_MAPS_API_KEY;

export const EMBEDDABLE_WIZARD_URL = import.meta.env.VITE_EMBEDDABLE_WIZARD_URL;
