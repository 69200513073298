import { useApolloClient } from "@apollo/client";
import { CardContainer, LoadingSpinner } from "@msys/ui";
import { Box, Divider, Stack, Typography } from "@mui/material";
import React from "react";
import { namedOperations } from "../../clients/graphqlTypes";
import { LogoutButton } from "../auth/LogoutButton";
import { SwicthOrganisationButton } from "../auth/SwicthOrganisationButton";
import { useAvailableUsers } from "../auth/useAvailableUsers";
import { PageNotFound } from "../commons/PageNotFound";
import { Page } from "../commons/layout/Page";
import { PageContainer } from "../commons/layout/PageContainer";
import { PageGrid } from "../commons/layout/PageGrid";
import { Attachment } from "../features/attachments/helpers";
import { UserAvatar } from "../features/users/UserAvatar";
import { UserDeleteAccountButton } from "../features/users/UserDeleteAccountButton";
import { UserInformationBox } from "../features/users/UserInformationBox";
import { UserRolesBox } from "../features/users/UserRolesBox";
import { UserCalendarBox } from "../features/users/boxes/UserCalendarBox";
import {
  useModifyMyUserAvatarMutation,
  useMyUserProfileQuery,
} from "./MyUserProfile.generated";

export function MyUserProfile() {
  const client = useApolloClient();
  const userQuery = useMyUserProfileQuery({
    client,
  });
  const { availableUsers } = useAvailableUsers();

  const [modifyUser, { loading: modifyUserLoading }] =
    useModifyMyUserAvatarMutation({
      client,
      refetchQueries: [namedOperations.Query.MyUserProfile],
    });

  async function onAttachment(attachment: Attachment | null) {
    await modifyUser({
      variables: {
        user: {
          avatar: attachment
            ? {
                url: attachment.url,
                title: attachment.title,
                mimeType: attachment.mimeType,
              }
            : null,
        },
      },
    });
  }

  const user = userQuery.data?.viewer;
  if (userQuery.loading && !user) return <LoadingSpinner />;
  if (!user) return <PageNotFound />;

  return (
    <Page title={`${user.firstname} ${user.familyname}`}>
      <PageContainer>
        <PageGrid columns={{ xs: 1, md: 2, xl: 4 }}>
          <>
            <CardContainer>
              <Stack direction="column" p={1} spacing={1}>
                <Stack
                  direction="row"
                  spacing={1}
                  justifyContent="center"
                  mb={1}
                >
                  <UserAvatar
                    userAvatar={user}
                    size="l"
                    onAttachment={onAttachment}
                    disabled={modifyUserLoading}
                  />
                </Stack>
                <Stack direction="column" spacing={1 / 2}>
                  <Typography variant="h2" align="center">
                    {user.firstname} {user.familyname}
                  </Typography>
                  {user && (
                    <Typography align="center">
                      {user.organisation.title}
                    </Typography>
                  )}
                </Stack>
                <UserInformationBox user={user} />
                <Divider />
                <UserRolesBox user={user} />
              </Stack>
            </CardContainer>

            <Box>
              <LogoutButton type="button" />
            </Box>
            {availableUsers.length > 1 && (
              <Box>
                <SwicthOrganisationButton fullWidth />
              </Box>
            )}
          </>
          <>
            <UserCalendarBox userId={user.id} />
            <Box>
              <UserDeleteAccountButton />
            </Box>
          </>
        </PageGrid>
      </PageContainer>
    </Page>
  );
}
