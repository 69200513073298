import { getFormattedPrice } from "@msys/formatting";
import { useTolgee } from "@tolgee/react";
import React from "react";
import { FilterChip } from "./FilterChip";

export function FilterPriceRangeChip({
  label,
  valueMin,
  valueMax,
  onReset,
}: {
  label: string;
  valueMin?: number | null;
  valueMax?: number | null;
  onReset(): void;
}) {
  const language = useTolgee(["language"]).getLanguage()!;
  if (valueMin && valueMax) {
    return (
      <FilterChip
        label={label}
        value={`${getFormattedPrice(valueMin, language)} – ${getFormattedPrice(
          valueMax,
          language
        )}`}
        onDelete={onReset}
      />
    );
  } else if (valueMin && !valueMax) {
    return (
      <FilterChip
        value={`${label} ≥ ${getFormattedPrice(valueMin, language)}`}
        onDelete={onReset}
      />
    );
  } else if (!valueMin && valueMax) {
    return (
      <FilterChip
        value={`${label} ≤ ${getFormattedPrice(valueMax, language)}`}
        onDelete={onReset}
      />
    );
  }
  return null;
}
