import { ButtonInputWithPopover, useSyncStates } from "@msys/ui";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Typography,
} from "@mui/material";
import { useTranslate } from "@tolgee/react";
import React from "react";
import { ProjectFilterKind } from "../../../../clients/graphqlTypes";
import { FilterProps } from "../../../commons/filters";
import {
  FilterChip,
  FilterChipProps,
} from "../../../commons/filters/FilterChip";
import { useProjectKinds } from "../useProjectKinds";

export function FilterProjectKindForm({
  value: externalValue,
  setValue: setExternalValue,
}: FilterProps<ProjectFilterKind[]>) {
  const { t } = useTranslate(["Global"]);
  const { projectKindOptions } = useProjectKinds();
  const [value, setValue, error] = useSyncStates(
    externalValue,
    setExternalValue,
    value => {
      if (value.length === 0)
        return t("Please select at least one value", { ns: "Global" });
    }
  );
  return (
    <Box p={1}>
      <FormControl
        component="fieldset"
        variant="standard"
        error={Boolean(error)}
      >
        <FormGroup>
          {projectKindOptions.map((option, index) => (
            <FormControlLabel
              control={
                <Checkbox
                  size="medium"
                  checked={value.includes(option.value)}
                  onChange={(event, checked) => {
                    if (checked) {
                      setValue(Array.from(new Set([...value, option.value])));
                    } else {
                      setValue(value.filter(v => v !== option.value));
                    }
                  }}
                />
              }
              label={<Typography variant="body1">{option.label}</Typography>}
              sx={{ ml: 0, mr: 0 }}
              key={`${option.value}-${index}`}
            />
          ))}
        </FormGroup>
        {Boolean(error) && <FormHelperText>{error}</FormHelperText>}
      </FormControl>
    </Box>
  );
}

export function FilterProjectKindButton(
  props: FilterProps<ProjectFilterKind[]>
) {
  const { t } = useTranslate("ProjectTypes");
  const { projectKindOptions } = useProjectKinds();

  return (
    <ButtonInputWithPopover
      label={t("Size")}
      counter={
        props.value &&
        props.value.length > 0 &&
        props.value.length < projectKindOptions.length
          ? props.value.length
          : undefined
      }
      content={<FilterProjectKindForm {...props} />}
      popoverWidth="md"
    />
  );
}

export function FilterProjectKindChip(
  props: Omit<FilterChipProps<ProjectFilterKind>, "label" | "getValueLabel">
) {
  const { t } = useTranslate("ProjectTypes");
  const { projectKindLabels } = useProjectKinds();

  return (
    <FilterChip
      label={t("Size")}
      resetValue={null}
      setValue={props.setValue}
      value={props.value}
      getValueLabel={value => projectKindLabels[value]}
    />
  );
}
