import { AutocompleteMultiple, CollapseSection } from "@msys/ui";
import { Typography, Stack } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { FormikProps, useFormik } from "formik";
import React, { Dispatch, SetStateAction } from "react";
import {
  OrganisationQuoteTemplatesFilters,
  OrganisationQuoteTemplatesSorting,
  TemplateSearchSortingInput,
} from "../../../../clients/graphqlTypes";
import { FilterModal } from "../../../commons/filters/FilterModal";
import {
  FilterAddedToShop,
  FilterCreatedAfter,
  FilterCreatedBefore,
  FilterMaxPrice,
  FilterMinPrice,
  QuoteTemplatesSorting,
} from "../filters";
import {
  templateFiltersDefaultValue,
  TemplateFiltersFormValues,
  templateFiltersValidationSchema,
  useTemplateFilterFields,
} from "../filters/TemplateFilterFields";
import { TemplateFilterFields_AvailableFiltersQueryVariables } from "../filters/TemplateFilterFields.generated";
import { RestrictedByCapabilityWithDebug } from "../../../auth/RestrictedByCapability";

export const DEFAULT_SORTING: OrganisationQuoteTemplatesSorting[] = [
  {
    column: "createdAt",
    direction: "desc",
  },
];

export const DEFAULT_SORTING2: TemplateSearchSortingInput[] = [
  {
    fieldSorting: { field: "createdAt", direction: "desc" },
  },
];

export type Filters = Pick<
  OrganisationQuoteTemplatesFilters,
  | "createdBefore"
  | "createdAfter"
  | "priceMin"
  | "priceMax"
  | "tagsAny"
  | "addedToShop"
>;

interface Props<F extends Filters> {
  filters: F;
  setFilters: Dispatch<SetStateAction<F>>;
  resetFilters: () => void;
  sorting: OrganisationQuoteTemplatesSorting[];
  setSorting: (newSorting: OrganisationQuoteTemplatesSorting[]) => void;
  handleClose: () => void;
  tags: string[];
}

export const TemplateQuotesFilterModal = <F extends Filters>({
  filters,
  setFilters,
  resetFilters,
  sorting,
  setSorting,
  handleClose,
  tags,
}: Props<F>) => {
  const { t } = useTranslate(["TemplatesSearch", "Global"]);

  const [filtersState, setFiltersState] = React.useState(filters);
  const [sortingState, setSortingState] = React.useState(sorting);

  return (
    <FilterModal
      title={t("Template filters", { ns: "TemplatesSearch" })}
      handleApply={async () => {
        setFilters(filtersState);
        setSorting(sortingState);
      }}
      handleClose={handleClose}
      handleReset={async () => {
        resetFilters();
      }}
    >
      <Typography variant="h3">{t("Sort by", { ns: "Global" })}</Typography>
      <QuoteTemplatesSorting
        sorting={sortingState[0]}
        setSorting={setSortingState}
        defaultSorting={DEFAULT_SORTING}
      />
      <Typography variant="h3">{t("Filter by", { ns: "Global" })}</Typography>
      <TemplateFiltersCommonForm
        tags={tags}
        filtersState={filtersState}
        setFiltersState={setFiltersState}
      />
    </FilterModal>
  );
};

export const TemplateQuotesFilterWithTemplateTypeModal = <F extends Filters>({
  filters,
  setFilters,
  resetFilters,
  sorting,
  setSorting,
  handleClose,
  tags,
  templateSearchVariables,
  formikProps: outsideFormikProps,
}: Props<F> & {
  templateSearchVariables: TemplateFilterFields_AvailableFiltersQueryVariables;
  formikProps: FormikProps<TemplateFiltersFormValues>;
}) => {
  const { t } = useTranslate(["TemplatesSearch", "Global"]);

  const [filtersState, setFiltersState] = React.useState(filters);
  const [sortingState, setSortingState] = React.useState(sorting);

  // we need an inner formik state to not immediately apply filters on the list
  const formikProps = useFormik<TemplateFiltersFormValues>({
    // @ts-ignore
    initialValues: {
      ...templateFiltersDefaultValue,
      ...outsideFormikProps.values,
    },
    validationSchema: templateFiltersValidationSchema,
    validateOnMount: true,
    onSubmit: async (newValues, formikHelpers) => {
      outsideFormikProps.setValues(newValues);
      await outsideFormikProps.submitForm();
    },
  });

  const { nonPropertyFields, propertyFields, isLoading } =
    useTemplateFilterFields({
      formikProps,
      templateSearchVariables,
    });

  return (
    <FilterModal
      title={t("Template filters", { ns: "TemplatesSearch" })}
      handleApply={async () => {
        setFilters(filtersState);
        setSorting(sortingState);
        await formikProps.submitForm();
      }}
      handleClose={handleClose}
      handleReset={async () => {
        resetFilters();
        await formikProps.setValues(templateFiltersDefaultValue);
        await formikProps.submitForm();
      }}
    >
      <Typography variant="h3">{t("Sort by", { ns: "Global" })}</Typography>
      <QuoteTemplatesSorting
        sorting={sortingState[0]}
        setSorting={setSortingState}
        defaultSorting={DEFAULT_SORTING}
      />
      <Typography variant="h3">{t("Filter by", { ns: "Global" })}</Typography>
      {nonPropertyFields}
      <TemplateFiltersCommonForm
        tags={tags}
        filtersState={filtersState}
        setFiltersState={setFiltersState}
      />
      <RestrictedByCapabilityWithDebug capability="SHOP">
        <FilterAddedToShop
          value={filtersState.addedToShop ?? undefined}
          setValue={newValue =>
            setFiltersState(filters => ({
              ...filters,
              addedToShop: newValue ?? null,
            }))
          }
        />
      </RestrictedByCapabilityWithDebug>
      {propertyFields.length > 0 && (
        <CollapseSection
          title={t("Properties", { ns: "TemplatesSearch" })}
          isInitiallyExpanded={true}
          itemCount={propertyFields.length}
        >
          <Stack direction="column" spacing={1}>
            {propertyFields}
          </Stack>
        </CollapseSection>
      )}
    </FilterModal>
  );
};

const TemplateFiltersCommonForm = <F extends Filters>({
  tags,
  filtersState,
  setFiltersState,
}: {
  tags: string[];
  filtersState: F;
  setFiltersState: React.Dispatch<React.SetStateAction<F>>;
}) => {
  const { t } = useTranslate(["Templates", "Global"]);

  return (
    <>
      <Stack direction="row" spacing={1}>
        <FilterMinPrice
          value={filtersState.priceMin ?? 0}
          setValue={newValue =>
            setFiltersState(filters => ({
              ...filters,
              priceMin: newValue === 0 ? null : newValue,
            }))
          }
        />
        <FilterMaxPrice
          value={filtersState.priceMax ?? 0}
          setValue={newValue =>
            setFiltersState(filters => ({
              ...filters,
              priceMax: newValue === 0 ? null : newValue,
            }))
          }
        />
      </Stack>
      <Stack direction="row" spacing={1}>
        <FilterCreatedAfter
          value={filtersState.createdAfter}
          setValue={newValue => {
            setFiltersState(filters => ({
              ...filters,
              createdAfter: newValue,
            }));
          }}
        />
        <FilterCreatedBefore
          value={filtersState.createdBefore}
          setValue={newValue =>
            setFiltersState(filters => ({
              ...filters,
              createdBefore: newValue,
            }))
          }
        />
      </Stack>
      <AutocompleteMultiple
        inputLabel={t("Tags", { ns: "Templates" })}
        options={tags}
        value={filtersState.tagsAny ?? []}
        onChange={value => {
          setFiltersState(state => ({ ...state, tagsAny: value }));
        }}
        filterOptions={(options, state) =>
          options.filter(option =>
            option
              .toLocaleLowerCase()
              .trim()
              .includes(state.inputValue.toLocaleLowerCase().trim())
          )
        }
      />
    </>
  );
};
