import { Capacitor } from "@capacitor/core";
import { assertNever } from "@msys/common";
import React from "react";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useMatch,
  useNavigate,
} from "react-router-dom";
import { SplashScreen } from "../common/SplashScreen";
import { LandingPage } from "./LandingPage";
import { M1RedirectRoute } from "./M1RedirectRoute";
import { CreateNewOrganisationPage } from "./auth/CreateNewOrganisationPage";
import { CurrentUserError } from "./auth/CurrentUserError";
import { InvalidSelectedUserError } from "./auth/InvalidSelectedUserError";
import { SelectUserPage } from "./auth/SelectUserPage";
import { SessionExpiredError } from "./auth/SessionExpiredError";
import { useAuth } from "./auth/useAuth";
import { useAvailableUsers } from "./auth/useAvailableUsers";
import { useSelectedUser } from "./auth/useSelectedUser";
import { useUserData } from "./auth/useUserData";
import { InstallerRoutes } from "./installer-routes/Routes";
import { CrmLinkingInvitation } from "./invitation/CrmLinkingInvitation";
import { InvitationPage } from "./invitation/InvitationPage";
import { OrganisationMembershipInvitation } from "./invitation/OrganisationMembershipInvitation";
import { ProjectContracteeInvitation } from "./invitation/ProjectContracteeInvitation";
import { useInvitationTokenContext } from "./invitation/useInvitationTokenContext";
import { MainRoutes } from "./main-routes/Routes";
import { OrganisationPublicRoutes } from "./public-routes/OrganisationPublicRoutes";
import { useUrlSearchParams } from "./commons/hooks/useUrlSearchParams";
import { ORGANISATION_TYPE_URL_SEARCH_PARAM } from "./constants";
import { isValidOrganisationType } from "./auth/isValidOrganisationType";
import { ImplicitFlow } from "./auth/ImplicitFlow";

export const AppRoutes = () => {
  const location = useLocation();
  const { isReady, isAuthenticated } = useAuth();

  const {
    token,
    tokenData,
    isLoading: invitationTokenDataIsLoading,
  } = useInvitationTokenContext();

  if (!isReady || invitationTokenDataIsLoading) {
    return <SplashScreen />;
  }

  if (
    !isAuthenticated &&
    token &&
    tokenData &&
    tokenData?.__typename !== "ReferralInfo"
  ) {
    return (
      <InvitationPage isAuthenticated={false}>
        {tokenData.__typename === "OrganisationMembershipInvitation" ? (
          <OrganisationMembershipInvitation
            isAuthenticated={false}
            invitation={tokenData}
          />
        ) : tokenData.__typename === "ProjectContracteeInvitation" ? (
          <ProjectContracteeInvitation
            isAuthenticated={false}
            invitation={tokenData}
          />
        ) : tokenData.__typename === "CrmLinkingInvitation" ? (
          <CrmLinkingInvitation
            isAuthenticated={false}
            invitation={tokenData}
          />
        ) : (
          assertNever(tokenData)
        )}
      </InvitationPage>
    );
  }

  return (
    <Routes>
      {/* LEGACY REDIRECTS */}
      <Route
        path="/authenticate"
        element={
          <Navigate to={{ pathname: "/next", search: location.search }} />
        }
      />
      <Route
        path="/authenticate/login"
        element={
          <Navigate to={{ pathname: "/next", search: location.search }} />
        }
      />
      <Route
        path="/authenticate/signup"
        element={
          <Navigate to={{ pathname: "/next", search: location.search }} />
        }
      />
      <Route
        path="/authenticate/signup/craftsman"
        element={
          <Navigate to={{ pathname: "/next", search: location.search }} />
        }
      />
      <Route
        path="/authenticate/signup/client"
        element={
          <Navigate to={{ pathname: "/next", search: location.search }} />
        }
      />
      {/* LEGACY REDIRECTS */}

      <Route index element={<LandingPage />} />
      <Route path="/org/*" element={<OrganisationPublicRoutes />} />
      <Route path={"*"} element={<AuthenticatedRoutes />} />
    </Routes>
  );
};

const AuthenticatedRoutes = () => {
  const { auth, isReady, isAuthenticated } = useAuth();
  const { selectedUserId, setSelectedUserId } = useSelectedUser();
  const {
    availableUsers,
    isLoading: availableUsersIsLoading,
    refetch: refetchAvailableUsers,
  } = useAvailableUsers();
  const {
    token,
    tokenData,
    isLoading: invitationTokenDataIsLoading,
  } = useInvitationTokenContext();
  const navigate = useNavigate();

  const { urlSearchParams } = useUrlSearchParams();
  const organisationType =
    urlSearchParams.get(ORGANISATION_TYPE_URL_SEARCH_PARAM) ?? "CRAFTSMAN";
  if (!isValidOrganisationType(organisationType)) {
    throw new Error("Invalid organisation type");
  }

  React.useEffect(() => {
    if (!selectedUserId && availableUsers.length === 1) {
      setSelectedUserId(availableUsers[0].id);
    }
  }, [availableUsers, selectedUserId, setSelectedUserId]);

  React.useEffect(() => {
    if (isReady && !isAuthenticated) {
      if (Capacitor.isNativePlatform()) {
        navigate("/");
      } else {
        auth.login();
      }
    }
  }, [auth, isAuthenticated, isReady, navigate]);

  if (!isAuthenticated) {
    return <SplashScreen />;
  }

  if (invitationTokenDataIsLoading) {
    return <SplashScreen />;
  }

  if (
    selectedUserId &&
    !availableUsersIsLoading &&
    availableUsers.every(user => user.id !== selectedUserId)
  ) {
    return <InvalidSelectedUserError />;
  }

  if (
    token &&
    tokenData &&
    tokenData.__typename === "OrganisationMembershipInvitation"
  ) {
    return (
      <InvitationPage isAuthenticated={true}>
        <OrganisationMembershipInvitation
          invitation={tokenData}
          isAuthenticated={true}
        />
      </InvitationPage>
    );
  }

  if (
    !availableUsersIsLoading &&
    availableUsers.length === 0 &&
    token &&
    tokenData &&
    tokenData?.__typename === "ProjectContracteeInvitation" &&
    organisationType === "CLIENT"
  ) {
    return (
      <ImplicitFlow
        invitationToken={tokenData.invitationToken}
        organisationName={`${auth.idTokenParsed?.given_name ?? ""} ${auth.idTokenParsed?.family_name ?? ""}`}
      />
    );
  }

  if (!availableUsersIsLoading && availableUsers.length === 0) {
    return (
      <CreateNewOrganisationPage
        referral={
          token && tokenData?.__typename === "ReferralInfo"
            ? { token, data: tokenData }
            : null
        }
        onComplete={userId => {
          setSelectedUserId(userId);
        }}
      />
    );
  }

  if (token && tokenData) {
    if (tokenData?.__typename === "ProjectContracteeInvitation") {
      return (
        <InvitationPage isAuthenticated={true}>
          <ProjectContracteeInvitation
            invitation={tokenData}
            isAuthenticated={true}
            availableUsers={availableUsers}
            availableUsersIsLoading={availableUsersIsLoading}
          />
        </InvitationPage>
      );
    } else if (tokenData?.__typename === "CrmLinkingInvitation") {
      return (
        <InvitationPage isAuthenticated={true}>
          <CrmLinkingInvitation
            invitation={tokenData}
            isAuthenticated={true}
            availableUsers={availableUsers}
            availableUsersIsLoading={availableUsersIsLoading}
          />
        </InvitationPage>
      );
    }
  }

  if (!selectedUserId) {
    return (
      <SelectUserPage
        availableUsers={availableUsers}
        availableUsersIsLoading={availableUsersIsLoading}
        onMount={refetchAvailableUsers}
      />
    );
  }

  return <UserRoutes />;
};

const UserRoutes = () => {
  const { isAuthenticated } = useAuth();
  const {
    isLoading: userDataIsLoading,
    currentUser,
    appViewerRole,
  } = useUserData();
  const match = useMatch(`/partner/m1plus/:m1AccountId/quote/:m1OfferNumber`);

  if (userDataIsLoading) {
    return <SplashScreen />;
  }

  if (!currentUser || !appViewerRole) {
    if (!isAuthenticated) {
      return <SessionExpiredError />;
    }

    return <CurrentUserError />;
  }

  if (match && match.params.m1AccountId && match.params.m1OfferNumber) {
    return (
      <M1RedirectRoute
        m1AccountId={Number(match.params.m1AccountId)}
        m1OfferNumber={match.params.m1OfferNumber}
      />
    );
  }

  // use a separate router for installers
  if (appViewerRole === "INSTALLER") {
    return <InstallerRoutes />;
  }

  return <MainRoutes />;
};
