import ErrorIcon from "@mui/icons-material/Error";
import {
  StandardTextFieldProps as MuiTextFieldProps,
  Tooltip,
} from "@mui/material";
import { Field, useField } from "formik";
import { TextField as MuiTextField } from "formik-mui";
import { isString } from "lodash";
import React, { useState } from "react";
import { getSelectedText } from "../../utils";

export interface FormattedFieldProps<Value> extends MuiTextFieldProps {
  id?: string;
  name: string;
  autoCompleteDisabled?: boolean;
  getFormattedValue: (value: Value | null | undefined) => string | undefined;
  getActualValue: (value: string) => Value;
  getEditableValue: (value: Value | null | undefined) => string;
  setFieldValue?: (field: string, value: Value) => void;
}

export const FormattedField = <Value,>({
  id,
  name,
  label,
  placeholder,
  autoCompleteDisabled = true,
  getFormattedValue,
  getActualValue,
  getEditableValue,
  setFieldValue: setFieldValueProp,
  ...props
}: FormattedFieldProps<Value>) => {
  const [isFocused, setIsFocused] = useState(false);
  const [dirtyValue, setDirtyValue] = useState("");

  const [{ value }, { error }, { setValue: setFormValue, setTouched }] =
    useField<Value>(name);

  const setValue = (value: string) => {
    if (setFieldValueProp) {
      setFieldValueProp(name, getActualValue(value));
    } else {
      setFormValue(getActualValue(value));
    }
  };

  function getValue(value: Value | null | undefined) {
    if (isFocused) {
      return dirtyValue;
    } else {
      return getFormattedValue(value);
    }
  }

  return (
    <Field
      {...props}
      component={MuiTextField}
      type={"text"}
      id={id ?? name}
      name={name}
      label={label}
      placeholder={placeholder}
      value={getValue(value) ?? ""}
      autoComplete={autoCompleteDisabled ? "off" : undefined}
      onChange={async (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      ) => {
        const dirtyValue = event.target.value;
        setDirtyValue(dirtyValue);
        setValue(dirtyValue);
        // Needed to resolve current field props.
        // https://github.com/jaredpalmer/formik/issues/529#issuecomment-400832225
        await Promise.resolve();
        setTouched(true);
      }}
      onFocus={(e: React.FocusEvent<HTMLInputElement>) => {
        const wasSelected = getSelectedText() === e.target.value;

        if (getEditableValue) {
          setIsFocused(true);
          setDirtyValue(getEditableValue(value));
          if (wasSelected) {
            setTimeout(() => {
              e.target.select();
            });
          }
        }
      }}
      onSelect={(e: React.SyntheticEvent<HTMLInputElement>) => {
        e.preventDefault(); // prevent context menu from opening (on mobile) when selecting text
      }}
      onBlur={(event: any) => {
        setIsFocused(false);
        if (props.onBlur) {
          props.onBlur(event);
        }
      }}
      error={Boolean(error) && isString(error)}
      helperText={Boolean(error) && isString(error) ? error : undefined}
      style={props.style}
      // error handling for extra-small size
      {...(props.size === "extra-small" && Boolean(error) && isString(error)
        ? {
            error: true,
            helperText: null,
            InputProps: {
              ...props.InputProps,
              startAdornment: (
                <Tooltip title={error}>
                  <ErrorIcon fontSize="small" color="error" />
                </Tooltip>
              ),
            },
            sx: {
              ...props.sx,
              ["& .MuiFormHelperText-root"]: {
                // @ts-ignore
                ...props.sx?.["& .MuiFormHelperText-root"],
                display: "none",
              },
            },
          }
        : undefined)}
    />
  );
};
