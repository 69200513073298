import { useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import {
  Autocomplete,
  CollapseSection,
  LabeledValue,
  LoadingSpinner,
  Modal,
  ModalActions,
  ModalContent,
  ModalOpenButton,
  ModalTitle,
  ModalWithSidePanelBaseContent,
  ModalWithSidePanelDialog,
  ModalWithSidePanelSections,
  useScreenWidth,
} from "@msys/ui";
import AddIcon from "@mui/icons-material/Add";
import BallotIcon from "@mui/icons-material/Ballot";
import DeleteIcon from "@mui/icons-material/Delete";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import {
  Box,
  Button,
  Checkbox,
  DialogActions,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Link as MuiLink,
  Stack,
  Tooltip,
} from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { Field, Form, Formik, FormikProps } from "formik";
import { TextField } from "formik-mui";
import { intersection, uniqueId } from "lodash";
import { Moment } from "moment/moment";
import { useSnackbar } from "notistack";
import React from "react";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import {
  Money,
  namedOperations,
  ProjectSourcePageName,
} from "../../../../clients/graphqlTypes";
import { useUserData } from "../../../auth/useUserData";
import { CheckboxField } from "../../../commons/form-fields/CheckboxField";
import { DatePickerField } from "../../../commons/form-fields/DatePickerField";
import { FormattedMoneyField } from "../../../commons/form-fields/FormattedMoneyField";
import { ConfirmModal } from "../../../commons/modals/ConfirmModal";
import { SelectedBoxWithIndicator } from "../../../commons/SelectedBoxWithIndicator";
import { selectLabelForBuilding } from "../../buildings/helper";
import { BuildingCreateModal } from "../../buildings/modals/BuildingCreateModal";
import { CrmCompanySelect } from "../../crm-companies/CrmCompanySelect";
import { CustomFieldConfigFragment } from "../../custom-fields/customFieldConfigs.generated";
import {
  CustomFieldsArray,
  CustomFieldsFormValues,
  getCustomFieldsInitialValues,
  getCustomFieldsSubmitValues,
  getCustomFieldsValidationSchema,
} from "../../custom-fields/EditCustomFieldModal";
import { useSetCustomFieldMutation } from "../../custom-fields/EditCustomFieldModal.generated";
import { useCustomFieldConfig } from "../../custom-fields/useCustomFieldConfig";
import {
  convertAnswers,
  QuoteCreateWizardSection,
  WizardAnswerResults,
} from "../../quotes/QuoteCreateWizardSection";
import {
  TemplateBase,
  TemplatesDisplay,
} from "../../templates/quote/TemplatesDisplay";
import { useCreateProjectMutation } from "../Projects.generated";
import {
  useCreateProjectModal_CreateDocumentItemMutation,
  useCreateProjectModal_CreateQuoteMutation,
  useCreateProjectModal_CrmCompanyQuery,
  useCreateProjectModal_OrganisationDefaultsQuery,
} from "./CreateProjectModal.generated";

export type CreateProjectInitialStatus = "opportunity" | "contracted";

export type CreateProjectType = "project" | "ticket";
const defaultCreateTypes: CreateProjectType[] = ["project", "ticket"];

type SidePanelState = {
  isTemplateSelectOpen: boolean;
  isTemplateWizardOpen: boolean;
  selectedTemplate: TemplateBase | null;
};

interface FormValues extends CustomFieldsFormValues {
  title: string;
  description: string;
  clientId: string | null;
  buildingId: string | null;
  buildingItemId: string | null;
  urgent: boolean;
  earliestStart: Moment | null;
  deadline: Moment | null;
  budgetAmount?: Money;
  createType: CreateProjectType;
  initialStatus: CreateProjectInitialStatus;
  template: TemplateBase | null;
  templateWizardAnswerResults: WizardAnswerResults | null;
}

interface Props {
  title?: string;
  buildingId?: string;
  buildingItemId?: string;
  crmOrganisationId?: string;
  pageName: ProjectSourcePageName;
  initialCreateType?: CreateProjectType;
  allowedCreateTypes?: CreateProjectType[];
  desiredProjectInitialStatus: CreateProjectInitialStatus;
  handleClose: () => void;
  handleComplete?: (projectId: string) => Promise<void> | void;
  refetchQueries?: string[];
}

export const CreateProjectModal = ({
  title,
  crmOrganisationId,
  buildingId,
  buildingItemId,
  pageName,
  initialCreateType,
  allowedCreateTypes = defaultCreateTypes,
  desiredProjectInitialStatus,
  handleClose,
  handleComplete,
  refetchQueries,
}: Props) => {
  const viewer = useUserData().currentUser!;
  const { enqueueSnackbar } = useSnackbar();

  const { t } = useTranslate([
    "ProjectCreate",
    "TicketCreate",
    "Projects",
    "Tickets",
    "Global",
    "QuoteCreate",
  ]);

  const client = useApolloClient();

  const [sidePanelState, setSidePanelState] = React.useState<SidePanelState>({
    isTemplateSelectOpen: false,
    isTemplateWizardOpen: false,
    selectedTemplate: null,
  });

  const { customFieldConfig: customFieldConfigs, loading } =
    useCustomFieldConfig("Project");

  const [createProject, { loading: createProjectLoading }] =
    useCreateProjectMutation({ client });
  const [setCustomField, { loading: setCustomFieldLoading }] =
    useSetCustomFieldMutation({ client });

  const query = useCreateProjectModal_OrganisationDefaultsQuery({
    client,
    fetchPolicy: "cache-first",
  });
  const organisationDefaults = getDataOrNull(query.data?.organisationDefaults);
  const defaultVerticalSettings = organisationDefaults?.defaultVerticalSettings;

  const [createQuote] = useCreateProjectModal_CreateQuoteMutation({ client });
  const [createItems] = useCreateProjectModal_CreateDocumentItemMutation({
    client,
  });

  const createTypes: CreateProjectType[] = React.useMemo(
    () =>
      intersection(
        allowedCreateTypes,
        viewer.organisation.isCraftsmanOrganisation
          ? ["project", "ticket"]
          : ["project"]
      ),
    [allowedCreateTypes, viewer.organisation.isCraftsmanOrganisation]
  );

  const initialStatuses: CreateProjectInitialStatus[] = React.useMemo(
    () =>
      viewer.organisation.isCraftsmanOrganisation
        ? ["opportunity", "contracted"]
        : ["opportunity"],
    [viewer.organisation.isCraftsmanOrganisation]
  );

  const initialValues = React.useMemo(
    (): FormValues => ({
      title: t("My new project", { ns: "ProjectCreate" }),
      description: "",
      clientId: crmOrganisationId ?? null,
      buildingId: buildingId ?? null,
      buildingItemId: buildingItemId ?? null,
      urgent: false,
      earliestStart: null,
      deadline: null,
      createType:
        initialCreateType && createTypes.includes(initialCreateType)
          ? initialCreateType
          : createTypes[0],
      initialStatus: initialStatuses.includes(desiredProjectInitialStatus)
        ? desiredProjectInitialStatus
        : initialStatuses[0],
      customFields: getCustomFieldsInitialValues(
        customFieldConfigs.filter(f => f.showInCreateForm),
        []
      ),
      template: null,
      templateWizardAnswerResults: null,
    }),
    [
      buildingId,
      buildingItemId,
      createTypes,
      crmOrganisationId,
      customFieldConfigs,
      desiredProjectInitialStatus,
      initialCreateType,
      initialStatuses,
      t,
    ]
  );

  const validationSchema = Yup.object().shape({
    title: Yup.string()
      .label(t("Project title", { ns: "ProjectCreate" }))
      .required(),
    description: Yup.string().label(t("Description", { ns: "TicketCreate" })),
    earliestStart: Yup.date()
      .label(t("Earliest start", { ns: "TicketCreate" }))
      .nullable(),
    deadline: Yup.date()
      .label(t("Deadline", { ns: "TicketCreate" }))
      .nullable(),
    clientId: Yup.string()
      .nullable()
      .label(t("Client", { ns: "ProjectCreate" }))
      .when([], {
        is: () => viewer.organisation.capabilities.includes("QUOTING"),
        then: schema => schema.required(),
      }),
    buildingId: Yup.string().nullable(),
    buildingItemId: Yup.string().nullable(),
    customFields: getCustomFieldsValidationSchema(customFieldConfigs),
    template: Yup.object()
      .shape({
        id: Yup.string().required(),
        title: Yup.string().required(),
        resolvedAsReadModelVersionNumber: Yup.number().optional().nullable(),
      })
      .nullable(),
  });

  const handleSubmit = async (values: FormValues) => {
    const result = await createProject({
      variables: {
        input: {
          title: values.title,
          description: values.description,
          type: "RENEWAL",
          crmOrganisationId: viewer.organisation.capabilities.includes(
            "QUOTING"
          )
            ? values.clientId
            : undefined,
          buildingItemId: values.buildingItemId,
          ticket: values.createType === "ticket",
          categories: [],
          urgent: values.urgent,
          earliestStart:
            values.earliestStart?.format("YYYY-MM-DD") || undefined,
          deadline: values.deadline?.format("YYYY-MM-DD") || undefined,
          budgetAmount: values.budgetAmount?.amount,
          pageName: pageName,
          desiredProjectInitialStatus: values.initialStatus,
        },
      },
      // TODO: instead of problems with refetchObservableQueries we are refetching explicitely. Fix if necessary!
      refetchQueries: [
        namedOperations.Query.PlanningProjects,
        namedOperations.Query.ProjectsList,
        ...(refetchQueries ?? []),
      ],
    });

    if (!result) return;

    const project = result.data!.createProject.project;
    const projectId = project.id;

    await setCustomField({
      variables: {
        input: {
          objectId: projectId,
          objectType: "Project",
          values: getCustomFieldsSubmitValues(
            customFieldConfigs,
            values.customFields
          ),
        },
      },
    });

    if (values.template) {
      const newQuote = await createQuote({
        variables: {
          input: {
            projectId,
            title: values.template.title,
            discountPercentage: 0,
            templateQuoteId: values.template.id,
            itemUuidSeed: values.templateWizardAnswerResults?.itemUuidSeed,
            applyItemActions: values.templateWizardAnswerResults?.answers.map(
              a => convertAnswers(a.applyItemAction)
            ),
          },
        },
      });

      const docId = newQuote.data?.createQuote.quote.id;

      if (!docId) {
        throw new Error(
          t("Error while creating quote", {
            ns: "QuoteCreate",
          })
        );
      }
    } else if (values.createType === "ticket") {
      const createQuoteResult = await createQuote({
        variables: {
          input: {
            projectId,
            title: t("Ticket Quoute: {title}", {
              ns: "TicketCreate",
              title: values.title,
            }),
            contractType:
              organisationDefaults?.defaultContractType ?? "fmsp_fr",
            isBinding: organisationDefaults?.defaultQuoteIsBinding ?? true,
            discountPercentage: 0,
          },
        },
      });

      if (!createQuoteResult) throw new Error(`createQuote failed`);

      const hourlyRate = defaultVerticalSettings?.find(
        s => s.vertical === "general"
      )?.averageWorkSellingPrice;

      const createItemResult = await createItems({
        variables: {
          input: {
            docId: createQuoteResult.data!.createQuote.quote.id,
            projectId,
            parentItemId: createQuoteResult.data!.createQuote.rootItem.id,
            items: [
              {
                type: "paid",
                title: values.title,
                decisionBehaviorOfSubitems: "NONE",
                estimatedTime:
                  values.budgetAmount?.amount && hourlyRate
                    ? (values.budgetAmount?.amount / hourlyRate) * 60
                    : undefined,
              },
            ],
          },
        },
      });

      if (!createItemResult) throw new Error(`createItem failed`);
    }

    handleClose();
    await handleComplete?.(projectId);

    enqueueSnackbar(t("Project created", { ns: "ProjectCreate" }));
  };

  const showSideContent =
    sidePanelState.isTemplateWizardOpen || sidePanelState.isTemplateSelectOpen;

  const formId = React.useMemo(() => uniqueId(), []);

  return (
    <ModalWithSidePanelDialog
      fixedHeight={showSideContent}
      dialogProps={{ maxWidth: showSideContent ? "xl" : "sm" }}
      handleClose={handleClose}
    >
      {loading ? (
        <ModalWithSidePanelBaseContent>
          <LoadingSpinner />
        </ModalWithSidePanelBaseContent>
      ) : (
        <Formik<FormValues>
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize
          validateOnMount
        >
          {formikProps => (
            <ModalWithSidePanelSections
              sideContent={
                sidePanelState.isTemplateSelectOpen ? (
                  <TemplateSelectSidePanel
                    sidePanelState={sidePanelState}
                    setSidePanelState={setSidePanelState}
                    formikProps={formikProps}
                  />
                ) : sidePanelState.isTemplateWizardOpen ? (
                  <TemplateWizardSidePanel
                    sidePanelState={sidePanelState}
                    setSidePanelState={setSidePanelState}
                    formikProps={formikProps}
                  />
                ) : null
              }
              maxWidth={"sm"}
              maxWidthWithSideContent={"xl"}
            >
              <ModalTitle
                title={title ?? t("New project", { ns: "ProjectCreate" })}
                handleClose={handleClose}
              />
              <ModalContent>
                <Form id={formId}>
                  <Stack spacing={2} direction="column">
                    {viewer.organisation.capabilities.includes("QUOTING") && (
                      <ClientSection
                        formikProps={formikProps}
                        crmOrganisationId={crmOrganisationId}
                      />
                    )}
                    <ProjectDetailsSection
                      initialStatuses={initialStatuses}
                      createTypes={createTypes}
                      formikProps={formikProps}
                      sidePanelState={sidePanelState}
                      setSidePanelState={setSidePanelState}
                    />
                    <AdditionalInfoSection
                      formikProps={formikProps}
                      customFieldConfigs={customFieldConfigs}
                    />
                  </Stack>
                </Form>
              </ModalContent>
              <ModalActions
                actionButtons={[
                  {
                    label: t("Cancel", { ns: "Global" }),
                    handleClick: handleClose,
                    buttonProps: {
                      variant: "text",
                      disabled: formikProps.isSubmitting,
                    },
                  },
                  {
                    label: t("Create project", { ns: "ProjectCreate" }),
                    buttonProps: {
                      type: "submit",
                      form: formId,
                      disabled: !formikProps.isValid,
                      loading: formikProps.isSubmitting,
                    },
                  },
                ]}
              />
            </ModalWithSidePanelSections>
          )}
        </Formik>
      )}
    </ModalWithSidePanelDialog>
  );
};

function ClientSection({
  formikProps,
  crmOrganisationId,
}: {
  formikProps: FormikProps<FormValues>;
  crmOrganisationId?: string;
}) {
  const { t } = useTranslate([
    "ProjectCreate",
    "TicketCreate",
    "Projects",
    "Tickets",
    "Global",
  ]);
  const client = useApolloClient();
  const crmOrganisationBuildingsQuery = useCreateProjectModal_CrmCompanyQuery({
    client,
    variables: { crmCompanyId: formikProps.values.clientId! },
    skip: !formikProps.values.clientId,
  });

  const crmCompany = getDataOrNull(
    crmOrganisationBuildingsQuery.data?.crmCompany
  )?.crmCompany;

  return (
    <CollapseSection
      title={t("Client details", {
        ns: "ProjectCreate",
      })}
      isInitiallyExpanded
    >
      <Stack spacing={1} direction="column">
        <CrmCompanySelect
          crmCompanyId={formikProps.values.clientId}
          inputLabel={t("Select client", { ns: "TicketCreate" })}
          required
          onChange={crmCompany => {
            formikProps.setValues(values => ({
              ...values,
              clientId: crmCompany?.id ?? null,
              buildingId: null,
              buildingItemId: null,
            }));
          }}
          canCreateNew
          disabled={Boolean(crmOrganisationId) || formikProps.isSubmitting}
          createNewLabel={t("Add new contact", {
            ns: "TicketCreate",
          })}
          createModalTitle={t("Create client", {
            ns: "ProjectCreate",
          })}
        />

        <Stack direction="row" spacing={1}>
          <Autocomplete
            inputLabel={t("Select location", {
              ns: "TicketCreate",
            })}
            options={
              formikProps.values.clientId &&
              crmCompany?.id === formikProps.values.clientId
                ? crmCompany.buildings
                : []
            }
            getOptionLabel={option => selectLabelForBuilding(option)}
            disabled={
              (formikProps.values.clientId
                ? formikProps.values.clientId.length === 0
                : true) || formikProps.isSubmitting
            }
            value={
              formikProps.values.clientId &&
              crmCompany?.id === formikProps.values.clientId
                ? crmCompany.buildings.find(
                    building => building.id === formikProps.values.buildingId
                  )
                : null
            }
            onChange={value => {
              const buildingId = value?.id ?? null;
              const buildingItemId = value?.rootItem.id ?? null;
              formikProps.setValues(values => ({
                ...values,
                buildingId,
                buildingItemId,
              }));
            }}
          />
          {formikProps.values.clientId && (
            <ModalOpenButton
              Modal={BuildingCreateModal}
              modalProps={{
                buildingOwningOrganisationId: formikProps.values.clientId,
                handleComplete: async (buildingId, buildingRootItemId) => {
                  await crmOrganisationBuildingsQuery.refetch();
                  formikProps.setValues(values => ({
                    ...values,
                    buildingId,
                    buildingItemId: buildingRootItemId,
                  }));
                },
              }}
            >
              <IconButton color="primary" size="large">
                <AddIcon />
              </IconButton>
            </ModalOpenButton>
          )}
        </Stack>

        {/*Excluded for now!*/}
        {/*<Autocomplete
        inputLabel={t("Placement", {
          ns: "TicketCreate",
        })}
        value={
          formikProps.values.clientId &&
          crmCompany?.id === formikProps.values.clientId
            ? crmCompany.buildings
                .find(building => building.id === formikProps.values.buildingId)
                ?.items?.find(
                  buildingItem =>
                    buildingItem.id === formikProps.values.buildingItemId
                ) ?? null
            : null
        }
        required
        disabled={
          formikProps.values.clientId && formikProps.values.buildingId
            ? formikProps.values.clientId.length === 0 &&
              formikProps.values.buildingId.length === 0
            : true
        }
        getOptionLabel={option => `${option.pathForPdf} ${option.title}`}
        options={
          formikProps.values.clientId &&
          crmCompany?.id === formikProps.values.clientId
            ? crmCompany.buildings.find(
                building => building.id === formikProps.values.buildingId
              )?.items ?? []
            : []
        }
        onChange={value => {
          const buildingItemId = value?.id ?? "";
          formikProps.setFieldValue("buildingItemId", buildingItemId);
        }}
      />*/}
      </Stack>
    </CollapseSection>
  );
}

function ProjectDetailsSection({
  formikProps,
  createTypes,
  initialStatuses,
  sidePanelState,
  setSidePanelState,
}: {
  formikProps: FormikProps<FormValues>;
  createTypes: CreateProjectType[];
  initialStatuses: CreateProjectInitialStatus[];
  sidePanelState: SidePanelState;
  setSidePanelState: React.Dispatch<React.SetStateAction<SidePanelState>>;
}) {
  const { t } = useTranslate([
    "ProjectCreate",
    "TicketCreate",
    "Projects",
    "Tickets",
    "Global",
    "Quote",
  ]);
  return (
    <CollapseSection
      title={t("Project details", { ns: "ProjectCreate" })}
      isInitiallyExpanded={true}
    >
      <Stack direction="column" spacing={1}>
        {!formikProps.values.template?.id ? (
          <Box width="100%">
            <SelectedBoxWithIndicator
              selected={sidePanelState.isTemplateSelectOpen}
            >
              <Button
                size="small"
                color="secondary"
                variant="text"
                startIcon={<AddIcon />}
                disabled={
                  sidePanelState.isTemplateSelectOpen ||
                  formikProps.isSubmitting
                }
                sx={{ textWrap: "nowrap" }}
                onClick={() =>
                  setSidePanelState(s => ({
                    ...s,
                    isTemplateSelectOpen: true,
                    selectedTemplate: null,
                  }))
                }
              >
                {t("Add quote template", { ns: "ProjectCreate" })}
              </Button>
            </SelectedBoxWithIndicator>
          </Box>
        ) : (
          <Box width="100%">
            <SelectedBoxWithIndicator
              selected={sidePanelState.isTemplateWizardOpen}
            >
              <Stack
                direction="row"
                spacing={1}
                justifyContent="space-between"
                alignItems="center"
              >
                <LabeledValue
                  label={t("Quote template", {
                    ns: "ProjectCreate",
                  })}
                >
                  <MuiLink
                    component={Link}
                    to={`/templates/documents/${formikProps.values.template.id}/${formikProps.values.template.resolvedAsReadModelVersionNumber}`}
                    target="_blank"
                  >
                    {formikProps.values.template.title}
                  </MuiLink>
                </LabeledValue>

                <Stack direction="row" spacing={0.5} alignItems="center">
                  <Tooltip title={t("Open wizard", { ns: "Quote" })}>
                    <Box display="flex">
                      <IconButton
                        color="primary"
                        size="small"
                        disabled={
                          sidePanelState.isTemplateWizardOpen ||
                          formikProps.isSubmitting
                        }
                        onClick={() =>
                          setSidePanelState(s => ({
                            ...s,
                            isTemplateWizardOpen: true,
                          }))
                        }
                      >
                        <BallotIcon />
                      </IconButton>
                    </Box>
                  </Tooltip>

                  <ModalOpenButton
                    Modal={ConfirmModal}
                    modalProps={{
                      title: t("Are you sure to remove this template?", {
                        ns: "ProjectCreate",
                      }),
                      text: t(
                        "Removing template will discard all previously answered template questions",
                        { ns: "ProjectCreate" }
                      ),
                      handleConfirm: () => {
                        formikProps.setValues({
                          ...formikProps.values,
                          ...(formikProps.values.title ===
                          formikProps.values.template?.title
                            ? {
                                title: t("My new project", {
                                  ns: "ProjectCreate",
                                }),
                              }
                            : undefined),
                          template: null,
                          templateWizardAnswerResults: null,
                        });
                        setSidePanelState(s => ({
                          ...s,
                          isTemplateSelectOpen: false,
                          isTemplateWizardOpen: false,
                          selectedTemplate: null,
                        }));
                      },
                    }}
                  >
                    <IconButton color="primary" size="small">
                      <DeleteIcon />
                    </IconButton>
                  </ModalOpenButton>
                </Stack>
              </Stack>
            </SelectedBoxWithIndicator>
          </Box>
        )}

        <Field
          component={TextField}
          name="title"
          label={t("Project title", { ns: "ProjectCreate" })}
          required
        />

        <Box>
          <Grid container spacing={1}>
            {initialStatuses.length > 0 && (
              <Grid item xs={12}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          formikProps.values.initialStatus === "contracted"
                        }
                        onChange={e => {
                          formikProps.setFieldValue(
                            "initialStatus",
                            e.currentTarget.checked
                              ? "contracted"
                              : "opportunity"
                          );
                        }}
                      />
                    }
                    label={t("Contract signed/agreed offline", {
                      ns: "ProjectCreate",
                    })}
                  />
                </FormGroup>
              </Grid>
            )}
            <Grid item xs={12} md={6}>
              <CheckboxField
                label={t("Urgent", { ns: "TicketCreate" })}
                name="urgent"
              />
            </Grid>
            {createTypes.length > 1 && (
              <Grid item xs={12} md={6}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formikProps.values.createType === "ticket"}
                        onChange={e => {
                          formikProps.setFieldValue(
                            "createType",
                            e.currentTarget.checked ? "ticket" : "project"
                          );
                        }}
                      />
                    }
                    label={t("Tag as ticket", { ns: "ProjectCreate" })}
                  />
                </FormGroup>
              </Grid>
            )}
          </Grid>
        </Box>
      </Stack>
    </CollapseSection>
  );
}

function AdditionalInfoSection({
  formikProps,
  customFieldConfigs,
}: {
  formikProps: FormikProps<FormValues>;
  customFieldConfigs: CustomFieldConfigFragment[];
}) {
  const { t } = useTranslate([
    "ProjectCreate",
    "TicketCreate",
    "Projects",
    "Tickets",
    "Global",
  ]);
  return (
    <CollapseSection
      title={t("Additional info", {
        ns: "ProjectCreate",
      })}
      isInitiallyExpanded={false}
    >
      <Stack direction="column" spacing={1}>
        <Field
          component={TextField}
          name="description"
          label={t("Description", { ns: "TicketCreate" })}
          multiline
        />
        <FormattedMoneyField
          name="budgetAmount"
          label={t("Budget", { ns: "TicketCreate" })}
          type="money"
        />

        <Stack direction="row" spacing={1}>
          <DatePickerField
            label={t("Earliest start", { ns: "TicketCreate" })}
            name="earliestStart"
          />
          <DatePickerField
            label={t("Deadline", { ns: "TicketCreate" })}
            name="deadline"
          />
        </Stack>

        {customFieldConfigs.length > 0 && (
          <CustomFieldsArray
            values={formikProps.values}
            isSubmitting={formikProps.isSubmitting}
            setFieldValue={formikProps.setFieldValue}
            customFieldConfigs={customFieldConfigs}
            objectType="Project"
            useAutoSave={false}
          />
        )}
      </Stack>
    </CollapseSection>
  );
}

function TemplateSelectSidePanel({
  formikProps,
  sidePanelState,
  setSidePanelState,
}: {
  formikProps: FormikProps<FormValues>;
  sidePanelState: SidePanelState;
  setSidePanelState: React.Dispatch<React.SetStateAction<SidePanelState>>;
}) {
  const { t } = useTranslate([
    "ProjectCreate",
    "TicketCreate",
    "Projects",
    "Tickets",
    "Global",
  ]);
  const { isMinDesktop } = useScreenWidth();

  const handleCloseTemplateSelect = () => {
    setSidePanelState(s => ({
      ...s,
      isTemplateSelectOpen: false,
      selectedTemplate: null,
    }));
  };
  const handleTemplateSelect = (template: TemplateBase | null) => {
    setSidePanelState(s => ({
      ...s,
      selectedTemplate: template,
    }));
  };
  const handleSubmitTemplateSelect = (template: TemplateBase | null) => {
    formikProps.setValues({
      ...formikProps.values,
      ...(template &&
      formikProps.values.title === t("My new project", { ns: "ProjectCreate" })
        ? { title: template.title }
        : undefined),
      template,
      templateWizardAnswerResults: null,
    });
    setSidePanelState(s => ({
      ...s,
      selectedTemplate: null,
      isTemplateSelectOpen: false,
      isTemplateWizardOpen: true,
    }));
  };

  const sideTemplateSelect = sidePanelState.isTemplateSelectOpen ? (
    <TemplatesDisplay
      fixedFilters={{ applicableFor: ["QUOTE"] }}
      selectedTemplate={sidePanelState.selectedTemplate}
      setSelectedTemplate={handleTemplateSelect}
      onInspectedTemplateClick={template => {
        handleSubmitTemplateSelect(template);
      }}
    />
  ) : null;

  return sidePanelState.isTemplateSelectOpen ? (
    isMinDesktop ? (
      <>
        <ModalTitle
          handleClose={handleCloseTemplateSelect}
          title={t("Select template", { ns: "ProjectCreate" })}
        />
        <ModalContent>{sideTemplateSelect}</ModalContent>
        <DialogActions sx={{ justifyContent: "space-between" }}>
          <IconButton color="primary" onClick={handleCloseTemplateSelect}>
            <KeyboardDoubleArrowLeftIcon />
          </IconButton>
          <Button
            color="primary"
            variant="contained"
            disabled={!sidePanelState.selectedTemplate}
            onClick={() => {
              handleSubmitTemplateSelect(sidePanelState.selectedTemplate);
            }}
          >
            {t("Select", { ns: "Global" })}
          </Button>
        </DialogActions>
      </>
    ) : (
      <Modal
        handleClose={handleCloseTemplateSelect}
        title={t("Select template", { ns: "ProjectCreate" })}
        actionButtons={[
          {
            label: t("Cancel", { ns: "Global" }),
            handleClick: handleCloseTemplateSelect,
            buttonProps: { variant: "text" },
          },
          {
            label: t("Select", { ns: "Global" }),
            handleClick: () => {
              handleSubmitTemplateSelect(sidePanelState.selectedTemplate);
            },
            buttonProps: { disabled: !sidePanelState.selectedTemplate },
          },
        ]}
      >
        {sideTemplateSelect}
      </Modal>
    )
  ) : null;
}

function TemplateWizardSidePanel({
  formikProps,
  sidePanelState,
  setSidePanelState,
}: {
  formikProps: FormikProps<FormValues>;
  sidePanelState: SidePanelState;
  setSidePanelState: React.Dispatch<React.SetStateAction<SidePanelState>>;
}) {
  const viewer = useUserData().currentUser!;
  const { isMinDesktop } = useScreenWidth();

  const handleAnswerTemplateWizard = (result: WizardAnswerResults) => {
    formikProps.setFieldValue("templateWizardAnswerResults", result);
  };
  const handleCompleteTemplateWizard = () => {
    setSidePanelState(s => ({
      ...s,
      isTemplateWizardOpen: false,
    }));
  };
  const handleCloseTemplateWizard = () => {
    setSidePanelState(s => ({
      ...s,
      isTemplateWizardOpen: false,
    }));
  };

  const sideTemplateWizard =
    sidePanelState.isTemplateWizardOpen && formikProps.values.template ? (
      <QuoteCreateWizardSection
        organisationId={viewer.organisation.id}
        templateId={formikProps.values.template.id}
        onComplete={handleCompleteTemplateWizard}
        onAnswer={handleAnswerTemplateWizard}
        initialAnswers={formikProps.values.templateWizardAnswerResults}
      />
    ) : null;

  return sidePanelState.isTemplateWizardOpen && formikProps.values.template ? (
    isMinDesktop ? (
      <>
        <ModalTitle
          handleClose={handleCloseTemplateWizard}
          title={formikProps.values.template.title}
        />
        <ModalContent>{sideTemplateWizard}</ModalContent>
      </>
    ) : (
      <Modal
        handleClose={handleCloseTemplateWizard}
        title={formikProps.values.template.title}
      >
        {sideTemplateWizard}
      </Modal>
    )
  ) : null;
}
