import { useApolloClient } from "@apollo/client";
import { getDataOrNull, notNull } from "@msys/common";
import { LabeledValue, ModalOpenButton } from "@msys/ui";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import { Box, Button, IconButton, Stack } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import React from "react";
import { ProductTypesSearchModal } from "./modals/ProductTypesSearchModal";
import { ProductTypesSearchModal_PimProductTypeFragment } from "./modals/ProductTypesSearchModal.generated";
import { useProductTypeInput_PimSearchProductTypesQuery } from "./ProductTypeInput.generated";

interface Props {
  productTypeId: string | null;
  label?: string;
  selectButtonLabel?: string;
  leftIcon?: React.ReactNode;
  rightButton?: React.ReactNode;
  onChange: (
    productType: ProductTypesSearchModal_PimProductTypeFragment
  ) => Promise<void> | void;
  onClear?: () => Promise<void> | void;
  required?: boolean;
  disabled?: boolean;
  canEdit?: boolean;
}

export const ProductTypeInput = ({
  productTypeId,
  label,
  selectButtonLabel,
  leftIcon,
  rightButton,
  required = false,
  disabled = false,
  canEdit = true,
  onChange,
  onClear,
}: Props) => {
  const { t } = useTranslate(["Product", "Global"]);
  const client = useApolloClient();
  const query = useProductTypeInput_PimSearchProductTypesQuery({
    client,
    variables: { productTypeIds: [productTypeId].filter(notNull) },
    skip: !productTypeId,
  });
  const productType = productTypeId
    ? getDataOrNull(query.data?.pimSearchProductTypes)?.productTypes?.[0] ??
      null
    : null;
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      spacing={1}
      width="100%"
    >
      <Stack
        direction="row"
        alignItems="center"
        minWidth={0}
        spacing={1}
        flex={1}
      >
        <LabeledValue
          label={
            label ??
            t("Product type", { ns: "Product" }) + (required ? " *" : "")
          }
          notSet={!productTypeId}
        >
          {productTypeId
            ? productType?.label ?? ""
            : t("Not set", { ns: "Global" })}
        </LabeledValue>
        {leftIcon}
      </Stack>
      {rightButton ? (
        <Box display="flex">{rightButton}</Box>
      ) : (
        canEdit &&
        (productTypeId ? (
          <ModalOpenButton
            Modal={ProductTypesSearchModal}
            modalProps={{
              handleSelectProductType: async (productType, handleClose) => {
                await onChange(productType);
                handleClose();
              },
              handleClearProductType: onClear
                ? async handleClose => {
                    await onClear();
                    handleClose();
                  }
                : undefined,
            }}
            disabled={disabled}
          >
            <IconButton size={"small"} color="primary" disabled={disabled}>
              <EditIcon />
            </IconButton>
          </ModalOpenButton>
        ) : (
          <Box display="flex">
            <ModalOpenButton
              Modal={ProductTypesSearchModal}
              modalProps={{
                handleSelectProductType: async (productType, handleClose) => {
                  await onChange(productType);
                  handleClose();
                },
              }}
              disabled={disabled}
            >
              <Button
                size="small"
                color="secondary"
                variant="text"
                startIcon={<AddIcon />}
                disabled={disabled}
                sx={{ textWrap: "nowrap" }}
              >
                {selectButtonLabel ?? t("Add product type", { ns: "Product" })}
              </Button>
            </ModalOpenButton>
          </Box>
        ))
      )}
    </Stack>
  );
};
