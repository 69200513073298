import { useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import { useFormatting } from "@msys/ui";
import { Link, Stack, Typography } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { isNil } from "lodash";
import React, { Dispatch, SetStateAction } from "react";
import { OrganisationQuoteTemplatesFilters } from "../../../../clients/graphqlTypes";
import {
  FilterChip,
  FilterChipBoolean,
  FilterChipGroup,
} from "../../../commons/filters/FilterChip";
import { PropertiesFilterChips } from "../../properties/PropertiesFilterChips";
import { TemplateFiltersFormValues } from "../filters/TemplateFilterFields";
import { useTemplatesQuoteFilterChips_TemplateTypesQuery } from "./TemplatesQuoteFilterChips.generated";

type Filters = Pick<
  OrganisationQuoteTemplatesFilters,
  | "createdAfter"
  | "createdBefore"
  | "priceMin"
  | "priceMax"
  | "tagsAny"
  | "addedToShop"
>;

interface Props<F extends Filters> {
  filters: F;
  setFilters: Dispatch<SetStateAction<F>>;
  templateFilters?: TemplateFiltersFormValues;
  setTemplateFilters?: Dispatch<SetStateAction<TemplateFiltersFormValues>>;
  onReset?(): void | Promise<void>;
  fixedFilters?: Partial<F> & Partial<TemplateFiltersFormValues>;
}

export const TemplatesQuoteFilterChips = <F extends Filters>({
  filters,
  setFilters,
  templateFilters,
  setTemplateFilters,
  fixedFilters,
  onReset,
}: Props<F>) => {
  const { t } = useTranslate("Templates");
  const { getFormattedDate, getFormattedPrice } = useFormatting();

  const client = useApolloClient();
  const templateTypesQuery = useTemplatesQuoteFilterChips_TemplateTypesQuery({
    client,
    variables: {
      ids: templateFilters?.templateTypeIds ?? [],
    },
    fetchPolicy: "cache-and-network",
    skip:
      !templateFilters?.templateTypeIds ||
      templateFilters.templateTypeIds.length === 0,
  });

  const templateTypes = React.useMemo(
    () =>
      getDataOrNull(
        (templateTypesQuery.data ?? templateTypesQuery.previousData)
          ?.templateTypes
      )?.templateTypes ?? [],
    [templateTypesQuery.data, templateTypesQuery.previousData]
  );

  const getTemplateTypeTitle = (id: string) =>
    templateTypes.find(templateType => templateType.id === id)?.title;

  const getTemplateTypePropertyTitle = (key: string) => {
    for (const templateType of templateTypes) {
      const prop = templateType.props2.find(p => p.key === key);
      if (prop) return prop.label;
    }
  };

  return canShowChips(filters, templateFilters) ? (
    <Stack
      direction={"row"}
      spacing={1}
      flexWrap="wrap"
      alignItems="center"
      useFlexGap
    >
      {templateFilters?.templateTypeIds &&
        templateFilters.templateTypeIds.length > 0 && (
          <FilterChipGroup
            values={templateFilters.templateTypeIds}
            setValues={newValues => {
              setTemplateFilters?.(filters => ({
                ...filters,
                templateTypeIds:
                  newValues && newValues.length > 0 ? newValues : null,
              }));
            }}
            getValueLabel={getTemplateTypeTitle}
            canEdit={isNil(fixedFilters?.templateTypeIds)}
          />
        )}
      <FilterChip
        label={t("Created after")}
        resetValue={null}
        setValue={newValue =>
          setFilters(filters => ({
            ...filters,
            createdAfter: newValue,
          }))
        }
        value={filters.createdAfter && getFormattedDate(filters.createdAfter)}
        canEdit={isNil(fixedFilters?.createdAfter)}
      />
      <FilterChip
        label={t("Created before")}
        resetValue={null}
        setValue={newValue =>
          setFilters(filters => ({
            ...filters,
            createdBefore: newValue,
          }))
        }
        value={filters.createdBefore && getFormattedDate(filters.createdBefore)}
        canEdit={isNil(fixedFilters?.createdBefore)}
      />
      <FilterChip
        label={t("Min price")}
        resetValue={null}
        setValue={newValue =>
          setFilters(filters => ({
            ...filters,
            priceMin: newValue,
          }))
        }
        value={filters.priceMin}
        canEdit={isNil(fixedFilters?.priceMin)}
        getValueLabel={value => getFormattedPrice(value) ?? ""}
      />
      <FilterChip
        label={t("Max price")}
        resetValue={null}
        setValue={newValue =>
          setFilters(filters => ({
            ...filters,
            priceMax: newValue,
          }))
        }
        value={filters.priceMax}
        canEdit={isNil(fixedFilters?.priceMax)}
        getValueLabel={value => getFormattedPrice(value) ?? ""}
      />
      <FilterChipBoolean
        label={t("Added to Shop")}
        setValue={newValue =>
          setFilters(filters => ({
            ...filters,
            addedToShop: newValue ?? null,
          }))
        }
        value={filters.addedToShop}
        canEdit={isNil(fixedFilters?.addedToShop)}
      />
      <FilterChipGroup
        label={t("Tag")}
        values={filters.tagsAny}
        getValueLabel={value => value}
        setValues={value =>
          setFilters(state => ({
            ...state,
            tagsAny: value,
          }))
        }
        canEdit={isNil(fixedFilters?.tagsAny)}
      />
      {templateFilters?.properties && (
        <PropertiesFilterChips
          values={templateFilters?.properties}
          setValues={newValue =>
            setTemplateFilters?.(filters => ({
              ...filters,
              properties: newValue ?? null,
            }))
          }
          canEdit={isNil(fixedFilters?.properties)}
          getKeyLabel={getTemplateTypePropertyTitle}
        />
      )}
      {onReset && canResetFilters(filters, templateFilters, fixedFilters) && (
        <Typography variant="body2">
          <Link onClick={onReset} color="secondary" sx={{ cursor: "pointer" }}>
            {t("Clear all", { ns: "Global" })}
          </Link>
        </Typography>
      )}
    </Stack>
  ) : null;
};

export const canShowChips = (
  filters: Filters,
  templateFilters?: TemplateFiltersFormValues
): boolean =>
  Boolean(
    !isNil(filters.priceMin) ||
      !isNil(filters.priceMax) ||
      !isNil(filters.createdAfter) ||
      !isNil(filters.createdBefore) ||
      !isNil(filters.addedToShop) ||
      filters.tagsAny?.length ||
      templateFilters?.templateTypeIds?.length ||
      templateFilters?.properties?.length
  );

export const canResetFilters = (
  filters: Filters,
  templateFilters?: TemplateFiltersFormValues,
  fixedFilters?: Partial<Filters> & Partial<TemplateFiltersFormValues>
): boolean =>
  Boolean(
    (!isNil(filters.priceMin) && isNil(fixedFilters?.priceMin)) ||
      (!isNil(filters.priceMax) && isNil(fixedFilters?.priceMax)) ||
      (!isNil(filters.createdAfter) && isNil(fixedFilters?.createdAfter)) ||
      (!isNil(filters.createdBefore) && isNil(fixedFilters?.createdBefore)) ||
      (!isNil(filters.addedToShop) && isNil(fixedFilters?.addedToShop)) ||
      (filters.tagsAny?.length && isNil(fixedFilters?.tagsAny)) ||
      (templateFilters?.templateTypeIds?.length &&
        isNil(fixedFilters?.templateTypeIds)) ||
      (templateFilters?.properties?.length && isNil(fixedFilters?.properties))
  );
