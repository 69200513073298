import { useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import {
  DataGrid,
  DebouncedSearchInput,
  ErrorMessage,
  GridColDef,
  ListHeader,
  LoadingSpinner as LoadingIndicator,
  MediaCardItem,
  MediaListItem,
  Modal,
  ModalActionButtonProps,
  useRunWithFlag,
  useScreenWidth,
} from "@msys/ui";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Stack,
  TablePagination,
  Typography,
} from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { isUndefined } from "lodash";
import React from "react";
import { useLatest } from "react-use";
import {
  PimSearchProductTypesSortBy,
  PimSearchProductTypesSortingInput,
} from "../../../../clients/graphqlTypes";
import { ButtonCircleWrapper } from "../../../commons/button/ButtonCircleWrapper";
import { SwitchCollectionViewButton } from "../../../commons/button/SwitchCollectionViewButton";
import { useCardsNumber } from "../../../commons/hooks/useCardsNumber";
import {
  CollectionView,
  useCollectionViewWithMobile,
} from "../../../commons/hooks/useCollectionView";
import { PaginationModel } from "../../../commons/pagination/usePaginationParams";
import {
  PAGE_LIST_RESULTS_PER_PAGE,
  RESULTS_PER_PAGE_OPTIONS,
} from "../../../constants";
import { ProductPropertyTypeChips } from "../ProductPropertyTypeChips";
import { ProductTypeOverviewModal } from "./ProductTypeOverviewModal";
import {
  ProductTypesSearchModal_PimProductTypeFragment,
  useProductTypesSearchModalQuery,
} from "./ProductTypesSearchModal.generated";

const ALLOWED_VIEWS: CollectionView[] = ["gallery", "list", "table"];

interface Props {
  title?: string;
  handleClose: () => void;
  handleSelectProductType: (
    productType: ProductTypesSearchModal_PimProductTypeFragment,
    handleClose: () => void
  ) => Promise<void> | void;
  handleClearProductType?: (handleClose: () => void) => Promise<void> | void;
  onProductTypeClick?: (
    productType: ProductTypesSearchModal_PimProductTypeFragment
  ) => void;
  initialSearchValue?: string;
  handleSearchValueChange?: (value: string) => void;
}

export const ProductTypesSearchModal = ({
  title,
  handleClose,
  handleSelectProductType,
  handleClearProductType,
  ...props
}: Props) => {
  const { t } = useTranslate(["ProductTypesSearch"]);

  const [selectedProductType, setSelectedProductType] =
    React.useState<ProductTypesSearchModal_PimProductTypeFragment | null>(null);

  const [processingSelect, runSelect] = useRunWithFlag();
  const [processingClear, runClear] = useRunWithFlag();
  const processing = processingSelect || processingClear;

  return (
    <Modal
      maxWidth="xl"
      fixedHeight
      title={title ?? t("Select product type", { ns: "ProductTypesSearch" })}
      actionButtons={
        [
          {
            label: t("Cancel", { ns: "Global" }),
            handleClick: handleClose,
            buttonProps: { variant: "text", disabled: processing },
          },
          ...(handleClearProductType
            ? ([
                {
                  label: t("Remove product type", {
                    ns: "ProductTypesSearch",
                  }),
                  handleClick: async () => {
                    await runClear(async () => {
                      await handleClearProductType(handleClose);
                    });
                  },
                  buttonProps: {
                    loading: processingClear,
                    disabled: processing,
                    variant: "outlined",
                  },
                },
              ] as ModalActionButtonProps[])
            : []),
          {
            label: t("Select", { ns: "Global" }),
            handleClick: async () => {
              if (!selectedProductType)
                throw new Error("Template type missing");
              await runSelect(async () => {
                await handleSelectProductType(selectedProductType, handleClose);
              });
            },
            buttonProps: {
              loading: processingSelect,
              disabled: !selectedProductType || processing,
            },
          },
        ] as ModalActionButtonProps[]
      }
      handleClose={handleClose}
    >
      <ProductTypesDisplay
        selectedProductType={selectedProductType}
        setSelectedProductType={setSelectedProductType}
        inspectedProductProcessing={processingSelect}
        onInspectedProductTypeClick={async productType => {
          await runSelect(async () => {
            await handleSelectProductType(productType, handleClose);
          });
        }}
        {...props}
      />
    </Modal>
  );
};

export const ProductTypesDisplay = ({
  initialSearchValue = "",
  handleSearchValueChange,
  inspectedProductProcessing,
  onInspectedProductTypeClick,
  onProductTypeClick,
  selectedProductType,
  setSelectedProductType,
}: {
  initialSearchValue?: string;
  handleSearchValueChange?: (value: string) => void;

  inspectedProductProcessing?: boolean;
  onInspectedProductTypeClick?: (
    productType: ProductTypesSearchModal_PimProductTypeFragment
  ) => Promise<void> | void;
  onProductTypeClick?: (
    productType: ProductTypesSearchModal_PimProductTypeFragment
  ) => void;
  selectedProductType?: ProductTypesSearchModal_PimProductTypeFragment | null;
  setSelectedProductType?: (
    productType: ProductTypesSearchModal_PimProductTypeFragment | null
  ) => void;
}) => {
  const { t } = useTranslate(["ProductTypesSearch", "Global", "ProductTypes"]);

  const [inspectedProductType, setInspectedProductType] =
    React.useState<ProductTypesSearchModal_PimProductTypeFragment | null>(null);

  const [sorting, setSorting] = React.useState<
    PimSearchProductTypesSortingInput[]
  >([{ column: "label", direction: "asc" }]);

  const [paginationModel, setPaginationModel] = React.useState<{
    page: number;
    pageSize: number;
  }>({ page: 0, pageSize: PAGE_LIST_RESULTS_PER_PAGE });

  const [activeView, setActiveView] =
    useCollectionViewWithMobile<CollectionView>(
      "productTypesListViewType",
      "table",
      "list"
    );

  const [searchTerm, setSearchTerm] =
    React.useState<string>(initialSearchValue);

  const client = useApolloClient();
  const query = useProductTypesSearchModalQuery({
    client,
    variables: {
      searchTerm,
      limit: paginationModel.pageSize,
      offset: paginationModel.pageSize * paginationModel.page,
      sorting,
    },
  });

  React.useEffect(() => {
    const totalCount = getDataOrNull(
      query.data?.pimSearchProductTypes
    )?.totalCount;
    if (
      !isUndefined(totalCount) &&
      totalCount < paginationModel.pageSize * paginationModel.page &&
      paginationModel.page > 0
    ) {
      setPaginationModel({ page: 0, pageSize: paginationModel.pageSize });
    }
  }, [query.data, paginationModel, setPaginationModel]);

  const items =
    getDataOrNull((query.data ?? query.previousData)?.pimSearchProductTypes)
      ?.productTypes ?? [];
  const totalCount =
    getDataOrNull((query.data ?? query.previousData)?.pimSearchProductTypes)
      ?.totalCount ?? 0;

  return (
    <Stack
      alignItems={"stretch"}
      height="100%"
      spacing={2}
      direction="row"
      minHeight={0}
    >
      <Stack
        direction={"column"}
        height="100%"
        spacing={1}
        flex={1}
        minWidth={0}
      >
        <ProductTypesSearchModalListHeader
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          activeView={activeView}
          setActiveView={setActiveView}
          handleSearchValueChange={handleSearchValueChange}
        />
        {query.error && <ErrorMessage message={query.error.message} />}
        <ProductTypesList
          loading={query.loading}
          items={items}
          totalCount={totalCount}
          paginationModel={paginationModel}
          setPaginationModel={setPaginationModel}
          sorting={sorting}
          setSorting={setSorting}
          activeView={activeView}
          setInspectedProductType={setInspectedProductType}
          onProductTypeClick={onProductTypeClick}
          selectedProductType={selectedProductType}
          setSelectedProductType={setSelectedProductType}
        />
      </Stack>
      {inspectedProductType && (
        <ProductTypeOverviewModal
          productTypeId={inspectedProductType.id}
          handleClose={() => setInspectedProductType(null)}
          dialogActionButton={
            onInspectedProductTypeClick ? (
              <Button
                onClick={async e => {
                  e.stopPropagation();
                  setInspectedProductType(null);
                  await onInspectedProductTypeClick(inspectedProductType);
                }}
                color="primary"
                variant="contained"
                disabled={inspectedProductProcessing}
              >
                {t("Use this product type", { ns: "ProductTypesSearch" })}
              </Button>
            ) : setSelectedProductType ? (
              <Button
                onClick={e => {
                  e.stopPropagation();
                  setInspectedProductType(null);
                  setSelectedProductType(inspectedProductType);
                }}
                color="primary"
                variant="contained"
              >
                {t("Select", { ns: "Global" })}
              </Button>
            ) : undefined
          }
        />
      )}
    </Stack>
  );
};

function ProductTypesList({
  loading,
  totalCount,
  items,
  setInspectedProductType,
  onProductTypeClick,
  selectedProductType,
  setSelectedProductType,
  paginationModel,
  setPaginationModel,
  sorting,
  setSorting,
  activeView,
}: {
  loading: boolean;
  totalCount: number;
  items: ProductTypesSearchModal_PimProductTypeFragment[];
  setInspectedProductType: (
    productType: ProductTypesSearchModal_PimProductTypeFragment
  ) => void;
  onProductTypeClick?: (
    productType: ProductTypesSearchModal_PimProductTypeFragment
  ) => void;
  selectedProductType?: ProductTypesSearchModal_PimProductTypeFragment | null;
  setSelectedProductType?: (
    productType: ProductTypesSearchModal_PimProductTypeFragment | null
  ) => void;
  paginationModel: PaginationModel;
  setPaginationModel: React.Dispatch<React.SetStateAction<PaginationModel>>;
  sorting: PimSearchProductTypesSortingInput[];
  setSorting: React.Dispatch<
    React.SetStateAction<PimSearchProductTypesSortingInput[]>
  >;
  activeView: CollectionView;
}) {
  const { t } = useTranslate(["ProductTypesSearch", "Global", "ProductTypes"]);

  const setInspectedProductTypeRef = useLatest(setInspectedProductType);
  const {
    spacing: cardsSpacing,
    sizeMeasureRef,
    columns: cardsColumns,
  } = useCardsNumber();

  const columns = React.useMemo(
    (): GridColDef<ProductTypesSearchModal_PimProductTypeFragment>[] => [
      {
        field: "label",
        headerName: t("Title", { ns: "Global" }),
        sortable: true,
        flex: 3,
        minWidth: 120,
        renderCell: ({ row: productType }) => productType.label,
      },
      {
        field: "key",
        headerName: t("Key", { ns: "ProductTypes" }),
        sortable: true,
        flex: 1.5,
        minWidth: 90,
        renderCell: ({ row: productType }) => productType.key,
      },
      {
        field: "properties",
        headerName: t("Properties", { ns: "ProductTypes" }),
        sortable: false,
        flex: 4,
        minWidth: 150,
        renderCell: ({ row: productType }) => (
          <ProductPropertyTypeChips
            props={productType.propertyTypes.map(p => p.propertyType)}
            wrap={false}
          />
        ),
      },
      {
        field: "synonyms",
        headerName: t("Synonyms", { ns: "ProductTypes" }),
        sortable: false,
        flex: 4,
        minWidth: 150,
        renderCell: ({ row: productType }) =>
          productType.labelSynonyms.join(", "),
      },
      {
        field: "actions",
        headerName: "",
        width: 50,
        sortable: false,
        resizable: false,
        renderCell: ({ row: productType }) => (
          <IconButton
            onClick={e => {
              e.stopPropagation();
              e.preventDefault();
              setInspectedProductTypeRef.current?.(productType);
            }}
          >
            <InfoOutlinedIcon color="secondary" />
          </IconButton>
        ),
      },
    ],
    [setInspectedProductTypeRef, t]
  );

  switch (activeView) {
    case "list":
      if (loading) return <LoadingIndicator />;
      return items.length > 0 ? (
        <Stack
          direction="column"
          spacing={1}
          flex={1}
          overflow="auto"
          minHeight={0}
        >
          <Stack
            direction="column"
            spacing={1}
            overflow="auto"
            flex={1}
            minHeight={0}
          >
            {items.map((productType, index) => {
              const Info = (
                <Typography
                  variant="caption"
                  component="div"
                  color="textSecondary"
                >
                  <Stack direction="row" alignItems="center" spacing={0.5}>
                    {productType.key}
                  </Stack>
                  {productType.labelSynonyms.length > 0 && (
                    <Stack direction="row" alignItems="center" spacing={0.5}>
                      {productType.labelSynonyms.join(", ")}
                    </Stack>
                  )}
                </Typography>
              );

              return (
                <MediaListItem
                  key={productType.id}
                  selected={productType.id === selectedProductType?.id}
                  onClick={() => {
                    if (onProductTypeClick) {
                      onProductTypeClick(productType);
                    } else {
                      setSelectedProductType?.(
                        selectedProductType?.id === productType.id
                          ? null
                          : productType
                      );
                    }
                  }}
                  title={productType.label}
                  description={productType.description ?? undefined}
                  Info={Info}
                  ActionButton={
                    <IconButton
                      onClick={event => {
                        event.stopPropagation();
                        setInspectedProductType(productType);
                      }}
                    >
                      <InfoOutlinedIcon color="secondary" />
                    </IconButton>
                  }
                />
              );
            })}
          </Stack>
          <TablePagination
            component="div"
            count={totalCount}
            page={paginationModel.page}
            onPageChange={(event, newPage) => {
              setPaginationModel({
                pageSize: paginationModel.pageSize,
                page: newPage,
              });
            }}
            rowsPerPage={paginationModel.pageSize}
            onRowsPerPageChange={event => {
              setPaginationModel({
                pageSize: parseInt(event.target.value, 10),
                page: 0,
              });
            }}
            labelRowsPerPage={t("Rows per page:", {
              ns: "Global",
            })}
          />
        </Stack>
      ) : (
        <Box padding={2} display="flex" justifyContent="center">
          {t("There seems to be nothing here", {
            ns: "Global",
          })}
        </Box>
      );

    case "gallery":
      if (loading) return <LoadingIndicator />;
      return (
        <Stack
          ref={sizeMeasureRef}
          direction="column"
          spacing={1}
          flex={1}
          overflow="auto"
          minHeight={0}
        >
          <Box overflow="auto" flex={1} minHeight={0}>
            <Grid container spacing={cardsSpacing}>
              {items.map((productType, index) => {
                const Info = (
                  <Typography
                    variant="caption"
                    component="div"
                    color="textSecondary"
                  >
                    <Stack direction="row" alignItems="center" spacing={0.5}>
                      {productType.key}
                    </Stack>
                    {productType.labelSynonyms.length > 0 && (
                      <Stack direction="row" alignItems="center" spacing={0.5}>
                        {productType.labelSynonyms.join(", ")}
                      </Stack>
                    )}
                  </Typography>
                );

                return (
                  <Grid key={productType.id} item {...cardsColumns}>
                    <MediaCardItem
                      key={productType.id}
                      selected={productType.id === selectedProductType?.id}
                      onClick={() => {
                        if (onProductTypeClick) {
                          onProductTypeClick(productType);
                        } else {
                          setSelectedProductType?.(
                            selectedProductType?.id === productType.id
                              ? null
                              : productType
                          );
                        }
                      }}
                      title={productType.label}
                      description={productType.description ?? undefined}
                      Info={Info}
                      ActionButton={
                        <ButtonCircleWrapper>
                          <IconButton
                            onClick={event => {
                              event.stopPropagation();
                              setInspectedProductType(productType);
                            }}
                          >
                            <InfoOutlinedIcon color="secondary" />
                          </IconButton>
                        </ButtonCircleWrapper>
                      }
                    />
                  </Grid>
                );
              })}
            </Grid>
          </Box>
          <TablePagination
            component="div"
            count={totalCount}
            page={paginationModel.page}
            onPageChange={(event, newPage) => {
              setPaginationModel({
                pageSize: paginationModel.pageSize,
                page: newPage,
              });
            }}
            rowsPerPage={paginationModel.pageSize}
            onRowsPerPageChange={event => {
              setPaginationModel({
                pageSize: parseInt(event.target.value, 10),
                page: 0,
              });
            }}
            labelRowsPerPage={t("Items per page:", {
              ns: "Global",
            })}
          />
        </Stack>
      );

    case "table":
      return (
        <DataGrid
          density="compact"
          loading={loading}
          hideFooter={totalCount === 0}
          onRowClick={({ row: productType }, event) => {
            if (onProductTypeClick) {
              onProductTypeClick(productType);
            } else {
              setSelectedProductType?.(
                selectedProductType?.id === productType.id ? null : productType
              );
            }
          }}
          localeText={{
            noRowsLabel: t("There are no items to display", {
              ns: "Global",
            }),
            MuiTablePagination: {
              labelRowsPerPage: t("Rows per page", {
                ns: "Global",
              }),
            },
          }}
          sortModel={sorting.map(s => ({
            field: s.column,
            sort: s.direction,
          }))}
          onSortModelChange={newModel => {
            setSorting(
              newModel.map(({ field, sort }) => ({
                column: field as PimSearchProductTypesSortBy,
                direction: sort ?? "asc",
              }))
            );
          }}
          columns={columns}
          rows={items}
          paginationModel={paginationModel}
          onPaginationModelChange={newPaginationModel => {
            setPaginationModel(newPaginationModel);
          }}
          rowSelectionModel={
            selectedProductType ? [selectedProductType.id] : []
          }
          disableColumnFilter
          pageSizeOptions={RESULTS_PER_PAGE_OPTIONS}
          rowCount={totalCount}
        />
      );
  }

  return null;
}

function ProductTypesSearchModalListHeader({
  searchTerm,
  setSearchTerm,
  handleSearchValueChange,
  activeView,
  setActiveView,
}: {
  searchTerm: string;
  setSearchTerm: React.Dispatch<React.SetStateAction<string>>;
  handleSearchValueChange?: (value: string) => void;
  activeView: CollectionView;
  setActiveView: (newValue: CollectionView) => void;
}) {
  const { t } = useTranslate(["Global"]);

  return (
    <>
      <ListHeader
        elementsOrder="separate-rows"
        SwitchViewButton={
          <SwitchCollectionViewButton
            allowedViews={ALLOWED_VIEWS}
            activeView={activeView}
            setActiveView={setActiveView}
          />
        }
        SearchField={
          <Stack direction={"row"} flex={1} spacing={1}>
            <DebouncedSearchInput
              defaultValue={searchTerm}
              placeholder={t("Search", {
                ns: "Global",
              })}
              onChangeSearchTerm={setSearchTerm}
              onImmediateChangeSearchTerm={handleSearchValueChange}
              autoFocus
              style={{ flexGrow: 1 }}
            />
          </Stack>
        }
      />
    </>
  );
}
