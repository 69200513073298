import { autocompleteClasses, Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
import { ProjectAssigneeSelectFormWithAutoSave } from "./ProjectAssigneeSelect";

interface Props {
  projectId: string;
  refetchQueries?: string[];
  readOnly?: boolean;
  disabled?: boolean;
  onAssigneeChange?: (assigneeUserId: string | null) => void | Promise<void>;
  onAssigneeChanged?: (
    assigneeUserId: string | null,
    membershipId: string | null
  ) => void | Promise<void>;
}

export const autocompleteProps = { open: true, PopperComponent };
const stackProps = { p: 0, spacing: 0.5 };

export const ProjectAssigneeEditPopover = ({
  projectId,
  refetchQueries,
  readOnly,
  disabled,
  onAssigneeChange,
  onAssigneeChanged,
}: Props) => {
  return (
    <Box
      sx={theme => ({ width: `${theme.layout.filters.popoverWidth.lg}px` })}
      onMouseDown={e => {
        e.stopPropagation();
      }}
      onTouchStart={e => {
        e.stopPropagation();
      }}
      onClick={e => {
        e.preventDefault();
        e.stopPropagation();
      }}
      onKeyPress={e => {
        e.stopPropagation();
      }}
      onKeyDown={e => {
        e.stopPropagation();
      }}
    >
      <ProjectAssigneeSelectFormWithAutoSave
        readOnly={readOnly}
        disabled={disabled}
        projectId={projectId}
        refetchQueries={refetchQueries}
        autocompleteProps={autocompleteProps}
        stackProps={stackProps}
        autoSaveType={null}
        autoSaveDebounceMs={0}
        onAssigneeChange={onAssigneeChange}
        onAssigneeChanged={onAssigneeChanged}
      />
    </Box>
  );
};

const StyledAutocompletePopper = styled("div")(({ theme }) => ({
  [`& .${autocompleteClasses.paper}`]: {
    boxShadow: "none",
    border: "none",
    margin: 0,
    color: "inherit",
    borderRadius: 0,
  },
  [`& .${autocompleteClasses.listbox}`]: {
    padding: 0,
    maxHeight: "300px",
    [`& .${autocompleteClasses.option}`]: {
      minHeight: "auto",
      alignItems: "flex-start",
      borderRadius: 0,
      // '&[aria-selected="true"]': {
      //   backgroundColor: "transparent",
      // },
      [`&.${autocompleteClasses.focused}, &.${autocompleteClasses.focused}[aria-selected="true"]`]:
        {
          backgroundColor: theme.palette.action.hover,
        },
    },
  },
  [`&.${autocompleteClasses.popperDisablePortal}`]: {
    position: "relative",
  },
}));

interface PopperComponentProps {
  anchorEl?: any;
  disablePortal?: boolean;
  open: boolean;
}

function PopperComponent(props: PopperComponentProps) {
  const { disablePortal, anchorEl, open, ...other } = props;
  return <StyledAutocompletePopper {...other} />;
}
