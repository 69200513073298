import { useApolloClient } from "@apollo/client";
import { CardContainer } from "@msys/ui";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import {
  Box,
  Divider,
  IconButton,
  Link,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { useSnackbar } from "notistack";
import React from "react";
import { Link as RouterLink, useParams } from "react-router-dom";
import { namedOperations } from "../../../clients/graphqlTypes";
import { LogoutButton } from "../../auth/LogoutButton";
import { RestrictedByOrganisationPermissionWithDebug } from "../../auth/RestrictedByOrganisationPermission";
import { SwicthOrganisationButton } from "../../auth/SwicthOrganisationButton";
import { useAvailableUsers } from "../../auth/useAvailableUsers";
import { useUserData } from "../../auth/useUserData";
import { Page, PageTopbarItem } from "../../commons/layout/Page";
import { PageContainer } from "../../commons/layout/PageContainer";
import { PageGrid } from "../../commons/layout/PageGrid";
import { Stack } from "../../commons/layout/Stack";
import { PageNotFound } from "../../commons/PageNotFound";
import { Attachment } from "../../features/attachments/helpers";
import { ConfirmDeactivationModal } from "../../features/users/ConfirmDeactivationModal";
import { UserAvatar } from "../../features/users/UserAvatar";
import { UserDeleteAccountButton } from "../../features/users/UserDeleteAccountButton";
import { UserInformationBox } from "../../features/users/UserInformationBox";
import { UserInformationForm } from "../../features/users/UserInformationForm";
import { UserRolesBox } from "../../features/users/UserRolesBox";
import { UserRolesForm } from "../../features/users/UserRolesForm";
import {
  useModifyUserAvatarMutation,
  useMyOrganisationUserProfileQuery,
  useToggleOrganisationMembershipActiveMutation,
} from "./OrganisationUserProfile.generated";

interface Props {
  submenuItems: PageTopbarItem[];
  userId?: string;
}

export function MyOrganisationUserProfile({
  submenuItems,
  userId: userIdProp,
}: Props) {
  const { userId: userIdParam } = useParams();
  const userId = userIdProp ?? userIdParam;
  if (!userId) throw new Error("User id is missing");

  const viewer = useUserData().currentUser!;
  const { t } = useTranslate(["UserProfile", "Global"]);

  const { availableUsers } = useAvailableUsers();

  const client = useApolloClient();
  const query = useMyOrganisationUserProfileQuery({
    client,
    variables: { userId },
    fetchPolicy: "network-only",
  });
  const user = query.data?.user;

  const [modifyUser, { loading: modifyUserLoading }] =
    useModifyUserAvatarMutation({
      client,
      refetchQueries: [namedOperations.Query.MyOrganisationUserProfile],
    });
  async function onAttachment(attachment: Attachment | null) {
    await modifyUser({
      variables: {
        user: {
          avatar: attachment
            ? {
                url: attachment.url,
                title: attachment.title,
                mimeType: attachment.mimeType,
              }
            : null,
        },
      },
    });
  }

  if (!query.loading && user?.organisationId !== viewer.organisation.id)
    return <PageNotFound />;

  const isViewersUser = viewer.id === userId;

  return (
    <Page
      title={user ? `${user.firstname} ${user.familyname}` : ""}
      submenuItems={submenuItems}
    >
      {user && (
        <PageContainer>
          <PageGrid columns={{ xs: 1, md: 2, xl: 4 }}>
            <>
              <CardContainer>
                <Stack flexDirection="column" p={1}>
                  <Stack justifyContent="center" mb={1}>
                    {isViewersUser ? (
                      <UserAvatar
                        userAvatar={user}
                        size="l"
                        onAttachment={onAttachment}
                        disabled={modifyUserLoading}
                      />
                    ) : (
                      <RestrictedByOrganisationPermissionWithDebug
                        permission="MANAGE_ORG"
                        otherwise={<UserAvatar userAvatar={user} size="l" />}
                      >
                        <UserAvatar
                          userAvatar={user}
                          size="l"
                          onAttachment={onAttachment}
                          disabled={modifyUserLoading}
                        />
                      </RestrictedByOrganisationPermissionWithDebug>
                    )}
                  </Stack>
                  {!isViewersUser && (
                    <RestrictedByOrganisationPermissionWithDebug permission="MANAGE_ORG">
                      <Box position="absolute" top={0} right={0} pr={1} pt={1}>
                        <UserProfileMenu member={user} />
                      </Box>
                    </RestrictedByOrganisationPermissionWithDebug>
                  )}

                  <Stack flexDirection="column" spacing={1}>
                    <Typography variant="h2" align="center">
                      {user.firstname} {user.familyname}
                    </Typography>

                    <Link
                      component={RouterLink}
                      to={`/organisation`}
                      sx={{ alignSelf: "center" }}
                    >
                      {`${user.organisationTitle}${
                        !user.active
                          ? ` (${t("Deactivated", {
                              ns: "Global",
                            })})`
                          : ""
                      }`}
                    </Link>
                  </Stack>

                  {isViewersUser ? (
                    <UserInformationForm key={`${user.id}-form`} user={user} />
                  ) : (
                    <RestrictedByOrganisationPermissionWithDebug
                      permission="MANAGE_ORG"
                      otherwise={
                        <UserInformationBox
                          key={`${user.id}-info-box`}
                          user={user}
                        />
                      }
                    >
                      <UserInformationForm
                        key={`${user.id}-info-form`}
                        user={user}
                      />
                    </RestrictedByOrganisationPermissionWithDebug>
                  )}
                  <Divider />

                  {isViewersUser ? (
                    <UserRolesForm key={`${user.id}-roles-form`} user={user} />
                  ) : (
                    <RestrictedByOrganisationPermissionWithDebug
                      permission="MANAGE_ORG"
                      otherwise={
                        <UserRolesBox
                          key={`${user.id}-roles-box`}
                          user={user}
                        />
                      }
                    >
                      <UserRolesForm
                        key={`${user.id}-roles-form`}
                        user={user}
                      />
                    </RestrictedByOrganisationPermissionWithDebug>
                  )}
                </Stack>
              </CardContainer>

              {isViewersUser && (
                <>
                  <Box>
                    <LogoutButton type="button" />
                  </Box>
                  {availableUsers.length > 1 && (
                    <Box>
                      <SwicthOrganisationButton fullWidth />
                    </Box>
                  )}
                </>
              )}
            </>
          </PageGrid>
        </PageContainer>
      )}
    </Page>
  );
}

const UserProfileMenu = ({
  member,
}: {
  member: {
    id: string;
    organisationId: string;
    active: boolean;
  };
}) => {
  const { t } = useTranslate("UserProfile");
  const { enqueueSnackbar } = useSnackbar();

  const client = useApolloClient();
  const [toggleOrganisationMembershipActive] =
    useToggleOrganisationMembershipActiveMutation({
      client,
    });

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [isDeactivating, setIsDeactivating] = React.useState(false);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const toggleMembershipActive = async (active: boolean) => {
    const { data } = await toggleOrganisationMembershipActive({
      variables: {
        input: {
          memberId: member.id,
          active,
        },
      },
    });
    enqueueSnackbar(
      data?.toggleOrganisationMembershipActive.member.active
        ? t("Membership activated")
        : t("Membership deactivated")
    );
  };

  return (
    <>
      <IconButton
        color="primary"
        size="small"
        onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
          setAnchorEl(event.currentTarget);
        }}
        aria-haspopup="true"
      >
        <MoreHorizIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={async () => {
            if (member.active) {
              setIsDeactivating(true);
            } else {
              await toggleMembershipActive(true);
            }
            handleClose();
          }}
        >
          {member.active
            ? t("Deactivate membership")
            : t("Activate membership")}
        </MenuItem>
      </Menu>
      {isDeactivating && (
        <ConfirmDeactivationModal
          organisationId={member.organisationId}
          userId={member.id}
          handleClose={() => {
            setIsDeactivating(false);
          }}
          handleComplete={async () => {
            await toggleMembershipActive(false);
            setIsDeactivating(false);
          }}
        />
      )}
    </>
  );
};
