import { List, ListItem, ListItemText } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { CardContainer } from "@msys/ui";
import { Project } from "../../../../clients/graphqlTypes";
import { useTranslate } from "@tolgee/react";
import { CreateProjectOrTicketButton } from "../../projects/buttons/CreateProjectOrTicketButton";
import { ProjectStatusBadge } from "../../projects/badges/ProjectStatusBadge";

interface Props {
  projects: Pick<Project, "id" | "title" | "state">[];
  buildingId: string;
  buildingItemId: string;
  crmOrganisationId?: string;
  canAdd?: boolean;
}

export const BuildingProjectsBox = ({
  buildingId,
  buildingItemId,
  projects,
  crmOrganisationId,
  canAdd = false,
}: Props) => {
  const { t } = useTranslate("Projects");

  return (
    <CardContainer
      title={t("Projects")}
      itemCount={projects.length}
      ActionButton={
        canAdd &&
        buildingItemId &&
        crmOrganisationId && (
          <CreateProjectOrTicketButton
            type="icon"
            size="small"
            crmOrganisationId={crmOrganisationId}
            buildingId={buildingId}
            buildingItemId={buildingItemId}
            pageName="Building"
          />
        )
      }
    >
      <List disablePadding>
        {projects.map((project, index, array) => (
          <ListItem
            key={project.id}
            component={Link}
            button
            divider={index < array.length - 1}
            to={`/projects/${project.id}`}
          >
            <ListItemText primary={project.title} />
            <ProjectStatusBadge projectState={project.state} align="right" />
          </ListItem>
        ))}
      </List>
    </CardContainer>
  );
};
