import { useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import {
  CardItem,
  DataGrid,
  DebouncedSearchInput,
  ErrorMessage,
  GridColDef,
  ListHeader,
  LoadingSpinner,
  Tabs,
} from "@msys/ui";
import { Stack, TablePagination } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { OrdersSorting } from "../../../clients/graphqlTypes";
import { SwitchCollectionViewButton } from "../../commons/button/SwitchCollectionViewButton";
import { useFiltersAndPagination } from "../../commons/filters/useFiltersAndPagination";
import {
  CollectionView,
  useCollectionViewWithMobile,
} from "../../commons/hooks/useCollectionView";
import { useStateWithUrlParam } from "../../commons/hooks/useStateWithUrlParam";
import { Page, PageTopbarItem } from "../../commons/layout/Page";
import { PageContainer } from "../../commons/layout/PageContainer";
import {
  PAGE_LIST_RESULTS_PER_PAGE,
  RESULTS_PER_PAGE_OPTIONS,
} from "../../constants";
import { NodePurchaseOrderListItem } from "../../features/purchase-orders/PurchaseOrderListItem";
import { PurchaseOrderStatusBadge } from "../../features/purchase-orders/PurchaseOrderStatusBadge";
import { PurchaseOrderTitle } from "../../features/purchase-orders/PurchaseOrderTitle";
import { useDataGridStateStore } from "../../features/users/useDataGridStateStore";
import {
  DocumentsPurchaseOrders_OrderNodeFragment,
  DocumentsPurchaseOrdersQueryVariables,
  useDocumentsPurchaseOrdersQuery,
} from "./PurchaseOrders.generated";

const ALLOWED_VIEWS: CollectionView[] = ["table", "list"];

type QuickFilterValue = "ALL" | "DRAFT" | "ORDERED";
type Filters = Omit<
  DocumentsPurchaseOrdersQueryVariables,
  "limit" | "offset" | "orderingCapability"
>;

const DEFAULT_SORTING: OrdersSorting[] = [
  {
    column: "createdAt",
    direction: "desc",
  },
];

interface Props {
  submenuItems: PageTopbarItem[];
}

export function PurchaseOrders({ submenuItems }: Props) {
  const navigate = useNavigate();
  const { t } = useTranslate([
    "Global",
    "ProjectOverview",
    "PurchaseOrders",
    "SidebarItems",
  ]);

  const [activeView, setActiveView] =
    useCollectionViewWithMobile<CollectionView>("orders", "table", "list");

  const quickFilterSettings: Record<
    QuickFilterValue,
    {
      label: string;
      disabled?: boolean;
      values: Filters;
    }
  > = React.useMemo(
    () => ({
      ALL: {
        label: t("All", {
          ns: "Global",
        }),
        values: {},
      },
      DRAFT: {
        label: t("Draft", {
          ns: "ProjectOverview",
        }),
        values: {
          filterStatus: "draft",
        },
      },
      ORDERED: {
        label: t("Ordered", {
          ns: "PurchaseOrders",
        }),
        values: {
          filterStatus: "ordered",
        },
      },
    }),
    [t]
  );

  const {
    offset,
    limit,
    paginationModel,
    setPaginationModel,
    filters,
    setFilters,
    toRemoveParams,
  } = useFiltersAndPagination<OrdersSorting, Filters>(
    {},
    DEFAULT_SORTING,
    PAGE_LIST_RESULTS_PER_PAGE
  );

  const [quickFilter, setQuickFilter] = useStateWithUrlParam<QuickFilterValue>(
    "quickfilter",
    "ALL",
    toRemoveParams
  );

  const QUERY_BASE_VARIABLES = {
    limit,
    offset,
    sorting: DEFAULT_SORTING,
  };

  const client = useApolloClient();
  const query = useDocumentsPurchaseOrdersQuery({
    client,
    variables: {
      ...QUERY_BASE_VARIABLES,
      ...quickFilterSettings[quickFilter].values,
      ...filters,
    },
  });
  const orders =
    getDataOrNull((query.data ?? query.previousData)?.orders)?.edges.map(
      e => e.node
    ) ?? [];
  const totalCount =
    getDataOrNull((query.data ?? query.previousData)?.orders)?.totalCount ?? 0;

  const stateStore = useDataGridStateStore("PurchaseOrders");

  return (
    <Page
      subtitle={t("Documents", {
        ns: "SidebarItems",
      })}
      title={t("Purchase Orders", {
        ns: "PurchaseOrders",
      })}
      submenuItems={submenuItems}
    >
      <PageContainer>
        <ListHeader
          // hideTitleOnMobile
          // title={t("PurchaseOrders::Purchase Orders")}
          SwitchViewButton={
            <SwitchCollectionViewButton
              allowedViews={ALLOWED_VIEWS}
              activeView={activeView}
              setActiveView={setActiveView}
            />
          }
          QuickFilter={
            <Tabs
              condensed
              useSelectOnMobile
              options={Object.entries(quickFilterSettings).map(
                ([key, value]) => {
                  return {
                    value: key as QuickFilterValue,
                    label: value.label,
                    disabled: value.disabled,
                  };
                }
              )}
              value={quickFilter}
              onChange={(newQuickFilter: QuickFilterValue) => {
                setQuickFilter(newQuickFilter);
              }}
            />
          }
          FilterButton={undefined}
          SearchField={
            <DebouncedSearchInput
              placeholder={t("Search", {
                ns: "Global",
              })}
              defaultValue={filters.searchTerm}
              onChangeSearchTerm={(newValue: string) => {
                setFilters(filters => ({
                  ...filters,
                  searchTerm: newValue,
                }));
              }}
            />
          }
          FilterChips={undefined}
          marginBottom={2}
        />

        {(() => {
          switch (activeView) {
            case "table":
              return (
                <DataGrid
                  stateStore={stateStore}
                  loading={query.loading}
                  hideFooter={totalCount === 0}
                  columns={
                    [
                      {
                        field: "order-number",
                        headerName: t("Order No", {
                          ns: "PurchaseOrders",
                        }),
                        flex: 1,
                        minWidth: 100,
                        sortable: false,
                        renderCell: ({ row: order }) => order.number,
                      },
                      {
                        field: "status",
                        headerName: t("Status", {
                          ns: "Global",
                        }),
                        flex: 1,
                        minWidth: 100,
                        sortable: false,
                        renderCell: ({ row: order }) => (
                          <PurchaseOrderStatusBadge order={order} />
                        ),
                      },
                      {
                        field: "title",
                        headerName: t("Title", {
                          ns: "Global",
                        }),
                        flex: 3,
                        minWidth: 100,
                        sortable: false,
                        renderCell: ({ row: order }) => (
                          <PurchaseOrderTitle order={order} />
                        ),
                      },
                      {
                        field: "projectNumber",
                        headerName: t("Project No", {
                          ns: "PurchaseOrders",
                        }),
                        flex: 1,
                        minWidth: 100,
                        sortable: false,
                        renderCell: ({ row: order }) => order.projectNumber,
                      },
                      {
                        field: "clientName",
                        headerName: t("Client name", {
                          ns: "PurchaseOrders",
                        }),
                        flex: 2,
                        minWidth: 100,
                        sortable: false,
                        renderCell: ({ row: order }) => order.clientName,
                      },
                      {
                        field: "supplierName",
                        headerName: t("Supplier name", {
                          ns: "PurchaseOrders",
                        }),
                        flex: 2,
                        minWidth: 100,
                        sortable: false,
                        renderCell: ({ row: order }) => order.supplierName,
                      },
                    ] as GridColDef<DocumentsPurchaseOrders_OrderNodeFragment>[]
                  }
                  rows={orders}
                  onRowClick={({ row: order }) =>
                    navigate(
                      `/projects/${order.projectId}/materials/purchase-orders/${order.id}`
                    )
                  }
                  paginationModel={paginationModel}
                  onPaginationModelChange={newPaginationModel => {
                    setPaginationModel(newPaginationModel);
                  }}
                  disableColumnFilter
                  pageSizeOptions={RESULTS_PER_PAGE_OPTIONS}
                  rowCount={totalCount}
                />
              );
            case "list":
              if (query.loading) return <LoadingSpinner />;
              if (totalCount === 0)
                return (
                  <ErrorMessage
                    message={t("There are no items to display", {
                      ns: "Global",
                    })}
                  />
                );
              return (
                <Stack width="100%" direction="column" spacing={1}>
                  {orders.map(order => (
                    <CardItem
                      key={order.id}
                      //@ts-ignore
                      component={Link}
                      to={`/projects/${order.projectId}/materials/purchase-orders/${order.id}`}
                    >
                      <NodePurchaseOrderListItem order={order} />
                    </CardItem>
                  ))}
                  <TablePagination
                    component="div"
                    count={totalCount}
                    page={paginationModel.page}
                    onPageChange={(event, newPage) => {
                      setPaginationModel({
                        pageSize: paginationModel.pageSize,
                        page: newPage,
                      });
                    }}
                    rowsPerPage={paginationModel.pageSize}
                    onRowsPerPageChange={event => {
                      setPaginationModel({
                        pageSize: parseInt(event.target.value, 10),
                        page: 0,
                      });
                    }}
                    labelRowsPerPage={t("Per page:", { ns: "Global" })}
                  />
                </Stack>
              );
          }
        })()}
      </PageContainer>
    </Page>
  );
}
