import {
  ButtonInputWithPopover,
  SelectMultiple,
  useSyncStates,
} from "@msys/ui";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Typography,
} from "@mui/material";
import { useTranslate } from "@tolgee/react";
import React from "react";
import { ProjectStateMachineStatus } from "../../../../clients/graphqlTypes";
import { FilterProps } from "../../../commons/filters";
import {
  FilterChipGroup,
  FilterChipGroupProps,
} from "../../../commons/filters/FilterChip";
import { useProjectStates } from "../useProjectStates";

export function FilterProjectStateForm({
  value: externalValue,
  setValue: setExternalValue,
  allowedStates,
}: FilterProps<ProjectStateMachineStatus[]> & {
  allowedStates: ProjectStateMachineStatus[];
}) {
  const { t } = useTranslate(["Global"]);
  const { projectStateOptions } = useProjectStates();
  const options = projectStateOptions.filter(o =>
    allowedStates.includes(o.value)
  );
  const [value, setValue, error] = useSyncStates(
    externalValue,
    setExternalValue,
    value => {
      if (value.length === 0)
        return t("Please select at least one value", { ns: "Global" });
    }
  );
  if (options.length <= 1) return null;
  return (
    <Box p={1}>
      <FormControl
        component="fieldset"
        variant="standard"
        error={Boolean(error)}
      >
        <FormGroup>
          {options.map((option, index) => (
            <FormControlLabel
              control={
                <Checkbox
                  size="medium"
                  checked={value.includes(option.value)}
                  onChange={(event, checked) => {
                    if (checked) {
                      setValue(Array.from(new Set([...value, option.value])));
                    } else {
                      setValue(value.filter(v => v !== option.value));
                    }
                  }}
                />
              }
              label={<Typography variant="body1">{option.label}</Typography>}
              sx={{ ml: 0, mr: 0 }}
              key={`${option.value}-${index}`}
            />
          ))}
        </FormGroup>
        {Boolean(error) && <FormHelperText>{error}</FormHelperText>}
      </FormControl>
    </Box>
  );
}

export function FilterProjectStateButton({
  allowedStates,
  ...props
}: FilterProps<ProjectStateMachineStatus[]> & {
  allowedStates: ProjectStateMachineStatus[];
}) {
  const { t } = useTranslate("ProjectState");
  const { projectStateOptions } = useProjectStates();
  const options = projectStateOptions.filter(o =>
    allowedStates.includes(o.value)
  );
  if (options.length <= 1) return null;

  return (
    <ButtonInputWithPopover
      label={t("Status")}
      counter={
        props.value &&
        props.value.length > 0 &&
        props.value.length < options.length
          ? props.value.length
          : undefined
      }
      content={
        <FilterProjectStateForm allowedStates={allowedStates} {...props} />
      }
      popoverWidth="md"
    />
  );
}

export function FilterProjectState({
  allowedStates,
  value,
  setValue,
  ...props
}: FilterProps<ProjectStateMachineStatus[]> & {
  allowedStates: ProjectStateMachineStatus[];
}) {
  const { t } = useTranslate("ProjectState");
  const { projectStateOptions } = useProjectStates();

  const options = projectStateOptions.filter(o =>
    allowedStates.includes(o.value)
  );
  if (options.length <= 1) return null;

  return (
    <SelectMultiple
      label={t("Project State")}
      options={options}
      value={Array.isArray(value) ? value : [value]}
      onChange={setValue}
      {...props}
    />
  );
}

export function FilterProjectStateChips(
  props: Omit<
    FilterChipGroupProps<ProjectStateMachineStatus>,
    "label" | "getValueLabel"
  > & { allowedStates: ProjectStateMachineStatus[] }
) {
  const { t } = useTranslate("ProjectState");
  const { projectStateLabels } = useProjectStates();
  if (props.values && props.values.length === props.allowedStates.length)
    return null;
  return (
    <FilterChipGroup
      {...props}
      getValueLabel={value => projectStateLabels[value]}
      label={t("Status")}
    />
  );
}
