import { Chip, ChipProps } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { without } from "lodash";
import * as React from "react";

export interface FilterChipProps<Value> {
  size?: "small" | "medium";
  label?: string;
  divider?: string;
  value: Value | null | undefined;
  getValueLabel?(value: Value): string | undefined;
  setValue?(value: Value | null | undefined): void;
  resetValue?: Value | null | undefined;
  avatar?: React.ReactElement;
  canEdit?: boolean;
  onDelete?(): void;
  color?: ChipProps["color"];
  variant?: ChipProps["variant"];
}

export function FilterChip<Value>({
  size,
  label,
  divider = ": ",
  value,
  getValueLabel,
  resetValue = undefined,
  setValue,
  avatar,
  canEdit = true,
  onDelete,
  color = "primary",
  variant,
}: FilterChipProps<Value>) {
  if (value === null || value === undefined || value === resetValue) {
    return null;
  }

  const valueLabel = getValueLabel ? getValueLabel(value) : value;

  if (getValueLabel && (valueLabel === null || valueLabel === undefined))
    return null;

  return (
    <Chip
      avatar={avatar}
      size={size ?? "small"}
      variant={variant}
      color={color}
      label={label ? `${label}${divider}${valueLabel}` : `${valueLabel}`}
      onDelete={
        onDelete ??
        (canEdit
          ? () => {
              setValue?.(resetValue);
            }
          : undefined)
      }
    />
  );
}

interface FilterChipBooleanProps
  extends Omit<FilterChipProps<boolean>, "resetValue" | "getValueLabel"> {
  getValueLabel?: Exclude<FilterChipProps<boolean>["getValueLabel"], undefined>;
}

export function FilterChipBoolean({
  label,
  value,
  getValueLabel,
  setValue,
  ...otherProps
}: FilterChipBooleanProps) {
  const { t } = useTranslate("Global");
  return (
    <FilterChip
      label={label}
      setValue={() => setValue?.(undefined)}
      value={value}
      getValueLabel={
        getValueLabel ??
        (value => {
          if (value === true) return t("Yes");
          if (value === false) return t("No");
          return "";
        })
      }
      {...otherProps}
    />
  );
}

export interface FilterChipGroupProps<Value>
  extends Omit<FilterChipProps<Value>, "value" | "setValue" | "resetValue"> {
  values: Array<Value> | Value | null | undefined;
  setValues: (values: Array<Value> | undefined) => void;
  getAvatar?: (value: Value) => React.ReactElement | undefined;
}

export function FilterChipGroup<Value>({
  size,
  label,
  values,
  getValueLabel,
  getAvatar,
  setValues,
  canEdit,
  color,
  variant,
}: FilterChipGroupProps<Value>) {
  if (!values) return null;

  const chips = Array.isArray(values) ? values : [values];

  return (
    <>
      {chips.map((value, index) => (
        <FilterChip
          key={index}
          size={size}
          label={label}
          value={value}
          getValueLabel={getValueLabel}
          setValue={() => {
            const newValues = without(chips, value);
            setValues(newValues.length > 0 ? newValues : undefined);
          }}
          avatar={getAvatar ? getAvatar(value) : undefined}
          canEdit={canEdit}
          color={color}
          variant={variant}
        />
      ))}
    </>
  );
}
