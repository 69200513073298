import { Select } from "@msys/ui";
import { isEqual } from "lodash";
import React from "react";
import {
  OrganisationProjectsSortBy,
  OrganisationProjectsSorting,
  SortDirection,
} from "../../../../clients/graphqlTypes";
import { useTranslate } from "@tolgee/react";

// const ALL_PROJECT_SORTING_FIELDS: OrganisationProjectsSortBy[] = [
//   "createdAt",
//   "title",
//   "state",
//   "budget",
//   "organisationName",
//   "categories",
//   "signedAt",
//   "earliestStart",
//   "buildingStreet",
//   "buildingPlz",
//   "buildingDistance",
//   "deadline",
// ];

const PROJECT_SORTING_FIELDS: {
  column: OrganisationProjectsSortBy;
  direction: SortDirection;
}[] = [
  { column: "createdAt", direction: "desc" },
  { column: "createdAt", direction: "asc" },
  { column: "budget", direction: "desc" },
  { column: "budget", direction: "asc" },
  { column: "earliestStart", direction: "asc" },
  { column: "deadline", direction: "asc" },
  { column: "buildingDistance", direction: "asc" },
  { column: "title", direction: "asc" },
  { column: "title", direction: "desc" },
];

interface Props {
  defaultSorting: OrganisationProjectsSorting[];
  sorting: OrganisationProjectsSorting;
  setSorting: (value: OrganisationProjectsSorting[]) => void;
}

export const ProjectsSorting = ({
  sorting,
  setSorting,
  defaultSorting,
}: Props) => {
  const { t } = useTranslate(["ProjectSorting", "Global"]);

  const sortByOptions = React.useMemo(() => {
    const projectsSortingLabels: Record<
      OrganisationProjectsSortBy,
      Record<SortDirection, string>
    > = {
      id: {
        asc: t("Project id at asc", {
          ns: "ProjectSorting",
        }),
        desc: t("Project id at desc", {
          ns: "ProjectSorting",
        }),
      },
      number: {
        asc: t("Project number at asc", {
          ns: "ProjectSorting",
        }),
        desc: t("Project number at desc", {
          ns: "ProjectSorting",
        }),
      },
      createdAt: {
        asc: t("Project created at asc", {
          ns: "ProjectSorting",
        }),
        desc: t("Project created at desc", {
          ns: "ProjectSorting",
        }),
      },
      createdBy: {
        asc: t("Project created by asc", {
          ns: "ProjectSorting",
        }),
        desc: t("Project created by desc", {
          ns: "ProjectSorting",
        }),
      },
      title: {
        asc: t("Project title asc", {
          ns: "ProjectSorting",
        }),
        desc: t("Project title desc", {
          ns: "ProjectSorting",
        }),
      },
      state: {
        asc: t("Project state asc", {
          ns: "ProjectSorting",
        }),
        desc: t("Project state desc", {
          ns: "ProjectSorting",
        }),
      },
      budget: {
        asc: t("Project budget asc", {
          ns: "ProjectSorting",
        }),
        desc: t("Project budget desc", {
          ns: "ProjectSorting",
        }),
      },
      organisationName: {
        asc: t("Organisation name asc", {
          ns: "ProjectSorting",
        }),
        desc: t("Organisation name desc", {
          ns: "ProjectSorting",
        }),
      },
      organisationEmail: {
        asc: t("Organisation email asc", {
          ns: "ProjectSorting",
        }),
        desc: t("Organisation email desc", {
          ns: "ProjectSorting",
        }),
      },
      categories: {
        asc: t("Project categories asc", {
          ns: "ProjectSorting",
        }),
        desc: t("Project categories desc", {
          ns: "ProjectSorting",
        }),
      },
      signedAt: {
        asc: t("Project signed at asc", {
          ns: "ProjectSorting",
        }),
        desc: t("Project signed at desc", {
          ns: "ProjectSorting",
        }),
      },
      earliestStart: {
        asc: t("Project start date asc", {
          ns: "ProjectSorting",
        }),
        desc: t("Project start date desc", {
          ns: "ProjectSorting",
        }),
      },
      buildingStreet: {
        asc: t("Building street asc", {
          ns: "ProjectSorting",
        }),
        desc: t("Building street desc", {
          ns: "ProjectSorting",
        }),
      },
      buildingPlz: {
        asc: t("Building plz asc", {
          ns: "ProjectSorting",
        }),
        desc: t("Building plz desc", {
          ns: "ProjectSorting",
        }),
      },
      buildingDistance: {
        asc: t("Building distance asc", {
          ns: "ProjectSorting",
        }),
        desc: t("Building distance desc", {
          ns: "ProjectSorting",
        }),
      },
      deadline: {
        asc: t("Project deadline asc", {
          ns: "ProjectSorting",
        }),
        desc: t("Project deadline desc", {
          ns: "ProjectSorting",
        }),
      },
      phase: {
        asc: t("Project phase asc", {
          ns: "ProjectSorting",
        }),
        desc: t("Project phase desc", {
          ns: "ProjectSorting",
        }),
      },
      assignee: {
        asc: t("Project assignee asc", {
          ns: "ProjectSorting",
        }),
        desc: t("Project assignee desc", {
          ns: "ProjectSorting",
        }),
      },
    };

    return PROJECT_SORTING_FIELDS.map(({ column, direction }) => ({
      label: projectsSortingLabels[column][direction],
      value: { column, direction },
    }));
  }, [t]);

  return (
    <Select
      label={t("Sort by", { ns: "Global" })}
      options={sortByOptions}
      value={
        sortByOptions.find(option => isEqual(option.value, sorting))?.value
      }
      onChange={value => {
        setSorting(value ? [value] : defaultSorting);
      }}
    />
  );
};
