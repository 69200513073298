import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { CollapseChip } from "./CollapseChip";
import { ellipsisStyle } from "./text/Ellipsis";

interface Props {
  title: React.ReactNode;
  icon?: React.ReactElement;
  count?: number;
}

export const CollapseTitle = ({ title, icon, count }: Props) => {
  return (
    <Stack direction={"row"} spacing={0.5} alignItems={"center"} minWidth={0}>
      {icon && (
        <Box display="flex" flexShrink={0} flexGrow={0}>
          {icon}
        </Box>
      )}
      <Typography
        variant="h4"
        color="primary"
        textAlign="left"
        sx={{
          minWidth: 0,
          flexShrink: 1,
          flexGrow: 1,
          // same as tabs
          fontWeight: 500,
          fontSize: "0.875rem",
          lineHeight: 1.25,
          letterSpacing: "0.02857em",
          textTransform: "uppercase",
          ...ellipsisStyle,
        }}
      >
        {title}
      </Typography>
      {count !== undefined && (
        <Box
          display="flex"
          alignItems="center"
          ml={0.5}
          component="span"
          height="1em"
          flexShrink={0}
          flexGrow={0}
        >
          <CollapseChip label={count} sx={{ ml: 0.5 }} />
        </Box>
      )}
    </Stack>
  );
};
