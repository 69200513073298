import { Stack } from "@mui/material";
import React from "react";
import { ProjectStateMachineStatus } from "../../../clients/graphqlTypes";
import { LocalDndProvider } from "../../../common/dnd";

interface Props {
  children: React.ReactNode;
}

export type KanbanDragItem<Edge> = {
  projectId: string;
  phaseId: string;
  state: ProjectStateMachineStatus;
  type: "kanban-card";
  edge: Edge;
};

export function KanbanContainer({ children }: Props) {
  return (
    <LocalDndProvider>
      <Stack
        flexBasis={0}
        flexGrow={1}
        overflow={"auto"}
        height={"100%"}
        direction="row"
        spacing={2}
      >
        {children}
      </Stack>
    </LocalDndProvider>
  );
}
