import { useApolloClient } from "@apollo/client";
import { Modal } from "@msys/ui";
import { Divider } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { Field, Form, Formik, yupToFormErrors } from "formik";
import { TextField } from "formik-mui";
import { omit, uniqueId } from "lodash";
import { useSnackbar } from "notistack";
import React, { useMemo } from "react";
import * as Yup from "yup";
import {
  OpeningHour,
  OpeningHourInput,
  PhoneInput,
} from "../../../../clients/graphqlTypes";
import { OpeningHoursField } from "../../../commons/form-fields/OpeningHoursField";
import {
  PHONE_REG_EXP,
  SITE_REG_EXP,
} from "../../../commons/form-fields/helpers";
import { Stack } from "../../../commons/layout/Stack";
import { AddressField } from "../../addresses/AddressField";
import { AddressDetails__AddressFragment } from "../../addresses/Addresses.generated";
import { PhonesField } from "../../phones/PhonesField";
import { defaultPhoneType } from "../../phones/usePhoneTypes";
import { useModifyOrganisationProfile__ContactMutation } from "../Organisations.generated";
import { EditOrganisationProfileContactModal_OrganisationProfileFragment } from "./EditOrganisationProfileContactModal.generated";

interface FormValues {
  contactAddress?: AddressDetails__AddressFragment;
  contactPhones: PhoneInput[];
  contactWebsite?: string;
  contactEmail?: string;
  openingHours?: Pick<OpeningHour, "isOpen" | "from" | "to">[];
}

interface Props {
  title?: string;
  organisationProfile: EditOrganisationProfileContactModal_OrganisationProfileFragment;
  handleClose: () => void;
  handleComplete?: (values: FormValues) => Promise<void> | void;
}

export const EditOrganisationProfileContactModal = ({
  title,
  organisationProfile,
  handleClose,
  handleComplete,
}: Props) => {
  const { t } = useTranslate([
    "OrganisationPublicProfile",
    "OrganisationProfile",
    "Global",
  ]);
  const { enqueueSnackbar } = useSnackbar();

  const client = useApolloClient();
  const [modifyProfile, { loading: isSubmitting }] =
    useModifyOrganisationProfile__ContactMutation({
      client,
    });

  const handleSubmit = async (values: FormValues) => {
    try {
      await modifyProfile({
        variables: {
          input: {
            slug: organisationProfile.slug,
            contactAddress: values.contactAddress
              ? {
                  ...omit(values.contactAddress, "__typename", "id"),
                  location: values.contactAddress.location
                    ? omit(values.contactAddress.location, "__typename")
                    : null,
                }
              : null,
            contactWebsite: values.contactWebsite,
            contactEmail: values.contactEmail,
            contactPhones: values.contactPhones
              .filter(p => p.number.trim())
              .map(p => omit(p, "__typename", "id")),
            openingHours: values.openingHours?.length
              ? values.openingHours.map((e, index) => ({
                  day: index,
                  isOpen: e.isOpen,
                  from: e.from,
                  to: e.to,
                }))
              : null,
          },
        },
      });
      await handleComplete?.(values);
      handleClose();
    } catch (e) {
      if (e instanceof Error) enqueueSnackbar(e.message, { variant: "error" });
    }
  };

  const validationSchema = React.useMemo(
    () =>
      Yup.object().shape({
        contactEmail: Yup.string()
          .label(
            t("Email", {
              ns: "OrganisationProfile",
            })
          )
          .email()
          .trim(),
        contactWebsite: Yup.string()
          .label(
            t("Website", {
              ns: "OrganisationProfile",
            })
          )
          .matches(
            SITE_REG_EXP,
            t("Website is not valid", {
              ns: "OrganisationProfile",
            })
          ),
        contactAddress: Yup.object()
          .label(t("Address", { ns: "OrganisationProfile" }))
          .nullable(),
        contactPhones: Yup.array().of(
          Yup.object().shape({
            id: Yup.string(),
            main: Yup.boolean(),
            type: Yup.string(),
            number: Yup.string().matches(
              PHONE_REG_EXP,
              t("Phone number is not valid", {
                ns: "OrganisationProfile",
              })
            ),
          })
        ),
        openingHours: Yup.array(
          Yup.object({
            from: Yup.string().nullable(),
            to: Yup.string().nullable(),
            isOpen: Yup.boolean().required(),
          })
        ).nullable(),
      }),
    [t]
  );

  const initialValues: FormValues = {
    contactAddress: organisationProfile.contactAddress ?? undefined,
    contactWebsite: organisationProfile.contactWebsite ?? undefined,
    contactEmail: organisationProfile.contactEmail ?? undefined,
    contactPhones:
      organisationProfile.contactPhones?.length > 0
        ? organisationProfile.contactPhones
        : [defaultPhoneType],
    openingHours: organisationProfile.openingHours ?? undefined,
  };

  const formId = useMemo(() => uniqueId(), []);

  return (
    <Modal
      title={
        title ??
        t("Edit contact information", {
          ns: "OrganisationPublicProfile",
        })
      }
      dialogProps={{ maxWidth: "sm" }}
      actionButtons={[
        {
          label: t("Cancel", {
            ns: "Global",
          }),
          handleClick: handleClose,
          buttonProps: { variant: "text", disabled: isSubmitting },
        },
        {
          label: t("Save", {
            ns: "Global",
          }),
          buttonProps: {
            type: "submit",
            form: formId,
            loading: isSubmitting,
            disabled: isSubmitting,
          },
        },
      ]}
      handleClose={handleClose}
    >
      <Formik<FormValues>
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values }) => (
          <Form id={formId}>
            <Stack flexDirection="column" width="100%">
              <Field
                component={TextField}
                disabled={false}
                name="contactWebsite"
                label={t("Website", {
                  ns: "OrganisationProfile",
                })}
              />
              <Field
                component={TextField}
                disabled={false}
                name="contactEmail"
                label={t("Email", {
                  ns: "OrganisationProfile",
                })}
                type="email"
              />
              <AddressField
                name="contactAddress"
                label={t("Address", {
                  ns: "OrganisationProfile",
                })}
                disabled={false}
              />
              <PhonesField
                name="contactPhones"
                buttonLabel={t("Add phone number", {
                  ns: "OrganisationProfile",
                })}
                value={values.contactPhones}
                disabled={false}
                autoSubmit={false}
              />
              <Divider />
              <OpeningHoursField name="openingHours" />
            </Stack>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
