import { SelectOption } from "../inputs/Select";
import { LabeledValue } from "./LabeledValue";

interface Props<Value> extends React.ComponentProps<typeof LabeledValue> {
  label: string;
  value?: Value[];
  options: SelectOption<Value>[];
  notSetText: string;
}

export const LabeledSelectMultipleValue = <Value,>({
  label,
  value,
  options,
  notSetText,
  ...props
}: Props<Value>) => {
  const selectedOptions = options.filter(o => value?.includes(o.value));
  return (
    <LabeledValue label={label} notSet={!selectedOptions.length} {...props}>
      {selectedOptions.length > 0
        ? selectedOptions.map(o => o.label).join(", ")
        : notSetText}
    </LabeledValue>
  );
};
