import { gql, useApolloClient } from "@apollo/client";
import { faArrowAltCircleUp } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getDataOrNull } from "@msys/common";
import {
  AutocompleteMultiple,
  CardItem,
  DataGrid,
  DebouncedSearchInput,
  ErrorMessage,
  GridColDef,
  ListHeader,
  LoadingSpinner,
  ModalOpenButton,
  SelectMultiple,
} from "@msys/ui";
import CheckIcon from "@mui/icons-material/Check";
import {
  Button,
  Icon,
  IconButton,
  Stack,
  TablePagination,
  Tooltip,
  Typography,
} from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { omitBy } from "lodash";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Avatar } from "../../commons/Avatar";
import { ButtonCreate } from "../../commons/button/Button";
import { SwitchCollectionViewButton } from "../../commons/button/SwitchCollectionViewButton";
import { FilterButton } from "../../commons/filters/FilterButton";
import { FilterChipGroup } from "../../commons/filters/FilterChip";
import { FilterModal } from "../../commons/filters/FilterModal";
import { useFiltersAndPagination } from "../../commons/filters/useFiltersAndPagination";
import {
  CollectionView,
  useCollectionViewWithMobile,
} from "../../commons/hooks/useCollectionView";
import { Page, PageTopbarItem } from "../../commons/layout/Page";
import { PageContainer } from "../../commons/layout/PageContainer";
import {
  PAGE_LIST_RESULTS_PER_PAGE,
  RESULTS_PER_PAGE_OPTIONS,
} from "../../constants";
import { CrmCompanyListItem } from "../../features/crm-companies/components/CrmCompanyListItem";
import { useCrmOrganisationAbcCategories } from "../../features/crm-companies/hooks/useCrmOrganisationAbcCategories";
import { CrmCompanyInviteModal } from "../../features/crm-companies/modals/CrmCompanyInviteModal";
import { CrmCreateModal } from "../../features/crm/CrmCreateModal";
import { CrmTagChips } from "../../features/crm/CrmTagChips";
import { MassImportCrmModal } from "../../features/crm/MassImportCrmModal";
import { OrganisationCategoryAvatar } from "../../features/organisations/OrganisationCategoryAvatar";
import { useOrganisationTags } from "../../features/organisations/useOrganisationTags";
import { useDataGridStateStore } from "../../features/users/useDataGridStateStore";
import {
  namedOperations,
  OrganisationCrmOrganisationsSortBy,
  OrganisationCrmOrganisationsSorting,
} from "../../../clients/graphqlTypes";
import {
  CrmAllQueryVariables,
  CrmCompanies__CrmCompanyFragment,
  useCrmAllQuery,
} from "./CrmAll.generated";
import { RestrictedByOrganisationPermissionWithDebug } from "../../auth/RestrictedByOrganisationPermission";

const ALLOWED_VIEWS: CollectionView[] = ["table", "list"];

type Filters = Omit<CrmAllQueryVariables, "limit" | "offset" | "sorting">;
const DEFAULT_SORTING: OrganisationCrmOrganisationsSorting[] = [
  {
    column: "organisationName",
    direction: "asc",
  },
];

interface Props {
  submenuItems: PageTopbarItem[];
}

export function CrmAll({ submenuItems }: Props) {
  const { t } = useTranslate(["CrmOrganisations", "Global", "CrmPageTopbar"]);
  const navigate = useNavigate();

  const [activeView, setActiveView] =
    useCollectionViewWithMobile<CollectionView>(
      "crm-companies",
      "table",
      "list"
    );

  const {
    offset,
    limit,
    paginationModel,
    setPaginationModel,
    filters,
    setFilters,
    resetFilters,
    sorting,
    setSorting,
  } = useFiltersAndPagination<OrganisationCrmOrganisationsSorting, Filters>(
    {},
    DEFAULT_SORTING,
    PAGE_LIST_RESULTS_PER_PAGE
  );

  const QUERY_BASE_VARIABLES = {
    offset,
    limit,
    sorting: DEFAULT_SORTING,
  };

  const client = useApolloClient();
  const query = useCrmAllQuery({
    client,
    variables: {
      ...QUERY_BASE_VARIABLES,
      ...omitBy(filters, filter => (filter ? filter.length === 0 : true)),
      sorting,
    },
  });

  const totalCount =
    getDataOrNull((query.data ?? query.previousData)?.crmCompanies)
      ?.totalCount ?? 0;
  const crmCompanies =
    getDataOrNull((query.data ?? query.previousData)?.crmCompanies)?.edges.map(
      edge => edge.node
    ) ?? [];
  const { tags } = useOrganisationTags("CRM_COMPANY");

  const renderInviteButton = React.useCallback(
    (crmCompany: CrmCompanies__CrmCompanyFragment) =>
      crmCompany.linkedSystemOrganisationId ? (
        <Tooltip
          title={t("Invitation accepted", {
            ns: "CrmOrganisations",
          })}
        >
          <CheckIcon fontSize="small" color="primary" />
        </Tooltip>
      ) : crmCompany.isInvited ? (
        <Button size="extra-small" variant="outlined" color="primary" disabled>
          {t("Invited", { ns: "CrmOrganisations" })}
        </Button>
      ) : (
        <ModalOpenButton
          Modal={CrmCompanyInviteModal}
          modalProps={{
            crmCompanyId: crmCompany.id,
            refetchQueries: [namedOperations.Query.CrmAll],
          }}
        >
          <Button
            size="extra-small"
            variant="outlined"
            color="primary"
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            {t("Invite", { ns: "CrmOrganisations" })}
          </Button>
        </ModalOpenButton>
      ),
    [t]
  );

  const columns = React.useMemo(
    (): GridColDef<CrmCompanies__CrmCompanyFragment>[] => [
      {
        field: "organisationNumber",
        headerName: t("No.", {
          ns: "CrmOrganisations",
        }),
        flex: 1,
        minWidth: 100,
        sortable: true,
        renderCell: ({ row: crmCompany }) => crmCompany.number,
      },
      {
        field: "organisationName",
        headerName: t("Name", {
          ns: "CrmOrganisations",
        }),
        flex: 2,
        minWidth: 100,
        sortable: true,
        renderCell: ({ row: crmCompany }) => (
          <Stack direction={"row"} alignItems="center" spacing={2}>
            <Avatar
              url={crmCompany.logo?.url}
              initials={crmCompany.title.substring(0, 2)}
              size="s"
              type="square"
            />
            <div>{crmCompany.title}</div>
          </Stack>
        ),
      },
      {
        field: "mainContact",
        headerName: t("Main Contact", {
          ns: "CrmOrganisations",
        }),
        flex: 2,
        minWidth: 100,
        sortable: false,
        renderCell: ({ row: crmCompany }) =>
          crmCompany.contactPerson?.fullname ?? "-",
      },
      {
        field: "tags",
        headerName: t("Tags", {
          ns: "CrmOrganisations",
        }),
        flex: 2,
        minWidth: 100,
        sortable: false,
        renderCell: ({ row: crmCompany }) => (
          <CrmTagChips
            tags={[...crmCompany.systemTags, ...crmCompany.customTags]}
            wrap={false}
          />
        ),
      },
      {
        field: "linked",
        headerName: t("linked?", {
          ns: "CrmOrganisations",
        }),
        flex: 1.5,
        minWidth: 80,
        maxWidth: 140,
        sortable: false,
        renderCell: ({ row: crmCompany }) => renderInviteButton(crmCompany),
      },
      {
        field: "duplicate",
        headerName: t("duplicate of", {
          ns: "CrmOrganisations",
        }),
        flex: 1.5,
        minWidth: 80,
        maxWidth: 140,
        sortable: false,
        renderCell: ({ row: crmCompany }) =>
          crmCompany.crmDuplicateOfId ? (
            <div
              style={{
                maxWidth: "80px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                backgroundColor: "red",
              }}
              title={crmCompany.crmDuplicateOfId}
            >
              {crmCompany.crmDuplicateOfId}
            </div>
          ) : null,
      },
      {
        field: "abcCategory",
        headerName: t("Priority", {
          ns: "CrmOrganisations",
        }),
        width: 100,
        sortable: false,
        renderCell: ({ row: crmCompany }) => (
          <OrganisationCategoryAvatar abcCategory={crmCompany.abcCategory} />
        ),
      },
    ],
    [renderInviteButton, t]
  );

  const stateStore = useDataGridStateStore("CrmAll");

  return (
    <Page
      title={t("Businesses & Groups", { ns: "CrmPageTopbar" })}
      submenuItems={submenuItems}
    >
      <PageContainer>
        <ListHeader
          // hideTitleOnMobile
          //title={t("CrmOrganisations::All")}
          // title={t("Businesses & Groups", {
          //   ns: "CrmPageTopbar",
          // })}
          SwitchViewButton={
            <SwitchCollectionViewButton
              allowedViews={ALLOWED_VIEWS}
              activeView={activeView}
              setActiveView={setActiveView}
            />
          }
          CreateButton={
            <RestrictedByOrganisationPermissionWithDebug permission="MANAGE_CRM">
              <ModalOpenButton
                Modal={CrmCreateModal}
                modalProps={{
                  title: t("New contact", {
                    ns: "CrmOrganisations",
                  }),
                  handleComplete: async (handleClose, organisation) => {
                    handleClose();
                    navigate(`/crm/organisations/${organisation.id}`);
                  },
                }}
              >
                <ButtonCreate
                  variant="contained"
                  color="primary"
                  //title={t("CrmOrganisations::Create contact")}
                  title={t("New contact", {
                    ns: "CrmOrganisations",
                  })}
                />
              </ModalOpenButton>
            </RestrictedByOrganisationPermissionWithDebug>
          }
          FilterButton={
            <ModalOpenButton
              Modal={CrmAllFilterModal}
              modalProps={{
                filters,
                setFilters,
                resetFilters,
                tags,
              }}
            >
              <FilterButton />
            </ModalOpenButton>
          }
          FilterChips={
            query.variables?.filterByTagsAny?.length ||
            query.variables?.filterAbcCategory?.length ? (
              <Stack direction={"row"} flexWrap="wrap" spacing={1}>
                <FilterChipGroup
                  label={t("Tag", {
                    ns: "CrmOrganisations",
                  })}
                  values={filters.filterByTagsAny}
                  getValueLabel={value => value}
                  setValues={value =>
                    setFilters(state => ({
                      ...state,
                      filterByTagsAny: value,
                    }))
                  }
                />
                <FilterChipGroup
                  label={t("Priority", {
                    ns: "CrmOrganisations",
                  })}
                  values={filters.filterAbcCategory}
                  getValueLabel={value => value}
                  setValues={value =>
                    setFilters(state => ({
                      ...state,
                      abcCategory: value,
                    }))
                  }
                />
              </Stack>
            ) : undefined
          }
          SearchField={
            <DebouncedSearchInput
              placeholder={t("Search", {
                ns: "Global",
              })}
              defaultValue={filters.filterSearchTerm}
              onChangeSearchTerm={newValue =>
                setFilters(filters => ({
                  ...filters,
                  filterSearchTerm: newValue,
                }))
              }
            />
          }
          ActionButtons={
            <Stack direction={"row"} spacing={1}>
              <RestrictedByOrganisationPermissionWithDebug permission="MANAGE_CRM">
                <ModalOpenButton Modal={MassImportCrmModal}>
                  <IconButton size="small" color="primary">
                    <Tooltip
                      title={t("Import crm data", {
                        ns: "CrmOrganisations",
                      })}
                    >
                      <Icon>
                        <FontAwesomeIcon icon={faArrowAltCircleUp} />
                      </Icon>
                    </Tooltip>
                  </IconButton>
                </ModalOpenButton>
              </RestrictedByOrganisationPermissionWithDebug>
            </Stack>
          }
          marginBottom={2}
        />
        {(() => {
          switch (activeView) {
            case "table":
              return (
                <DataGrid
                  stateStore={stateStore}
                  density="standard"
                  loading={query.loading}
                  hideFooter={totalCount === 0}
                  columns={columns}
                  rows={crmCompanies}
                  onRowClick={row => navigate(`/crm/organisations/${row.id}`)}
                  paginationModel={paginationModel}
                  onPaginationModelChange={newPaginationModel => {
                    setPaginationModel(newPaginationModel);
                  }}
                  disableColumnFilter
                  pageSizeOptions={RESULTS_PER_PAGE_OPTIONS}
                  rowCount={totalCount}
                  sortModel={sorting.map(s => ({
                    field: s.column,
                    sort: s.direction,
                  }))}
                  onSortModelChange={newModel => {
                    setSorting(
                      newModel.map(({ field, sort }) => ({
                        column: field as OrganisationCrmOrganisationsSortBy,
                        direction: sort ?? "asc",
                      }))
                    );
                  }}
                />
              );
            case "list":
              if (query.loading) return <LoadingSpinner />;
              if (totalCount === 0)
                return (
                  <ErrorMessage
                    message={t("There are no items to display", {
                      ns: "Global",
                    })}
                  />
                );
              return (
                <Stack direction="column" spacing={1}>
                  {crmCompanies.map(crmCompany => (
                    <CardItem
                      key={crmCompany.id}
                      //@ts-ignore
                      component={Link}
                      to={`/crm/organisations/${crmCompany.id}`}
                    >
                      <CrmCompanyListItem
                        crmCompany={crmCompany}
                        Action={renderInviteButton(crmCompany)}
                      />
                    </CardItem>
                  ))}
                  <TablePagination
                    component="div"
                    count={totalCount}
                    page={paginationModel.page}
                    onPageChange={(event, newPage) => {
                      setPaginationModel({
                        pageSize: paginationModel.pageSize,
                        page: newPage,
                      });
                    }}
                    rowsPerPage={paginationModel.pageSize}
                    onRowsPerPageChange={event => {
                      setPaginationModel({
                        pageSize: parseInt(event.target.value, 10),
                        page: 0,
                      });
                    }}
                    labelRowsPerPage={t("Per page:", { ns: "Global" })}
                  />
                </Stack>
              );
          }
        })()}
      </PageContainer>
    </Page>
  );
}

function CrmAllFilterModal({
  filters,
  setFilters,
  resetFilters,
  handleClose,
  tags,
}: {
  filters: Filters;
  setFilters: React.Dispatch<React.SetStateAction<Filters>>;
  resetFilters: () => void;
  handleClose: () => void;
  tags: string[];
}) {
  const { t } = useTranslate(["CrmOrganisations", "Global"]);

  const [filtersState, setFiltersState] = React.useState(filters);

  const { abcCategoryOptions } = useCrmOrganisationAbcCategories();

  return (
    <FilterModal
      title={t("Businesses & Groups Filters", {
        ns: "CrmOrganisations",
      })}
      handleClose={handleClose}
      handleApply={() => {
        setFilters(filtersState);
      }}
      handleReset={resetFilters}
    >
      <Typography variant="h3">
        {t("Filter by", {
          ns: "Global",
        })}
      </Typography>
      <AutocompleteMultiple
        inputLabel={t("Tags", { ns: "CrmOrganisations" })}
        options={tags}
        value={filtersState.filterByTagsAny ?? []}
        onChange={value => {
          setFiltersState(state => ({ ...state, filterByTagsAny: value }));
        }}
        filterOptions={(options, state) =>
          options.filter(option =>
            option
              .toLocaleLowerCase()
              .trim()
              .includes(state.inputValue.toLocaleLowerCase().trim())
          )
        }
      />
      <SelectMultiple
        label={t("Priority", {
          ns: "CrmOrganisations",
        })}
        options={abcCategoryOptions}
        value={filtersState.filterAbcCategory ?? []}
        onChange={value => {
          setFiltersState(state => ({ ...state, filterAbcCategory: value }));
        }}
      />
    </FilterModal>
  );
}
