import { TableCell, TableRow } from "@mui/material";
import { AvailableReplacementsModal } from "../../documents/AvailableReplacementsModal";
import { useQuoteTextReplacements } from "../useQuoteTextReplacements";

interface Props {
  handleClose(): void;
}

export function QuoteTextsAvailableReplacementsModal({ handleClose }: Props) {
  const replacements = useQuoteTextReplacements();

  return (
    <AvailableReplacementsModal handleClose={handleClose}>
      {Object.entries(replacements)
        .sort((a, b) => a[0].localeCompare(b[0]))
        .map(([name, description], index) => (
          <TableRow key={index}>
            <TableCell>{name}</TableCell>
            <TableCell>{description}</TableCell>
          </TableRow>
        ))}
    </AvailableReplacementsModal>
  );
}
