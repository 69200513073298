import { draftStateToHtml } from "@msys/textutils";
import { CollapseSection, Modal, RichTextEditor } from "@msys/ui";
import { Stack } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { convertFromRaw, RawDraftContentState } from "draft-js";
import { Field, Form, Formik, FormikProps } from "formik";
import { TextField } from "formik-mui";
import { uniqueId } from "lodash";
import React from "react";
import * as Yup from "yup";
import { cleanHTML } from "../../utils";
import { TemplateTypeInput } from "../templateTypes/TemplateTypeInput";

export interface FormValues {
  title: string;
  description: string;
  product: { articleNumber: string; supplierId: string } | null;
  templateType: { id: string; title: string } | null;
}

interface Props {
  title?: string;
  templateTitle?: string;
  handleClose: () => void;
  handleComplete: (values: FormValues) => Promise<void> | void;
  showTemplateTypeSelect?: boolean;
  initialTemplateTypeId?: string;
}

export const CreateQuoteTemplateModal = ({
  title,
  templateTitle = "",
  handleClose,
  handleComplete,
  showTemplateTypeSelect = false,
  initialTemplateTypeId,
}: Props) => {
  const { t } = useTranslate(["QuoteCreate", "QuoteItem", "Global"]);

  const formId = React.useMemo(() => uniqueId(), []);

  const validationSchema = Yup.object().shape({
    title: Yup.string()
      .label(
        t("Title", {
          ns: "Global",
        })
      )
      .required(),
    description: Yup.string().label(
      t("Description", {
        ns: "QuoteItem",
      })
    ),
    templateType: Yup.object({
      id: Yup.string().required(),
      title: Yup.string(),
    }).nullable(),
  });

  const initialValues: FormValues = React.useMemo(
    () => ({
      title: templateTitle || "",
      description: "",
      product: null,
      templateType: initialTemplateTypeId
        ? { id: initialTemplateTypeId, title: "" }
        : null,
    }),
    [templateTitle, initialTemplateTypeId]
  );

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={handleComplete}
    >
      {(formikProps: FormikProps<FormValues>) => {
        return (
          <Modal
            title={title ?? t("New Quote Template", { ns: "QuoteCreate" })}
            maxWidth="sm"
            actionButtons={[
              {
                label: t("Cancel", { ns: "Global" }),
                handleClick: handleClose,
                buttonProps: {
                  variant: "text",
                  disabled: formikProps.isSubmitting,
                },
              },
              {
                label: t("Create Template", { ns: "QuoteCreate" }),
                buttonProps: {
                  type: "submit",
                  form: formId,
                  loading: formikProps.isSubmitting,
                  disabled: formikProps.isSubmitting || !formikProps.isValid,
                },
              },
            ]}
            handleClose={handleClose}
          >
            <Form id={formId}>
              <Stack direction="column" spacing={1}>
                {showTemplateTypeSelect && (
                  <TemplateTypeInput
                    templateTypeId={formikProps.values.templateType?.id ?? null}
                    onChange={templateType => {
                      formikProps.setValues(values => ({
                        ...values,
                        templateType: templateType,
                        title: values.title || templateType?.title || "",
                      }));
                    }}
                    onClear={() => {
                      formikProps.setValues(values => ({
                        ...values,
                        templateType: null,
                      }));
                    }}
                    required={false}
                  />
                )}
                <Field
                  label={t("Title", { ns: "Global" })}
                  name="title"
                  component={TextField}
                  required
                  autoFocus
                />
                <RichTextEditor
                  label={t("Description", { ns: "QuoteItem" })}
                  htmlContent={formikProps.values.description}
                  onChange={async (content: RawDraftContentState) => {
                    formikProps.setFieldValue(
                      "description",
                      cleanHTML(draftStateToHtml(convertFromRaw(content)))
                    );
                  }}
                  saveButtonLabel={t("Save", { ns: "Global" })}
                  cancelButtonLabel={t("Cancel", { ns: "Global" })}
                />
              </Stack>
            </Form>
          </Modal>
        );
      }}
    </Formik>
  );
};

export interface CreateQuoteTemplateProcessRef {
  createQuoteTemplate: (templateTitle?: string) => Promise<FormValues | null>;
}

export const CreateQuoteTemplateProcess = React.forwardRef(
  (
    props: Omit<Props, "handleClose" | "handleComplete">,
    forwardedRef: React.Ref<CreateQuoteTemplateProcessRef>
  ) => {
    const [state, setState] = React.useState<{
      isOpen: boolean;
      templateTitle: string | null;
    }>({ isOpen: false, templateTitle: null });

    const resolveRef = React.useRef<
      ((values: FormValues | null) => void) | null
    >(null);

    React.useImperativeHandle(
      forwardedRef,
      () => ({
        createQuoteTemplate: (templateTitle?: string) =>
          new Promise<FormValues | null>(resolve => {
            resolveRef.current = resolve;
            setState({ isOpen: true, templateTitle: templateTitle ?? null });
          }),
      }),
      []
    );

    return state.isOpen ? (
      <CreateQuoteTemplateModal
        handleClose={() => {
          resolveRef.current?.(null);
          resolveRef.current = null;
          setState({ isOpen: false, templateTitle: null });
        }}
        handleComplete={values => {
          resolveRef.current?.(values);
          resolveRef.current = null;
          setState({ isOpen: false, templateTitle: null });
        }}
        {...props}
        templateTitle={state.templateTitle ?? props.templateTitle}
      />
    ) : null;
  }
);
