import { useApolloClient } from "@apollo/client";
import { assertNever, getDataOrNull, notNull } from "@msys/common";
import {
  Autocomplete,
  ButtonInputWithPopover,
  ButtonSelect,
  ButtonSelectMultiple,
  getFormattedNumber,
  PriceSliderWithInput,
  Select,
  SelectMultiple,
  useDebouncedValue,
} from "@msys/ui";
import { Box, Typography } from "@mui/material";
import { TFunction } from "@tolgee/core";
import { useTolgee, useTranslate } from "@tolgee/react";
import { FormikProps } from "formik";
import { isNull, partition, pick } from "lodash";
import { matchSorter } from "match-sorter";
import React from "react";
import * as Yup from "yup";
import { ProductSearchFilterPropertyFilterInput } from "../../../../clients/graphqlTypes";
import {
  ProductFilterFields_AvailableFiltersQueryVariables,
  useProductFilterFields_AvailableFiltersQuery,
} from "./ProductFilterFields.generated";

type ProductProperty =
  | {
      key: string;
      values: { count: number; value: boolean }[];
      type: "bool";
      isOrganisationFavourite: boolean;
    }
  | {
      key: string;
      values: { count: number; value: number }[];
      type: "number";
      isOrganisationFavourite: boolean;
    }
  | {
      key: string;
      values: { count: number; value: string }[];
      type: "text";
      isOrganisationFavourite: boolean;
    };
export type ProductFiltersFormValues = {
  supplierProductCategoryKey: string | null;
  organisationFavourite: boolean | null;
  supplierIds: string[] | null;
  productTypeIds: string[] | null;
  brands: string[] | null;
  brandLines: string[] | null;
  netPriceMin: number | null;
  netPriceMax: number | null;
  listPriceMin: number | null;
  listPriceMax: number | null;
  properties: ProductSearchFilterPropertyFilterInput[] | null;
};

type FiltersViewType = "inputs" | "buttons";

interface Props {
  productSearchVariables: ProductFilterFields_AvailableFiltersQueryVariables;
  formikProps: FormikProps<ProductFiltersFormValues>;
  hideProductTypes?: boolean;
  hideSupplier?: boolean;
  hideBrands?: boolean;
  hideBrandLines?: boolean;
  hideNetPrice?: boolean;
  hideListPrice?: boolean;
  showOnlyPropertyFiltersWithValues?: boolean;
  canChangeProductType?: boolean;
  viewType?: FiltersViewType;
}

export function useProductFilterFields({
  productSearchVariables,
  formikProps,
  hideProductTypes,
  hideSupplier,
  hideBrands,
  hideBrandLines,
  hideNetPrice,
  hideListPrice,
  showOnlyPropertyFiltersWithValues,
  canChangeProductType = true,
  viewType = "inputs",
}: Props): {
  nonPropertyFields: React.ReactElement[];
  popularPropertyFields: React.ReactElement[];
  advancePropertyFields: React.ReactElement[];
  isLoading: boolean;
} {
  const { t } = useTranslate(["Product", "Global", "ProductSearch"]);
  const language = useTolgee(["language"]).getLanguage()!;

  const { values, setFieldValue, setValues } = formikProps;

  const debouncedValues = useDebouncedValue(values, 500);

  const variables = React.useMemo(
    () => ({
      ...productSearchVariables,
      filters: {
        ...productSearchVariables.filters,
        ...debouncedValues,
      },
    }),
    [productSearchVariables, debouncedValues]
  );

  const client = useApolloClient();
  const availableFiltersQuery = useProductFilterFields_AvailableFiltersQuery({
    client,
    variables,
  });
  const availableFilters = getDataOrNull(
    (availableFiltersQuery.data ?? availableFiltersQuery.previousData)
      ?.pimSearchProductsAvailableFilters
  );

  const availableOptions = React.useMemo(() => {
    const productTypeOptions = availableFilters?.productTypeIds?.map(pt => ({
      value: pt.id,
      label: `${pt.productType.label} (${pt.productType.key})`,
      counter: pt.count,
    }));
    const productTypeIds = productTypeOptions?.map(pt => pt.value) ?? [];
    const productTypeCounters =
      productTypeOptions?.reduce(
        (acc, pt) => {
          acc[pt.value] = pt.counter;
          return acc;
        },
        {} as Record<string, number>
      ) ?? {};
    const productTypeLabels =
      productTypeOptions?.reduce(
        (acc, pt) => {
          acc[pt.value] = pt.label;
          return acc;
        },
        {} as Record<string, string>
      ) ?? {};
    const filterProductTypeOptions = (
      options: { value: string; label: string }[],
      { inputValue }: { inputValue: string }
    ) =>
      matchSorter(options, inputValue, { keys: ["label"] }).sort(
        (o1, o2) =>
          (productTypeCounters[o2.value] ?? 0) -
          (productTypeCounters[o1.value] ?? 0)
      );
    const filterProductTypeStringOptions = (
      options: string[],
      { inputValue }: { inputValue: string }
    ) =>
      filterProductTypeOptions(
        options.map(o => ({ value: o, label: productTypeLabels[o] })),
        { inputValue }
      ).map(o => o.value);
    const supplierIds = availableFilters?.supplierIds?.map(s => ({
      value: s.id,
      label: s.organisation.title,
      counter: s.count,
    }));
    const brands = availableFilters?.brands.map(b => ({
      value: b.key,
      label: b.label,
      counter: b.count,
    }));
    const brandLines = availableFilters?.brandLines.map(b => ({
      value: b.key,
      label: b.label,
      counter: b.count,
    }));
    const properties =
      availableFilters?.propertiesBool &&
      availableFilters?.propertiesNumber &&
      availableFilters?.propertiesText
        ? ([
            ...availableFilters.propertiesBool.map(p => ({
              ...p,
              type: "bool",
            })),
            ...availableFilters.propertiesNumber.map(p => ({
              ...p,
              type: "number",
            })),
            ...availableFilters.propertiesText.map(p => ({
              ...p,
              type: "text",
            })),
          ].sort((p1, p2) => p1.key.localeCompare(p2.key)) as ProductProperty[])
        : undefined;

    const [popularProperties, advancesProperties] = partition(
      properties,
      property => property.isOrganisationFavourite
    );

    function filterProperties(property: ProductProperty) {
      if (property.isOrganisationFavourite) return true;

      return productSearchVariables.filters?.properties?.some(
        filter => filter.key === property.key
      );
    }

    return {
      productTypeIds,
      productTypeCounters,
      productTypeLabels,
      filterProductTypeOptions,
      filterProductTypeStringOptions,
      supplierIds,
      brands,
      brandLines,
      popularProperties: showOnlyPropertyFiltersWithValues
        ? popularProperties.filter(filterProperties)
        : popularProperties,
      advancesProperties: showOnlyPropertyFiltersWithValues
        ? advancesProperties.filter(filterProperties)
        : advancesProperties,
    };
  }, [
    availableFilters,
    productSearchVariables,
    showOnlyPropertyFiltersWithValues,
  ]);

  const nonPropertyFields = React.useMemo(
    (): React.ReactElement[] =>
      [
        (!hideProductTypes ||
          (values.productTypeIds && values.productTypeIds.length > 0)) &&
        availableOptions?.productTypeIds &&
        availableOptions?.productTypeIds.length > 0 ? (
          viewType === "inputs" ? (
            <Autocomplete
              key="productTypeIds-input"
              inputLabel={t("Product type", { ns: "Product" })}
              options={availableOptions?.productTypeIds ?? []}
              getOptionLabel={option =>
                availableOptions?.productTypeLabels[option] ?? ""
              }
              value={values.productTypeIds?.[0] ?? null}
              onChange={newValue => {
                setFieldValue("productTypeIds", newValue ? [newValue] : null);
              }}
              filterOptions={availableOptions?.filterProductTypeStringOptions}
              renderOption={(props, option, { selected }) => (
                <li
                  {...props}
                  style={{
                    ...props.style,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <span>
                    {availableOptions?.productTypeLabels[option] ?? ""}
                  </span>
                  {availableOptions?.productTypeCounters[option] !==
                  undefined ? (
                    <Typography
                      variant="caption"
                      sx={{ color: "grey.600", ml: 1 }}
                    >
                      {getFormattedNumber(
                        availableOptions?.productTypeCounters[option],
                        language
                      )}
                    </Typography>
                  ) : null}
                </li>
              )}
              disabled={!canChangeProductType}
            />
          ) : viewType === "buttons" ? (
            <ButtonSelect
              key="productTypeIds-button"
              label={t("Product type", { ns: "Product" })}
              searchLabel={t("Search", { ns: "Global" })}
              noOptionsLabel={t("There are no items to display", {
                ns: "Global",
              })}
              options={(availableOptions?.productTypeIds ?? []).map(id => ({
                value: id,
                label: availableOptions?.productTypeLabels[id] ?? "",
                counter: availableOptions?.productTypeCounters[id],
              }))}
              value={values.productTypeIds?.[0] ?? null}
              onChange={newValue => {
                setFieldValue("productTypeIds", newValue ? [newValue] : null);
              }}
              filterOptions={availableOptions?.filterProductTypeOptions}
              disabled={!canChangeProductType}
              popoverWidth="xl"
              clearable
            />
          ) : null
        ) : null,
        (!hideSupplier ||
          (values.supplierIds && values.supplierIds.length > 0)) &&
        availableOptions?.supplierIds &&
        availableOptions?.supplierIds.length > 0 ? (
          viewType === "inputs" ? (
            <SelectMultiple
              key="supplierIds-input"
              label={t("Suppliers", { ns: "Product" })}
              options={availableOptions?.supplierIds ?? []}
              value={values.supplierIds ?? []}
              onChange={newValue => {
                setFieldValue(
                  "supplierIds",
                  newValue.length > 0 ? newValue : null
                );
                if (!newValue || !newValue.length) {
                  setFieldValue("supplierProductCategoryKey", null);
                }
              }}
            />
          ) : viewType === "buttons" ? (
            <ButtonSelectMultiple
              key="supplierIds-button"
              label={t("Suppliers", { ns: "Product" })}
              searchLabel={t("Search", { ns: "Global" })}
              noOptionsLabel={t("There are no items to display", {
                ns: "Global",
              })}
              options={availableOptions?.supplierIds ?? []}
              value={values.supplierIds ?? []}
              onChange={newValue => {
                setFieldValue(
                  "supplierIds",
                  newValue.length > 0 ? newValue : null
                );
                if (!newValue || !newValue.length) {
                  setFieldValue("supplierProductCategoryKey", null);
                }
              }}
              popoverWidth="xl"
            />
          ) : null
        ) : null,
        (!hideBrands || (values.brands && values.brands.length > 0)) &&
        availableOptions?.brands &&
        availableOptions?.brands.length > 0 ? (
          viewType === "inputs" ? (
            <SelectMultiple
              key="brands-input"
              label={t("Brands", { ns: "Product" })}
              options={availableOptions?.brands ?? []}
              value={values.brands ?? []}
              onChange={newValue => {
                setFieldValue("brands", newValue.length > 0 ? newValue : null);
              }}
            />
          ) : viewType === "buttons" ? (
            <ButtonSelectMultiple
              key="brands-button"
              label={t("Brands", { ns: "Product" })}
              searchLabel={t("Search", { ns: "Global" })}
              noOptionsLabel={t("There are no items to display", {
                ns: "Global",
              })}
              options={availableOptions?.brands ?? []}
              value={values.brands ?? []}
              onChange={newValue => {
                setFieldValue("brands", newValue.length > 0 ? newValue : null);
              }}
              popoverWidth="xl"
            />
          ) : null
        ) : null,
        (!hideBrandLines ||
          (values.brandLines && values.brandLines.length > 0)) &&
        availableOptions?.brandLines &&
        availableOptions?.brandLines.length > 0 ? (
          viewType === "inputs" ? (
            <SelectMultiple
              key="brandLines-input"
              label={t("Brand lines", { ns: "Product" })}
              options={availableOptions?.brandLines ?? []}
              value={values.brandLines ?? []}
              onChange={newValue => {
                setFieldValue(
                  "brandLines",
                  newValue.length > 0 ? newValue : null
                );
              }}
            />
          ) : viewType === "buttons" ? (
            <ButtonSelectMultiple
              key="brandLines-button"
              label={t("Brand lines", { ns: "Product" })}
              searchLabel={t("Search", { ns: "Global" })}
              noOptionsLabel={t("There are no items to display", {
                ns: "Global",
              })}
              options={availableOptions?.brandLines ?? []}
              value={values.brandLines ?? []}
              onChange={newValue => {
                setFieldValue(
                  "brandLines",
                  newValue.length > 0 ? newValue : null
                );
              }}
              popoverWidth="xl"
            />
          ) : null
        ) : null,
        !hideNetPrice && availableFilters?.netPrice ? (
          viewType === "inputs" ? (
            <PriceSliderWithInput
              key="netPrice-input"
              label={t("Net price", { ns: "Global" })}
              range={[
                availableFilters.netPrice.min,
                availableFilters.netPrice.max,
              ]}
              labelMin={t("Net price min", { ns: "ProductSearch" })}
              labelMax={t("Net price max", { ns: "ProductSearch" })}
              value={[values.netPriceMin, values.netPriceMax]}
              setValue={newValue => {
                if (
                  values.netPriceMin !== newValue[0] ||
                  values.netPriceMax !== newValue[1]
                ) {
                  setValues({
                    ...values,
                    netPriceMin: newValue[0],
                    netPriceMax: newValue[1],
                  });
                }
              }}
              debounceMs={100}
            />
          ) : viewType === "buttons" ? (
            <ButtonInputWithPopover
              key="netPrice-button"
              label={t("Net price", { ns: "Global" })}
              counter={
                (values.netPriceMin ? 1 : 0) + (values.netPriceMax ? 1 : 0)
              }
              popoverWidth="xxl"
              content={
                <Box p={1}>
                  <PriceSliderWithInput
                    key="netPrice-input"
                    label={t("Net price", { ns: "Global" })}
                    range={[
                      availableFilters.netPrice.min,
                      availableFilters.netPrice.max,
                    ]}
                    labelMin={t("Min", { ns: "Global" })}
                    labelMax={t("Max", { ns: "Global" })}
                    value={[values.netPriceMin, values.netPriceMax]}
                    setValue={newValue => {
                      if (
                        values.netPriceMin !== newValue[0] ||
                        values.netPriceMax !== newValue[1]
                      ) {
                        setValues({
                          ...values,
                          netPriceMin: newValue[0],
                          netPriceMax: newValue[1],
                        });
                      }
                    }}
                  />
                </Box>
              }
              overflowVisible
            />
          ) : null
        ) : null,
        !hideListPrice && availableFilters?.listPrice ? (
          viewType === "inputs" ? (
            <PriceSliderWithInput
              key="listPrice-input"
              label={t("List price", { ns: "Global" })}
              range={[
                availableFilters.listPrice.min,
                availableFilters.listPrice.max,
              ]}
              labelMin={t("List price min", { ns: "ProductSearch" })}
              labelMax={t("List price max", { ns: "ProductSearch" })}
              value={[values.listPriceMin, values.listPriceMax]}
              setValue={newValue => {
                if (
                  values.listPriceMin !== newValue[0] ||
                  values.listPriceMax !== newValue[1]
                ) {
                  setValues({
                    ...values,
                    listPriceMin: newValue[0],
                    listPriceMax: newValue[1],
                  });
                }
              }}
              debounceMs={100}
            />
          ) : viewType === "buttons" ? (
            <ButtonInputWithPopover
              key="listPrice-button"
              label={t("List price", { ns: "Global" })}
              counter={
                (values.listPriceMin ? 1 : 0) + (values.listPriceMax ? 1 : 0)
              }
              popoverWidth="xxl"
              content={
                <Box p={1}>
                  <PriceSliderWithInput
                    key="listPrice-input"
                    label={t("List price", { ns: "Global" })}
                    range={[
                      availableFilters.listPrice.min,
                      availableFilters.listPrice.max,
                    ]}
                    labelMin={t("Min", { ns: "Global" })}
                    labelMax={t("Max", { ns: "Global" })}
                    value={[values.listPriceMin, values.listPriceMax]}
                    setValue={newValue => {
                      if (
                        values.listPriceMin !== newValue[0] ||
                        values.listPriceMax !== newValue[1]
                      ) {
                        setValues({
                          ...values,
                          listPriceMin: newValue[0],
                          listPriceMax: newValue[1],
                        });
                      }
                    }}
                  />
                </Box>
              }
              overflowVisible
            />
          ) : null
        ) : null,
      ].filter(notNull),
    [
      hideProductTypes,
      values.productTypeIds,
      values.supplierIds,
      values.brands,
      values.brandLines,
      values.netPriceMin,
      values.netPriceMax,
      values.listPriceMin,
      values.listPriceMax,
      availableOptions?.productTypeIds,
      availableOptions?.filterProductTypeOptions,
      availableOptions?.filterProductTypeStringOptions,
      availableOptions?.supplierIds,
      availableOptions?.brands,
      availableOptions?.brandLines,
      availableOptions?.productTypeLabels,
      availableOptions?.productTypeCounters,
      t,
      canChangeProductType,
      hideSupplier,
      hideBrands,
      hideBrandLines,
      availableFilters?.netPrice,
      availableFilters?.listPrice,
      hideNetPrice,
      hideListPrice,
      setFieldValue,
      language,
      viewType,
    ]
  );

  const popularPropertyFields = React.useMemo((): React.ReactElement[] => {
    const options = {
      t,
      language,
      values: {
        properties: values.properties,
      },
      setFieldValue,
      viewType,
    };
    return availableOptions.popularProperties &&
      availableOptions.popularProperties.length > 0
      ? availableOptions.popularProperties
          .map(property => renderPropertyInput(property, options))
          .filter(notNull)
      : [];
  }, [
    availableOptions.popularProperties,
    t,
    language,
    values.properties,
    setFieldValue,
    viewType,
  ]);

  const advancePropertyFields = React.useMemo((): React.ReactElement[] => {
    const options = {
      t,
      language,
      values: {
        properties: values.properties,
      },
      setFieldValue,
      viewType,
    };
    return availableOptions.advancesProperties &&
      availableOptions.advancesProperties.length > 0
      ? availableOptions.advancesProperties
          .map(property => renderPropertyInput(property, options))
          .filter(notNull)
      : [];
  }, [
    availableOptions.advancesProperties,
    t,
    language,
    values.properties,
    setFieldValue,
    viewType,
  ]);

  return {
    nonPropertyFields,
    popularPropertyFields,
    advancePropertyFields,
    isLoading: availableFiltersQuery.loading,
  };
}

export const productFiltersValidationSchema = Yup.object().shape({
  productTypeIds: Yup.array(Yup.string().required()).nullable(),
  supplierIds: Yup.array(Yup.string().required()).nullable(),
  manufacturerIds: Yup.array(Yup.string().required()).nullable(),
  brands: Yup.array(Yup.string().required()).nullable(),
  brandLines: Yup.array(Yup.string().required()).nullable(),
  netPriceMin: Yup.number().nullable(),
  netPriceMax: Yup.number().nullable(),
  listPriceMin: Yup.number().nullable(),
  listPriceMax: Yup.number().nullable(),
  properties: Yup.array()
    .of(
      Yup.object({
        key: Yup.string().required(),
        textFilter: Yup.object({
          operatorText: Yup.string().oneOf(["eq"]),
          valueText: Yup.string(),
        })
          .nullable()
          .optional(),
        textInFilter: Yup.object({
          operatorTextIn: Yup.string().oneOf(["in"]),
          valueTextIn: Yup.array(Yup.string().required()),
        })
          .nullable()
          .optional(),
        boolFilter: Yup.object({
          operatorBool: Yup.string().oneOf(["eq"]),
          valueBool: Yup.bool(),
        })
          .nullable()
          .optional(),
        boolInFilter: Yup.object({
          operatorBoolIn: Yup.string().oneOf(["in"]),
          valueBoolIn: Yup.array(Yup.bool().required()),
        })
          .nullable()
          .optional(),
        numberFilter: Yup.object({
          operatorNumber: Yup.string().oneOf(["eq", "lt", "lte", "gt", "gte"]),
          valueNumber: Yup.number(),
        })
          .nullable()
          .optional(),
        numberInFilter: Yup.object({
          operatorNumberIn: Yup.string().oneOf(["in"]),
          valueNumberIn: Yup.array(Yup.number().required()),
        })
          .nullable()
          .optional(),
        numberBetweenFilter: Yup.object({
          operatorNumberBetween: Yup.string().oneOf(["between"]),
          valueNumberBetween: Yup.object({
            min: Yup.number(),
            max: Yup.number(),
          }),
        })
          .nullable()
          .optional(),
      })
    )
    .nullable(),
});

export const getProductFormValues = <T extends ProductFiltersFormValues>(
  values: T
): ProductFiltersFormValues =>
  pick(
    values,
    "organisationFavourite",
    "supplierIds",
    "productTypeIds",
    "brands",
    "brandLines",
    "netPriceMin",
    "netPriceMax",
    "listPriceMin",
    "listPriceMax",
    "properties",
    "supplierProductCategoryKey"
  );

export const productFiltersDefaultValue: ProductFiltersFormValues = {
  supplierProductCategoryKey: null,
  organisationFavourite: null,
  supplierIds: null,
  productTypeIds: null,
  brands: null,
  brandLines: null,
  netPriceMin: null,
  netPriceMax: null,
  listPriceMin: null,
  listPriceMax: null,
  properties: null,
};

function renderPropertyInput(
  property: ProductProperty,
  {
    t,
    language,
    setFieldValue,
    values,
    viewType,
  }: {
    t: TFunction<"Global"[]>;
    language: string;
    setFieldValue: FormikProps<ProductFiltersFormValues>["setFieldValue"];
    values: Pick<ProductFiltersFormValues, "properties">;
    viewType: FiltersViewType;
  }
): React.ReactElement | null {
  const propertyFilter = values.properties?.find(
    propertyFilter => property.key === propertyFilter.key
  );

  switch (property.type) {
    case "text": {
      const options = property.values.map(v => ({
        label: v.value,
        value: v.value,
        counter: v.count,
      }));
      const value = propertyFilter?.textFilter
        ? [propertyFilter.textFilter.valueText]
        : propertyFilter?.textInFilter
          ? propertyFilter.textInFilter.valueTextIn
          : [];
      const onChange = (newValue: string[]) => {
        const newPropertyFilters =
          values.properties?.filter(
            propertyFilter => property.key !== propertyFilter.key
          ) ?? [];
        if (newValue.length > 0) {
          newPropertyFilters.push({
            key: property.key,
            textInFilter: { operatorTextIn: "in", valueTextIn: newValue },
          });
        }
        setFieldValue("properties", newPropertyFilters);
      };
      return viewType === "inputs" ? (
        <SelectMultiple
          key={`${property.key}-input`}
          label={property.key}
          options={options ?? []}
          value={value}
          onChange={onChange}
        />
      ) : viewType === "buttons" ? (
        <ButtonSelectMultiple
          key={`${property.key}-button`}
          label={property.key}
          searchLabel={t("Search", { ns: "Global" })}
          noOptionsLabel={t("There are no items to display", {
            ns: "Global",
          })}
          options={options ?? []}
          value={value}
          onChange={onChange}
          popoverWidth="lg"
        />
      ) : null;
    }
    case "number": {
      const options = property.values.map(v => ({
        label: getFormattedNumber(v.value, language),
        value: v.value.toString(),
        counter: v.count,
      }));

      const value = propertyFilter?.numberBetweenFilter
        ? property.values
            .filter(
              v =>
                v.value >=
                  propertyFilter.numberBetweenFilter!.valueNumberBetween.min &&
                v.value <=
                  propertyFilter.numberBetweenFilter!.valueNumberBetween.max
            )
            .map(v => v.value.toString())
        : propertyFilter?.numberInFilter
          ? propertyFilter.numberInFilter.valueNumberIn.map(v => v.toString())
          : propertyFilter?.numberFilter
            ? propertyFilter.numberFilter.operatorNumber === "eq"
              ? [propertyFilter.numberFilter.valueNumber.toString()]
              : propertyFilter.numberFilter.operatorNumber === "lt" ||
                  propertyFilter.numberFilter.operatorNumber === "lte"
                ? property.values
                    .filter(
                      v => v.value <= propertyFilter.numberFilter!.valueNumber
                    )
                    .map(v => v.value.toString())
                : propertyFilter.numberFilter.operatorNumber === "gt" ||
                    propertyFilter.numberFilter.operatorNumber === "gte"
                  ? property.values
                      .filter(
                        v => v.value >= propertyFilter.numberFilter!.valueNumber
                      )
                      .map(v => v.value.toString())
                  : assertNever(propertyFilter.numberFilter.operatorNumber)
            : [];
      const onChange = (newValue: string[]) => {
        const newPropertyFilters =
          values.properties?.filter(
            propertyFilter => property.key !== propertyFilter.key
          ) ?? [];
        if (newValue.length > 0) {
          newPropertyFilters.push({
            key: property.key,
            numberInFilter: {
              operatorNumberIn: "in",
              valueNumberIn: newValue.map(v => parseFloat(v)),
            },
          });
        }
        setFieldValue("properties", newPropertyFilters);
      };
      return viewType === "inputs" ? (
        <SelectMultiple
          key={`${property.key}-input`}
          label={property.key}
          options={options ?? []}
          value={value}
          onChange={onChange}
        />
      ) : viewType === "buttons" ? (
        <ButtonSelectMultiple
          key={`${property.key}-button`}
          label={property.key}
          searchLabel={t("Search", { ns: "Global" })}
          noOptionsLabel={t("There are no items to display", {
            ns: "Global",
          })}
          options={options ?? []}
          value={value}
          onChange={onChange}
          popoverWidth="md"
        />
      ) : null;
    }
    case "bool": {
      const options = property.values.map(v => ({
        label:
          v.value === true
            ? t("Yes", { ns: "Global" })
            : t("No", { ns: "Global" }),
        value: v.value === true ? "true" : "false",
        counter: v.count,
      }));

      const value = propertyFilter?.boolFilter
        ? propertyFilter?.boolFilter.valueBool === true
          ? "true"
          : "false"
        : "";
      const onChange = (newValue: string | null) => {
        const newPropertyFilters =
          values.properties?.filter(
            propertyFilter => property.key !== propertyFilter.key
          ) ?? [];
        if (newValue === "true" || newValue === "false") {
          newPropertyFilters.push({
            key: property.key,
            boolFilter: {
              operatorBool: "eq",
              valueBool: newValue === "true" ? true : false,
            },
          });
        }
        setFieldValue("properties", newPropertyFilters);
      };
      return viewType === "inputs" ? (
        <Select
          key={`${property.key}-input`}
          label={property.key}
          options={options ?? []}
          value={value}
          onChange={onChange}
          clearable
          onClear={() => onChange(null)}
        />
      ) : viewType === "buttons" ? (
        <ButtonSelect
          key={`${property.key}-button`}
          label={property.key}
          searchLabel={t("Search", { ns: "Global" })}
          noOptionsLabel={t("There are no items to display", {
            ns: "Global",
          })}
          options={options ?? []}
          value={value || null}
          onChange={onChange}
          popoverWidth="md"
          clearable
        />
      ) : null;
    }
    default:
      return null;
  }
}

export function isAnyFilterSet(filters: ProductFiltersFormValues) {
  return (
    isArrayFilterSet(filters.brandLines) ||
    isArrayFilterSet(filters.brands) ||
    isArrayFilterSet(filters.productTypeIds) ||
    isArrayFilterSet(filters.properties) ||
    isArrayFilterSet(filters.supplierIds) ||
    !isNull(filters.listPriceMax) ||
    !isNull(filters.listPriceMin) ||
    !isNull(filters.netPriceMax) ||
    !isNull(filters.netPriceMin) ||
    !isNull(filters.organisationFavourite) ||
    !isNull(filters.supplierProductCategoryKey)
  );
}

function isArrayFilterSet(filter: any[] | null) {
  return filter && filter.length > 0;
}
