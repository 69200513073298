import { type PopperProps as MuiPopperProps } from "@mui/base/Popper";
import {
  ClickAwayListener,
  Paper,
  PaperProps,
  Popover,
  PopoverProps,
  Popper,
} from "@mui/material";
import { Theme } from "@mui/material/styles";
import { SxProps } from "@mui/system";
import { noop } from "lodash";
import React from "react";
import { useDebouncedValue } from "../hooks/useDebouncedValue";

interface Props extends Omit<PopoverProps, "open" | "content"> {
  children: React.ReactElement;
  content: React.ReactNode;
  popoverRef?: React.MutableRefObject<PopoverOpenButtonRef | undefined>;
  onOpen?: () => void;
}

export interface PopoverOpenButtonRef {
  handleClose(): void;
}

function _PopoverOpenButton(
  {
    children,
    content,
    popoverRef,
    onOpen,
    onClose,
    ...props
  }: React.PropsWithChildren<Props>,
  ref: React.ForwardedRef<HTMLButtonElement>
) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  React.useImperativeHandle(
    popoverRef,
    () => ({
      handleClose() {
        onClose?.({}, "backdropClick");
        setAnchorEl(null);
      },
    }),
    [onClose]
  );

  const buttonWithOpen = React.cloneElement(children, {
    onClick: (e: React.MouseEvent<HTMLButtonElement>) => {
      onOpen?.();
      children.props.onClick?.(e);
      setAnchorEl(e.currentTarget);
    },
    ref,
  });

  return (
    <>
      {buttonWithOpen}
      {/* TODO: flags aren't working correctly! Still receiving focus in popover which messes up usage on mobile */}
      <Popover
        disableAutoFocus={true}
        disableEnforceFocus={true}
        disableEscapeKeyDown={true}
        disableRestoreFocus={true}
        disableScrollLock={true}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={(e, reason) => {
          (e as any)?.preventDefault();
          (e as any)?.stopPropagation();
          onClose?.(e, reason);
          setAnchorEl(null);
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        PaperProps={{ sx: { my: 0.5 } }}
        {...props}
      >
        {content}
      </Popover>
    </>
  );
}

interface PopperProps {
  children: React.ReactElement;
  content: React.ReactNode;
  popoverRef?: React.MutableRefObject<PopoverOpenButtonRef | undefined>;
  paperProps?: PaperProps;
  modifiers?: MuiPopperProps["modifiers"];
  placement?: MuiPopperProps["placement"];
  onOpen?: () => void;
  onClose?: () => void;
  keepMounted?: boolean;
  sx?: SxProps<Theme>;
}

// a mobile-friendly version of popover which is not stealing focus, and just open absolutely positioned popover
function _PopperPopoverOpenButton(
  {
    children,
    content,
    popoverRef,
    paperProps,
    modifiers,
    placement,
    onOpen,
    onClose,
    keepMounted = false,
    sx,
  }: React.PropsWithChildren<PopperProps>,
  ref: React.ForwardedRef<HTMLButtonElement>
) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  React.useImperativeHandle(
    popoverRef,
    () => ({
      handleClose() {
        onClose?.();
        setAnchorEl(null);
      },
    }),
    [onClose]
  );

  const buttonWithOpen = React.cloneElement(children, {
    onClick: (e: React.MouseEvent<HTMLButtonElement>) => {
      onOpen?.();
      children.props.onClick?.(e);
      setAnchorEl(e.currentTarget);
    },
    ref,
  });

  return (
    <>
      {buttonWithOpen}
      <PopperPopover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={e => {
          e.preventDefault();
          e.stopPropagation();
          onClose?.();
          setAnchorEl(null);
        }}
        paperProps={paperProps}
        modifiers={modifiers}
        placement={placement}
        keepMounted={keepMounted}
        sx={sx}
      >
        {content}
      </PopperPopover>
    </>
  );
}

function PopperPopover({
  anchorEl,
  open,
  onClose,
  children,
  paperProps,
  modifiers,
  placement,
  disablePortal = true,
  keepMounted = false,
  sx,
}: React.PropsWithChildren<{
  anchorEl?: null | Element;
  open: boolean;
  onClose(event: MouseEvent | TouchEvent): void;
  paperProps?: PaperProps;
  modifiers?: MuiPopperProps["modifiers"];
  placement?: MuiPopperProps["placement"];
  keepMounted?: boolean;
  disablePortal?: boolean;
  sx?: SxProps<Theme>;
}>) {
  const openDebounced = useDebouncedValue(open, 250);
  return (
    <Popper
      open={open}
      anchorEl={anchorEl}
      disablePortal={disablePortal}
      keepMounted={keepMounted}
      modifiers={modifiers}
      placement={placement}
      sx={sx}
    >
      <ClickAwayListener
        onClickAway={open && openDebounced ? onClose : noop}
        mouseEvent="onClick"
        touchEvent="onTouchEnd"
      >
        <Paper {...paperProps}>{children}</Paper>
      </ClickAwayListener>
    </Popper>
  );
}

export const PopoverOpenButton = React.memo(
  React.forwardRef(_PopoverOpenButton)
);
export const PopperPopoverOpenButton = React.memo(
  React.forwardRef(_PopperPopoverOpenButton)
);
