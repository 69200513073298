import BalconyOutlinedIcon from "@mui/icons-material/BalconyOutlined";
import BathtubOutlinedIcon from "@mui/icons-material/BathtubOutlined";
import CarpenterOutlinedIcon from "@mui/icons-material/CarpenterOutlined";
import CountertopsOutlinedIcon from "@mui/icons-material/CountertopsOutlined";
import DoorFrontOutlinedIcon from "@mui/icons-material/DoorFrontOutlined";
import ElectricalServicesOutlinedIcon from "@mui/icons-material/ElectricalServicesOutlined";
import FormatPaintOutlinedIcon from "@mui/icons-material/FormatPaintOutlined";
import HeatPumpOutlinedIcon from "@mui/icons-material/HeatPumpOutlined";
import HvacOutlinedIcon from "@mui/icons-material/HvacOutlined";
import LandscapeOutlinedIcon from "@mui/icons-material/LandscapeOutlined";
import OtherHousesOutlinedIcon from "@mui/icons-material/OtherHousesOutlined";
import PlumbingOutlinedIcon from "@mui/icons-material/PlumbingOutlined";
import RoofingOutlinedIcon from "@mui/icons-material/RoofingOutlined";
import SolarPowerOutlinedIcon from "@mui/icons-material/SolarPowerOutlined";
import WaterDropOutlinedIcon from "@mui/icons-material/WaterDropOutlined";
import WindowOutlinedIcon from "@mui/icons-material/WindowOutlined";

import { SvgIcon } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import React from "react";
import { Skill } from "../../../clients/graphqlTypes";
import { ReactComponent as ConcreteIcon } from "../../assets/icons/service-concrete-work.svg";
import { ReactComponent as FloorsIcon } from "../../assets/icons/service-floors.svg";

export type Category = {
  value: Skill;
  name: string;
  icon: JSX.Element;
};

export function useCategories() {
  const { t } = useTranslate("Categories");

  const categoryLabels: Record<Skill, string> = React.useMemo(
    () => ({
      PAINTING: t("Painting"),
      HEATING: t("Heating"),
      BATHROOM: t("Bathroom"),
      FLOORS: t("Floors"),
      ELECTRICIAN: t("Electrician"),
      ROOF: t("Roof"),
      SOLAR: t("Solar"),
      PLUMBING: t("Plumbing"),
      KITCHEN: t("Kitchen"),
      WINDOWS: t("Windows"),
      LANDSCAPE: t("Landscape"),
      DOORS: t("Doors"),
      AERATION: t("Aeration"),
      BALCONY: t("Balcony"),
      CONCRETE_WORK: t("Concrete Work"),
      ENGRAVING: t("Engraving"),
      CARPENTER: t("Carpenter"),
      WARM_WATER: t("Warm water"),
      WATER_TREATMENT: t("Water treatment"),
      CLIMATE_AND_AIRCONDITIONING: t("Climate and airconditioning"),
      OTHER: t("Other"),
    }),
    [t]
  );

  const popularCategories: Category[] = React.useMemo(
    () => [
      {
        value: "PAINTING",
        name: categoryLabels["PAINTING"],
        icon: <FormatPaintOutlinedIcon />,
      },
      {
        value: "HEATING",
        name: categoryLabels["HEATING"],
        icon: <HeatPumpOutlinedIcon />,
      },
      {
        value: "BATHROOM",
        name: categoryLabels["BATHROOM"],
        icon: <BathtubOutlinedIcon />,
      },
      {
        value: "FLOORS",
        name: categoryLabels["FLOORS"],
        icon: (
          <SvgIcon>
            <FloorsIcon />
          </SvgIcon>
        ),
      },
      {
        value: "ELECTRICIAN",
        name: categoryLabels["ELECTRICIAN"],
        icon: <ElectricalServicesOutlinedIcon />,
      },
      {
        value: "ROOF",
        name: categoryLabels["ROOF"],
        icon: <RoofingOutlinedIcon />,
      },
      {
        value: "SOLAR",
        name: categoryLabels["SOLAR"],
        icon: <SolarPowerOutlinedIcon />,
      },
      {
        value: "PLUMBING",
        name: categoryLabels["PLUMBING"],
        icon: <PlumbingOutlinedIcon />,
      },
    ],
    [categoryLabels]
  );

  const allCategories: Category[] = React.useMemo(
    () => [
      ...popularCategories,
      {
        value: "KITCHEN",
        name: categoryLabels["KITCHEN"],
        icon: <CountertopsOutlinedIcon />,
      },
      {
        value: "WINDOWS",
        name: categoryLabels["WINDOWS"],
        icon: <WindowOutlinedIcon />,
      },
      {
        value: "LANDSCAPE",
        name: categoryLabels["LANDSCAPE"],
        icon: <LandscapeOutlinedIcon />,
      },
      {
        value: "DOORS",
        name: categoryLabels["DOORS"],
        icon: <DoorFrontOutlinedIcon />,
      },
      {
        value: "AERATION",
        name: categoryLabels["AERATION"],
        icon: <HvacOutlinedIcon />,
      },
      {
        value: "BALCONY",
        name: categoryLabels["BALCONY"],
        icon: <BalconyOutlinedIcon />,
      },
      {
        value: "CONCRETE_WORK",
        name: categoryLabels["CONCRETE_WORK"],
        icon: (
          <SvgIcon>
            <ConcreteIcon />
          </SvgIcon>
        ),
      },
      {
        value: "ENGRAVING",
        name: categoryLabels["ENGRAVING"],
        icon: <CarpenterOutlinedIcon />,
      },
      {
        value: "CARPENTER",
        name: categoryLabels["CARPENTER"],
        icon: <CarpenterOutlinedIcon />,
      },
      {
        value: "WARM_WATER",
        name: categoryLabels["WARM_WATER"],
        icon: <WaterDropOutlinedIcon />,
      },
      {
        value: "WATER_TREATMENT",
        name: categoryLabels["WATER_TREATMENT"],
        icon: <WaterDropOutlinedIcon />,
      },
      {
        value: "CLIMATE_AND_AIRCONDITIONING",
        name: categoryLabels["CLIMATE_AND_AIRCONDITIONING"],
        icon: <HvacOutlinedIcon />,
      },
      {
        value: "OTHER",
        name: categoryLabels["OTHER"],
        icon: <OtherHousesOutlinedIcon />,
      },
    ],
    [categoryLabels, popularCategories]
  );

  return { popularCategories, allCategories, categoryLabels };
}
