/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ProjectPhaseFragment = { __typename: 'OrganisationProjectPhase', id: string, name: string, applicableForState: Types.ProjectStateMachineStatus, color: string };

export type ModifyOrganisationProjectPhaseMutationVariables = Types.Exact<{
  input: Types.ModifyOrganisationProjectPhaseInput;
}>;


export type ModifyOrganisationProjectPhaseMutation = { modifyOrganisationProjectPhase: { __typename: 'ModifyOrganisationProjectPhaseResult', projectPhase: { __typename: 'OrganisationProjectPhase', id: string, name: string, applicableForState: Types.ProjectStateMachineStatus, color: string } } };

export type RearrangeOrganisationProjectPhaseMutationVariables = Types.Exact<{
  input: Types.RearrangeOrganisationProjectPhaseInput;
  filterIncludeStates?: Types.InputMaybe<Array<Types.ProjectStateMachineStatus>>;
}>;


export type RearrangeOrganisationProjectPhaseMutation = { rearrangeOrganisationProjectPhase: { __typename: 'RearrangeOrganisationProjectPhaseResult', organisationSettings: { __typename: 'OrganisationSetting', id: string, projectPhases: Array<{ __typename: 'OrganisationProjectPhase', id: string, name: string, applicableForState: Types.ProjectStateMachineStatus, color: string }> } } };

export type RemoveOrganisationProjectPhaseMutationVariables = Types.Exact<{
  input: Types.RemoveOrganisationProjectPhaseInput;
  filterIncludeStates?: Types.InputMaybe<Array<Types.ProjectStateMachineStatus>>;
}>;


export type RemoveOrganisationProjectPhaseMutation = { removeOrganisationProjectPhase: { __typename: 'RemoveOrganisationProjectPhaseResult', organisationSettings: { __typename: 'OrganisationSetting', id: string, projectPhases: Array<{ __typename: 'OrganisationProjectPhase', id: string, name: string, applicableForState: Types.ProjectStateMachineStatus, color: string }> } } };

export type AddOrganisationProjectPhaseMutationVariables = Types.Exact<{
  phaseName: Types.Scalars['String']['input'];
  phaseColor: Types.Scalars['String']['input'];
  state: Types.ProjectStateMachineStatus;
}>;


export type AddOrganisationProjectPhaseMutation = { addOrganisationProjectPhase: { __typename: 'AddOrganisationProjectPhaseResult', organisationSettings: { __typename: 'OrganisationSetting', id: string, projectPhases: Array<{ __typename: 'OrganisationProjectPhase', id: string, name: string, applicableForState: Types.ProjectStateMachineStatus, color: string }> } } };

export const ProjectPhaseFragmentDoc = gql`
    fragment ProjectPhase on OrganisationProjectPhase {
  id
  name
  applicableForState
  color
}
    `;
export const ModifyOrganisationProjectPhaseDocument = gql`
    mutation ModifyOrganisationProjectPhase($input: ModifyOrganisationProjectPhaseInput!) {
  modifyOrganisationProjectPhase(input: $input) {
    projectPhase {
      id
      ...ProjectPhase
    }
  }
}
    ${ProjectPhaseFragmentDoc}`;
export type ModifyOrganisationProjectPhaseMutationFn = Apollo.MutationFunction<ModifyOrganisationProjectPhaseMutation, ModifyOrganisationProjectPhaseMutationVariables>;

/**
 * __useModifyOrganisationProjectPhaseMutation__
 *
 * To run a mutation, you first call `useModifyOrganisationProjectPhaseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useModifyOrganisationProjectPhaseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [modifyOrganisationProjectPhaseMutation, { data, loading, error }] = useModifyOrganisationProjectPhaseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useModifyOrganisationProjectPhaseMutation(baseOptions?: Apollo.MutationHookOptions<ModifyOrganisationProjectPhaseMutation, ModifyOrganisationProjectPhaseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ModifyOrganisationProjectPhaseMutation, ModifyOrganisationProjectPhaseMutationVariables>(ModifyOrganisationProjectPhaseDocument, options);
      }
export type ModifyOrganisationProjectPhaseMutationHookResult = ReturnType<typeof useModifyOrganisationProjectPhaseMutation>;
export type ModifyOrganisationProjectPhaseMutationResult = Apollo.MutationResult<ModifyOrganisationProjectPhaseMutation>;
export type ModifyOrganisationProjectPhaseMutationOptions = Apollo.BaseMutationOptions<ModifyOrganisationProjectPhaseMutation, ModifyOrganisationProjectPhaseMutationVariables>;
export const RearrangeOrganisationProjectPhaseDocument = gql`
    mutation RearrangeOrganisationProjectPhase($input: RearrangeOrganisationProjectPhaseInput!, $filterIncludeStates: [ProjectStateMachineStatus!]) {
  rearrangeOrganisationProjectPhase(input: $input) {
    organisationSettings {
      id
      projectPhases(filterIncludeState: $filterIncludeStates) {
        id
        ...ProjectPhase
      }
    }
  }
}
    ${ProjectPhaseFragmentDoc}`;
export type RearrangeOrganisationProjectPhaseMutationFn = Apollo.MutationFunction<RearrangeOrganisationProjectPhaseMutation, RearrangeOrganisationProjectPhaseMutationVariables>;

/**
 * __useRearrangeOrganisationProjectPhaseMutation__
 *
 * To run a mutation, you first call `useRearrangeOrganisationProjectPhaseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRearrangeOrganisationProjectPhaseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rearrangeOrganisationProjectPhaseMutation, { data, loading, error }] = useRearrangeOrganisationProjectPhaseMutation({
 *   variables: {
 *      input: // value for 'input'
 *      filterIncludeStates: // value for 'filterIncludeStates'
 *   },
 * });
 */
export function useRearrangeOrganisationProjectPhaseMutation(baseOptions?: Apollo.MutationHookOptions<RearrangeOrganisationProjectPhaseMutation, RearrangeOrganisationProjectPhaseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RearrangeOrganisationProjectPhaseMutation, RearrangeOrganisationProjectPhaseMutationVariables>(RearrangeOrganisationProjectPhaseDocument, options);
      }
export type RearrangeOrganisationProjectPhaseMutationHookResult = ReturnType<typeof useRearrangeOrganisationProjectPhaseMutation>;
export type RearrangeOrganisationProjectPhaseMutationResult = Apollo.MutationResult<RearrangeOrganisationProjectPhaseMutation>;
export type RearrangeOrganisationProjectPhaseMutationOptions = Apollo.BaseMutationOptions<RearrangeOrganisationProjectPhaseMutation, RearrangeOrganisationProjectPhaseMutationVariables>;
export const RemoveOrganisationProjectPhaseDocument = gql`
    mutation RemoveOrganisationProjectPhase($input: RemoveOrganisationProjectPhaseInput!, $filterIncludeStates: [ProjectStateMachineStatus!]) {
  removeOrganisationProjectPhase(input: $input) {
    organisationSettings {
      id
      projectPhases(filterIncludeState: $filterIncludeStates) {
        id
        ...ProjectPhase
      }
    }
  }
}
    ${ProjectPhaseFragmentDoc}`;
export type RemoveOrganisationProjectPhaseMutationFn = Apollo.MutationFunction<RemoveOrganisationProjectPhaseMutation, RemoveOrganisationProjectPhaseMutationVariables>;

/**
 * __useRemoveOrganisationProjectPhaseMutation__
 *
 * To run a mutation, you first call `useRemoveOrganisationProjectPhaseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveOrganisationProjectPhaseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeOrganisationProjectPhaseMutation, { data, loading, error }] = useRemoveOrganisationProjectPhaseMutation({
 *   variables: {
 *      input: // value for 'input'
 *      filterIncludeStates: // value for 'filterIncludeStates'
 *   },
 * });
 */
export function useRemoveOrganisationProjectPhaseMutation(baseOptions?: Apollo.MutationHookOptions<RemoveOrganisationProjectPhaseMutation, RemoveOrganisationProjectPhaseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveOrganisationProjectPhaseMutation, RemoveOrganisationProjectPhaseMutationVariables>(RemoveOrganisationProjectPhaseDocument, options);
      }
export type RemoveOrganisationProjectPhaseMutationHookResult = ReturnType<typeof useRemoveOrganisationProjectPhaseMutation>;
export type RemoveOrganisationProjectPhaseMutationResult = Apollo.MutationResult<RemoveOrganisationProjectPhaseMutation>;
export type RemoveOrganisationProjectPhaseMutationOptions = Apollo.BaseMutationOptions<RemoveOrganisationProjectPhaseMutation, RemoveOrganisationProjectPhaseMutationVariables>;
export const AddOrganisationProjectPhaseDocument = gql`
    mutation AddOrganisationProjectPhase($phaseName: String!, $phaseColor: String!, $state: ProjectStateMachineStatus!) {
  addOrganisationProjectPhase(
    input: {state: $state, name: $phaseName, color: $phaseColor}
  ) {
    organisationSettings {
      id
      projectPhases(filterIncludeState: [$state]) {
        id
        ...ProjectPhase
      }
    }
  }
}
    ${ProjectPhaseFragmentDoc}`;
export type AddOrganisationProjectPhaseMutationFn = Apollo.MutationFunction<AddOrganisationProjectPhaseMutation, AddOrganisationProjectPhaseMutationVariables>;

/**
 * __useAddOrganisationProjectPhaseMutation__
 *
 * To run a mutation, you first call `useAddOrganisationProjectPhaseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddOrganisationProjectPhaseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addOrganisationProjectPhaseMutation, { data, loading, error }] = useAddOrganisationProjectPhaseMutation({
 *   variables: {
 *      phaseName: // value for 'phaseName'
 *      phaseColor: // value for 'phaseColor'
 *      state: // value for 'state'
 *   },
 * });
 */
export function useAddOrganisationProjectPhaseMutation(baseOptions?: Apollo.MutationHookOptions<AddOrganisationProjectPhaseMutation, AddOrganisationProjectPhaseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddOrganisationProjectPhaseMutation, AddOrganisationProjectPhaseMutationVariables>(AddOrganisationProjectPhaseDocument, options);
      }
export type AddOrganisationProjectPhaseMutationHookResult = ReturnType<typeof useAddOrganisationProjectPhaseMutation>;
export type AddOrganisationProjectPhaseMutationResult = Apollo.MutationResult<AddOrganisationProjectPhaseMutation>;
export type AddOrganisationProjectPhaseMutationOptions = Apollo.BaseMutationOptions<AddOrganisationProjectPhaseMutation, AddOrganisationProjectPhaseMutationVariables>;