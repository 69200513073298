import { Switch } from "@msys/ui";
import { FormControl, FormHelperText, type SwitchProps } from "@mui/material";
import { useField } from "formik";
import { isString } from "lodash";
import React from "react";

interface Props {
  name: string;
  label: string;
  disabled?: boolean;
}

export const SwitchField = ({
  name,
  label,
  disabled,
  ...props
}: Props & SwitchProps) => {
  const [{ value }, { error }, { setValue }] = useField<boolean>(name);
  return (
    <FormControl disabled={disabled} error={Boolean(error)}>
      <Switch
        label={label}
        checked={Boolean(value)}
        onChange={e => setValue(e.target.checked)}
        disabled={disabled}
        {...props}
      />
      {Boolean(error) && isString(error) && (
        <FormHelperText>{error}</FormHelperText>
      )}
    </FormControl>
  );
};
