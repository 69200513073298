import { ModalContent, ModalDialog, ModalTitle } from "@msys/ui";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import { Button } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import React from "react";
import {
  QuoteCreateWizardSection,
  WizardResults,
} from "../QuoteCreateWizardSection";

export function QuoteCreateWizardModal({
  organisationId,
  templateId,
  onComplete,
}: {
  organisationId: string;
  templateId: string;
  onComplete: (result: WizardResults | null) => Promise<void> | void;
}) {
  const { t } = useTranslate(["QuoteCreate"]);

  const handleClose = React.useCallback(async () => {
    await onComplete(null);
  }, [onComplete]);

  return (
    <ModalDialog handleClose={handleClose}>
      <ModalTitle
        headerActions={
          <Button
            variant="text"
            endIcon={<KeyboardDoubleArrowRightIcon />}
            onClick={handleClose}
          >
            {t("Skip wizard", { ns: "QuoteCreate" })}
          </Button>
        }
      />
      <ModalContent>
        <QuoteCreateWizardSection
          organisationId={organisationId}
          templateId={templateId}
          onComplete={onComplete}
        />
      </ModalContent>
    </ModalDialog>
  );
}
