import { ApolloCache, useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import { CardItem, CollapseTitle, LoadingSpinner } from "@msys/ui";
import MultipleStopIcon from "@mui/icons-material/MultipleStop";
import { Box, Stack, Typography } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { omit } from "lodash";
import React from "react";
import { useDrag, useDrop } from "react-dnd";
import { Link } from "react-router-dom";
import { useLatest } from "react-use";
import {
  addEntryToEdges,
  removeEntryFromEdges,
} from "../../../clients/apollo-client-pagination";
import { ProjectStateMachineStatus } from "../../../clients/graphqlTypes";
import { FetchMoreListener } from "../../commons/FetchMoreListener";
import {
  KanbanColumn,
  KanbanColumnOverlay,
} from "../../commons/kanban/KanbanColumn";
import {
  KanbanContainer,
  KanbanDragItem,
} from "../../commons/kanban/KanbanContainer";
import { useKanbanColumnCollapseStore } from "../../commons/kanban/useKanbanColumnCollapseStore";
import {
  ProjectListItem,
  ProjectListItemDisplayConfig,
} from "./components/ProjectListItem";
import { ProjectPhaseAndStateChangeMenuButton } from "./ProjectPhaseAndStateChangeMenuButton";
import { ProjectPhasesConfigurator } from "./ProjectPhasesConfigurator";
import { ProjectPhaseFragment } from "./ProjectPhasesConfigurator.generated";
import {
  ProjectCardItemMemoized_ProjectFragment,
  ProjectsKanbanListDocument,
  ProjectsKanbanListQueryVariables,
  useProjectsKanbanListQuery,
  useProjectsKanbanQuery,
} from "./ProjectsKanban.generated";
import { ProjectsListQueryVariables } from "./ProjectsList.generated";
import { useChangeProjectPhaseMutation } from "./useProjectChangePhase.generated";
import { ProjectStateMachineEvent } from "./useProjectStates";

type ProjectEdge = {
  __typename: "ProjectEdge";
  cursor?: string | null | undefined;
  node: ProjectCardItemMemoized_ProjectFragment;
};

export function ProjectsKanban({
  displayConfig,
  variables,
  isConfiguring,
  setIsConfiguring,
}: {
  displayConfig: ProjectListItemDisplayConfig;
  variables: Omit<ProjectsListQueryVariables, "offset" | "limit">;
  isConfiguring: boolean;
  setIsConfiguring: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const [opportunitiesCount, setOpportunitiesCount] = React.useState<
    Record<string, number>
  >({});

  const onOpportunitiesCountChange = (phaseId: string, count: number) => {
    setOpportunitiesCount(state => ({ ...state, [phaseId]: count }));
  };

  const client = useApolloClient();
  const query = useProjectsKanbanQuery({
    client,
    variables: { filterIncludeState: ["contracted"] },
  });
  const phases = query.data?.organisationSettings.projectPhases ?? [];

  const { collapsedState, setPhaseCollapsed, collapseStateLoading } =
    useKanbanColumnCollapseStore({
      key: "Project-KanBan-Phases-Collapsed",
      columns: phases,
    });

  if (query.loading || collapseStateLoading) return <LoadingSpinner />;

  return (
    <Stack spacing={2} flexBasis={0} flexGrow={1} justifyContent={"stretch"}>
      <ProjectPhasesConfigurator
        state={"contracted"}
        phases={phases}
        opportunitiesCount={opportunitiesCount}
        isConfiguring={isConfiguring}
        setIsConfiguring={setIsConfiguring}
      />
      <KanbanContainer>
        {phases.map(phase => (
          <ProjectPhaseColumn
            key={phase.id}
            phase={phase}
            isCollapsed={collapsedState?.[phase.id] ?? false}
            setCollapsed={collapsed => {
              setPhaseCollapsed(phase.id, collapsed);
            }}
            availablePhases={phases}
            displayConfig={displayConfig}
            onCountChange={(count: number) =>
              onOpportunitiesCountChange(phase.id, count)
            }
            variables={variables}
          />
        ))}
      </KanbanContainer>
    </Stack>
  );
}

const LIMIT = 20;

function ProjectPhaseColumn({
  phase,
  isCollapsed,
  setCollapsed,
  availablePhases,
  displayConfig,
  onCountChange,
  variables,
}: {
  phase: ProjectPhaseFragment;
  isCollapsed: boolean;
  setCollapsed: (collapsed: boolean) => void;
  availablePhases: ProjectPhaseFragment[];
  displayConfig: ProjectListItemDisplayConfig;
  onCountChange(count: number): void;
  variables: Omit<ProjectsListQueryVariables, "offset" | "limit">;
}) {
  const { t } = useTranslate(["Global"]);

  const client = useApolloClient();

  const queryVariables = React.useMemo(
    () => getProjectKanbanListQueryVariables(variables, phase.id),
    [variables, phase.id]
  );

  const query = useProjectsKanbanListQuery({
    client,
    variables: queryVariables,
    fetchPolicy: "network-only",
  });

  const projectEdges = getDataOrNull(query.data?.projects)?.edges;
  const totalCount = getDataOrNull(query.data?.projects)?.totalCount;

  const onCountChangeLatest = useLatest(onCountChange);
  React.useEffect(() => {
    if (totalCount !== undefined) onCountChangeLatest.current?.(totalCount);
  }, [totalCount, onCountChangeLatest]);

  const canFetchMore = Boolean(
    projectEdges && totalCount && projectEdges.length < totalCount
  );

  const fetchMore = async () => {
    const after =
      getDataOrNull(query.data?.projects)?.pageInfo?.endCursor ?? null;
    if (!after) return;
    await query.fetchMore({
      variables: {
        after,
        first: LIMIT,
      },
    });
  };

  const [changePhase, { loading: changePhaseLoading }] =
    useChangeProjectPhaseMutation({
      client,
    });

  const [{ isOver, isDragging }, drop] = useDrop<
    KanbanDragItem<ProjectEdge>,
    Promise<void>,
    { isOver: boolean; isDragging: boolean }
  >({
    accept: "kanban-card",
    drop: async (item, monitor) => {
      if (phase.id !== item.phaseId) {
        await changePhase({
          variables: {
            input: { projectId: item.projectId, newPhaseId: phase.id },
          },
          update: (cache, result) => {
            changeProjectPhaseCacheUpdate(
              item.phaseId,
              phase.id,
              cache,
              variables,
              item.edge
            );
          },
        });
      }
    },
    collect: monitor => ({
      isOver: monitor.isOver(),
      isDragging: monitor.canDrop(),
    }),
  });

  return (
    <KanbanColumn
      ref={drop}
      title={<CollapseTitle title={phase.name} count={totalCount} />}
      color={phase.color}
      collapsedTitle={`${phase.name}${
        totalCount !== undefined ? ` (${totalCount})` : ""
      }`}
      isCollapsed={isCollapsed}
      setCollapsed={setCollapsed}
      overlay={
        (isDragging && isOver) || changePhaseLoading ? (
          <KanbanColumnOverlay
            loading={changePhaseLoading}
            selected={isDragging && isOver}
          />
        ) : undefined
      }
    >
      {query.loading && !projectEdges ? (
        <LoadingSpinner />
      ) : (
        <Stack spacing={1} padding={1}>
          {projectEdges?.map(projectEdge => (
            <ProjectCardItemMemoized
              key={projectEdge.node.id}
              projectEdge={projectEdge}
              phaseId={phase.id}
              availablePhases={availablePhases}
              variables={variables}
              displayConfig={displayConfig}
            />
          ))}
          {!projectEdges?.length && (
            <Box
              minHeight={80}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Typography align="center" color="grey.500" variant="body2">
                {t("There are no items to display", { ns: "Global" })}
              </Typography>
            </Box>
          )}
          <FetchMoreListener enabled={canFetchMore} fetchMore={fetchMore} />
        </Stack>
      )}
    </KanbanColumn>
  );
}

function getProjectKanbanListQueryVariables(
  variables: Omit<ProjectsListQueryVariables, "offset" | "limit">,
  phaseId: string
): ProjectsKanbanListQueryVariables {
  return {
    ...omit(variables, ["limit", "offset", "filterPhaseIds"]),
    filterByPhaseId: phaseId,
    sorting: [
      { column: "createdAt", direction: "desc" },
      { column: "id", direction: "desc" },
    ],
    after: null,
    first: LIMIT,
  };
}

const ProjectCardItemMemoized = React.memo(function ProjectCardItem({
  phaseId,
  availablePhases,
  projectEdge,
  displayConfig,
  variables,
}: {
  phaseId: string;
  availablePhases: ProjectPhaseFragment[];
  projectEdge: ProjectEdge;
  displayConfig: ProjectListItemDisplayConfig;
  variables: Omit<ProjectsListQueryVariables, "offset" | "limit">;
}) {
  const { t } = useTranslate(["Projects"]);

  const [, drag] = useDrag<
    KanbanDragItem<ProjectEdge>,
    void,
    { isDragging: boolean }
  >({
    item: {
      type: "kanban-card",
      phaseId,
      projectId: projectEdge.node.id,
      state: projectEdge.node.state,
      edge: projectEdge,
    },
  });

  return (
    <CardItem
      ref={drag}
      key={projectEdge.node.id}
      // @ts-ignore
      component={Link}
      to={`/projects/${projectEdge.node.id}`}
      hoverable
    >
      <ProjectListItem
        project={projectEdge.node}
        showStatus={false}
        showAssignee
        Action={
          <ProjectPhaseAndStateChangeMenuButton
            projectId={projectEdge.node.id}
            projectPhaseId={phaseId}
            projectState={projectEdge.node.state}
            availablePhases={availablePhases.filter(p => p.id !== phaseId)}
            Icon={<MultipleStopIcon fontSize="small" />}
            buttonProps={{
              size: "small",
              sx: { padding: "1px" },
            }}
            // onStateChangeRefetchQueries={[namedOperations.Query.ProjectsList]}
            phaseGroupLabel={t("Move project to another phase", {
              ns: "Projects",
            })}
            changeProjectPhaseUpdate={(oldPhaseId, newPhaseId, cache) => {
              changeProjectPhaseCacheUpdate(
                oldPhaseId,
                newPhaseId,
                cache,
                variables,
                projectEdge
              );
            }}
            stateGroupLabel={t("Mark project as completed or cancelled", {
              ns: "Projects",
            })}
            changeProjectStateUpdate={(oldState, newStateEvent, cache) => {
              changeProjectStateCacheUpdate(
                oldState,
                newStateEvent,
                phaseId,
                cache,
                variables,
                projectEdge
              );
            }}
          />
        }
        displayConfig={displayConfig}
      />
    </CardItem>
  );
});

function changeProjectPhaseCacheUpdate(
  oldPhaseId: string,
  newPhaseId: string,
  cache: ApolloCache<any>,
  variables: Omit<ProjectsListQueryVariables, "offset" | "limit">,
  projectEdge: ProjectEdge
) {
  const fromVariables = getProjectKanbanListQueryVariables(
    variables,
    oldPhaseId
  );
  const toVariables = getProjectKanbanListQueryVariables(variables, newPhaseId);
  cache.updateQuery(
    {
      query: ProjectsKanbanListDocument,
      variables: fromVariables,
    },
    data => {
      if (data && data.projects)
        return {
          ...data,
          projects: removeEntryFromEdges(data.projects, projectEdge),
        };
    }
  );
  cache.updateQuery(
    {
      query: ProjectsKanbanListDocument,
      variables: toVariables,
    },
    data => {
      if (data && data.projects)
        return {
          ...data,
          projects: addEntryToEdges(data.projects, projectEdge),
        };
    }
  );
}

function changeProjectStateCacheUpdate(
  oldState: ProjectStateMachineStatus,
  newStateEvent: ProjectStateMachineEvent,
  oldPhaseId: string,
  cache: ApolloCache<any>,
  variables: Omit<ProjectsListQueryVariables, "offset" | "limit">,
  projectEdge: ProjectEdge
) {
  if (oldState === "contracted") {
    const fromVariables = getProjectKanbanListQueryVariables(
      variables,
      oldPhaseId
    );
    cache.updateQuery(
      {
        query: ProjectsKanbanListDocument,
        variables: fromVariables,
      },
      data => {
        if (data && data.projects)
          return {
            ...data,
            projects: removeEntryFromEdges(data.projects, projectEdge),
          };
      }
    );
  }
}
