import { ButtonInputWithPopover } from "@msys/ui";
import { Box } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import React from "react";
import {
  FilterByOrganisationUsers,
  FilterProps,
} from "../../../commons/filters";
import { FilterChipGroupProps } from "../../../commons/filters/FilterChip";
import { FilterOrganisationUsersChips } from "../../organisations/OrganisationUsersSelect";
import { autocompleteProps } from "../ProjectAssigneeEditPopover";

export function FilterAssigneesChips(
  props: Omit<FilterChipGroupProps<string>, "label" | "getValueLabel">
) {
  const { t } = useTranslate("ProjectMembers");
  return <FilterOrganisationUsersChips {...props} label={t("Assignee")} />;
}

export const FilterAssignees = (props: FilterProps<string[]>) => {
  const { t } = useTranslate("Projects");
  return (
    <FilterByOrganisationUsers
      label={t("Assignees")}
      placeholder={t("Select assignees")}
      {...props}
    />
  );
};

export function FilterProjectAssigneesForm(props: FilterProps<string[]>) {
  const { t } = useTranslate("Projects");
  return (
    <Box>
      <FilterByOrganisationUsers
        label={t("Assignees")}
        placeholder={t("Search assignees")}
        autocompleteProps={{ ...autocompleteProps, renderTags: () => null }}
        {...props}
      />
    </Box>
  );
}

export function FilterProjectAssigneesButton(props: FilterProps<string[]>) {
  const { t } = useTranslate("Projects");
  return (
    <ButtonInputWithPopover
      label={t("Assignees")}
      counter={
        props.value && props.value.length > 0 ? props.value.length : undefined
      }
      content={<FilterProjectAssigneesForm {...props} />}
      popoverWidth="xl"
    />
  );
}
