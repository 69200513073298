import { useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import { Grid } from "@mui/material";
import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { TemplateSearchFiltersInput } from "../../../../clients/graphqlTypes";
import { RestrictedByOrganisationPermissionWithDebug } from "../../../auth/RestrictedByOrganisationPermission";
import {
  BreadcrumbItem,
  Page,
  PageTopbarItem,
} from "../../../commons/layout/Page";
import { PageContainer } from "../../../commons/layout/PageContainer";
import { CreateQuoteTemplateButton } from "../../../features/templates/quote/buttons/CreateTemplateQuoteButton";
import { TemplatesDisplay } from "../../../features/templates/quote/TemplatesDisplay";
import { TemplateTypeOverviewHeaderBox } from "../../../features/templateTypes/TemplateTypeOverviewHeaderBox";
import { TemplateTypeOverviewPropertyBox } from "../../../features/templateTypes/TemplateTypeOverviewPropertyBox";
import { buildDocPath } from "../../../utils";
import { useTemplateQuoteTemplateTypeQuery } from "./TemplateQuoteTemplateType.generated";

interface Props {
  docId: string;
  submenuItems: PageTopbarItem[];
  prefixBreadcrumbs: BreadcrumbItem[];
  pageSubHeader: React.ReactElement | null;
  pathToDocList: string;
}

export function TemplateQuoteTemplateType({
  docId,
  submenuItems,
  prefixBreadcrumbs,
  pageSubHeader,
  pathToDocList,
}: Props) {
  const { templateTypeId } = useParams();
  if (!templateTypeId) throw new Error("Template type id missing");

  const navigate = useNavigate();

  const location = useLocation();

  const client = useApolloClient();
  const templateTypeQuery = useTemplateQuoteTemplateTypeQuery({
    client,
    variables: { id: templateTypeId },
  });
  const templateType = getDataOrNull(templateTypeQuery.data?.templateType);

  const fixedFilters = React.useMemo(
    (): TemplateSearchFiltersInput => ({
      templateTypeIds: [templateTypeId],
      sources: ["mine_unpublished", "subscribed"],
    }),
    [templateTypeId]
  );

  return (
    <Page
      title={templateType?.title ?? ""}
      submenuItems={submenuItems}
      breadcrumbs={prefixBreadcrumbs.concat({
        title: templateType?.title ?? "",
        to: location.pathname,
      })}
      subHeader={pageSubHeader}
      action={[
        <RestrictedByOrganisationPermissionWithDebug permission="MANAGE_TEMPLATE">
          <CreateQuoteTemplateButton
            pathToDocList={pathToDocList}
            templateTypeId={templateTypeId}
          />
        </RestrictedByOrganisationPermissionWithDebug>,
      ]}
    >
      {templateType && (
        <PageContainer>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} xl={3}>
              <TemplateTypeOverviewHeaderBox templateType={templateType} />
            </Grid>
            <Grid item xs={12} md={6} xl={3}>
              <TemplateTypeOverviewPropertyBox templateType={templateType} />
            </Grid>
            <Grid item xs={12}>
              <TemplatesDisplay
                onTemplateClick={template =>
                  navigate(
                    buildDocPath(
                      `${pathToDocList}/${template.id}`,
                      template.resolvedAsReadModelVersionNumber ?? null
                    )
                  )
                }
                fixedFilters={fixedFilters}
              />
            </Grid>
          </Grid>
        </PageContainer>
      )}
    </Page>
  );
}
