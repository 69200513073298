import { ModalOpenButton } from "@msys/ui";
import AddIcon from "@mui/icons-material/Add";
import { IconButton, Tooltip } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { useNavigate } from "react-router-dom";
import {
  namedOperations,
  ProjectSourcePageName,
} from "../../../../clients/graphqlTypes";
import { useUserData } from "../../../auth/useUserData";
import { ButtonCreate } from "../../../commons/button/Button";
import {
  CreateProjectModal,
  CreateProjectType,
} from "../modals/CreateProjectModal";

interface Props {
  onCreate?: (projectId: string) => Promise<void> | void;
  crmOrganisationId?: string;
  buildingId?: string;
  buildingItemId?: string;
  pageName: ProjectSourcePageName;
  refetchQueries?: string[];
  autoNavigate?: boolean;
  type: "icon" | "button";
  size?: "small" | "large";
  initialCreateType?: CreateProjectType;
  desiredProjectInitialStatus?: "opportunity" | "contracted";
}

export const CreateProjectOrTicketButton = ({
  onCreate,
  crmOrganisationId,
  buildingId,
  buildingItemId,
  pageName,
  type,
  size = "small",
  refetchQueries,
  autoNavigate = true,
  initialCreateType = "project",
  desiredProjectInitialStatus,
}: Props) => {
  const { t } = useTranslate("ProjectCreate");
  const navigate = useNavigate();

  const viewer = useUserData().currentUser!;

  const title = t("New project");

  return (
    <ModalOpenButton
      Modal={CreateProjectModal}
      modalProps={{
        refetchQueries: [
          namedOperations.Query.ProjectsList,
          ...(refetchQueries ?? []),
        ],
        crmOrganisationId,
        buildingId,
        buildingItemId,
        pageName,
        handleComplete: async projectId => {
          await onCreate?.(projectId);
          if (autoNavigate) {
            navigate({
              pathname: `/projects/${projectId}/overview`,
              search: new URLSearchParams({ create: "true" }).toString(),
            });
          }
        },
        desiredProjectInitialStatus:
          desiredProjectInitialStatus ??
          (viewer.organisation.isCraftsmanOrganisation
            ? "contracted"
            : "opportunity"),
        initialCreateType,
      }}
    >
      {type === "button" ? (
        <ButtonCreate title={title} sx={{ flexShrink: 0, flexGrow: 0 }} />
      ) : type === "icon" ? (
        <IconButton
          color="primary"
          aria-label={title}
          size={size}
          sx={{ flexShrink: 0, flexGrow: 0 }}
        >
          <Tooltip title={title}>
            <AddIcon />
          </Tooltip>
        </IconButton>
      ) : (
        <div />
      )}
    </ModalOpenButton>
  );
};
