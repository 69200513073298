import { useApolloClient } from "@apollo/client";
import { Divider } from "@mui/material";
import omitDeep from "omit-deep-lodash";
import React from "react";
import { Content, ContentInput, ItemType } from "../../../clients/graphqlTypes";
import { ALLOW_AI_FEATURE } from "../../constants";
import { useContentsSetMutation } from "../contents/Content.generated";
import { AddContentMenuItem } from "../doc-items/menus/AddContentMenuItem";
import { AiDescriptionMenuItem } from "../doc-items/menus/AiDescriptionMenuItem";
import { ItemStateMenuItem } from "../doc-items/menus/ItemStateMenuItem";
import { ItemTypeMenuItem } from "../doc-items/menus/ItemTypeMenuItem";
import { QuoteItemMenuItems_ItemFragment } from "./QuoteItemMenuItems.generated";

export type ItemAction = "CHANGHE_ITEM_TYPE";

export const QuoteItemMenuItems = ({
  projectId,
  docId,
  item,
  onSetContentRefetchQueries,
  documentItemTypes,
  expandedItemIds,
  isMenuOpen,
  disabledActions,
}: {
  projectId: string | null;
  docId: string;
  item: QuoteItemMenuItems_ItemFragment;
  onSetContentRefetchQueries?: string[];
  documentItemTypes: ItemType[];
  expandedItemIds: string[] | undefined;
  isMenuOpen: boolean;
  disabledActions?: ItemAction[];
}) => {
  const client = useApolloClient();

  const [setContents] = useContentsSetMutation({
    client,
    refetchQueries: onSetContentRefetchQueries,
    // awaitRefetchQueries: true,
  });

  const addArticle = React.useCallback(
    async function addArticle(newArticle: Content) {
      const newContent = [...item.contents, newArticle];

      await setContents({
        variables: {
          input: {
            id: item.id,
            contents: newContent.map(item =>
              omitDeep(item, ["__typename"])
            ) as ContentInput[],
          },
        },
      });
    },
    [item, setContents]
  );

  const showItemStateMenuItem =
    !item.linkedQuoteTemplate &&
    item.type === "paid" &&
    (item.agreement !== "YES" || item.binding !== "binding");

  const showAiMenuItem = item.type === "section";

  return (
    <>
      {showItemStateMenuItem && (
        <ItemStateMenuItem
          projectId={projectId}
          docId={docId}
          itemId={item.id}
          itemType={item.type}
          itemState={item.binding}
        />
      )}
      {/* we need to trigger refetch when menu gets opened */}
      {isMenuOpen && (
        <ItemTypeMenuItem
          projectId={projectId}
          docId={docId}
          itemId={item.id}
          itemType={item.type}
          allowedTypes={documentItemTypes}
          expandedItemIds={expandedItemIds}
          disabled={disabledActions?.includes("CHANGHE_ITEM_TYPE")}
        />
      )}
      <Divider />
      {/* TODO: add placement in building */}
      {ALLOW_AI_FEATURE && showAiMenuItem && (
        <AiDescriptionMenuItem
          projectId={projectId}
          docId={docId}
          itemId={item.id}
        />
      )}
      <AddContentMenuItem addArticle={addArticle} />
    </>
  );
};
