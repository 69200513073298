import { useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import {
  CardItem,
  DataGrid,
  DebouncedSearchInput,
  ErrorMessage,
  FormattedPrice,
  GridColDef,
  ListHeader,
  LoadingSpinner,
  ModalOpenButton,
  Select,
  useFormatting,
} from "@msys/ui";
import { Stack, TablePagination, Typography } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { isEqual } from "lodash";
import { useSnackbar } from "notistack";
import React from "react";
import { Outlet, useNavigate } from "react-router-dom";
import {
  MarketplaceOpportunitiesSortBy,
  MarketplaceOpportunitiesSorting,
  namedOperations,
  SortDirection,
} from "../../../clients/graphqlTypes";
import { SwitchCollectionViewButton } from "../../commons/button/SwitchCollectionViewButton";
import { FilterButton } from "../../commons/filters/FilterButton";
import { FilterChip } from "../../commons/filters/FilterChip";
import { FilterModal } from "../../commons/filters/FilterModal";
import { useFiltersAndPagination } from "../../commons/filters/useFiltersAndPagination";
import {
  CollectionView,
  useCollectionViewWithMobile,
} from "../../commons/hooks/useCollectionView";
import { Page, PageTopbarItem } from "../../commons/layout/Page";
import { PageContainer } from "../../commons/layout/PageContainer";
import {
  PAGE_LIST_RESULTS_PER_PAGE,
  RESULTS_PER_PAGE_OPTIONS,
} from "../../constants";
import { getAddressLabel } from "../../features/addresses/helpers";
import {
  FilterMinBudget,
  FilterProjectCategory,
  FilterProjectCategoryChips,
  FilterProjectTypeChips,
} from "../../features/projects/filters";
import { IncomingRequestListItem } from "../../features/requests/RequestListItem";
import {
  useCreateProjectForRequestMutation,
  useProvideRequestFeedbackMutation,
} from "../../features/requests/Requests.generated";
import { useDataGridStateStore } from "../../features/users/useDataGridStateStore";
import {
  MarketplaceOpportunitiesQueryVariables,
  RequestRowFragment,
  useMarketplaceOpportunitiesQuery,
} from "./OpportunitiesMarketplace.generated";

type Filters = Omit<
  MarketplaceOpportunitiesQueryVariables,
  "limit" | "marketplaceCapability"
>;

const ALLOWED_VIEWS: CollectionView[] = ["table", "list"];
const DEFAULT_SORTING: MarketplaceOpportunitiesSorting[] = [
  {
    column: "createdAt",
    direction: "desc",
  },
];

interface Props {
  submenuItems: PageTopbarItem[];
}

export function OpportunitiesMarketplace({ submenuItems }: Props) {
  const navigate = useNavigate();
  const { t } = useTranslate(["Opportunities", "Global", "RequestOverview"]);
  const { getFormattedDate, getFormattedPrice, getFormattedFloat } =
    useFormatting();

  const { enqueueSnackbar } = useSnackbar();

  const client = useApolloClient();

  const [createProjectForRequest, { loading: createLoading }] =
    useCreateProjectForRequestMutation({
      client,
    });
  const [provideRequestFeedback, { loading: feedbackLoading }] =
    useProvideRequestFeedbackMutation({
      client,
    });

  const handleInterested = async (
    requestId: string,
    maybeInterested = false
  ) => {
    try {
      const res = await createProjectForRequest({
        variables: {
          input: { requestId },
        },
      });
      await provideRequestFeedback({
        variables: {
          input: {
            requestId,
            feedback: maybeInterested ? "MAYBE" : "YES",
          },
        },
        refetchQueries: [namedOperations.Query.MarketplaceOpportunities],
        awaitRefetchQueries: true,
      });

      const projectId = res.data?.createProjectForRequest.project.id;

      if (!projectId)
        throw new Error(
          t("Creating project failed", { ns: "RequestOverview" })
        );

      const pathToProject = `/projects/${projectId}`;
      enqueueSnackbar(
        maybeInterested
          ? t("Opportunity moved to inbox", { ns: "RequestOverview" })
          : t("Opportunity moved to shortlisted", { ns: "RequestOverview" })
      );
      navigate(pathToProject);
    } catch (e) {
      if (e instanceof Error) enqueueSnackbar(e.message, { variant: "error" });
    }
  };

  const pathToPage = "/opportunities/marketplace";

  const [activeView, setActiveView] =
    useCollectionViewWithMobile<CollectionView>(
      "marketplace-opportunities",
      "table",
      "list"
    );

  const {
    offset,
    limit,
    paginationModel,
    setPaginationModel,
    filters,
    setFilters,
    resetFilters,
    sorting,
    setSorting,
    toRemoveParams,
  } = useFiltersAndPagination<MarketplaceOpportunitiesSorting, Filters>(
    {},
    DEFAULT_SORTING,
    PAGE_LIST_RESULTS_PER_PAGE
  );

  const QUERY_BASE_VARIABLES = {
    offset,
    limit,
    sorting: DEFAULT_SORTING,
  };

  const query = useMarketplaceOpportunitiesQuery({
    client,
    variables: {
      ...QUERY_BASE_VARIABLES,
      ...filters,
      sorting,
    },
  });

  const columns: GridColDef<RequestRowFragment>[] = [
    {
      field: "title",
      headerName: t("Title", {
        ns: "Opportunities",
      }),
      editable: false,
      sortable: false,
      flex: 3,
      minWidth: 100,
      valueGetter: ({ row: opportunity }) => opportunity.title,
    },
    {
      field: "clientName",
      headerName: t("Client", {
        ns: "Opportunities",
      }),
      editable: false,
      sortable: false,
      flex: 3,
      minWidth: 100,
      valueGetter: ({ row: opportunity }) =>
        [
          opportunity.requestorDoc.contractee.companyName,
          `${opportunity.requestorDoc.contractee.firstname} ${opportunity.requestorDoc.contractee.familyname}`.trim(),
        ]

          .filter(Boolean)
          .join(" - "),
      renderCell: ({ row: opportunity }) =>
        [
          opportunity.requestorDoc.contractee.companyName,
          `${opportunity.requestorDoc.contractee.firstname} ${opportunity.requestorDoc.contractee.familyname}`.trim(),
        ]
          .filter(Boolean)
          .join(" - "),
    },
    {
      field: "address",
      headerName: t("Address", {
        ns: "Opportunities",
      }),
      editable: false,
      sortable: false,
      flex: 3,
      minWidth: 100,
      valueGetter: ({ row: opportunity }) =>
        getAddressLabel(opportunity.address),
      renderCell: ({ row: opportunity }) =>
        getAddressLabel(opportunity.address),
    },
    {
      field: "budgetAmount",
      headerName: t("Budget", {
        ns: "Opportunities",
      }),
      editable: false,
      flex: 1,
      minWidth: 100,
      align: "right",
      headerAlign: "right",
      valueGetter: ({ row: opportunity }) => opportunity.budget?.amount ?? 0,
      renderCell: ({ row: opportunity }) => {
        return (
          <Typography
            component="span"
            align="right"
            variant="body2"
            style={{ fontWeight: 500 }}
          >
            <FormattedPrice value={opportunity.budget} />
          </Typography>
        );
      },
    },
    {
      field: "createdAt",
      headerName: t("Created at", {
        ns: "Opportunities",
      }),
      editable: false,
      flex: 1,
      minWidth: 100,
      valueGetter: ({ row: opportunity }) =>
        getFormattedDate(opportunity.createdAt),
      renderCell: ({ row: opportunity }) =>
        getFormattedDate(opportunity.createdAt),
    },
  ];

  const opportunities =
    getDataOrNull(
      (query.data ?? query.previousData)?.marketplaceOpportunities
    )?.edges.map(e => e.node) ?? [];
  const total =
    getDataOrNull((query.data ?? query.previousData)?.marketplaceOpportunities)
      ?.totalCount ?? 0;

  const stateStore = useDataGridStateStore("OpportunitiesMarketplace");

  return (
    <Page
      subtitle={t("Opportunities", { ns: "Opportunities" })}
      title={t("Marketplace", { ns: "Opportunities" })}
      submenuItems={submenuItems}
    >
      <PageContainer>
        <ListHeader
          // hideTitleOnMobile
          // title={title}
          SwitchViewButton={
            <SwitchCollectionViewButton
              allowedViews={ALLOWED_VIEWS}
              activeView={activeView}
              setActiveView={setActiveView}
            />
          }
          FilterButton={
            <ModalOpenButton
              Modal={OpportunitiesMarketplaceFilterModal}
              modalProps={{
                sorting,
                setSorting,
                filters,
                setFilters,
                resetFilters,
              }}
            >
              <FilterButton />
            </ModalOpenButton>
          }
          SearchField={
            <DebouncedSearchInput
              placeholder={t("Search", {
                ns: "Global",
              })}
              defaultValue={filters.filterSearchTerm}
              onChangeSearchTerm={newValue =>
                setFilters(filters => ({
                  ...filters,
                  filterSearchTerm: newValue,
                }))
              }
            />
          }
          FilterChips={
            query.variables?.filterCategories?.length ||
            query.variables?.filterType?.length ||
            query.variables?.filterBuildingZip ||
            query.variables?.filterMinBudget ||
            query.variables?.filterWithinRadiusKm ? (
              <Stack direction="row" spacing={1} flexWrap="wrap">
                <FilterProjectCategoryChips
                  values={query.variables?.filterCategories}
                  setValues={newValue =>
                    setFilters(filters => ({
                      ...filters,
                      filterCategories: newValue,
                    }))
                  }
                />
                <FilterProjectTypeChips
                  values={query.variables?.filterType}
                  setValues={newValue =>
                    setFilters(filters => ({
                      ...filters,
                      filterType: newValue,
                    }))
                  }
                />
                <FilterChip
                  label={t("Zip Code", {
                    ns: "Opportunities",
                  })}
                  resetValue={""}
                  setValue={newValue =>
                    setFilters(filters => ({
                      ...filters,
                      filterBuildingZip: newValue,
                    }))
                  }
                  value={query.variables?.filterBuildingZip}
                />
                <FilterChip
                  label={t("Min Budget", {
                    ns: "Opportunities",
                  })}
                  resetValue={0}
                  setValue={newValue =>
                    setFilters(filters => ({
                      ...filters,
                      filterMinBudget: newValue,
                    }))
                  }
                  value={query.variables?.filterMinBudget}
                  getValueLabel={value => getFormattedPrice(value) ?? ""}
                />

                <FilterChip
                  label={t("Within radius (km)", {
                    ns: "Opportunities",
                  })}
                  resetValue={0}
                  setValue={newValue =>
                    setFilters(filters => ({
                      ...filters,
                      filterWithinRadiusKm: newValue,
                    }))
                  }
                  value={query.variables?.filterWithinRadiusKm}
                  getValueLabel={value => getFormattedFloat(value)}
                />
              </Stack>
            ) : undefined
          }
          mb={2}
        />

        {(() => {
          switch (activeView) {
            case "table":
              return (
                <DataGrid
                  stateStore={stateStore}
                  loading={query.loading || createLoading || feedbackLoading}
                  hideFooter={total === 0}
                  columns={columns}
                  rows={opportunities}
                  onRowClick={async ({ row }) => {
                    // FIXME - temporary marking as interested on click (MEIS-8911)
                    await handleInterested(row.id);
                    // TODO - marketplace opportunity permissions to view
                    // navigate(`${pathToPage}/${row.id}`);
                  }}
                  disableColumnFilter
                  sortModel={sorting.map(s => ({
                    field: s.column,
                    sort: s.direction,
                  }))}
                  onSortModelChange={newModel => {
                    setSorting(
                      newModel.map(({ field, sort }) => ({
                        column: field as MarketplaceOpportunitiesSortBy,
                        direction: sort ?? "asc",
                      }))
                    );
                  }}
                  paginationModel={paginationModel}
                  onPaginationModelChange={newPaginationModel => {
                    setPaginationModel(newPaginationModel);
                  }}
                  pageSizeOptions={RESULTS_PER_PAGE_OPTIONS}
                  rowCount={total}
                />
              );
            case "list":
              if (query.loading) return <LoadingSpinner />;
              if (total === 0)
                return (
                  <ErrorMessage
                    message={t("There are no items to display", {
                      ns: "Global",
                    })}
                  />
                );
              return (
                <Stack direction="column" spacing={1}>
                  {opportunities.map(opportunity => (
                    <CardItem
                      key={opportunity.id}
                      //@ts-ignore
                      // TODO - marketplace opportunity permissions to view
                      // component={Link}
                      // to={`${pathToPage}/${opportunity.id}`}
                      onClick={async () => {
                        // FIXME - temporary marking as interested on click (MEIS-8911)
                        await handleInterested(opportunity.id);
                      }}
                      disabled={createLoading || feedbackLoading}
                    >
                      <IncomingRequestListItem request={opportunity} />
                    </CardItem>
                  ))}
                  <TablePagination
                    component="div"
                    count={total}
                    page={paginationModel.page}
                    onPageChange={(event, newPage) => {
                      setPaginationModel({
                        pageSize: paginationModel.pageSize,
                        page: newPage,
                      });
                    }}
                    rowsPerPage={paginationModel.pageSize}
                    onRowsPerPageChange={event => {
                      setPaginationModel({
                        pageSize: parseInt(event.target.value, 10),
                        page: 0,
                      });
                    }}
                    labelRowsPerPage={t("Per page:", { ns: "Global" })}
                  />
                </Stack>
              );
          }
        })()}
        <Outlet />
      </PageContainer>
    </Page>
  );
}

function OpportunitiesMarketplaceFilterModal({
  handleClose,
  sorting,
  setSorting,
  filters,
  setFilters,
  resetFilters,
}: {
  handleClose: () => void;
  sorting: MarketplaceOpportunitiesSorting[];
  setSorting: (sorting: MarketplaceOpportunitiesSorting[]) => void;
  filters: Filters;
  setFilters: React.Dispatch<React.SetStateAction<Filters>>;
  resetFilters: () => void;
}) {
  const { t } = useTranslate(["Opportunities", "Global", "ProjectSorting"]);

  const [filtersState, setFiltersState] = React.useState(filters);
  const [sortingState, setSortingState] = React.useState(sorting);

  const sortByOptions = React.useMemo(() => {
    const opportunitiesSortingLabels: Record<
      Exclude<MarketplaceOpportunitiesSortBy, "title">,
      Record<SortDirection, string>
    > = {
      // title: {
      //   asc: t("Project title asc", {
      //     ns: "ProjectSorting",
      //   }),
      //   desc: t("Project title desc", {
      //     ns: "ProjectSorting",
      //   }),
      // },
      createdAt: {
        asc: t("Project created at asc", {
          ns: "ProjectSorting",
        }),
        desc: t("Project created at desc", {
          ns: "ProjectSorting",
        }),
      },
      budgetAmount: {
        asc: t("Project budget asc", {
          ns: "ProjectSorting",
        }),
        desc: t("Project budget desc", {
          ns: "ProjectSorting",
        }),
      },
    };

    const ALL_SORTING_FIELDS: Exclude<
      MarketplaceOpportunitiesSortBy,
      "title"
    >[] = [
      "createdAt",
      //  "title",
      "budgetAmount",
    ];

    return ALL_SORTING_FIELDS.flatMap(value => [
      {
        label: opportunitiesSortingLabels[value]["asc"],
        value: { column: value, direction: "asc" as SortDirection },
      },
      {
        label: opportunitiesSortingLabels[value]["desc"],
        value: { column: value, direction: "desc" as SortDirection },
      },
    ]);
  }, [t]);

  return (
    <FilterModal
      title={t("Opportunity Filters", {
        ns: "Opportunities",
      })}
      handleApply={() => {
        setFilters(filtersState);
        setSorting(sortingState);
      }}
      handleClose={handleClose}
      handleReset={resetFilters}
    >
      <Typography variant="h3">
        {t("Sort by", {
          ns: "Global",
        })}
      </Typography>
      <Stack direction="column" spacing={1}>
        <Select
          label={t("Sort by", {
            ns: "Global",
          })}
          options={sortByOptions}
          value={
            sortByOptions.find(option => isEqual(option.value, sortingState[0]))
              ?.value
          }
          onChange={value => {
            setSortingState(value ? [value] : DEFAULT_SORTING);
          }}
        />
        <Typography variant="h3">
          {t("Filter by", {
            ns: "Global",
          })}
        </Typography>
        {/* <FilterProjectType
          value={filtersState.filterType ?? []}
          setValue={newValue =>
            setFiltersState(filters => ({
              ...filters,
              filterType: newValue,
            }))
          }
        /> */}
        <FilterProjectCategory
          value={filtersState.filterCategories ?? []}
          setValue={newValue =>
            setFiltersState(filters => ({
              ...filters,
              filterCategories: newValue,
            }))
          }
        />
        <FilterMinBudget
          value={filtersState.filterMinBudget ?? 0}
          setValue={newValue =>
            setFiltersState(filters => ({
              ...filters,
              filterMinBudget: newValue,
            }))
          }
        />
      </Stack>
    </FilterModal>
  );
}
