import { Modal } from "@msys/ui";
import { DialogContentText, Stack } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { Form, Formik } from "formik";
import { once, uniqueId } from "lodash";
import React from "react";
import { ImplementsTemplateTypePropertiesStrategy } from "../../../../clients/graphqlTypes";
import { CheckboxField } from "../../../commons/form-fields/CheckboxField";
import { RadioGroupField } from "../../../commons/form-fields/RadioGroupField";

interface FormValues {
  strategy: ImplementsTemplateTypePropertiesStrategy;
  mergeIdenticalProperties: boolean;
}

// Add -------------------------------------------------------------------------

interface TemplateTypeAddPropertiesStrategyModalProps {
  handleComplete?: (strategy: ImplementsTemplateTypePropertiesStrategy) => void;
  handleClose: () => void;
}

export const TemplateTypeAddPropertiesStrategyModal = ({
  handleComplete,
  handleClose,
}: TemplateTypeAddPropertiesStrategyModalProps) => {
  const { t } = useTranslate(["Global", "TemplateTypes"]);
  const formId = React.useMemo(() => uniqueId(), []);
  return (
    <Formik<FormValues>
      onSubmit={async values => {
        handleComplete?.(
          values.strategy === "keepProperties" &&
            values.mergeIdenticalProperties
            ? "keepPropertiesWithMerging"
            : values.strategy
        );
        handleClose();
      }}
      enableReinitialize
      initialValues={{
        strategy: "discardProperties",
        mergeIdenticalProperties: true,
      }}
    >
      {formikProps => (
        <Modal
          title={t("Adjust item properties", {
            ns: "TemplateTypes",
          })}
          handleClose={handleClose}
          actionButtons={[
            {
              label: t("Cancel", { ns: "Global" }),
              handleClick: handleClose,
              buttonProps: { variant: "text" },
            },
            {
              label: t("Save and apply template type", {
                ns: "TemplateTypes",
              }),
              buttonProps: {
                form: formId,
                type: "submit",
              },
            },
          ]}
          notInStack
        >
          <Form id={formId}>
            <Stack direction="column" spacing={1}>
              <DialogContentText>
                {t(
                  "There appear to be some custom properties set up. Would you like to:",
                  {
                    ns: "TemplateTypes",
                  }
                )}
              </DialogContentText>
              <RadioGroupField
                name="strategy"
                options={
                  [
                    {
                      value: "discardProperties",
                      label: t(
                        "Discard all existing properties and only use template type properties",
                        {
                          ns: "TemplateTypes",
                        }
                      ),
                    },
                    {
                      value: "keepProperties",
                      label: t(
                        "Keep all old properties along with the template type properties",
                        {
                          ns: "TemplateTypes",
                        }
                      ),
                    },
                  ] as {
                    value: ImplementsTemplateTypePropertiesStrategy;
                    label: string;
                  }[]
                }
              />
              {formikProps.values.strategy === "keepProperties" && (
                <CheckboxField
                  name="mergeIdenticalProperties"
                  label={t("Automatically merge identical properties", {
                    ns: "TemplateTypes",
                  })}
                  size="small"
                  labelTypographyProps={{ variant: "body2" }}
                  sx={{ ml: 5.5 }}
                />
              )}
            </Stack>
          </Form>
        </Modal>
      )}
    </Formik>
  );
};

// Remove ----------------------------------------------------------------------

interface TemplateTypeRemovePropertiesStrategyModalProps {
  handleComplete?: (strategy: ImplementsTemplateTypePropertiesStrategy) => void;
  handleClose: () => void;
}

export const TemplateTypeRemovePropertiesStrategyModal = ({
  handleComplete,
  handleClose,
}: TemplateTypeRemovePropertiesStrategyModalProps) => {
  const { t } = useTranslate(["Global", "TemplateTypes"]);
  const formId = React.useMemo(() => uniqueId(), []);
  return (
    <Formik<FormValues>
      onSubmit={async values => {
        handleComplete?.(values.strategy);
        handleClose();
      }}
      enableReinitialize
      initialValues={{
        strategy: "discardProperties",
        mergeIdenticalProperties: true,
      }}
    >
      <Modal
        title={t("Keep or remove template type properties", {
          ns: "TemplateTypes",
        })}
        handleClose={handleClose}
        actionButtons={[
          {
            label: t("Cancel", { ns: "Global" }),
            handleClick: handleClose,
            buttonProps: { variant: "text" },
          },
          {
            label: t("Remove template type", {
              ns: "TemplateTypes",
            }),
            buttonProps: {
              form: formId,
              type: "submit",
            },
          },
        ]}
        notInStack
      >
        <Form id={formId}>
          <Stack direction="column" spacing={1}>
            <DialogContentText>
              {t("Would you like to:", { ns: "TemplateTypes" })}
            </DialogContentText>
            <RadioGroupField
              name="strategy"
              options={
                [
                  {
                    value: "discardProperties",
                    label: t(
                      "Discard all properties of template type being removed",
                      { ns: "TemplateTypes" }
                    ),
                  },
                  {
                    value: "keepProperties",
                    label: t("Keep all properties", { ns: "TemplateTypes" }),
                  },
                ] as {
                  value: ImplementsTemplateTypePropertiesStrategy;
                  label: string;
                }[]
              }
            />
          </Stack>
        </Form>
      </Modal>
    </Formik>
  );
};

// Process ---------------------------------------------------------------------

export interface TemplateTypePropertiesProcessRef {
  start: (
    type: "add" | "remove"
  ) => Promise<ImplementsTemplateTypePropertiesStrategy | null>;
}

export const TemplateTypeRemovePropertiesProcess = React.memo(
  React.forwardRef(
    (_: {}, forwardedRef: React.Ref<TemplateTypePropertiesProcessRef>) => {
      const [type, setType] = React.useState<"add" | "remove" | null>(null);

      const resolveRef = React.useRef<
        | ((value: ImplementsTemplateTypePropertiesStrategy | null) => void)
        | null
      >(null);

      React.useImperativeHandle(forwardedRef, () => ({
        start: (type: "add" | "remove") =>
          new Promise<ImplementsTemplateTypePropertiesStrategy | null>(
            resolve => {
              resolveRef.current = once(resolve);
              setType(type);
            }
          ),
      }));

      return type === "add" ? (
        <TemplateTypeAddPropertiesStrategyModal
          handleClose={() => {
            setType(null);
            resolveRef.current?.(null);
          }}
          handleComplete={strategy => {
            resolveRef.current?.(strategy);
          }}
        />
      ) : type === "remove" ? (
        <TemplateTypeRemovePropertiesStrategyModal
          handleClose={() => {
            setType(null);
            resolveRef.current?.(null);
          }}
          handleComplete={strategy => {
            resolveRef.current?.(strategy);
          }}
        />
      ) : null;
    }
  )
);
