import { QuoteTextReplacementName } from "@msys/textutils";
import { useTranslate } from "@tolgee/react";

export function useQuoteTextReplacements() {
  const { t } = useTranslate(["OrganisationSettings"]);

  const replacements: Record<QuoteTextReplacementName, string> = {
    "{{contractee name}}": t(
      `Full name of the contractee, e.g. "Company ABC" or "Mr John Smith"`,
      {
        ns: "OrganisationSettings",
      }
    ),
    "{{contractee salutation}}": t(
      'Salutation of the contractee, e.g. "Mr" or "Mrs"',
      { ns: "OrganisationSettings" }
    ),
    "{{contractee firstname}}": t('First name of the contractee, e.g. "John"', {
      ns: "OrganisationSettings",
    }),
    "{{contractee lastname}}": t('Last name of the contractee, e.g. "Smith"', {
      ns: "OrganisationSettings",
    }),
    "{{contractee number}}": t("CRM number of the contractee", {
      ns: "OrganisationSettings",
    }),
    "{{contractee address}}": t(`Address of the contractee`, {
      ns: "OrganisationSettings",
    }),
    "{{contractee email}}": t(`Email address of the contractee`, {
      ns: "OrganisationSettings",
    }),
    "{{contractee phone}}": t(`Phone number of the contractee`, {
      ns: "OrganisationSettings",
    }),
    "{{contractee contact person name}}": t(
      `First and last name of the contractee's contact person, e.g. "John Smith"`,
      {
        ns: "OrganisationSettings",
      }
    ),
    "{{contractee contact person salutation}}": t(
      `Salutation of the contractee's contact person, e.g. "Mr"`,
      {
        ns: "OrganisationSettings",
      }
    ),
    "{{contractee contact person firstname}}": t(
      `First name of the contractee's contact person, e.g. "John"`,
      {
        ns: "OrganisationSettings",
      }
    ),
    "{{contractee contact person lastname}}": t(
      `Last name of the contractee's contact person, e.g. "Smith"`,
      {
        ns: "OrganisationSettings",
      }
    ),
    "{{end client name}}": t(
      `Full name of the end client, e.g. "Agency X" or "Mrs Jane Smith"`,
      {
        ns: "OrganisationSettings",
      }
    ),
    "{{end client salutation}}": t(
      'Salutation of the individual end client, e.g. "Mr" or "Mrs"',
      {
        ns: "OrganisationSettings",
      }
    ),
    "{{end client firstname}}": t('First name of the end client, e.g. "Jane"', {
      ns: "OrganisationSettings",
    }),
    "{{end client lastname}}": t('Last name of the end client, e.g. "Smith"', {
      ns: "OrganisationSettings",
    }),
    "{{end client address}}": t(`Address of the end client`, {
      ns: "OrganisationSettings",
    }),
    "{{end client email}}": t(`Email address of the end client`, {
      ns: "OrganisationSettings",
    }),
    "{{end client phone}}": t(`Phone number of the end client`, {
      ns: "OrganisationSettings",
    }),
    "{{end client contact person name}}": t(
      `First and last name of the end client contact person, e.g. "John Smith"`,
      {
        ns: "OrganisationSettings",
      }
    ),
    "{{end client contact person salutation}}": t(
      `Salutation of the end client contact person, e.g. "Mr"`,
      {
        ns: "OrganisationSettings",
      }
    ),
    "{{end client contact person firstname}}": t(
      `First name of the end client contact person, e.g. "John"`,
      {
        ns: "OrganisationSettings",
      }
    ),
    "{{end client contact person lastname}}": t(
      `Last name of the end client contact person, e.g. "Smith"`,
      {
        ns: "OrganisationSettings",
      }
    ),
    "{{contact person name}}": t(
      'The first and last name of the contact person, e.g. "John Smith"',
      {
        ns: "OrganisationSettings",
      }
    ),
    "{{contact person firstname}}": t(
      'The first and last name of the contact person, e.g. "John Smith"',
      {
        ns: "OrganisationSettings",
      }
    ),
    "{{contact person lastname}}": t(
      'The last name of the contact person, e.g. "Smith"',
      {
        ns: "OrganisationSettings",
      }
    ),
    "{{contact person salutation}}": t('Salutation, e.g. "Mr"', {
      ns: "OrganisationSettings",
    }),
    "{{contact person email}}": t("Email address of the contact person", {
      ns: "OrganisationSettings",
    }),
    "{{contact person phone}}": t("Phone number of the contact person", {
      ns: "OrganisationSettings",
    }),
    "{{organisation name}}": t("The name of your organisation", {
      ns: "OrganisationSettings",
    }),
    "{{organisation billing address}}": t(
      "The billing address of your organisation",
      {
        ns: "OrganisationSettings",
      }
    ),
    "{{your name}}": t(`Your full name, e.g. "John Doe"`, {
      ns: "OrganisationSettings",
    }),
    "{{project name}}": t("The name of the project", {
      ns: "OrganisationSettings",
    }),
    "{{project number}}": t("The number of the project", {
      ns: "OrganisationSettings",
    }),
    "{{project address}}": t(
      'Full address of the project, e.g. "Hackescher Markt 4, 10178 Berlin"',
      {
        ns: "OrganisationSettings",
      }
    ),
    "{{quote name}}": t("The name of the quote", {
      ns: "OrganisationSettings",
    }),
    "{{quote number}}": t("The number of the quote", {
      ns: "OrganisationSettings",
    }),
    "{{quote earliest work start}}": t(
      "The earliest possible start of the work for the quote",
      {
        ns: "OrganisationSettings",
      }
    ),
    "{{quote work duration}}": t("The work duration of the quote", {
      ns: "OrganisationSettings",
    }),
    "{{quote valid until}}": t("The date the quote is valid until", {
      ns: "OrganisationSettings",
    }),
    "{{quote publish date}}": t("The publish date of quote", {
      ns: "OrganisationSettings",
    }),
  };

  return replacements;
}
