import {
  Box,
  ButtonBase,
  ButtonBaseProps,
  Stack,
  StackProps,
  SvgIconProps,
} from "@mui/material";
import React from "react";

import { CollapseIcon } from "./CollapseIcon";

interface Props {
  isExpanded: boolean;
  setIsExpanded: (newValue: boolean) => void;
  title?: React.ReactElement;
  color?: SvgIconProps["color"];
  fullWidth?: boolean;
}

export const CollapseButton = ({
  isExpanded,
  setIsExpanded,
  title,
  color,
  ...props
}: Props & Omit<ButtonBaseProps, "title" | "onClick">) => {
  return (
    <CollapseButtonBase
      title={title}
      onClick={(e: React.SyntheticEvent) => {
        e.preventDefault();
        e.stopPropagation();
        setIsExpanded?.(!isExpanded);
      }}
      startIcon={<CollapseIcon isCollapsed={!isExpanded} color={color} />}
      {...props}
    />
  );
};

export const CollapseButtonBase = ({
  title,
  fullWidth,
  startIcon,
  endIcon,
  ...props
}: {
  title?: React.ReactElement;
  fullWidth?: boolean;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
} & Omit<ButtonBaseProps, "title">) => {
  return (
    // @ts-ignore
    <StackAsButton
      direction="row"
      alignItems="center"
      spacing={0.5}
      p={0.5}
      pr={!title || endIcon ? 0.5 : 1.5}
      minHeight="30px"
      width={fullWidth ? "100%" : undefined}
      {...props}
    >
      {startIcon && (
        <Box
          height="20px"
          display="flex"
          alignItems="center"
          flexShrink={0}
          flexGrow={0}
        >
          {startIcon}
        </Box>
      )}
      {title && (
        <Box display="flex" flex={1} minWidth={0}>
          {title}
        </Box>
      )}
      {endIcon && (
        <Box
          height="20px"
          display="flex"
          alignItems="center"
          flexShrink={0}
          flexGrow={0}
        >
          {endIcon}
        </Box>
      )}
    </StackAsButton>
  );
};

const StackAsButton = ({
  ...props
}: React.PropsWithChildren<Omit<StackProps, "component" | "sx">>) => {
  return (
    // @ts-ignore
    <Stack
      component={ButtonBase}
      focusRipple
      sx={theme => ({
        borderRadius: "4px",
        transition: "background-color 0.1s ease-out",
        ["&:hover"]: {
          backgroundColor: theme.palette.action.hover,
        },
        ["&:active"]: {
          backgroundColor: theme.palette.action.selected,
        },
        [".MuiTouchRipple-root"]: { margin: 0 },
      })}
      {...props}
    />
  );
};
