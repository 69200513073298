import { useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import { Modal } from "@msys/ui";
import { Stack } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import { uniqueId } from "lodash";
import { useSnackbar } from "notistack";
import React from "react";
import * as Yup from "yup";
import { RadioGroupField } from "../../commons/form-fields/RadioGroupField";
import {
  TemplateBase,
  TemplatesDisplay,
} from "../templates/quote/TemplatesDisplay";
import {
  useCreateRequirementModalQuery,
  useCreateRequirementMutation,
} from "./CreateRequirementModal.generated";

type CreateRequirementSource = "empty" | "template" | "m1";

interface FormValues {
  title: string;
  template: TemplateBase | null;
  source: CreateRequirementSource;
}

interface Props {
  projectId: string;
  title?: string;
  handleClose: () => void;
  handleComplete?: (requirementId?: string) => Promise<void> | void;
  refetchQueries?: string[];
}

export const RequirementCreateModal = ({
  projectId,
  title,
  handleClose,
  handleComplete,
  refetchQueries,
}: Props) => {
  const { enqueueSnackbar } = useSnackbar();

  const { t } = useTranslate(["RequirementCreate", "Global", "Templates"]);

  const client = useApolloClient();
  const query = useCreateRequirementModalQuery({
    client,
    variables: {
      projectId: projectId,
    },
  });
  const project = getDataOrNull(query.data?.project)?.project;

  // const [meister1ImportMultiBrandLead, { loading: m1Loading }] =
  //   useMeister1ImportMultiBrandLeadMutation({ client, refetchQueries });

  const [createRequirement] = useCreateRequirementMutation({
    client,
    refetchQueries,
  });

  const handleSubmit = async (values: FormValues) => {
    try {
      let title = values.title;

      if (values.source === "template") {
        if (!values.template) throw new Error("Template is required");
        title = `${values.template.title} - ${t("Requirement", {
          ns: "RequirementCreate",
        })}`;
      }

      const newRequirement = await createRequirement({
        variables: {
          input: {
            projectId,
            title,
            templateQuoteId:
              values.source === "template"
                ? values.template?.id ?? undefined
                : undefined,
          },
        },
      });

      if (!newRequirement) {
        enqueueSnackbar(
          t("Error while creating requirement", {
            ns: "RequirementCreate",
          })
        );
        return;
      }

      const docId = newRequirement.data?.createRequirement.requirement.id;

      if (!docId) {
        enqueueSnackbar(
          t("Error while creating requirement", {
            ns: "RequirementCreate",
          })
        );
        return;
      }

      enqueueSnackbar(
        t("Requirement created", {
          ns: "RequirementCreate",
        })
      );

      await handleComplete?.(docId);
      handleClose();
    } catch (e) {
      if (e instanceof Error) enqueueSnackbar(e.message, { variant: "error" });
    }
  };

  const validationSchema = Yup.object().shape({
    title: Yup.string()
      .label(t("Title", { ns: "Global" }))
      .when("source", {
        is: "empty",
        then: Yup.string().required(),
      }),
    template: Yup.object()
      .shape({
        id: Yup.string().required(),
        title: Yup.string().required(),
        resolvedAsReadModelVersionNumber: Yup.number().optional().nullable(),
      })
      .when("source", {
        is: "template",
        then: Yup.object().required(
          t("Template must be selected", {
            ns: "RequirementCreate",
          })
        ),
      })
      .nullable(),
    source: Yup.string().oneOf(["empty", "template", "m1"]).required(),
  });

  const initialValues: FormValues = React.useMemo(
    () => ({
      title: `${project?.title ?? ""} - ${t("Requirement", {
        ns: "RequirementCreate",
      })}`,
      template: null,
      source: "template",
    }),
    [t, project?.title]
  );

  const formId = React.useMemo(() => uniqueId(), []);

  return (
    <Formik<FormValues>
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      enableReinitialize
      initialValues={initialValues}
      validateOnMount
    >
      {formikProps => (
        <Modal
          id={"requirement-create-modal"}
          title={t("Create new requirement", { ns: "RequirementCreate" })}
          handleClose={handleClose}
          actionButtons={[
            {
              label: t("Cancel", {
                ns: "Global",
              }),
              handleClick: handleClose,
              buttonProps: { variant: "text" },
            },
            {
              label: t("Create requirement", { ns: "RequirementCreate" }),
              buttonProps: {
                form: formId,
                type: "submit",
                disabled: !formikProps.isValid,
                loading: formikProps.isSubmitting,
              },
            },
          ]}
          isLoading={query.loading}
          maxWidth={formikProps.values.source === "template" ? "xl" : "sm"}
          fixedHeight={formikProps.values.source === "template"}
        >
          <Form id={formId} style={{ height: "100%" }}>
            <Stack direction="column" spacing={1} height="100%" minHeight={0}>
              <RadioGroupField
                name="source"
                options={[
                  {
                    value: "template",
                    label: t("Select template", {
                      ns: "RequirementCreate",
                    }),
                  },
                  {
                    value: "empty",
                    label: t("Create from scratch", {
                      ns: "RequirementCreate",
                    }),
                  },
                ]}
                inline
              />
              {formikProps.values.source === "template" && (
                <TemplatesDisplay
                  fixedFilters={{ applicableFor: ["REQUIREMENT"] }}
                  selectedTemplate={formikProps.values.template}
                  setSelectedTemplate={template =>
                    formikProps.setFieldValue("template", template)
                  }
                  onInspectedTemplateClick={async template => {
                    formikProps.setFieldValue("template", template);
                    setTimeout(async () => {
                      await formikProps.submitForm();
                    });
                  }}
                />
              )}
              {formikProps.values.source === "empty" && (
                <Stack direction="column" spacing={1}>
                  <Field
                    autoFocus
                    label={t("Title", { ns: "Global" })}
                    name="title"
                    component={TextField}
                    required
                  />
                </Stack>
              )}
            </Stack>
          </Form>
        </Modal>
      )}
    </Formik>
  );
};
