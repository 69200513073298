import { gql } from "@apollo/client";
import { LabeledValue, RichTextValue } from "@msys/ui";
import WarningIcon from "@mui/icons-material/Warning";
import { Grid, Typography } from "@mui/material";
import { Stack } from "../../../commons/layout/Stack";
import { notSetSymbol } from "../../doc-items/helpers";
import {
  itemHasProperties,
  useQuoteItemProperties,
} from "../../item-properties/itemProperties";
import { TemplateQuotePreviewItemDescription_ItemFragment } from "./TemplateQuotePreviewItemDescription.generated";

interface Props {
  item: TemplateQuotePreviewItemDescription_ItemFragment;
  columns?: number;
}

export const TemplateQuotePreviewItemDescription = ({
  item,
  columns = 2,
}: Props) => {
  const [properties, productProperties] = useQuoteItemProperties(item);

  const ItemDescription =
    item.description || properties.length > 0 ? (
      <Typography
        variant="body2"
        component="div"
        sx={{ wordBreak: "break-word" }}
      >
        {item.description ? (
          <RichTextValue
            key={item.description}
            htmlContent={item.description}
            style={{ marginBottom: properties.length > 0 ? 8 : 0 }}
          />
        ) : null}
        {properties.length > 0 ? (
          <Grid container columns={columns} spacing={1}>
            {properties.map(property => (
              <Grid key={property.key} item xs={1}>
                <LabeledValue
                  label={property.key}
                  labelIcon={
                    property.value === notSetSymbol ? WarningIcon : undefined
                  }
                  labelIconProps={
                    property.value === notSetSymbol
                      ? { color: "warning" }
                      : undefined
                  }
                >
                  {property.value}
                </LabeledValue>
              </Grid>
            ))}
          </Grid>
        ) : null}
      </Typography>
    ) : null;

  const ProductTitle =
    item.product &&
    item.product.title &&
    item.product.titleClientVisibility !== "hide" ? (
      <Typography
        variant="h4"
        sx={{ fontWeight: "normal", wordBreak: "break-word" }}
      >
        {item.product.title}
      </Typography>
    ) : null;

  const ProductDescription =
    (item.product &&
      item.product.description &&
      item.product.descriptionClientVisibility !== "hide") ||
    productProperties.length > 0 ? (
      <Typography
        variant="body2"
        component="div"
        sx={{ wordBreak: "break-word" }}
      >
        {item.product &&
        item.product.description &&
        item.product.descriptionClientVisibility !== "hide" ? (
          <div>
            <RichTextValue
              key={item.product.description}
              htmlContent={item.product.description}
              style={{ marginBottom: productProperties.length > 0 ? 8 : 0 }}
            />
          </div>
        ) : null}
        {productProperties.length > 0 ? (
          <Grid container columns={columns} spacing={1}>
            {productProperties.map(property => (
              <Grid key={property.key} item xs={1}>
                <LabeledValue label={property.key}>
                  {property.value}
                </LabeledValue>
              </Grid>
            ))}
          </Grid>
        ) : null}
      </Typography>
    ) : null;

  return ItemDescription || ProductTitle || ProductDescription ? (
    <Stack flexDirection="column" spacing={1}>
      {ItemDescription}
      {ProductTitle}
      {ProductDescription}
    </Stack>
  ) : null;
};

export const templateQuoteItemHasDescription = (
  item: TemplateQuotePreviewItemDescription_ItemFragment
): boolean => {
  return Boolean(
    itemHasProperties(item) ||
      item.description ||
      (item.product &&
        item.product.title &&
        item.product.titleClientVisibility !== "hide") ||
      (item.product &&
        item.product.description &&
        item.product.descriptionClientVisibility !== "hide")
  );
};
