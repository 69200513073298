import { notNull } from "@msys/common";
import { CollapseSection, LoadingSpinner, Modal, Switch } from "@msys/ui";
import { Box, Stack } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { FormikProps, useFormik } from "formik";
import {
  productFiltersDefaultValue,
  ProductFiltersFormValues,
  productFiltersValidationSchema,
  useProductFilterFields,
} from "../filters/ProductFilterFields";

interface Props
  extends Pick<
    Parameters<typeof useProductFilterFields>[0],
    "productSearchVariables" | "canChangeProductType"
  > {
  handleClose: () => void;
  formikProps: FormikProps<ProductFiltersFormValues>;
  onReset?(): void;
  onCancel?(): void;
  submitButtonTitle?: string;
}

export const ProductFilterModal = ({
  handleClose,
  formikProps: outsideFormikProps,
  onReset,
  onCancel,
  submitButtonTitle,
  ...props
}: Props) => {
  const { t } = useTranslate(["Product", "ProductSearch", "Global"]);

  // we need an inner formik state to not immediately apply filters on the list
  const formikProps = useFormik<ProductFiltersFormValues>({
    initialValues: {
      ...productFiltersDefaultValue,
      ...props.productSearchVariables.filters,
    },
    validationSchema: productFiltersValidationSchema,
    validateOnMount: true,
    onSubmit: async (newValues, formikHelpers) => {
      outsideFormikProps.setValues(newValues);
      outsideFormikProps.submitForm();
    },
  });

  const {
    nonPropertyFields,
    popularPropertyFields,
    advancePropertyFields,
    isLoading,
  } = useProductFilterFields({
    formikProps,
    ...props,
  });

  const { isValid, dirty, errors, values } = formikProps;

  return (
    <Modal
      dialogProps={{ maxWidth: "xs" }}
      handleClose={handleClose}
      headerButtons={
        isLoading ? (
          <div>
            <LoadingSpinner size="s" padding={0.5} />
          </div>
        ) : undefined
      }
      title={t("Product Filters", { ns: "Product" })}
      // @ts-ignore
      actionButtons={[
        onCancel
          ? {
              label: t("Cancel", { ns: "Global" }),
              handleClick: () => {
                onCancel?.();
                handleClose();
              },
              buttonProps: {
                variant: "text",
              },
            }
          : null,
        onReset
          ? {
              label: t("Reset", { ns: "Global" }),
              handleClick: () => {
                onReset?.();
                handleClose();
              },
              buttonProps: {
                variant: "text",
              },
            }
          : null,
        {
          label: submitButtonTitle ?? t("Apply", { ns: "Global" }),
          handleClick: async () => {
            await formikProps.submitForm();
            handleClose();
          },
          buttonProps: {
            disabled: !dirty || !isValid,
          },
        },
      ].filter(notNull)}
      alwaysVisible
    >
      <Stack spacing={1}>
        <Box>
          <Switch
            label={t("Favourites", { ns: "ProductSearch" })}
            checked={formikProps.values.organisationFavourite ?? false}
            onChange={(_, checked) => {
              formikProps.setFieldValue("organisationFavourite", checked);
            }}
          />
        </Box>

        {nonPropertyFields.length > 0 && (
          <Stack spacing={1}>{nonPropertyFields}</Stack>
        )}

        {popularPropertyFields.length > 0 && (
          <CollapseSection
            title={t("Popular filters", { ns: "ProductSearch" })}
            isInitiallyExpanded={true}
            itemCount={popularPropertyFields.length}
          >
            <Stack spacing={1}>{popularPropertyFields}</Stack>
          </CollapseSection>
        )}
        {advancePropertyFields.length > 0 && (
          <CollapseSection
            title={t("Advanced filters", { ns: "ProductSearch" })}
            isInitiallyExpanded={false}
            itemCount={advancePropertyFields.length}
          >
            <Stack spacing={1}>{advancePropertyFields}</Stack>
          </CollapseSection>
        )}
      </Stack>
    </Modal>
  );
};
