/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ProductTypeInput_PimSearchProductTypesQueryVariables = Types.Exact<{
  productTypeIds: Array<Types.Scalars['ID']['input']>;
}>;


export type ProductTypeInput_PimSearchProductTypesQuery = { pimSearchProductTypes: { __typename: 'MissingCapabilities' } | { __typename: 'PimSearchProductTypesResult', totalCount: number, productTypes: Array<{ __typename: 'PimProductType', id: string, key: string, label: string }> } };

export type ProductTypeInput_ProductTypeFragment = { __typename: 'PimProductType', id: string, key: string, label: string };

export const ProductTypeInput_ProductTypeFragmentDoc = gql`
    fragment ProductTypeInput_ProductType on PimProductType {
  id
  key
  label
}
    `;
export const ProductTypeInput_PimSearchProductTypesDocument = gql`
    query ProductTypeInput_PimSearchProductTypes($productTypeIds: [ID!]!) {
  pimSearchProductTypes(limit: 1, offset: 0, productTypeIds: $productTypeIds) {
    ... on PimSearchProductTypesResult {
      productTypes {
        id
        ...ProductTypeInput_ProductType
      }
      totalCount
    }
  }
}
    ${ProductTypeInput_ProductTypeFragmentDoc}`;

/**
 * __useProductTypeInput_PimSearchProductTypesQuery__
 *
 * To run a query within a React component, call `useProductTypeInput_PimSearchProductTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductTypeInput_PimSearchProductTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductTypeInput_PimSearchProductTypesQuery({
 *   variables: {
 *      productTypeIds: // value for 'productTypeIds'
 *   },
 * });
 */
export function useProductTypeInput_PimSearchProductTypesQuery(baseOptions: Apollo.QueryHookOptions<ProductTypeInput_PimSearchProductTypesQuery, ProductTypeInput_PimSearchProductTypesQueryVariables> & ({ variables: ProductTypeInput_PimSearchProductTypesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductTypeInput_PimSearchProductTypesQuery, ProductTypeInput_PimSearchProductTypesQueryVariables>(ProductTypeInput_PimSearchProductTypesDocument, options);
      }
export function useProductTypeInput_PimSearchProductTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductTypeInput_PimSearchProductTypesQuery, ProductTypeInput_PimSearchProductTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductTypeInput_PimSearchProductTypesQuery, ProductTypeInput_PimSearchProductTypesQueryVariables>(ProductTypeInput_PimSearchProductTypesDocument, options);
        }
export function useProductTypeInput_PimSearchProductTypesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ProductTypeInput_PimSearchProductTypesQuery, ProductTypeInput_PimSearchProductTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ProductTypeInput_PimSearchProductTypesQuery, ProductTypeInput_PimSearchProductTypesQueryVariables>(ProductTypeInput_PimSearchProductTypesDocument, options);
        }
export type ProductTypeInput_PimSearchProductTypesQueryHookResult = ReturnType<typeof useProductTypeInput_PimSearchProductTypesQuery>;
export type ProductTypeInput_PimSearchProductTypesLazyQueryHookResult = ReturnType<typeof useProductTypeInput_PimSearchProductTypesLazyQuery>;
export type ProductTypeInput_PimSearchProductTypesSuspenseQueryHookResult = ReturnType<typeof useProductTypeInput_PimSearchProductTypesSuspenseQuery>;
export type ProductTypeInput_PimSearchProductTypesQueryResult = Apollo.QueryResult<ProductTypeInput_PimSearchProductTypesQuery, ProductTypeInput_PimSearchProductTypesQueryVariables>;