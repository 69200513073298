import FolderIcon from "@mui/icons-material/Folder";
import InfoIcon from "@mui/icons-material/Info";
import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { Props2AllFragment } from "../../doc-items/properties.generated";
import { PropertyValueItem } from "../../properties/PropertyValueItems";

interface Props {
  props: Props2AllFragment[];
  templateTypeTitle?: string;
}

export const TemplateTypeAndPropsInfo = ({
  templateTypeTitle,
  props,
}: Props) => {
  if (!templateTypeTitle && props.length === 0) return null;
  return (
    <Typography variant="caption" component="div" color="textSecondary">
      {templateTypeTitle && (
        <Stack direction="row" alignItems="center" spacing={0.5}>
          <Box
            sx={theme => ({
              display: "flex",
              flexGrow: 0,
              flexShrink: 0,
              alignSelf: "flex-start",
              height: `calc(${theme.typography.caption.fontSize} * ${theme.typography.caption.lineHeight})`,
            })}
          >
            <FolderIcon style={{ fontSize: "15px", flexShrink: 0 }} />
          </Box>
          <Box minWidth={0} sx={{ overflowWrap: "break-word" }}>
            {templateTypeTitle}
          </Box>
        </Stack>
      )}
      {props.length > 0 && (
        <Stack direction="row" alignItems="center" spacing={0.5}>
          <Box
            sx={theme => ({
              display: "flex",
              flexGrow: 0,
              flexShrink: 0,
              alignSelf: "flex-start",
              height: `calc(${theme.typography.caption.fontSize} * ${theme.typography.caption.lineHeight})`,
            })}
          >
            <InfoIcon style={{ fontSize: "15px", flexShrink: 0 }} />
          </Box>
          <Box minWidth={0} sx={{ overflowWrap: "break-word" }}>
            {props.map((prop, index) => (
              <React.Fragment key={index}>
                {index > 0 ? " • " : ""}
                {<PropertyValueItem prop={prop} key={prop.key} />}
              </React.Fragment>
            ))}
          </Box>
        </Stack>
      )}
    </Typography>
  );
};
