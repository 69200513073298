import { Switch } from "@msys/ui";
import { useTranslate } from "@tolgee/react";
import React from "react";
import { useUserData } from "../../../auth/useUserData";
import {
  FilterByBoolean,
  FilterByNumber,
  FilterByText,
  FilterProps,
} from "../../../commons/filters";
import type { Filters } from "../ProjectsList";
export {
  FilterProjectCategory,
  FilterProjectCategoryChips,
} from "./ProjectCategory";
export { ProjectsSorting } from "./ProjectSorting";
export { FilterProjectState, FilterProjectStateChips } from "./ProjectState";
export { FilterProjectType, FilterProjectTypeChips } from "./ProjectType";

export const FilterBuildingZip = (props: FilterProps<string>) => {
  const { t } = useTranslate("Projects");
  return (
    <FilterByText
      label={t("ZIP")}
      placeholder={t("Enter zipcode")}
      {...props}
    />
  );
};

export const FilterWithinRadiusKm = (
  props: FilterProps<number> & { label?: string }
) => {
  const { t } = useTranslate("Projects");
  return (
    <FilterByNumber
      label={props.label || `${t("Proximity")} (${t("in km; 0 = any")})`}
      placeholder={t("e.g. 40")}
      // helperText={t("in km; 0 = any")}
      {...props}
    />
  );
};

export const FilterMinBudget = (props: FilterProps<number>) => {
  const { t } = useTranslate("Projects");
  return (
    <FilterByNumber
      label={`${t("Minimum Budget")} (${t("in EUR; 0 = any")})`}
      placeholder={t("e.g. 10000")}
      // helperText={t("in EUR; 0 = any")}
      {...props}
    />
  );
};

export const FilterPlanned = (props: FilterProps<boolean | undefined>) => {
  const { t } = useTranslate("Projects");
  return (
    <FilterByBoolean
      label={t("Planning")}
      yesLabel={t("Only planned")}
      noLabel={t("Only not planned")}
      {...props}
    />
  );
};

export function FilterRelevantToMe<F extends Filters>({
  filters,
  setFilters,
}: {
  filters: F;
  setFilters: React.Dispatch<React.SetStateAction<F>>;
}) {
  const { t } = useTranslate(["Opportunities"]);
  const viewer = useUserData().currentUser!;
  return (
    <Switch
      label={t("Relevant to me", { ns: "Opportunities" })}
      checked={Boolean(
        filters.filterRelevantToUserIds &&
          filters.filterRelevantToUserIds.length > 0 &&
          filters.filterRelevantToUserIds.includes(viewer.id)
      )}
      onChange={(_, checked) => {
        setFilters(filters => {
          let newRelevantIds = [...(filters.filterRelevantToUserIds ?? [])];
          if (checked) {
            if (!newRelevantIds.includes(viewer.id))
              newRelevantIds.push(viewer.id);
          } else {
            if (newRelevantIds.includes(viewer.id))
              newRelevantIds = newRelevantIds.filter(id => id !== viewer.id);
          }
          return {
            ...filters,
            filterRelevantToUserIds:
              newRelevantIds.length > 0 ? newRelevantIds : undefined,
          };
        });
      }}
    />
  );
}
