import React from "react";

export const ConditionalWrapper = ({
  condition,
  wrapper,
  children,
}: React.PropsWithChildren<{
  condition: boolean;
  wrapper: (children: React.ReactNode) => React.ReactNode;
}>) => (condition ? wrapper(children) : children);
