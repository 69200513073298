import { Box, Slider, Stack } from "@mui/material";
import React from "react";
import { useFormatting } from "../formatting/useFormatting";
import { FormattedPriceInput } from "./FormattedPriceInput";
import { useDebouncedSync } from "../hooks/useDebouncedSync";

export function PriceSliderWithInput({
  label,
  labelMin,
  labelMax,
  range,
  value,
  setValue,
  debounceMs,
}: {
  label: string;
  labelMin: string;
  labelMax: string;
  range: [number, number];
  value: [number | null, number | null];
  setValue: (newValue: [number, number]) => void;
  debounceMs?: number;
}) {
  const { getFormattedPrice } = useFormatting();

  const [min, max] = range;
  const passedValue = React.useMemo(
    (): [number, number] => [value[0] ?? min, value[1] ?? max],
    [max, min, value]
  );

  const isRangeEqual = React.useCallback(
    (
      v1: [number, number] | null | undefined,
      v2: [number, number] | null | undefined
    ) => {
      return (
        (v1?.[0] ?? min) === (v2?.[0] ?? min) &&
        (v1?.[1] ?? max) === (v2?.[1] ?? max)
      );
    },
    [max, min]
  );

  const [internalValue, setInternalValue] = useDebouncedSync<[number, number]>({
    externalValue: passedValue,
    setExternalValue: setValue,
    isEqualValues: isRangeEqual,
    debounceMs,
  });

  const handleSliderChange = (
    event: React.SyntheticEvent | Event,
    newValue: number | number[]
  ) => {
    if (!Array.isArray(newValue))
      throw new Error(`Invalid value for range slider: ${newValue}`);

    const [newMin, newMax] = newValue;
    setInternalValue([newMin, newMax]);
  };

  const handleMinInputChange = (value: number | null) => {
    setInternalValue([value ?? min, internalValue[1]]);
  };
  const handleMaxInputChange = (value: number | null) => {
    setInternalValue([internalValue[0], value ?? max]);
  };

  return (
    <Stack direction="row" spacing={2} alignItems="center">
      <Box flexBasis="25%" flexGrow={1} flexShrink={1}>
        <FormattedPriceInput
          label={labelMin}
          value={internalValue[0]}
          min={0}
          // min={min}
          // max={max}
          // size="extra-small"
          onChange={handleMinInputChange}
          inputProps={{
            step: 1,
            "aria-labelledby": "input-slider",
          }}
          variant="standard"
        />
      </Box>
      <Box
        flexBasis="50%"
        minWidth="80px"
        maxWidth="150px"
        flexGrow={2}
        flexShrink={2}
      >
        <Slider
          // size={"small"}
          value={internalValue}
          step={1}
          onChange={handleSliderChange}
          valueLabelDisplay="auto"
          aria-labelledby="input-slider"
          getAriaLabel={() => label}
          getAriaValueText={value => getFormattedPrice(value) ?? ""}
          valueLabelFormat={value => getFormattedPrice(value) ?? ""}
          track={"normal"}
          min={min}
          max={max}
        />
      </Box>
      <Box flexBasis="25%" flexGrow={1} flexShrink={1}>
        <FormattedPriceInput
          label={labelMax}
          value={internalValue[1]}
          min={0}
          // min={min}
          // max={max}
          // size="extra-small"
          onChange={handleMaxInputChange}
          inputProps={{
            step: 1,
            "aria-labelledby": "input-slider",
          }}
          variant="standard"
        />
      </Box>
    </Stack>
  );
}
