import { useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import { Stack, StackProps } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { Form, Formik, useFormikContext } from "formik";
import React from "react";
import * as Yup from "yup";
import { AutoSave } from "../../commons/form-fields/AutoSave";
import { OrganisationUserSelect } from "../organisations/OrganisationUserSelect";
import { useAddProjectMemberFromOrganisationMembersMutation } from "./modals/AddProjectMemberModal.generated";
import {
  useProjectAssigneeSelectQuery,
  useSetProjectAssigneeMutation,
} from "./ProjectAssigneeSelect.generated";

export interface FormValues {
  assigneeUserId: string | null;
}

export const useProjectAssigneeSelect = ({
  projectId,
  refetchQueries,
  onAssigneeChange,
  onAssigneeChanged,
}: {
  projectId: string;
  refetchQueries?: string[];
  onAssigneeChange?: (assigneeUserId: string | null) => void | Promise<void>;
  onAssigneeChanged?: (
    assigneeUserId: string | null,
    membershipId: string | null
  ) => void | Promise<void>;
}) => {
  const { t } = useTranslate(["ProjectMembers", "Global"]);

  const client = useApolloClient();
  const query = useProjectAssigneeSelectQuery({
    client,
    variables: { projectId },
    fetchPolicy: "network-only",
  });
  const [setAssignee] = useSetProjectAssigneeMutation({
    client,
  });
  const [addProjectMember] = useAddProjectMemberFromOrganisationMembersMutation(
    {
      client,
    }
  );
  const activeMembers = query.data?.organisationMemberships ?? [];
  const assigneeMembershipId =
    getDataOrNull(query.data?.project)?.project?.assignee?.id ?? null;
  const projectMembers =
    getDataOrNull(query.data?.project)?.project?.internalStakeholders ?? [];
  const projectRoles = getDataOrNull(query.data?.project)?.project?.roles ?? [];
  const assigneeUserId = assigneeMembershipId
    ? projectMembers.find(m => m.id === assigneeMembershipId)?.user?.id ?? null
    : null;
  const projectCouldBeUnassigned =
    query.data?.organisationSettings?.projectCouldBeUnassigned ?? false;
  const required = !projectCouldBeUnassigned;

  const initialValues: FormValues = {
    assigneeUserId,
  };

  const validationSchema = Yup.object().shape({
    assigneeUserId: Yup.string()
      .label(t("Assignee", { ns: "ProjectMembers" }))
      .when([], {
        is: () => required,
        then: schema => schema.required(),
      })
      .nullable(),
  });

  const onSubmit = async ({
    assigneeUserId: newAssigneeUserId,
  }: FormValues) => {
    if (assigneeUserId === newAssigneeUserId) return;
    await onAssigneeChange?.(newAssigneeUserId);
    let membershipId = newAssigneeUserId
      ? projectMembers.find(m => m.user.id === newAssigneeUserId)?.id ?? null
      : null;
    if (newAssigneeUserId) {
      if (!membershipId) {
        const user = activeMembers.find(u => u.id === newAssigneeUserId);
        if (!user) throw new Error("Cannot find user");
        let userRoleIds = user.defaultProjectRoles.map(r => r.id);
        if (!userRoleIds.length) {
          userRoleIds = projectRoles
            .filter(r => r.internalName === "PROJECT_MEMBER")
            .map(r => r.id);
        }
        const result = await addProjectMember({
          variables: {
            projectId: projectId,
            userId: user.id,
            roleIds: userRoleIds,
          },
        });
        membershipId =
          result.data?.addProjectMemberFromOrganisationMembers.project.internalStakeholders.find(
            m => m.user.id === user.id
          )?.id ?? null;
      }
      if (!membershipId) throw new Error("Cannot add member to project");
    }
    await setAssignee({
      variables: {
        projectId,
        membershipId,
      },
      refetchQueries,
      awaitRefetchQueries: true,
    });
    await onAssigneeChanged?.(newAssigneeUserId, membershipId);
  };

  return {
    initialValues,
    validationSchema,
    onSubmit,
    required,
    query,
  };
};

interface Props {
  projectId: string;
  refetchQueries?: string[];
  readOnly?: boolean;
  autocompleteProps?: React.ComponentProps<
    typeof OrganisationUserSelect
  >["autocompleteProps"];
  stackProps?: StackProps;
  autoSaveType?: React.ComponentProps<typeof AutoSave>["type"];
  autoSaveDebounceMs?: number;
  disabled?: boolean;
  onAssigneeChange?: (assigneeUserId: string | null) => void | Promise<void>;
  onAssigneeChanged?: (
    assigneeUserId: string | null,
    membershipId: string | null
  ) => void | Promise<void>;
}

export const ProjectAssigneeSelectFormWithAutoSave = ({
  projectId,
  refetchQueries,
  readOnly,
  autocompleteProps,
  stackProps,
  autoSaveType,
  autoSaveDebounceMs,
  disabled,
  onAssigneeChange,
  onAssigneeChanged,
}: Props) => {
  const { initialValues, validationSchema, onSubmit, required, query } =
    useProjectAssigneeSelect({
      projectId,
      refetchQueries,
      onAssigneeChange,
      onAssigneeChanged,
    });

  if (query.loading) return null;

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {formikProps => (
        <Form>
          <Stack p={1} direction="column" spacing={1} {...stackProps}>
            <ProjectAssigneeField
              required={required}
              readOnly={readOnly}
              disabled={disabled ?? formikProps.isSubmitting}
              autocompleteProps={autocompleteProps}
            />
            <AutoSave type={autoSaveType} debounceMs={autoSaveDebounceMs} />
          </Stack>
        </Form>
      )}
    </Formik>
  );
};

export function ProjectAssigneeField({
  readOnly,
  disabled,
  required,
  autocompleteProps,
}: {
  readOnly?: boolean;
  disabled?: boolean;
  required?: boolean;
  autocompleteProps?: React.ComponentProps<
    typeof OrganisationUserSelect
  >["autocompleteProps"];
}) {
  const { t } = useTranslate(["ProjectMembers", "Global"]);
  const { values, setFieldValue, errors } = useFormikContext<FormValues>();
  return (
    <OrganisationUserSelect
      inputLabel={t("Assignee", { ns: "ProjectMembers" })}
      placeholder={t("Unassigned", { ns: "ProjectMembers" })}
      userId={values.assigneeUserId}
      onChange={userId => {
        setFieldValue("assigneeUserId", userId);
      }}
      required={required}
      disabled={disabled}
      readOnly={readOnly}
      error={errors.assigneeUserId}
      autocompleteProps={autocompleteProps}
    />
  );
}
