import { useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import { DataGrid, GridColDef } from "@msys/ui";
import { Chip } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  BreadcrumbItem,
  Page,
  PageTopbarItem,
} from "../../../commons/layout/Page";
import { PageContainer } from "../../../commons/layout/PageContainer";
import { fromVersionNumberParamToVersionNumberInt } from "../../../utils";
import {
  TemplateQuoteTemplateTypes_TemplateDeepUsedTemplateTypesResultTemplateTypeFragment,
  useTemplateQuoteTemplateTypesQuery,
} from "./TemplateQuoteTemplateTypes.generated";

interface Props {
  docId: string;
  submenuItems: PageTopbarItem[];
  breadcrumbs: BreadcrumbItem[];
  pageSubHeader: React.ReactElement | null;
}

export function TemplateQuoteTemplateTypes({
  docId,
  submenuItems,
  breadcrumbs,
  pageSubHeader,
}: Props) {
  const { versionNumber: versionNumberFromParam } = useParams();
  const versionNumber = fromVersionNumberParamToVersionNumberInt(
    versionNumberFromParam
  );
  if (!versionNumber) throw new Error("Version number missing");

  const navigate = useNavigate();

  const location = useLocation();

  const { t } = useTranslate(["Global", "Templates"]);

  const client = useApolloClient();
  const query = useTemplateQuoteTemplateTypesQuery({
    client,
    variables: { id: docId, versionNumber },
  });
  const quoteTemplate = getDataOrNull(
    query.data?.quoteTemplateVersion
  )?.quoteTemplate;
  const templateTypes = getDataOrNull(
    query.data?.templateDeepUsedTemplateTypes
  )?.templateTypes;

  const columns: GridColDef<TemplateQuoteTemplateTypes_TemplateDeepUsedTemplateTypesResultTemplateTypeFragment>[] =
    [
      {
        field: "title",
        headerName: t("Title", {
          ns: "Global",
        }),
        flex: 1,
        renderCell: ({ row }) => row.templateType.title,
      },
      {
        field: "count",
        headerName: "",
        flex: 1,
        maxWidth: 80,
        minWidth: 40,
        renderCell: ({ row }) => (
          <Chip
            variant="outlined"
            clickable={false}
            label={row.implementedByTemplatesCount}
            size="small"
            sx={{ ml: 0.5, mt: 0.25 }}
          />
        ),
      },
    ];

  return (
    <Page
      title={quoteTemplate?.title ?? ""}
      subtitle={t("Used template types", { ns: "Templates" })}
      submenuItems={submenuItems}
      breadcrumbs={breadcrumbs}
      subHeader={pageSubHeader}
    >
      <PageContainer>
        <DataGrid<TemplateQuoteTemplateTypes_TemplateDeepUsedTemplateTypesResultTemplateTypeFragment>
          columns={columns}
          rows={templateTypes ?? []}
          getRowId={row => row.templateType.id}
          paginationMode="client"
          loading={query.loading}
          onRowClick={({ row }) => {
            navigate(`${location.pathname}/${row.templateType.id}`);
          }}
        />
      </PageContainer>
    </Page>
  );
}
