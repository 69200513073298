import { Paper, Theme } from "@mui/material";
import { SystemStyleObject } from "@mui/system";
import React from "react";

interface Props extends Omit<React.ComponentProps<typeof Paper>, "sx"> {
  selected?: boolean;
  disabled?: boolean;
  hoverable?: boolean;
  padding?: number;
  sx?: (theme: Theme) => SystemStyleObject<Theme>;
}

export const CardItem = React.forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<Props>
>(
  (
    {
      children,
      padding = 1,
      selected,
      disabled,
      hoverable,
      onClick,
      sx,
      ...props
    },
    forwardedRef
  ) => {
    return (
      <Paper
        ref={forwardedRef}
        onClick={!disabled ? onClick : undefined}
        sx={theme => ({
          display: "block",
          color: "inherit",
          textDecoration: "none",
          cursor: onClick && !disabled ? "pointer" : undefined,
          boxShadow: selected ? "inset 0 0 0 2px #377dc3" : undefined,
          opacity: disabled ? theme.palette.action.disabledOpacity : undefined,
          padding,
          ...(hoverable
            ? { ["&:hover"]: { backgroundColor: theme.palette.grey[100] } }
            : undefined),
          ...sx?.(theme),
        })}
        {...props}
      >
        {children}
      </Paper>
    );
  }
);
