import { CollapseSection } from "@msys/ui";
import { Stack } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { useFormikContext } from "formik";
import { AskWhen, AskWhom } from "../../../clients/graphqlTypes";
import { SelectMultipleField } from "../../commons/form-fields/SelectMultipleField";
import { TextField } from "../../commons/form-fields/TextField";
import { LabeledQuestionControlSection } from "./LabeledQuestionControlSection";
import { useQuestionContol } from "./useQuestionContol";

export interface QuestionControlSectionFormValues {
  askWhom: AskWhom[];
  askWhen: AskWhen[];
  prompt: string;
}

interface Props {
  isInitiallyExpanded?: boolean;
  defaultPrompt?: string;
  allowedAskWhen?: AskWhen[];
  readOnly?: boolean;
  disabled?: boolean;
}

export const QuestionControlSection = ({
  isInitiallyExpanded = false,
  defaultPrompt,
  allowedAskWhen,
  readOnly,
  disabled,
}: Props) => {
  const { t } = useTranslate(["QuoteItem", "Global"]);
  const { askWhomOptions, askWhenOptions } = useQuestionContol(allowedAskWhen);
  const formikProps = useFormikContext<QuestionControlSectionFormValues>();

  return (
    <CollapseSection
      title={t("Question control", {
        ns: "QuoteItem",
      })}
      isInitiallyExpanded={isInitiallyExpanded}
    >
      {!readOnly ? (
        <Stack direction="column" spacing={1}>
          <Stack direction={"row"} spacing={1}>
            <SelectMultipleField
              name={"askWhen"}
              label={t("Ask when", { ns: "QuoteItem" })}
              options={askWhenOptions}
              inputProps={{ readOnly }}
              disabled={disabled ?? formikProps.isSubmitting}
            />
            <SelectMultipleField
              name={"askWhom"}
              options={askWhomOptions}
              label={t("Ask whom", { ns: "QuoteItem" })}
              inputProps={{ readOnly }}
              disabled={disabled ?? formikProps.isSubmitting}
            />
          </Stack>
          <TextField
            name={"prompt"}
            label={t("Prompt", { ns: "QuoteItem" })}
            placeholder={defaultPrompt}
            inputProps={{ readOnly }}
            disabled={disabled ?? formikProps.isSubmitting}
          />
        </Stack>
      ) : (
        <LabeledQuestionControlSection
          allowedAskWhen={allowedAskWhen}
          askWhen={formikProps.values.askWhen}
          askWhom={formikProps.values.askWhom}
          prompt={formikProps.values.prompt}
        />
      )}
    </CollapseSection>
  );
};

export const defaultQuestionControlValues: QuestionControlSectionFormValues = {
  askWhen: [],
  askWhom: ["contractor"],
  prompt: "",
};
