import { useTranslate } from "@tolgee/react";
import React from "react";

export type RelationshipRole =
  | "PARTNER"
  | "OWNER"
  | "TENANT"
  | "CARETAKER"
  | "PROPERTY_MANAGEMENT"
  | "CHIMNEY_SWEEPER"
  | "CLEANING"
  | "FACILITY_MANAGEMENT"
  | "PORTER"
  | "CONTRACTEE_CONTACT"
  | "MAIN_CONTRACTEE"
  | "MAIN_CONTRACTEE_CONTACT";

export function useRelationshipRoles() {
  const { t } = useTranslate("Buildings");

  const roles: Record<RelationshipRole, string> = React.useMemo(
    () => ({
      PARTNER: t("Partner"),
      OWNER: t("Owner"),
      TENANT: t("Tenant"),
      CARETAKER: t("Caretaker"),
      PROPERTY_MANAGEMENT: t("Property management"),
      CHIMNEY_SWEEPER: t("Chimney sweeper"),
      CLEANING: t("Cleaning"),
      FACILITY_MANAGEMENT: t("Facility management"),
      PORTER: t("Porter"),
      CONTRACTEE_CONTACT: t("Contratcee contact"),
      MAIN_CONTRACTEE: t("Main contractee"),
      MAIN_CONTRACTEE_CONTACT: t("Main contractee contact"),
    }),
    [t]
  );

  const getRoleLabel = React.useCallback(
    (value: string) => {
      return value in roles ? roles[value as RelationshipRole] : undefined;
    },
    [roles]
  );

  const roleOptions = React.useMemo(
    () =>
      Object.entries(roles).map(([key, value]) => ({
        value: key as RelationshipRole,
        label: value,
      })),
    [roles]
  );

  return {
    roleOptions,
    getRoleLabel,
  };
}
