import {
  ConditionalWrapper,
  ellipsisStyle,
  inverseTheme,
  useScreenWidth,
} from "@msys/ui";
import CloseFullscreenIcon from "@mui/icons-material/CloseFullscreen";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import {
  Box,
  Divider,
  IconButton,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import { colord } from "colord";
import React from "react";

export const EXPANDED_COLUMN_MIN_WIDTH = 220;
export const COLLAPSED_COLUMN_WIDTH = 36;

const COLOR_WHITE = "#FFFFFF";

interface Props {
  isCollapsed: boolean;
  setCollapsed: (collapsed: boolean) => void;
  collapsedTitle: string;
  title: React.ReactNode;
  color: string;
  showCollapsedButton?: boolean;
  children: React.ReactNode;
  overlay?: React.ReactNode;
}

export const KanbanColumn = React.forwardRef<HTMLDivElement, Props>(
  (
    {
      isCollapsed,
      setCollapsed,
      collapsedTitle,
      title,
      color,
      children,
      overlay,
      showCollapsedButton = true,
    },
    forwardedRef
  ) => {
    const { isMinTablet } = useScreenWidth();
    const isWhite = colord(color).isEqual(COLOR_WHITE);
    const isDark = colord(color).isDark();
    return (
      <Box
        ref={forwardedRef}
        flexBasis={
          isCollapsed
            ? COLLAPSED_COLUMN_WIDTH
            : isMinTablet
              ? EXPANDED_COLUMN_MIN_WIDTH
              : "100%"
        }
        flexShrink={0}
        flexGrow={1}
        width={0}
        maxWidth={isCollapsed ? COLLAPSED_COLUMN_WIDTH : undefined}
        minHeight={0}
        overflow="auto"
        position="relative"
      >
        <Paper
          sx={theme => ({
            position: "relative",
            ...(!isWhite
              ? {
                  outlineColor: color,
                  outlineOffset: "-2px",
                  outlineWidth: "2px",
                  outlineStyle: "solid",
                }
              : {
                  outlineColor: theme.palette.divider,
                  outlineOffset: "-1px",
                  outlineWidth: "1px",
                  outlineStyle: "solid",
                }),
          })}
          elevation={0}
          variant={"elevation"}
        >
          <Box
            position="sticky"
            top={0}
            zIndex={2}
            sx={theme => ({
              borderTopLeftRadius: "inherit",
              borderTopRightRadius: "inherit",
              ...(isWhite && {
                outlineColor: theme.palette.divider,
                outlineOffset: "-1px",
                outlineWidth: "1px",
                outlineStyle: "solid",
              }),
            })}
          >
            <ConditionalWrapper
              condition={isDark}
              wrapper={children => (
                <MuiThemeProvider theme={inverseTheme}>
                  {children}
                </MuiThemeProvider>
              )}
            >
              <Stack
                direction={"row"}
                alignItems="center"
                justifyContent="space-between"
                maxWidth={"100%"}
                height="40px"
                width={"100%"}
                px={isCollapsed ? 0 : 1}
                py={0.5}
                sx={{
                  backgroundColor: color,
                  borderTopLeftRadius: "inherit",
                  borderTopRightRadius: "inherit",
                  ...ellipsisStyle,
                }}
                spacing={1}
              >
                {isCollapsed ? (
                  showCollapsedButton && (
                    <IconButton
                      size={"small"}
                      color={"primary"}
                      onClick={() => {
                        setCollapsed(false);
                      }}
                    >
                      <OpenInFullIcon />
                    </IconButton>
                  )
                ) : (
                  <>
                    {title}
                    <Stack direction="row" alignItems="center" spacing={0.5}>
                      {showCollapsedButton && (
                        <IconButton
                          size={"small"}
                          color={"primary"}
                          onClick={() => {
                            setCollapsed(true);
                          }}
                        >
                          <CloseFullscreenIcon />
                        </IconButton>
                      )}
                    </Stack>
                  </>
                )}
              </Stack>
            </ConditionalWrapper>
          </Box>
          {isCollapsed ? (
            <Typography
              padding={1}
              variant="h5"
              textTransform={"uppercase"}
              sx={{
                writingMode: "vertical-lr",
                transform: "rotate(180deg)",
                margin: "0 auto",
              }}
            >
              {collapsedTitle}
            </Typography>
          ) : (
            children
          )}
          {overlay}
        </Paper>
      </Box>
    );
  }
);

export const KanbanColumnOverlay = ({
  loading,
  selected,
}: {
  loading?: boolean;
  selected?: boolean;
}) => (
  <Box
    sx={theme => ({
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: loading
        ? theme.custom.colors.loadingOverlayBackground
        : selected
          ? theme.custom.colors.selectedOverlayBackground
          : "transparent",
      pointerEvents: "none",
      p: 1,
      display: "flex",
      direction: "column",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: `${theme.shape.borderRadius}px`,
    })}
  />
);
