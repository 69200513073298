/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import { ProjectPhaseFragmentDoc } from '../ProjectPhasesConfigurator.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ProjectPhasesQueryVariables = Types.Exact<{
  allowedStates: Array<Types.ProjectStateMachineStatus>;
}>;


export type ProjectPhasesQuery = { organisationSettings: { __typename: 'OrganisationSetting', id: string, projectPhases: Array<{ __typename: 'OrganisationProjectPhase', id: string, name: string, applicableForState: Types.ProjectStateMachineStatus, color: string }> } };


export const ProjectPhasesDocument = gql`
    query ProjectPhases($allowedStates: [ProjectStateMachineStatus!]!) {
  organisationSettings {
    id
    projectPhases(filterIncludeState: $allowedStates) {
      id
      ...ProjectPhase
    }
  }
}
    ${ProjectPhaseFragmentDoc}`;

/**
 * __useProjectPhasesQuery__
 *
 * To run a query within a React component, call `useProjectPhasesQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectPhasesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectPhasesQuery({
 *   variables: {
 *      allowedStates: // value for 'allowedStates'
 *   },
 * });
 */
export function useProjectPhasesQuery(baseOptions: Apollo.QueryHookOptions<ProjectPhasesQuery, ProjectPhasesQueryVariables> & ({ variables: ProjectPhasesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectPhasesQuery, ProjectPhasesQueryVariables>(ProjectPhasesDocument, options);
      }
export function useProjectPhasesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectPhasesQuery, ProjectPhasesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectPhasesQuery, ProjectPhasesQueryVariables>(ProjectPhasesDocument, options);
        }
export function useProjectPhasesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ProjectPhasesQuery, ProjectPhasesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ProjectPhasesQuery, ProjectPhasesQueryVariables>(ProjectPhasesDocument, options);
        }
export type ProjectPhasesQueryHookResult = ReturnType<typeof useProjectPhasesQuery>;
export type ProjectPhasesLazyQueryHookResult = ReturnType<typeof useProjectPhasesLazyQuery>;
export type ProjectPhasesSuspenseQueryHookResult = ReturnType<typeof useProjectPhasesSuspenseQuery>;
export type ProjectPhasesQueryResult = Apollo.QueryResult<ProjectPhasesQuery, ProjectPhasesQueryVariables>;