import { ColorPicker } from "@msys/ui";
import { useFormikContext } from "formik";
import { get, isString } from "lodash";
import React from "react";

export interface Props {
  id?: string;
  fullWidth?: boolean;
  required?: boolean;
  disabled?: boolean;
  label: string;
  placeholder?: string;
  name: string;
  value?: string;
  onChange?: (newValue: string) => void;
}

export const ColorPickerField: React.FC<Props> = ({
  id,
  label,
  placeholder,
  required,
  disabled,
  fullWidth = true,
  name,
  value,
  onChange,
}) => {
  const { errors, values, setFieldValue, isSubmitting } =
    useFormikContext<Record<string, any>>();

  const error = get(errors, name);

  return (
    <ColorPicker
      error={Boolean(error)}
      helperText={error && isString(error) ? error : undefined}
      fullWidth={fullWidth}
      required={required}
      disabled={disabled ?? isSubmitting}
      id={id}
      name={name}
      label={label}
      placeholder={placeholder}
      value={value ?? get(values, name) ?? ""}
      onChange={
        onChange ??
        (newColor => {
          setFieldValue(name, newColor);
        })
      }
    />
  );
};
