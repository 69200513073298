import { debounce, isEqualWith } from "lodash";
import moment from "moment/moment";
import React from "react";

export const customizerWithDates = (v1: any, v2: any) => {
  if (v1 instanceof moment && v2 instanceof moment) {
    return v1.valueOf() === v2.valueOf();
  }
  if (v1 instanceof Date && v2 instanceof Date) {
    return v1.valueOf() === v2.valueOf();
  }
};

export const defaultIsEqualValues = <V>(
  v1: V | undefined,
  v2: V | undefined
) => {
  return isEqualWith(v1, v2, customizerWithDates);
};

interface Props<V> {
  externalValue: V;
  setExternalValue: (v: V) => void;
  debounceMs?: number;
  isEqualValues?: (v1: V | undefined, v2: V | undefined) => boolean;
}

export const useDebouncedSync = <V>({
  debounceMs = 500,
  externalValue,
  setExternalValue,
  isEqualValues = defaultIsEqualValues,
}: Props<V>): [V, (newValue: V) => void] => {
  const [internalValue, setInternalValue] = React.useState<V>(externalValue);

  const latestInternalValue = React.useRef<V>(internalValue);
  latestInternalValue.current = internalValue;

  const latestExternalValue = React.useRef<V>(externalValue);
  latestExternalValue.current = externalValue;

  const latestSyncedValue = React.useRef<V | undefined>(undefined);
  const latestPreviousValue = React.useRef<V | undefined>(undefined);

  const latestRef = React.useRef({ setExternalValue, isEqualValues });
  latestRef.current = { setExternalValue, isEqualValues };

  const needSync = React.useRef<boolean>(false);

  const syncWithExternalValue = React.useCallback(
    debounce(
      (newValue: V) => {
        if (latestSyncedValue.current !== undefined) {
          needSync.current = true;
          return;
        }
        latestPreviousValue.current = latestExternalValue.current;
        latestSyncedValue.current = newValue;

        latestRef.current.setExternalValue(newValue);
      },
      debounceMs,
      {
        leading: false,
        trailing: true,
      }
    ),
    [debounceMs]
  );

  const setInternalValueWithSync = React.useCallback(
    (newValue: V) => {
      setInternalValue(newValue);
      syncWithExternalValue(newValue);
    },
    [syncWithExternalValue]
  );

  React.useEffect(() => {
    if (latestSyncedValue.current !== undefined) {
      if (
        latestPreviousValue.current !== undefined &&
        latestRef.current.isEqualValues(
          latestPreviousValue.current,
          externalValue
        )
      ) {
        return;
      }
      if (
        !latestRef.current.isEqualValues(
          latestSyncedValue.current,
          externalValue
        )
      ) {
        setInternalValue(externalValue);
      }
      latestSyncedValue.current = undefined;
      latestPreviousValue.current = undefined;
    } else {
      if (
        !latestRef.current.isEqualValues(
          latestInternalValue.current,
          externalValue
        )
      ) {
        setInternalValue(externalValue);
      }
    }
    if (needSync.current) {
      setTimeout(() => {
        syncWithExternalValue(latestInternalValue.current);
      });
      needSync.current = false;
    }
  }, [externalValue, syncWithExternalValue]);

  return [internalValue, setInternalValueWithSync];
};
