import { useLocalStorageAsState } from "@msys/ui";
import { useMediaQuery, useTheme } from "@mui/material";

export type CollectionView = "table" | "list" | "gallery" | "swipe" | "kanban";

export const useCollectionView = <View extends CollectionView = CollectionView>(
  key: string,
  defaultView: View
): [View, (newValue: View | undefined) => void] => {
  const [activeView, setActiveView] = useLocalStorageAsState<View>(
    key,
    defaultView
  );
  return [activeView, setActiveView];
};

export const useCollectionViewWithMobile = <
  View extends CollectionView = CollectionView,
>(
  key: string,
  defaultViewDesktop: View,
  defaultViewMobile: View
): [View, (newValue: View | undefined) => void] => {
  const theme = useTheme();
  const isMinSm = useMediaQuery(
    theme.breakpoints.up(theme.breakpoints.values.sm),
    { noSsr: true }
  );
  const [activeView, setActiveView] = useLocalStorageAsState<View>(
    `msys-${key}-${isMinSm ? "desktop" : "mobile"}-listViewType`,
    isMinSm ? defaultViewDesktop : defaultViewMobile
  );
  return [activeView, setActiveView];
};
