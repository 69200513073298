import { useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import {
  ErrorMessage,
  ModalOpenProcess,
  ModalOpenProcessRef,
  useScreenWidth,
} from "@msys/ui";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import {
  Box,
  Checkbox,
  Divider,
  FilledInput,
  IconButton,
  IconButtonProps,
  Stack,
  SvgIconProps,
} from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { useFormikContext } from "formik";
import React from "react";
import { useEffectOnce, useLockBodyScroll, useUpdateEffect } from "react-use";
import { ButtonCreate } from "../../../commons/button/Button";
import { ButtonCircleWrapper } from "../../../commons/button/ButtonCircleWrapper";
import {
  CollectionView,
  useCollectionViewWithMobile,
} from "../../../commons/hooks/useCollectionView";
import { LayoutWithLeftColumn } from "../../../commons/layout/LayoutWithLeftColumn";
import { track } from "../../../track";
import { productFormValuesToInput } from "../../doc-items/boxes/ProductBox";
import {
  FormValues,
  getProductUniqueId,
  SectionTab,
} from "../../doc-items/SearchProductsAndTemplates";
import { SupplierCatalogueValue } from "../../suppliers/SupplierCatalogueSelect";
import { useDataGridStateStore } from "../../users/useDataGridStateStore";
import { OrganisationSupplierProductFavouriteButton } from "../buttons/OrganisationSupplierProductFavouriteButton";
import { ProductsListView } from "../components/ProductsListView";
import {
  isAnyFilterSet,
  ProductFiltersFormValues,
} from "../filters/ProductFilterFields";
import { ProductFilterFields_AvailableFiltersQueryVariables } from "../filters/ProductFilterFields.generated";
import { productFiltersDefaultValue } from "../filters/ProductFilters";
import { useProductSearchType } from "../filters/useProductSearchTypes";
import { useCanCreateSupplierCatalogueProduct } from "../helper";
import { ProductSearchItem__ProductSearchResultFragment } from "../Product.generated";
import { ProductCategoryTreeChip } from "../ProductCategoryTreeChip";
import {
  ProductCategoryTreeSelect,
  useProductCategoryTree,
} from "../ProductCategoryTreeSelect";
import { ProductSearchLandingPage } from "../ProductSearchLandingPage";
import {
  ChildrenFn,
  ProductSearchListHeader,
} from "../ProductSearchListHeader";
import {
  ProductAskTypeProcess,
  ProductAskTypeProcessRef,
} from "./ProductAskTypeModal";
import {
  ProductCreateModal,
  ProductCreateModalProps,
} from "./ProductCreateModal";
import { ProductOverviewModal } from "./ProductOverviewModal";
import { useProductSearchModalQuery } from "./ProductSearchModal.generated";
import {
  SupplierCatalogueProductCreateModal,
  SupplierCatalogueProductCreateModalProps,
} from "./SupplierCatalogueProductCreateModal";

const ALLOWED_VIEWS: CollectionView[] = ["gallery", "list", "table"];

interface Props {
  initialSearchValue?: string;
  handleSearchValueChange?(value: string): void;
  onProductSearchVariablesChange?(
    variables: ProductFilterFields_AvailableFiltersQueryVariables
  ): void;
  Form: React.ReactNode;
  AddToListIcon?: React.ComponentType<SvgIconProps>;
}

export const ProductSelectMultipleComponent = ({
  initialSearchValue = "",
  handleSearchValueChange,
  onProductSearchVariablesChange,
  Form,
  AddToListIcon = AddShoppingCartIcon,
}: Props) => {
  const formikProps = useFormikContext<FormValues>();
  const { t } = useTranslate(["Product", "Global", "ProductSearch"]);
  const { isMinTablet, isMinLargeDesktop } = useScreenWidth();

  const [productOverviewModalProduct, setProductOverviewModalProduct] =
    React.useState<ProductSearchItem__ProductSearchResultFragment | null>(null);

  const { productSearchTypeOptions, productSearchType, setProductSearchType } =
    useProductSearchType();

  const handleSearchValueChangeRef = React.useRef(handleSearchValueChange);
  handleSearchValueChangeRef.current = handleSearchValueChange;

  const [paginationModel, setPaginationModel] = React.useState<{
    page: number;
    pageSize: number;
  }>({ page: 0, pageSize: 25 });

  const [searchTerm, setSearchTerm] =
    React.useState<string>(initialSearchValue);

  useUpdateEffect(() => {
    handleSearchValueChangeRef.current?.(searchTerm);
  }, [searchTerm]);

  const [filters, setFilters] = React.useState<ProductFiltersFormValues>(
    productFiltersDefaultValue
  );

  const productSearchVariables = React.useMemo(
    () => ({
      searchTerm:
        productSearchType === "fulltextSearch" ? searchTerm : undefined,
      specificFieldQueryFields:
        productSearchType !== "fulltextSearch"
          ? [{ field: productSearchType, value: searchTerm }]
          : undefined,
      filters,
    }),
    [productSearchType, searchTerm, filters]
  );

  const onVariablesChangeRef = React.useRef(onProductSearchVariablesChange);
  onVariablesChangeRef.current = onProductSearchVariablesChange;

  React.useEffect(() => {
    onVariablesChangeRef.current?.(productSearchVariables);
  }, [productSearchVariables]);

  const [section, setSection] = React.useState<SectionTab>(
    Boolean(searchTerm) || isAnyFilterSet(filters) ? "results" : "landing"
  );

  // automatically switching to results when needed
  useEffectOnce(() => {
    if (Boolean(searchTerm) || isAnyFilterSet(filters)) {
      setSection("results");
    }
  });
  useUpdateEffect(() => {
    if (
      (Boolean(searchTerm) || isAnyFilterSet(filters)) &&
      section === "landing"
    )
      setSection("results");
  }, [searchTerm, filters]);

  const client = useApolloClient();
  const query = useProductSearchModalQuery({
    client,
    variables: {
      ...productSearchVariables,
      limit: paginationModel.pageSize,
      offset: paginationModel.pageSize * paginationModel.page,
    },
    skip: section !== "results",
  });

  const products =
    getDataOrNull(
      (query.data ?? query.previousData)?.pimSearchProducts
    )?.edges.map(e => e.node) ?? [];
  const totalCount =
    getDataOrNull((query.data ?? query.previousData)?.pimSearchProducts)
      ?.totalCount ?? 0;

  const resetFilters = () => {
    setFilters(productFiltersDefaultValue);
  };

  const [activeView, setActiveView] =
    useCollectionViewWithMobile<CollectionView>(
      "products-list-modal",
      "list",
      "list"
    );

  const [searchEnabled, setSearchEnabled] = React.useState<boolean>(false);
  useLockBodyScroll(searchEnabled);

  const handleBackClick =
    section === "results"
      ? () => {
          resetFilters();
          setSection("landing");
        }
      : undefined;

  const handleSearchSubmit = () => {
    if (section === "landing") setSection("results");
  };

  const productCategoryProps = useProductCategoryTree(
    filters.supplierIds?.[0] ?? null,
    filters.supplierProductCategoryKey ?? null
  );

  const showCategoriesSelect = Boolean(
    productCategoryProps.supplier &&
      (productCategoryProps.childCategories.length ||
        productCategoryProps.productCategory)
  );

  const handleCategoryTreeSelect = ({
    supplierId,
    supplierProductCategoryKey,
  }: {
    supplierProductCategoryKey?: string | null;
    supplierId?: string | null;
  }) => {
    const newFilters = { ...filters };
    if (supplierId === null) {
      newFilters.supplierIds = null;
    }
    if (supplierProductCategoryKey !== undefined) {
      newFilters.supplierProductCategoryKey = supplierProductCategoryKey;
    }
    setFilters(newFilters);
  };

  React.useEffect(() => {
    const totalCount = getDataOrNull(query.data?.pimSearchProducts)?.totalCount;
    if (
      totalCount &&
      totalCount < paginationModel.pageSize * paginationModel.page &&
      paginationModel.page > 0
    ) {
      setPaginationModel({ page: 0, pageSize: paginationModel.pageSize });
    }
  }, [query.data, paginationModel, setPaginationModel]);

  const selectedDocIndex = productOverviewModalProduct
    ? formikProps.values.products.findIndex(
        prd =>
          getProductUniqueId(prd.product) ===
          getProductUniqueId(productOverviewModalProduct)
      )
    : -1;

  return (
    <>
      <ProductSelectMultipleModalListHeader
        filters={filters}
        setFilters={setFilters}
        resetFilters={resetFilters}
        productSearchTypeOptions={productSearchTypeOptions}
        productSearchType={productSearchType}
        setProductSearchType={setProductSearchType}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        activeView={activeView}
        setActiveView={setActiveView}
        searchEnabled={searchEnabled}
        setSearchEnabled={setSearchEnabled}
        productSearchVariables={productSearchVariables}
        handleSupplierCatalogueProductCreate={(product, handleClose) => {
          formikProps.setFieldValue("products", [
            ...formikProps.values.products,
            { quantity: 1, product },
          ]);
          handleClose();
        }}
        handleProductCreate={values => {
          formikProps.setFieldValue("products", [
            ...formikProps.values.products,
            { quantity: 1, product: productFormValuesToInput(values) },
          ]);
        }}
        hideFilters={section !== "results"}
        handleBackClick={handleBackClick}
        handleSearchSubmit={handleSearchSubmit}
        AdditionalChips={
          !isMinTablet &&
          showCategoriesSelect &&
          productCategoryProps.supplier ? (
            <ProductCategoryTreeChip
              supplier={productCategoryProps.supplier}
              productCategory={productCategoryProps.productCategory}
              handleSelect={handleCategoryTreeSelect}
            />
          ) : undefined
        }
      >
        {({ SearchLine, FiltersLine, ChipsLine, SearchBackdrop }) =>
          isMinTablet ? (
            <Stack
              alignItems={"stretch"}
              height="100%"
              spacing={{ xs: 1, md: 1.5 }}
              direction="row"
            >
              <Stack
                direction={"column"}
                height="100%"
                spacing={1}
                flex={1}
                minWidth={0}
              >
                <Box>
                  <Box
                    overflow="hidden"
                    px={{ xs: 1, md: 1.5 }}
                    mx={{ xs: -1, md: -1.5 }}
                    pb={1}
                    mb={-1}
                    width="100%"
                    boxSizing="content-box"
                  >
                    {SearchLine}
                  </Box>
                </Box>
                {SearchBackdrop}
                {query.error && <ErrorMessage message={query.error.message} />}
                {section === "results" ? (
                  <LayoutWithLeftColumn
                    name="ProductSearchModal"
                    leftColumn={
                      showCategoriesSelect ? (
                        <ProductCategoryTreeSelect
                          {...productCategoryProps}
                          categoryKey={
                            filters.supplierProductCategoryKey ?? null
                          }
                          onSelect={handleCategoryTreeSelect}
                        />
                      ) : undefined
                    }
                    spacing={0}
                    leftColumnWidth={isMinLargeDesktop ? "xs" : "xxs"}
                  >
                    <Stack
                      direction={"column"}
                      height="100%"
                      spacing={1}
                      flex={1}
                      minWidth={0}
                    >
                      {FiltersLine}
                      {ChipsLine}
                      <ProductsList
                        items={products}
                        totalCount={totalCount}
                        activeView={activeView}
                        loading={query.loading}
                        onSelectItem={setProductOverviewModalProduct}
                        paginationModel={paginationModel}
                        setPaginationModel={setPaginationModel}
                        productSearchVariables={productSearchVariables}
                        AddToListIcon={AddToListIcon}
                      />
                    </Stack>
                  </LayoutWithLeftColumn>
                ) : section === "landing" ? (
                  <Box flex={1} overflow="auto">
                    <ProductSearchLandingPage
                      onProductClick={product =>
                        setProductOverviewModalProduct(product)
                      }
                      renderProductSelectButton={product => (
                        <ButtonCircleWrapper>
                          <ProductAddButton
                            product={product}
                            productSearchVariables={productSearchVariables}
                            AddToListIcon={AddToListIcon}
                          />
                        </ButtonCircleWrapper>
                      )}
                      setProductSearchFilters={setFilters}
                    />
                  </Box>
                ) : null}
              </Stack>

              <Divider orientation="vertical" flexItem />
              <Stack
                direction={"column"}
                height={"100%"}
                overflow="hidden"
                spacing={1}
                flexGrow={0}
                flexShrink={0}
                sx={{ width: theme => theme.layout.columnWidth.md }}
              >
                {Form}
              </Stack>
            </Stack>
          ) : (
            <Stack
              direction={"column"}
              spacing={1}
              height="100%"
              width="100%"
              minHeight={0}
              minWidth={0}
            >
              <Box
                overflow="hidden"
                px={{ xs: 1, md: 1.5 }}
                mx={{ xs: -1, md: -1.5 }}
                pb={1}
                mb={-1}
                width="100%"
                boxSizing="content-box"
              >
                {SearchLine}
              </Box>
              {SearchBackdrop}
              {query.error && <ErrorMessage message={query.error.message} />}
              {section === "results" ? (
                <>
                  {FiltersLine}
                  {ChipsLine}
                  <ProductsList
                    items={products}
                    totalCount={totalCount}
                    activeView={activeView}
                    loading={query.loading}
                    onSelectItem={setProductOverviewModalProduct}
                    paginationModel={paginationModel}
                    setPaginationModel={setPaginationModel}
                    productSearchVariables={productSearchVariables}
                    AddToListIcon={AddToListIcon}
                  />
                </>
              ) : section === "landing" ? (
                <Box flex={1} overflow="auto">
                  <ProductSearchLandingPage
                    onProductClick={product =>
                      setProductOverviewModalProduct(product)
                    }
                    renderProductSelectButton={product => (
                      <ButtonCircleWrapper>
                        <ProductAddButton
                          product={product}
                          productSearchVariables={productSearchVariables}
                          AddToListIcon={AddToListIcon}
                        />
                      </ButtonCircleWrapper>
                    )}
                    setProductSearchFilters={setFilters}
                  />
                </Box>
              ) : null}
            </Stack>
          )
        }
      </ProductSelectMultipleModalListHeader>
      {productOverviewModalProduct && (
        <ProductOverviewModal
          actionButtonProps={{
            label: t("Add to list", { ns: "ProductSearch" }),
            handleClick: () => {
              formikProps.setFieldValue("products", [
                ...formikProps.values.products,
                { quantity: 1, product: productOverviewModalProduct },
              ]);
              setProductOverviewModalProduct(null);
            },
            buttonProps: {
              color: "primary" as const,
              variant: "contained" as const,
              startIcon: <AddToListIcon />,
              disabled: selectedDocIndex >= 0,
            },
          }}
          productArticleNumber={productOverviewModalProduct.articleNumber}
          productSupplierId={productOverviewModalProduct.supplierId}
          handleClose={() => setProductOverviewModalProduct(null)}
        />
      )}
    </>
  );
};

function ProductSelectMultipleModalListHeader({
  handleSupplierCatalogueProductCreate,
  handleProductCreate,
  children,
  ...props
}: Omit<
  React.ComponentProps<typeof ProductSearchListHeader>,
  "ActionButtons" | "allowedViews" | "children"
> & {
  handleSupplierCatalogueProductCreate?: SupplierCatalogueProductCreateModalProps["handleComplete"];
  handleProductCreate?: ProductCreateModalProps["handleSubmit"];
  children: ChildrenFn;
}) {
  const { t } = useTranslate(["Product", "Global", "ProductSearch"]);

  const [initialSupplierCatalogue, setInitialSupplierCatalogue] =
    React.useState<SupplierCatalogueValue | null>(null);

  const { canCreateSupplierCatalogueProduct } =
    useCanCreateSupplierCatalogueProduct();

  const productCreateRef = React.useRef<ModalOpenProcessRef>();
  const supplierCatalogueProductCreateRef = React.useRef<ModalOpenProcessRef>();
  const productAskTypeProcessRef =
    React.useRef<ProductAskTypeProcessRef | null>(null);

  const handleCreateNewProduct = async () => {
    if (
      handleProductCreate &&
      handleSupplierCatalogueProductCreate &&
      canCreateSupplierCatalogueProduct
    ) {
      // both possible - need to ask at first which product to create
      const values = await productAskTypeProcessRef.current?.open();
      if (values) {
        if (values.productType === "one-time") {
          productCreateRef.current?.open();
        } else if (values.productType === "catalogue") {
          setInitialSupplierCatalogue(values.supplierCatalogue || null);
          setTimeout(() => {
            supplierCatalogueProductCreateRef.current?.open();
          });
        }
      }
    } else if (handleProductCreate) {
      // one-time used product
      productCreateRef.current?.open();
    } else if (
      handleSupplierCatalogueProductCreate &&
      canCreateSupplierCatalogueProduct
    ) {
      // supplier catalogue product
      setInitialSupplierCatalogue(null);
      setTimeout(() => {
        supplierCatalogueProductCreateRef.current?.open();
      });
    }
  };

  const ActionButtons =
    handleProductCreate ||
    (handleSupplierCatalogueProductCreate &&
      canCreateSupplierCatalogueProduct) ? (
      <ButtonCreate
        title={t("New product", { ns: "ProductSearch" })}
        onClick={handleCreateNewProduct}
      />
    ) : undefined;

  return (
    <>
      <ProductSearchListHeader
        {...props}
        children={children}
        allowedViews={ALLOWED_VIEWS}
        ActionButtons={ActionButtons}
        px={{ xs: 1, md: 1.5 }}
        mx={{ xs: -1, md: -1.5 }}
        pt={1}
        mt={-1}
      />
      {handleProductCreate && (
        <ModalOpenProcess
          ref={productCreateRef}
          Modal={ProductCreateModal}
          modalProps={{
            handleSubmit: async values => {
              await handleProductCreate(values);
            },
          }}
        />
      )}
      {handleSupplierCatalogueProductCreate &&
        canCreateSupplierCatalogueProduct && (
          <ModalOpenProcess
            ref={supplierCatalogueProductCreateRef}
            Modal={SupplierCatalogueProductCreateModal}
            modalProps={{
              initialSupplierCatalogue,
              handleComplete: handleSupplierCatalogueProductCreate,
            }}
          />
        )}
      <ProductAskTypeProcess ref={productAskTypeProcessRef} />
    </>
  );
}

function ProductsList({
  items,
  totalCount,
  loading,
  activeView,
  onSelectItem,
  paginationModel,
  setPaginationModel,
  productSearchVariables,
  AddToListIcon,
}: {
  items: ProductSearchItem__ProductSearchResultFragment[];
  totalCount: number;
  activeView: CollectionView;
  loading: boolean;
  onSelectItem(product: ProductSearchItem__ProductSearchResultFragment): void;
  paginationModel: { page: number; pageSize: number };
  setPaginationModel: (newPaginationModel: {
    page: number;
    pageSize: number;
  }) => void;
  // for tracking purposes only
  productSearchVariables?: ProductFilterFields_AvailableFiltersQueryVariables;
  AddToListIcon: React.ComponentType<SvgIconProps>;
}) {
  const stateStore = useDataGridStateStore("ProductSelectMultipleModal");
  const CardActionButton = React.useCallback(
    ({
      product,
    }: {
      product: ProductSearchItem__ProductSearchResultFragment;
    }) => (
      <>
        <ButtonCircleWrapper>
          <OrganisationSupplierProductFavouriteButton
            product={product}
            canEdit={true}
          />
        </ButtonCircleWrapper>
        <ButtonCircleWrapper>
          <ProductAddButton
            product={product}
            productSearchVariables={productSearchVariables}
            AddToListIcon={AddToListIcon}
          />
        </ButtonCircleWrapper>
      </>
    ),
    [AddToListIcon, productSearchVariables]
  );
  const TableCheckbox = React.useCallback(
    ({
      product,
    }: {
      product: ProductSearchItem__ProductSearchResultFragment;
    }) => (
      <ProductCheckbox
        product={product}
        productSearchVariables={productSearchVariables}
      />
    ),
    [productSearchVariables]
  );
  const TableActions = React.useCallback(
    ({
      product,
    }: {
      product: ProductSearchItem__ProductSearchResultFragment;
    }) => (
      <>
        <OrganisationSupplierProductFavouriteButton
          product={product}
          canEdit={true}
        />
        <ProductQuantityInput product={product} />
        <ProductAddButton
          product={product}
          productSearchVariables={productSearchVariables}
          AddToListIcon={AddToListIcon}
        />
      </>
    ),
    [AddToListIcon, productSearchVariables]
  );

  return (
    <ProductsListView
      items={items}
      totalCount={totalCount}
      activeView={activeView}
      loading={loading}
      paginationModel={paginationModel}
      setPaginationModel={setPaginationModel}
      onClick={onSelectItem}
      CardActionButton={CardActionButton}
      TableActions={TableActions}
      TableCheckbox={TableCheckbox}
      stateStore={stateStore}
      tableActionsWidth={160}
    />
  );
}

function ProductAddButton({
  product,
  size = "small",
  productSearchVariables,
  AddToListIcon,
}: {
  product: ProductSearchItem__ProductSearchResultFragment;
  size?: IconButtonProps["size"];
  // for tracking purposes only
  productSearchVariables?: ProductFilterFields_AvailableFiltersQueryVariables;
  AddToListIcon: React.ComponentType<SvgIconProps>;
}) {
  const formikProps = useFormikContext<FormValues>();
  const index = formikProps.values.products.findIndex(
    prd => getProductUniqueId(prd.product) === getProductUniqueId(product)
  );
  return (
    <IconButton
      onClick={e => {
        e.stopPropagation();
        track({
          eventName: "SelectProduct",
          data: { product, variables: productSearchVariables },
        });
        formikProps.setFieldValue("products", [
          ...formikProps.values.products,
          { quantity: 1, product },
        ]);
      }}
      color="primary"
      size={size}
      disabled={index >= 0}
    >
      <AddToListIcon fontSize="small" />
    </IconButton>
  );
}

function ProductQuantityInput({
  product,
}: {
  product: ProductSearchItem__ProductSearchResultFragment;
}) {
  const { t } = useTranslate(["ProductSearch"]);
  const formikProps = useFormikContext<FormValues>();
  const index = formikProps.values.products.findIndex(
    prd => getProductUniqueId(prd.product) === getProductUniqueId(product)
  );
  return (
    <FilledInput
      onClick={e => {
        e.stopPropagation();
      }}
      type="number"
      inputProps={{ type: "number", min: 0, step: 0.1 }}
      size="extra-small"
      placeholder={t("Quantity", { ns: "ProductSearch" })}
      value={index >= 0 ? formikProps.values.products[index].quantity : 0}
      onChange={e => {
        const newValue = parseFloat(e.target.value || "0");
        if (!Number.isNaN(newValue)) {
          if (newValue > 0 && index < 0) {
            // add
            formikProps.setFieldValue("products", [
              ...formikProps.values.products,
              { quantity: newValue, product },
            ]);
          } else if (newValue === 0 && index >= 0) {
            // remove
            formikProps.setFieldValue(
              "products",
              formikProps.values.products.filter((prd, i) => i !== index)
            );
          } else if (newValue >= 0) {
            // replace
            formikProps.setFieldValue(
              "products",
              formikProps.values.products.map((prd, i) =>
                i === index ? { ...prd, quantity: newValue } : prd
              )
            );
          }
        }
      }}
    />
  );
}

function ProductCheckbox({
  product,
  productSearchVariables,
}: {
  product: ProductSearchItem__ProductSearchResultFragment;
  // for tracking purposes only
  productSearchVariables?: ProductFilterFields_AvailableFiltersQueryVariables;
}) {
  const formikProps = useFormikContext<FormValues>();
  const index = formikProps.values.products.findIndex(
    prd => getProductUniqueId(prd.product) === getProductUniqueId(product)
  );
  const checked = index >= 0;
  return (
    <Checkbox
      checked={checked}
      onChange={() => {
        if (checked) {
          track({
            eventName: "UnselectProduct",
            data: { product },
          });
          // remove
          formikProps.setFieldValue(
            "products",
            formikProps.values.products.filter((prd, i) => i !== index)
          );
        } else {
          track({
            eventName: "SelectProduct",
            data: { product, variables: productSearchVariables },
          });
          // add
          formikProps.setFieldValue("products", [
            ...formikProps.values.products,
            { quantity: 1, product },
          ]);
        }
      }}
      onClick={e => {
        e.stopPropagation();
      }}
    />
  );
}
