import { useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import { Autocomplete, getFilterOptions } from "@msys/ui";
import { Stack } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import {
  ProjectSourcePageName,
  namedOperations,
} from "../../../clients/graphqlTypes";
import { RestrictedByOrganisationPermissionWithDebug } from "../../auth/RestrictedByOrganisationPermission";
import { useProjectSelectQuery } from "./ProjectSelect.generated";
import { CreateProjectOrTicketButton } from "./buttons/CreateProjectOrTicketButton";
import { CreateProjectType } from "./modals/CreateProjectModal";

const filterOptionsByTitle = getFilterOptions(["title"]);

export function ProjectSelect({
  crmCompanyId,
  projectId,
  onChange,
  inputLabel,
  placeholder,
  required,
  error,
  disabled,
  canCreateNew,
  pageName,
  onCreateRefetchQueries,
  onCreateAutoNavigate,
  initialCreateType,
  desiredProjectInitialStatus,
}: {
  crmCompanyId?: string;
  projectId: string | null;
  onChange: (projectId: string | null) => void;
  inputLabel: string;
  placeholder?: string;
  required?: boolean;
  error?: string;
  disabled?: boolean;
  canCreateNew?: boolean;
  pageName: ProjectSourcePageName;
  onCreateRefetchQueries?: string[];
  onCreateAutoNavigate?: boolean;
  initialCreateType?: CreateProjectType;
  desiredProjectInitialStatus?: "opportunity" | "contracted";
}) {
  const { t } = useTranslate(["Task"]);

  const client = useApolloClient();
  const query = useProjectSelectQuery({
    client,
    variables: {
      crmCompanyId,
    },
  });
  const projects =
    getDataOrNull(query.data?.projects)?.edges.map(edge => edge.node) ?? [];

  return (
    <Stack direction={"row"} spacing={1}>
      <Autocomplete
        inputLabel={inputLabel}
        placeholder={placeholder}
        required={required}
        options={projects}
        error={error}
        getOptionLabel={option => option.title}
        filterOptions={filterOptionsByTitle}
        renderOption={(props, option) => (
          <li
            {...props}
            key={option.id}
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            {option.title}
          </li>
        )}
        value={projects.find(project => project.id === projectId)}
        onChange={value => onChange(value?.id ?? null)}
        disabled={disabled || query.loading}
      />
      {canCreateNew && (
        <RestrictedByOrganisationPermissionWithDebug permission="CREATE_PROJECT">
          <CreateProjectOrTicketButton
            type="icon"
            crmOrganisationId={crmCompanyId}
            size="large"
            pageName={pageName}
            refetchQueries={[
              ...(onCreateRefetchQueries ?? []),
              namedOperations.Query.ProjectSelect,
            ]}
            initialCreateType={initialCreateType}
            desiredProjectInitialStatus={desiredProjectInitialStatus}
            autoNavigate={onCreateAutoNavigate}
            onCreate={projectId => {
              onChange(projectId);
            }}
          />
        </RestrictedByOrganisationPermissionWithDebug>
      )}
    </Stack>
  );
}
