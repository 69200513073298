/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TemplateQuoteTemplateTypesQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
  versionNumber: Types.Scalars['Int']['input'];
}>;


export type TemplateQuoteTemplateTypesQuery = { quoteTemplateVersion: { __typename: 'MissingCapabilities' } | { __typename: 'MissingPermissions' } | { __typename: 'QuoteTemplateVersionResult', quoteTemplate?: { __typename: 'QuoteTemplate', id: string, resolvedAsReadModelVersionNumber?: number | null, title: string } | null }, templateDeepUsedTemplateTypes: { __typename: 'TemplateDeepUsedTemplateTypesResult', templateTypes: Array<{ __typename: 'TemplateDeepUsedTemplateTypesResultTemplateType', implementedByTemplatesCount: number, templateType: { __typename: 'TemplateType', id: string, key: string, title: string, description: string } }> } };

export type TemplateQuoteTemplateTypes_TemplateDeepUsedTemplateTypesResultTemplateTypeFragment = { __typename: 'TemplateDeepUsedTemplateTypesResultTemplateType', implementedByTemplatesCount: number, templateType: { __typename: 'TemplateType', id: string, key: string, title: string, description: string } };

export type TemplateQuoteTemplateTypes_TemplateTypeFragment = { __typename: 'TemplateType', id: string, key: string, title: string, description: string };

export const TemplateQuoteTemplateTypes_TemplateTypeFragmentDoc = gql`
    fragment TemplateQuoteTemplateTypes_TemplateType on TemplateType {
  id
  key
  title
  description
}
    `;
export const TemplateQuoteTemplateTypes_TemplateDeepUsedTemplateTypesResultTemplateTypeFragmentDoc = gql`
    fragment TemplateQuoteTemplateTypes_TemplateDeepUsedTemplateTypesResultTemplateType on TemplateDeepUsedTemplateTypesResultTemplateType {
  implementedByTemplatesCount
  templateType {
    ...TemplateQuoteTemplateTypes_TemplateType
  }
}
    ${TemplateQuoteTemplateTypes_TemplateTypeFragmentDoc}`;
export const TemplateQuoteTemplateTypesDocument = gql`
    query TemplateQuoteTemplateTypes($id: ID!, $versionNumber: Int!) {
  quoteTemplateVersion(id: $id, versionNumber: $versionNumber) {
    ... on QuoteTemplateVersionResult {
      quoteTemplate {
        id
        resolvedAsReadModelVersionNumber
        title
      }
    }
  }
  templateDeepUsedTemplateTypes(templateId: $id, versionNumber: $versionNumber) {
    templateTypes {
      ...TemplateQuoteTemplateTypes_TemplateDeepUsedTemplateTypesResultTemplateType
    }
  }
}
    ${TemplateQuoteTemplateTypes_TemplateDeepUsedTemplateTypesResultTemplateTypeFragmentDoc}`;

/**
 * __useTemplateQuoteTemplateTypesQuery__
 *
 * To run a query within a React component, call `useTemplateQuoteTemplateTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTemplateQuoteTemplateTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTemplateQuoteTemplateTypesQuery({
 *   variables: {
 *      id: // value for 'id'
 *      versionNumber: // value for 'versionNumber'
 *   },
 * });
 */
export function useTemplateQuoteTemplateTypesQuery(baseOptions: Apollo.QueryHookOptions<TemplateQuoteTemplateTypesQuery, TemplateQuoteTemplateTypesQueryVariables> & ({ variables: TemplateQuoteTemplateTypesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TemplateQuoteTemplateTypesQuery, TemplateQuoteTemplateTypesQueryVariables>(TemplateQuoteTemplateTypesDocument, options);
      }
export function useTemplateQuoteTemplateTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TemplateQuoteTemplateTypesQuery, TemplateQuoteTemplateTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TemplateQuoteTemplateTypesQuery, TemplateQuoteTemplateTypesQueryVariables>(TemplateQuoteTemplateTypesDocument, options);
        }
export function useTemplateQuoteTemplateTypesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<TemplateQuoteTemplateTypesQuery, TemplateQuoteTemplateTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TemplateQuoteTemplateTypesQuery, TemplateQuoteTemplateTypesQueryVariables>(TemplateQuoteTemplateTypesDocument, options);
        }
export type TemplateQuoteTemplateTypesQueryHookResult = ReturnType<typeof useTemplateQuoteTemplateTypesQuery>;
export type TemplateQuoteTemplateTypesLazyQueryHookResult = ReturnType<typeof useTemplateQuoteTemplateTypesLazyQuery>;
export type TemplateQuoteTemplateTypesSuspenseQueryHookResult = ReturnType<typeof useTemplateQuoteTemplateTypesSuspenseQuery>;
export type TemplateQuoteTemplateTypesQueryResult = Apollo.QueryResult<TemplateQuoteTemplateTypesQuery, TemplateQuoteTemplateTypesQueryVariables>;