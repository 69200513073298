import { FormattedPrice, FormattedTime } from "@msys/ui";
import React from "react";
import {
  Agreement,
  AskWhom,
  ContractType,
  PermissionName,
} from "../../../clients/graphqlTypes";
import { RestrictedByDocumentPermissionWithDebug } from "../../auth/RestrictedByDocumentPermission";
import { ConfirmModalProps } from "../../commons/modals/ConfirmProcess";
import { QUOTE_ITEM_TYPES } from "../../constants";
import { isItemVisibleToOtherSide } from "../../trees-virtual/helpers";
import { TreeViewMode, VirtualItem } from "../../trees-virtual/types";
import { BareTreeItem } from "../../trees/BareTreeItem";
import { BareTreeTableItem } from "../../trees/BareTreeTableItem";
import { TreeDataContainer } from "../../trees/components/TreeDataContainer";
import { TreeRightIcons } from "../../trees/components/TreeRightIcons";
import { InputComponentProps, ItemComponentProps } from "../../trees/types";
import { assertNever } from "../../utils";
import { CreateItemInput } from "../doc-items/CreateItemInput";
import { itemHasEstimatedCalculationBox } from "../doc-items/app-conditions/boxes";
import { DecisionModalButton } from "../doc-items/buttons/DecisionModalButton";
import { TemplatePlaceholderSelectTemplateButton } from "../doc-items/buttons/TemplatePlaceholderSelectTemplateButton";
import { DecisionContingencyCheckbox } from "../doc-items/fields/DecisionContingencyCheckbox";
import { DecisionOptionRadioOrCheckbox } from "../doc-items/fields/DecisionOptionRadioOrCheckbox";
import { hasAnyQuestions } from "../doc-items/helpers";
import { DocItemContextMenuItems } from "../doc-items/menus/DocItemContextMenuItems";
import {
  EstimatedCalculationEmptyRow,
  EstimatedCalculationRow,
} from "../doc-items/tables/EstimatedCalculationRow";
import {
  QuoteTreeItem_Data123Fragment,
  QuoteTreeItem_TreeClientItemFragment,
  QuoteTreeItem_TreeItemFragment,
  QuoteTreeItem_TreeTableItemFragment,
} from "./quote-trees.generated";

export function createTreeItemInput<
  Item extends VirtualItem,
  ChildItem extends VirtualItem,
>({
  viewMode,
  quoteId,
  createItem,
  createItemByType,
  createItemFromTemplate,
  createItemsFromTemplates,
  createItemsWithProducts,
  pasteItem,
  isAllowedToPasteItem,
}: {
  viewMode: TreeViewMode;
  quoteId: string;
} & Pick<
  React.ComponentProps<typeof CreateItemInput>,
  | "createItem"
  | "createItemByType"
  | "createItemFromTemplate"
  | "createItemsFromTemplates"
  | "createItemsWithProducts"
  | "pasteItem"
  | "isAllowedToPasteItem"
>) {
  return (itemProps: InputComponentProps<Item, ChildItem>) => (
    <CreateItemInput
      viewMode={viewMode}
      doc={{ id: quoteId, docType: "QUOTE", templateIsDraft: false }}
      documentItemTypes={QUOTE_ITEM_TYPES}
      createItem={createItem}
      createItemByType={createItemByType}
      createItemFromTemplate={createItemFromTemplate}
      createItemsFromTemplates={createItemsFromTemplates}
      createItemsWithProducts={createItemsWithProducts}
      pasteItem={pasteItem}
      isAllowedToPasteItem={isAllowedToPasteItem}
      {...itemProps}
    />
  );
}

export const createTreeItem =
  <T extends QuoteTreeItem_TreeItemFragment>({
    pathToDocPage,
    projectId,
    doc,
    quoteId,
    docAgreement,
    startConfirmProcess,
    navigateToItem,
    expandedItemIds,
    setItemExpanded,
    pasteItem,
    isAllowedToPasteItem,
    viewerDecisionRole,
  }: {
    pathToDocPage: string;
    projectId: string;
    doc: {
      viewerPermissions: PermissionName[];
    };
    quoteId: string;
    docAgreement: Agreement;
    startConfirmProcess: (
      props: ConfirmModalProps
    ) => Promise<boolean | undefined>;
    navigateToItem: (id: string) => void;
    expandedItemIds: string[] | undefined;
    setItemExpanded: (id: string, expanded: boolean) => void;
    pasteItem: ((docId: string, parentItemId: string) => Promise<void>) | null;
    isAllowedToPasteItem: (parentItem: T) => boolean;
    viewerDecisionRole: AskWhom | undefined;
  }) =>
  (itemProps: ItemComponentProps<T>) => {
    const [Data123Left, Data123Right] = React.useMemo(
      () => getData123(itemProps.item, doc),
      [itemProps.item]
    );

    const IconRightButtons = React.useMemo(
      () => (
        <>
          {itemProps.item.placeholderForTemplateType && (
            <TemplatePlaceholderSelectTemplateButton
              projectId={projectId}
              docId={quoteId}
              itemId={itemProps.item.id}
              templateTypeName={
                itemProps.item.placeholderForTemplateType.templateType.title
              }
              templateSearchFilterDefaults={
                itemProps.item.templateSearchFilterDefaults
              }
              templateSearchSortingDefaults={
                itemProps.item.templateSearchSortingDefaults
              }
              templateApplicableFor="QUOTE"
              canFinalize={true}
              setItemExpanded={setItemExpanded}
              expandedItemIds={expandedItemIds}
              type="icon"
              color={"orange"}
            />
          )}
          {hasAnyQuestions(itemProps.item, "QUOTE", viewerDecisionRole) && (
            <DecisionModalButton
              projectId={projectId}
              docType="QUOTE"
              docId={quoteId}
              itemId={itemProps.item.id}
              canFinalize={true}
              viewerDecisionRole={viewerDecisionRole}
              expandedItemIds={expandedItemIds}
              type="icon"
            />
          )}
          {itemProps.item.decisionIsContingentItem &&
            !itemProps.item.isItemEliminated && (
              <DecisionContingencyCheckbox
                projectId={projectId}
                docId={quoteId}
                itemId={itemProps.item.id}
                item={itemProps.item}
                disabled={itemProps.item.deletedAt !== null}
              />
            )}
          {itemProps.parentItem?.type === "decision" &&
            !itemProps.item.decisionOptionElimination &&
            !itemProps.item.isItemEliminated && (
              <DecisionOptionRadioOrCheckbox
                projectId={projectId}
                docId={quoteId}
                item={itemProps.item}
                disabled={itemProps.item.deletedAt !== null}
                expandedItemIds={expandedItemIds}
              />
            )}
        </>
      ),
      [itemProps.item, itemProps.parentItem]
    );

    const ContextMenu = React.useMemo(
      () => (
        <DocItemContextMenuItems
          pathToDocPage={pathToDocPage}
          docId={quoteId}
          docType="QUOTE"
          projectId={projectId}
          item={itemProps.item}
          startConfirmProcess={startConfirmProcess}
          expandedItemIds={expandedItemIds}
          pasteItem={isAllowedToPasteItem(itemProps.item) ? pasteItem : null}
        />
      ),
      [itemProps.item]
    );

    const RightIcons = React.useMemo(
      () => (
        <TreeRightIcons
          document={doc}
          item={itemProps.item}
          isVisibleToOtherSide={isItemVisibleToOtherSide(
            itemProps.item,
            itemProps.allItems
          )}
        />
      ),
      [itemProps.item, itemProps.allItems]
    );

    return (
      <BareTreeItem
        docAgreement={docAgreement}
        Data123Left={Data123Left}
        Data123Right={Data123Right}
        IconRightButtons={IconRightButtons}
        ContextMenu={ContextMenu}
        RightIcons={RightIcons}
        showPath={itemProps.parentItem?.type !== "decision"}
        item={itemProps.item}
        title={
          itemProps.item.pendingChangeAttributes?.title ?? itemProps.item.title
        }
        pathForPdf={
          itemProps.item.pendingChangeAttributes?.pathForPdf ??
          itemProps.item.pathForPdf
        }
        isRootItem={itemProps.isRootItem}
        depth={itemProps.depth}
        isGreyedOut={itemProps.isGreyedOut}
        isHidden={itemProps.isHidden}
        isPriceHidden={itemProps.isPriceHidden}
        subcontractPath={itemProps.subcontractPath}
        collapseButton={itemProps.collapseButton}
        onClick={() => {
          setItemExpanded(itemProps.item.id, true);
        }}
        to={`${pathToDocPage}/items/${itemProps.item.id}`}
      />
    );
  };

export const createTreeClientItem =
  <T extends QuoteTreeItem_TreeClientItemFragment>({
    pathToDocPage,
    projectId,
    doc,
    docAgreement,
    quoteId,
    expandedItemIds,
    viewerDecisionRole,
  }: {
    pathToDocPage: string;
    projectId: string;
    doc: {
      viewerPermissions: PermissionName[];
    };
    quoteId: string;
    docAgreement: Agreement;
    expandedItemIds: string[] | undefined;
    viewerDecisionRole: AskWhom | undefined;
  }) =>
  (itemProps: ItemComponentProps<T>) => {
    const [Data123Left, Data123Right] = React.useMemo(
      () => getData123(itemProps.item, doc),
      [itemProps.item]
    );

    const IconRightButtons = React.useMemo(
      () => (
        <>
          {hasAnyQuestions(itemProps.item, "QUOTE", viewerDecisionRole) && (
            <DecisionModalButton
              projectId={projectId}
              docType="QUOTE"
              docId={quoteId}
              itemId={itemProps.item.id}
              canFinalize={true}
              viewerDecisionRole={viewerDecisionRole}
              expandedItemIds={expandedItemIds}
              type="icon"
              isOptionHidden={item => item.isHidden}
            />
          )}
          {itemProps.item.decisionIsContingentItem &&
            !itemProps.item.isItemEliminated && (
              <DecisionContingencyCheckbox
                projectId={projectId}
                docId={quoteId}
                itemId={itemProps.item.id}
                item={itemProps.item}
              />
            )}
          {itemProps.parentItem?.type === "decision" &&
            !itemProps.item.decisionOptionElimination &&
            !itemProps.item.isItemEliminated && (
              <DecisionOptionRadioOrCheckbox
                projectId={projectId}
                docId={quoteId}
                item={itemProps.item}
                expandedItemIds={expandedItemIds}
              />
            )}
        </>
      ),
      [itemProps.item, itemProps.parentItem]
    );

    const RightIcons = React.useMemo(
      () => (
        <TreeRightIcons
          document={doc}
          item={itemProps.item}
          isVisibleToOtherSide={isItemVisibleToOtherSide(
            itemProps.item,
            itemProps.allItems
          )}
        />
      ),
      [itemProps.item, itemProps.allItems]
    );

    return (
      <BareTreeItem
        docAgreement={docAgreement}
        Data123Left={Data123Left}
        Data123Right={Data123Right}
        IconRightButtons={IconRightButtons}
        RightIcons={RightIcons}
        showPath={itemProps.parentItem?.type !== "decision"}
        item={itemProps.item}
        title={
          itemProps.item.pendingChangeAttributes?.title ?? itemProps.item.title
        }
        pathForPdf={
          itemProps.item.pendingChangeAttributes?.pathForPdf ??
          itemProps.item.pathForPdf
        }
        isRootItem={itemProps.isRootItem}
        depth={itemProps.depth}
        isGreyedOut={itemProps.isGreyedOut}
        isHidden={itemProps.isHidden}
        isPriceHidden={itemProps.isPriceHidden}
        subcontractPath={itemProps.subcontractPath}
        collapseButton={itemProps.collapseButton}
      />
    );
  };

export const createTreeTableItem =
  <T extends QuoteTreeItem_TreeTableItemFragment>({
    pathToDocPage,
    projectId,
    doc,
    quoteId,
    docAgreement,
    startConfirmProcess,
    navigateToItem,
    expandedItemIds,
    setItemExpanded,
    pasteItem,
    viewerDecisionRole,
  }: {
    pathToDocPage: string;
    projectId: string;
    doc: {
      contractType: ContractType;
      viewerPermissions: PermissionName[];
    };
    quoteId: string;
    docAgreement: Agreement;
    startConfirmProcess: (
      props: ConfirmModalProps
    ) => Promise<boolean | undefined>;
    navigateToItem: (id: string) => void;
    expandedItemIds: string[] | undefined;
    setItemExpanded: (id: string, expanded: boolean) => void;
    pasteItem: ((docId: string, parentItemId: string) => Promise<void>) | null;
    isAllowedToPasteItem: (parentItem: T) => boolean;
    viewerDecisionRole: AskWhom | undefined;
  }) =>
  (itemProps: ItemComponentProps<T>) => {
    const [Data123Left, Data123Right] = React.useMemo(
      () => getData123(itemProps.item, doc),
      [itemProps.item]
    );

    const IconRightButtons = React.useMemo(
      () => (
        <>
          {hasAnyQuestions(itemProps.item, "QUOTE", viewerDecisionRole) && (
            <DecisionModalButton
              projectId={projectId}
              docType="QUOTE"
              docId={quoteId}
              itemId={itemProps.item.id}
              canFinalize={true}
              viewerDecisionRole={viewerDecisionRole}
              expandedItemIds={expandedItemIds}
              type="icon"
            />
          )}
          {itemProps.item.decisionIsContingentItem &&
            !itemProps.item.isItemEliminated && (
              <DecisionContingencyCheckbox
                projectId={projectId}
                docId={quoteId}
                itemId={itemProps.item.id}
                item={itemProps.item}
              />
            )}
          {itemProps.parentItem?.type === "decision" &&
            !itemProps.item.decisionOptionElimination &&
            !itemProps.item.isItemEliminated && (
              <DecisionOptionRadioOrCheckbox
                projectId={projectId}
                docId={quoteId}
                item={itemProps.item}
                expandedItemIds={expandedItemIds}
              />
            )}
        </>
      ),
      [itemProps.item, itemProps.parentItem]
    );

    const ContextMenu = React.useMemo(
      () => (
        <DocItemContextMenuItems
          pathToDocPage={pathToDocPage}
          docId={quoteId}
          docType="QUOTE"
          projectId={projectId}
          item={itemProps.item}
          startConfirmProcess={startConfirmProcess}
          expandedItemIds={expandedItemIds}
          pasteItem={pasteItem}
        />
      ),
      [itemProps.item]
    );

    const RightIcons = React.useMemo(
      () => (
        <TreeRightIcons
          document={doc}
          item={itemProps.item}
          isVisibleToOtherSide={isItemVisibleToOtherSide(
            itemProps.item,
            itemProps.allItems
          )}
        />
      ),
      [itemProps.item, itemProps.allItems]
    );

    const FormRow = React.useMemo(() => {
      const hasEstimatedCalculationBox = itemHasEstimatedCalculationBox(
        itemProps.item
      );
      return hasEstimatedCalculationBox ? (
        <EstimatedCalculationRow
          itemId={itemProps.item.id}
          projectId={projectId}
          docId={quoteId}
          item={itemProps.item}
          contractType={doc.contractType}
        />
      ) : (
        <EstimatedCalculationEmptyRow />
      );
    }, [itemProps.item]);

    return (
      <BareTreeTableItem
        docAgreement={docAgreement}
        Data123Left={Data123Left}
        Data123Right={Data123Right}
        FormRow={FormRow}
        IconRightButtons={IconRightButtons}
        ContextMenu={ContextMenu}
        RightIcons={RightIcons}
        showPath={itemProps.parentItem?.type !== "decision"}
        item={itemProps.item}
        title={
          itemProps.item.pendingChangeAttributes?.title ?? itemProps.item.title
        }
        pathForPdf={
          itemProps.item.pendingChangeAttributes?.pathForPdf ??
          itemProps.item.pathForPdf
        }
        isRootItem={itemProps.isRootItem}
        depth={itemProps.depth}
        isGreyedOut={itemProps.isGreyedOut}
        isHidden={itemProps.isHidden}
        isPriceHidden={itemProps.isPriceHidden}
        subcontractPath={itemProps.subcontractPath}
        collapseButton={itemProps.collapseButton}
        onClick={() => {
          navigateToItem(itemProps.item.id);
          setItemExpanded(itemProps.item.id, true);
        }}
      />
    );
  };

export const getData123 = (
  item: QuoteTreeItem_Data123Fragment,
  doc: {
    viewerPermissions: PermissionName[];
  }
): [JSX.Element | null, JSX.Element | null] => {
  switch (item.type) {
    case "paid": {
      // TODO this is causing issues for deleted items; for now just show "null" as value instead of breaking
      const calculateForQuote = item.proposedCalculation;
      if (!calculateForQuote) return [null, null];

      return [
        <TreeDataContainer key={"left"}>
          <div>{calculateForQuote.quantity}x</div>
          {calculateForQuote.timePerUnit > 0 && (
            <RestrictedByDocumentPermissionWithDebug
              permission="SEE_QUOTES_CALCULATION"
              document={doc}
            >
              {
                <div>
                  <FormattedTime value={calculateForQuote.timePerUnit} />
                </div>
              }
            </RestrictedByDocumentPermissionWithDebug>
          )}
          {calculateForQuote.quantity !== 1 && (
            <RestrictedByDocumentPermissionWithDebug
              permission="READ_QUOTES"
              document={doc}
            >
              <div>
                <FormattedPrice value={calculateForQuote.pricePerUnit} />
              </div>
            </RestrictedByDocumentPermissionWithDebug>
          )}
          <RestrictedByDocumentPermissionWithDebug
            permission="READ_QUOTES"
            document={doc}
          >
            <div style={{ fontWeight: "bold" }}>
              <FormattedPrice value={calculateForQuote.priceSubTotal} />
            </div>
          </RestrictedByDocumentPermissionWithDebug>
        </TreeDataContainer>,
        null,
      ];
    }
    case "section": {
      const calculateForQuote = item.proposedCalculation;

      if (!calculateForQuote) return [null, null];

      if (
        calculateForQuote.timePerUnit === 0 &&
        calculateForQuote.pricePerUnit === 0 &&
        calculateForQuote.quantity === 1
      ) {
        return [null, null];
      }
      return [
        null,
        <TreeDataContainer key={"right"}>
          {calculateForQuote.quantity !== 1 && (
            <>
              <div>{calculateForQuote.quantity}x</div>
              {calculateForQuote.timePerUnit !== 0 && (
                <RestrictedByDocumentPermissionWithDebug
                  permission="SEE_QUOTES_CALCULATION"
                  document={doc}
                >
                  {
                    <div>
                      <FormattedTime value={calculateForQuote.timePerUnit} />
                    </div>
                  }
                </RestrictedByDocumentPermissionWithDebug>
              )}
              {calculateForQuote.pricePerUnit !== 0 && (
                <RestrictedByDocumentPermissionWithDebug
                  permission="READ_QUOTES"
                  document={doc}
                >
                  <div>
                    <FormattedPrice value={calculateForQuote.pricePerUnit} />
                  </div>
                </RestrictedByDocumentPermissionWithDebug>
              )}
            </>
          )}
          <RestrictedByDocumentPermissionWithDebug
            permission="READ_QUOTES"
            document={doc}
          >
            {calculateForQuote.timeTotal !== 0 && (
              <div style={{ fontWeight: "bold" }}>
                <FormattedTime value={calculateForQuote.timeTotal} />
              </div>
            )}
            {calculateForQuote.priceSubTotal !== 0 && (
              <div style={{ fontWeight: "bold" }}>
                <FormattedPrice value={calculateForQuote.priceSubTotal} />
              </div>
            )}
          </RestrictedByDocumentPermissionWithDebug>
        </TreeDataContainer>,
      ];
    }
    case "decision": {
      const calculateForQuote = item.proposedCalculation;

      if (!calculateForQuote) return [null, null];

      if (
        calculateForQuote.timePerUnit === 0 &&
        calculateForQuote.pricePerUnit === 0 &&
        calculateForQuote.quantity === 1
      ) {
        return [null, null];
      }
      return [
        null,
        <TreeDataContainer key={"right"}>
          {calculateForQuote.quantity !== 1 && (
            <>
              <div>{calculateForQuote.quantity}x</div>
              {calculateForQuote.timePerUnit !== 0 && (
                <RestrictedByDocumentPermissionWithDebug
                  permission="SEE_QUOTES_CALCULATION"
                  document={doc}
                >
                  {
                    <div>
                      <FormattedTime value={calculateForQuote.timePerUnit} />
                    </div>
                  }
                </RestrictedByDocumentPermissionWithDebug>
              )}
              {calculateForQuote.pricePerUnit !== 0 && (
                <RestrictedByDocumentPermissionWithDebug
                  permission="READ_QUOTES"
                  document={doc}
                >
                  <div>
                    <FormattedPrice value={calculateForQuote.pricePerUnit} />
                  </div>
                </RestrictedByDocumentPermissionWithDebug>
              )}
            </>
          )}
          <RestrictedByDocumentPermissionWithDebug
            permission="READ_QUOTES"
            document={doc}
          >
            {calculateForQuote.timeTotal !== 0 && (
              <div style={{ fontWeight: "bold" }}>
                <FormattedTime
                  value={calculateForQuote.timeTotal}
                  notSetText=""
                />
              </div>
            )}
            {calculateForQuote.priceSubTotal !== 0 && (
              <div style={{ fontWeight: "bold" }}>
                <FormattedPrice value={calculateForQuote.priceSubTotal} />
              </div>
            )}
          </RestrictedByDocumentPermissionWithDebug>
        </TreeDataContainer>,
      ];
    }
    case "unpaid":
    case "defect": {
      return [null, null];
    }
    default:
      throw assertNever(item.type);
  }
};
