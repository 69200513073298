import { useTranslate } from "@tolgee/react";
import React from "react";
import { QuotingStatus } from "../../../clients/graphqlTypes";

export const useQuotingStatus = () => {
  const { t } = useTranslate(["Global"]);
  const quotingStatusLabels = React.useMemo(
    (): Record<QuotingStatus, string> => ({
      none: t("None", { ns: "Global" }),
      non_binding: t("Non-binding", { ns: "Global" }),
      binding: t("Binding", { ns: "Global" }),
    }),
    [t]
  );

  return { quotingStatusLabels };
};
