import { InfoLabelMessage } from "@msys/ui";
import { Stack, TextField } from "@mui/material";
import { GoogleMap, Marker } from "@react-google-maps/api";
import { useTranslate } from "@tolgee/react";
import React from "react";
import { AddressInput as AddressInputGQLType } from "../../../clients/graphqlTypes";
import { useGoogleMapsApi } from "../../../common/google-maps/useGoogleMapsApi";
import { AddressSearch } from "./AddressSearch";

const defaultZoom = 15;

// Hackescher Markt
const defaultCenter = {
  lat: 52.523502,
  lng: 13.401785,
};

export interface AddressInputProps
  extends Omit<React.ComponentProps<typeof AddressSearch>, "value"> {
  value: AddressInputGQLType | null;
  allowCustom?: boolean;
}

export const AddressInput = ({
  value,
  onChange,
  allowCustom = false,
  ...props
}: AddressInputProps) => {
  const { isLoaded } = useGoogleMapsApi();
  const { t } = useTranslate("Address");

  const handleChange = (newValue: AddressInputGQLType | null) => {
    onChange(newValue);
  };

  const [center, setCenter] = React.useState<{ lat: number; lng: number }>(
    defaultCenter
  );
  const lastCenter = React.useRef<{ lat: number; lng: number }>(defaultCenter);

  React.useEffect(() => {
    if (value && value.location) {
      if (
        lastCenter.current.lat !== value.location.lat ||
        lastCenter.current.lng !== value.location.lng
      ) {
        const newValue = { lat: value.location.lat, lng: value.location.lng };
        lastCenter.current = newValue;
        setCenter(newValue);
      }
    }
  }, [value]);

  return (
    <Stack direction="column" spacing={1}>
      <AddressSearch {...props} value={value} onChange={onChange} />
      {value && (
        <>
          <TextField
            label={t("Street and house number")}
            value={value.streetLines1}
            onChange={event =>
              handleChange({ ...value, streetLines1: event.target.value })
            }
            disabled={!allowCustom}
          />
          <Stack direction="row" spacing={1}>
            <TextField
              label={t("Post code")}
              value={value.postalCode}
              onChange={event =>
                handleChange({ ...value, postalCode: event.target.value })
              }
              disabled={!allowCustom}
            />
            <TextField
              label={t("City/town")}
              value={value.city}
              onChange={event =>
                handleChange({ ...value, city: event.target.value })
              }
              disabled={!allowCustom}
            />
          </Stack>
          {/* <TextField
            label={t("Note (eg. doorbell, additional directions)")}
            value={note}
            // onChange={(event) => handleNoteChange({ note: event.target.value })}
          /> */}
          {isLoaded && allowCustom && (
            <>
              {/*@ts-ignore property 'children' does not exist*/}
              <GoogleMap
                options={{ mapTypeControl: false, streetViewControl: false }}
                mapTypeId={google.maps.MapTypeId.ROADMAP}
                mapContainerStyle={{ width: "100%", height: 200 }}
                zoom={defaultZoom}
                center={center}
              >
                <Marker
                  visible
                  draggable
                  position={
                    value && value.location
                      ? { lat: value.location.lat, lng: value.location.lng }
                      : defaultCenter
                  }
                  zIndex={1}
                  onDragEnd={e => {
                    const newValue = {
                      lat: e.latLng.lat(),
                      lng: e.latLng.lng(),
                    };
                    lastCenter.current = newValue;
                    handleChange({
                      ...value,
                      location: newValue,
                    });
                  }}
                />
              </GoogleMap>
              <InfoLabelMessage
                message={t("Drag marker to change address position on a map")}
              />
            </>
          )}
        </>
      )}
    </Stack>
  );
};
