import { MenuButton, MenuItemWithIcon, useScreenWidth } from "@msys/ui";
import TableChartIcon from "@mui/icons-material/TableChart";
import ViewCarouselIcon from "@mui/icons-material/ViewCarousel";
import ViewKanbanIcon from "@mui/icons-material/ViewKanban";
import ViewListIcon from "@mui/icons-material/ViewList";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import { Box, ToggleButton, ToggleButtonGroup, Tooltip } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import React from "react";
import { CollectionView } from "../hooks/useCollectionView";
import { ButtonIconWithArrow } from "./Button";

interface Props<View extends CollectionView = CollectionView> {
  allowedViews: View[];
  activeView: View | undefined;
  setActiveView: (view: View) => void;
  inline?: boolean;
}

export const SwitchCollectionViewButton = <
  View extends CollectionView = CollectionView,
>({
  allowedViews,
  activeView: passedActiveView,
  setActiveView,
  inline,
}: Props<View>) => {
  const { t } = useTranslate(["Templates", "Global"]);
  const { isMinTablet } = useScreenWidth();

  const activeView = passedActiveView ?? allowedViews[0];

  const VIEWS: Record<
    CollectionView,
    { icon: React.ComponentType; label: string }
  > = {
    kanban: {
      icon: ViewKanbanIcon,
      label: t("Pipeline view", { ns: "Global" }),
    },
    table: {
      icon: TableChartIcon,
      label: t("Table view", { ns: "Templates" }),
    },
    list: {
      icon: ViewListIcon,
      label: t("List view", { ns: "Templates" }),
    },
    gallery: {
      icon: ViewModuleIcon,
      label: t("Gallery view", { ns: "Templates" }),
    },
    swipe: {
      icon: ViewCarouselIcon,
      label: t("Swipe view", { ns: "Templates" }),
    },
  };
  const CurrentIcon = VIEWS[activeView].icon;

  const [open, setOpen] = React.useState<boolean>(false);

  if (inline ?? isMinTablet) {
    return (
      <Box display="flex" alignSelf="flex-start">
        <ToggleButtonGroup
          size="extra-small"
          exclusive
          value={activeView}
          onChange={(
            event: React.MouseEvent<HTMLElement>,
            newView: string | null
          ) => {
            if (newView === null) return;

            setActiveView(newView as View);
          }}
        >
          {Object.entries(VIEWS)
            .filter(
              ([key]) => !allowedViews || allowedViews.includes(key as View)
            )
            .map(([key, { icon: Icon, label }]) => (
              <ToggleButton key={key} value={key}>
                <Tooltip title={label}>
                  <Icon />
                </Tooltip>
              </ToggleButton>
            ))}
        </ToggleButtonGroup>
      </Box>
    );
  }

  return (
    <MenuButton
      onToggle={setOpen}
      Button={<ButtonIconWithArrow Icon={<CurrentIcon />} open={open} />}
    >
      {Object.entries(VIEWS)
        .filter(([key]) => !allowedViews || allowedViews.includes(key as View))
        .map(([key, { icon: Icon, label }]) => (
          <MenuItemWithIcon
            icon={<Icon />}
            key={key}
            color="primary"
            onClick={() => setActiveView(key as View)}
            component="div"
            selected={key === activeView}
          >
            {label}
          </MenuItemWithIcon>
        ))}
    </MenuButton>
  );
};
