import { CardItem, ModalOpenButton } from "@msys/ui";
import DeleteIcon from "@mui/icons-material/Delete";
import { Box, Button, Stack, Tooltip } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { Form, Formik } from "formik";
import React from "react";
import * as Yup from "yup";
import { ColorPickerField } from "../../commons/form-fields/ColorPickerField";
import { ManualSave } from "../../commons/form-fields/ManualSave";
import { TextField } from "../../commons/form-fields/TextField";
import { ConfirmModal } from "../../commons/modals/ConfirmModal";
import { ProjectPhaseFragment } from "./ProjectPhasesConfigurator.generated";

interface FormValues {
  phaseName: string;
  phaseColor: string;
}

export function PhaseConfigCard({
  phase,
  handleChange,
  isUsed,
  isOnlyPhase,
  handleDelete,
  isNameAvailable,
}: {
  phase: ProjectPhaseFragment;
  handleChange: (phaseId: string, values: FormValues) => Promise<void>;
  isUsed: boolean;
  isOnlyPhase: boolean;
  handleDelete: () => Promise<void>;
  isNameAvailable: (phaseName: string) => boolean;
}) {
  const { t } = useTranslate(["Projects", "OrganisationBranding", "Global"]);

  const initialValues: FormValues = {
    phaseName: phase.name,
    phaseColor: phase.color,
  };
  const validationSchema = Yup.object().shape({
    phaseName: Yup.string()
      .label(t("Phase name", { ns: "Projects" }))
      .trim()
      .required(t("Phase name can't be empty", { ns: "Projects" }))
      .test(
        "alreadyUsed",
        t("This name is already used", { ns: "Projects" }),
        (name: string | undefined | null) => {
          return Boolean(name && isNameAvailable(name));
        }
      ),
    phaseColor: Yup.string()
      .required()
      .matches(
        /^#[a-f\d]{6}$/i,
        t("Wrong color format, only HEX is allowed", {
          ns: "OrganisationBranding",
        })
      )
      .label(t("Phase color", { ns: "Projects" })),
  });
  const handleSubmit = async (values: FormValues) => {
    await handleChange(phase.id, values);
  };

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {formikProps => (
        <CardItem sx={theme => ({ flex: 1 })}>
          <Form>
            <Stack direction={"column"} spacing={1} p={1}>
              <TextField
                name={"phaseName"}
                label={t("Phase name", { ns: "Projects" })}
                required
              />
              <ColorPickerField
                label={t("Phase color", { ns: "Projects" })}
                name="phaseColor"
                required
              />
              <Tooltip
                title={
                  isOnlyPhase
                    ? t("At least one phase is needed", { ns: "Projects" })
                    : isUsed
                      ? t(
                          "Move all opportunities out of the column in order to delete it", // FIXME: this use used also for projects
                          { ns: "Projects" }
                        )
                      : ""
                }
              >
                <Box display="flex">
                  <ModalOpenButton
                    Modal={ConfirmModal}
                    modalProps={{
                      title: t("Are you sure you want to delete this phase?", {
                        ns: "Projects",
                      }),
                      handleConfirm: handleDelete,
                    }}
                  >
                    <Button
                      color="secondary"
                      size="small"
                      disabled={isUsed || isOnlyPhase}
                      startIcon={<DeleteIcon />}
                    >
                      {t("Delete phase", { ns: "Projects" })}
                    </Button>
                  </ModalOpenButton>
                </Box>
              </Tooltip>
              <ManualSave
                onCancel={formikProps.handleReset}
                disabled={formikProps.isSubmitting}
                disabledCancel={!formikProps.dirty}
                saveButtonTitle={t("Save", { ns: "Global" })}
              />
            </Stack>
          </Form>
        </CardItem>
      )}
    </Formik>
  );
}
