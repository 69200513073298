import { Box, Stack } from "@mui/material";
import React from "react";
import { cssValue } from "../../common/MuiThemeProvider";
import { ReactComponent as SelectedIndicator } from "../assets/selected-indicator.svg";

interface Props {
  selected: boolean;
}

export const SelectedBoxWithIndicator = ({
  selected,
  children,
}: React.PropsWithChildren<Props>) => {
  return selected ? (
    <Stack direction="row" spacing={0} minWidth={0} alignItems="center">
      <Box
        flex={1}
        p={0.5}
        boxShadow={cssValue.selectedBorderShadow}
        borderRadius={cssValue.boxBorderRadius}
      >
        {children}
      </Box>
      <SelectedIndicator />
    </Stack>
  ) : (
    <>{children}</>
  );
};
