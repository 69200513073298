import { useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import {
  DataGrid,
  DebouncedSearchInput,
  ErrorMessage,
  GridColDef,
  ListHeader,
  LoadingSpinner as LoadingIndicator,
  MediaCardItem,
  MediaListItem,
  Modal,
  ModalActionButtonProps,
  useRunWithFlag,
  useScreenWidth,
} from "@msys/ui";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Stack,
  TablePagination,
} from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { isUndefined } from "lodash";
import React from "react";
import { useLatest } from "react-use";
import { ButtonCircleWrapper } from "../../commons/button/ButtonCircleWrapper";
import { SwitchCollectionViewButton } from "../../commons/button/SwitchCollectionViewButton";
import { useCardsNumber } from "../../commons/hooks/useCardsNumber";
import {
  CollectionView,
  useCollectionViewWithMobile,
} from "../../commons/hooks/useCollectionView";
import { PaginationModel } from "../../commons/pagination/usePaginationParams";
import {
  PAGE_LIST_RESULTS_PER_PAGE,
  RESULTS_PER_PAGE_OPTIONS,
} from "../../constants";
import { TemplateTypeOverviewModal } from "./TemplateTypeOverviewModal";
import {
  TemplateTypesSearchModal_TemplateTypeFragment,
  useTemplateTypesSearchModalQuery,
} from "./TemplateTypesSearchModal.generated";

const ALLOWED_VIEWS: CollectionView[] = ["gallery", "list", "table"];

interface Props {
  title?: string;
  handleClose: () => void;
  handleSelectTemplateType: (
    templateType: TemplateTypesSearchModal_TemplateTypeFragment,
    handleClose: () => void
  ) => Promise<void> | void;
  handleClearTemplateType?: (handleClose: () => void) => Promise<void> | void;
  onTemplateTypeClick?: (
    templateType: TemplateTypesSearchModal_TemplateTypeFragment
  ) => void;
  initialSearchValue?: string;
  handleSearchValueChange?: (value: string) => void;
  excludeTemplateTypeIds?: string[];
}

export const TemplateTypesSearchModal = ({
  title,
  handleClose,
  handleSelectTemplateType,
  handleClearTemplateType,
  ...props
}: Props) => {
  const { t } = useTranslate(["TemplateTypesSearch"]);

  const [selectedTemplateType, setSelectedTemplateType] =
    React.useState<TemplateTypesSearchModal_TemplateTypeFragment | null>(null);

  const [processingSelect, runSelect] = useRunWithFlag();
  const [processingClear, runClear] = useRunWithFlag();
  const processing = processingSelect || processingClear;

  return (
    <Modal
      maxWidth="lg"
      fixedHeight
      title={title ?? t("Select template type", { ns: "TemplateTypesSearch" })}
      actionButtons={
        [
          {
            label: t("Cancel", { ns: "Global" }),
            handleClick: handleClose,
            buttonProps: { variant: "text", disabled: processing },
          },
          ...(handleClearTemplateType
            ? ([
                {
                  label: t("Remove template type", {
                    ns: "TemplateTypesSearch",
                  }),
                  handleClick: async () => {
                    await runClear(async () => {
                      await handleClearTemplateType(handleClose);
                    });
                  },
                  buttonProps: {
                    loading: processingClear,
                    variant: "outlined",
                    disabled: processing,
                  },
                },
              ] as ModalActionButtonProps[])
            : []),
          {
            label: t("Select", { ns: "Global" }),
            handleClick: async () => {
              if (!selectedTemplateType)
                throw new Error("Template type missing");
              await runSelect(async () => {
                await handleSelectTemplateType(
                  selectedTemplateType,
                  handleClose
                );
              });
            },
            buttonProps: {
              loading: processingSelect,
              disabled: !selectedTemplateType || processing,
            },
          },
        ] as ModalActionButtonProps[]
      }
      handleClose={handleClose}
    >
      <TemplateTypesDisplay
        selectedTemplateType={selectedTemplateType}
        setSelectedTemplateType={setSelectedTemplateType}
        inspectedProductProcessing={processingSelect}
        onInspectedTemplateTypeClick={async templateType => {
          await runSelect(async () => {
            await handleSelectTemplateType(templateType, handleClose);
          });
        }}
        {...props}
      />
    </Modal>
  );
};

const TemplateTypesDisplay = ({
  initialSearchValue = "",
  excludeTemplateTypeIds,
  handleSearchValueChange,
  inspectedProductProcessing,
  onInspectedTemplateTypeClick,
  onTemplateTypeClick,
  selectedTemplateType,
  setSelectedTemplateType,
}: {
  initialSearchValue?: string;
  handleSearchValueChange?: (value: string) => void;

  excludeTemplateTypeIds?: string[];

  inspectedProductProcessing?: boolean;
  onInspectedTemplateTypeClick?: (
    templateType: TemplateTypesSearchModal_TemplateTypeFragment
  ) => Promise<void> | void;
  onTemplateTypeClick?: (
    templateType: TemplateTypesSearchModal_TemplateTypeFragment
  ) => void;
  selectedTemplateType?: TemplateTypesSearchModal_TemplateTypeFragment | null;
  setSelectedTemplateType?: (
    templateType: TemplateTypesSearchModal_TemplateTypeFragment | null
  ) => void;
}) => {
  const { t } = useTranslate(["TemplateTypesSearch", "Global"]);

  const [inspectedTemplateType, setInspectedTemplateType] =
    React.useState<TemplateTypesSearchModal_TemplateTypeFragment | null>(null);

  const [paginationModel, setPaginationModel] = React.useState<{
    page: number;
    pageSize: number;
  }>({ page: 0, pageSize: PAGE_LIST_RESULTS_PER_PAGE });

  const [searchTerm, setSearchTerm] =
    React.useState<string>(initialSearchValue);

  const client = useApolloClient();
  const query = useTemplateTypesSearchModalQuery({
    client,
    variables: {
      searchTerm,
      limit: paginationModel.pageSize,
      offset: paginationModel.pageSize * paginationModel.page,
      filter: {
        excludeTemplateTypeIds,
      },
    },
  });

  const [activeView, setActiveView] =
    useCollectionViewWithMobile<CollectionView>(
      "templateTypesListViewType",
      "table",
      "list"
    );

  React.useEffect(() => {
    const totalCount = getDataOrNull(
      query.data?.searchTemplateTypes
    )?.totalCount;
    if (
      !isUndefined(totalCount) &&
      totalCount < paginationModel.pageSize * paginationModel.page &&
      paginationModel.page > 0
    ) {
      setPaginationModel({ page: 0, pageSize: paginationModel.pageSize });
    }
  }, [query.data, paginationModel, setPaginationModel]);

  const items =
    getDataOrNull(
      (query.data ?? query.previousData)?.searchTemplateTypes
    )?.edges.map(e => e.node) ?? [];
  const totalCount =
    getDataOrNull((query.data ?? query.previousData)?.searchTemplateTypes)
      ?.totalCount ?? 0;

  return (
    <Stack
      alignItems={"stretch"}
      height="100%"
      spacing={2}
      direction="row"
      minHeight={0}
    >
      <Stack
        direction={"column"}
        height="100%"
        spacing={1}
        flex={1}
        minWidth={0}
      >
        <TemplateTypesSearchModalListHeader
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          activeView={activeView}
          setActiveView={setActiveView}
          handleSearchValueChange={handleSearchValueChange}
        />
        {query.error && <ErrorMessage message={query.error.message} />}
        <TemplateTypesList
          loading={query.loading}
          items={items}
          totalCount={totalCount}
          paginationModel={paginationModel}
          setPaginationModel={setPaginationModel}
          activeView={activeView}
          setInspectedTemplateType={setInspectedTemplateType}
          onTemplateTypeClick={onTemplateTypeClick}
          selectedTemplateType={selectedTemplateType}
          setSelectedTemplateType={setSelectedTemplateType}
        />
      </Stack>
      {inspectedTemplateType && (
        <TemplateTypeOverviewModal
          templateTypeId={inspectedTemplateType.id}
          handleClose={() => setInspectedTemplateType(null)}
          dialogActionButton={
            onInspectedTemplateTypeClick ? (
              <Button
                onClick={async e => {
                  e.stopPropagation();
                  setInspectedTemplateType(null);
                  await onInspectedTemplateTypeClick(inspectedTemplateType);
                }}
                color="primary"
                variant="contained"
                disabled={inspectedProductProcessing}
              >
                {t("Use this template type", { ns: "TemplateTypesSearch" })}
              </Button>
            ) : setSelectedTemplateType ? (
              <Button
                onClick={e => {
                  e.stopPropagation();
                  setInspectedTemplateType(null);
                  setSelectedTemplateType(inspectedTemplateType);
                }}
                color="primary"
                variant="contained"
              >
                {t("Select", { ns: "Global" })}
              </Button>
            ) : undefined
          }
        />
      )}
    </Stack>
  );
};

function TemplateTypesList({
  loading,
  totalCount,
  items,
  setInspectedTemplateType,
  onTemplateTypeClick,
  paginationModel,
  setPaginationModel,
  activeView,
  selectedTemplateType,
  setSelectedTemplateType,
}: {
  loading: boolean;
  totalCount: number;
  items: TemplateTypesSearchModal_TemplateTypeFragment[];
  setInspectedTemplateType: (
    templateType: TemplateTypesSearchModal_TemplateTypeFragment
  ) => void;
  onTemplateTypeClick?: (
    templateType: TemplateTypesSearchModal_TemplateTypeFragment
  ) => void;
  selectedTemplateType?: TemplateTypesSearchModal_TemplateTypeFragment | null;
  setSelectedTemplateType?: (
    templateType: TemplateTypesSearchModal_TemplateTypeFragment | null
  ) => void;
  paginationModel: PaginationModel;
  setPaginationModel: React.Dispatch<React.SetStateAction<PaginationModel>>;
  activeView: CollectionView;
}) {
  const { t } = useTranslate(["TemplateTypesSearch", "Global"]);
  const {
    spacing: cardsSpacing,
    sizeMeasureRef,
    columns: cardsColumns,
  } = useCardsNumber();

  const setInspectedTemplateTypeRef = useLatest(setInspectedTemplateType);

  const columns = React.useMemo(
    (): GridColDef<TemplateTypesSearchModal_TemplateTypeFragment>[] => [
      {
        field: "title",
        headerName: t("Title", { ns: "Templates" }),
        sortable: false,
        flex: 3,
        minWidth: 150,
        renderCell: ({ row: templateType }) => templateType.title,
      },
      {
        field: "actions",
        headerName: "",
        width: 50,
        sortable: false,
        resizable: false,
        renderCell: ({ row: templateType }) => (
          <IconButton
            onClick={e => {
              e.stopPropagation();
              e.preventDefault();
              setInspectedTemplateTypeRef.current?.(templateType);
            }}
          >
            <InfoOutlinedIcon color="secondary" />
          </IconButton>
        ),
      },
    ],
    [setInspectedTemplateTypeRef, t]
  );

  switch (activeView) {
    case "list":
      if (loading) return <LoadingIndicator />;
      return items.length > 0 ? (
        <Stack
          direction="column"
          spacing={1}
          flex={1}
          overflow="auto"
          minHeight={0}
        >
          <Stack
            direction="column"
            spacing={1}
            overflow="auto"
            flex={1}
            minHeight={0}
          >
            {items.map((templateType, index) => {
              return (
                <MediaListItem
                  key={templateType.id}
                  selected={templateType.id === selectedTemplateType?.id}
                  onClick={() => {
                    if (onTemplateTypeClick) {
                      onTemplateTypeClick(templateType);
                    } else {
                      setSelectedTemplateType?.(
                        selectedTemplateType?.id === templateType.id
                          ? null
                          : templateType
                      );
                    }
                  }}
                  title={templateType.title}
                  description={templateType.description}
                  ActionButton={
                    <IconButton
                      onClick={event => {
                        event.stopPropagation();
                        setInspectedTemplateType(templateType);
                      }}
                    >
                      <InfoOutlinedIcon color="secondary" />
                    </IconButton>
                  }
                />
              );
            })}
          </Stack>
          <TablePagination
            component="div"
            count={totalCount}
            page={paginationModel.page}
            onPageChange={(event, newPage) => {
              setPaginationModel({
                pageSize: paginationModel.pageSize,
                page: newPage,
              });
            }}
            rowsPerPage={paginationModel.pageSize}
            onRowsPerPageChange={event => {
              setPaginationModel({
                pageSize: parseInt(event.target.value, 10),
                page: 0,
              });
            }}
            labelRowsPerPage={t("Rows per page:", {
              ns: "Global",
            })}
          />
        </Stack>
      ) : (
        <Box padding={2} display="flex" justifyContent="center">
          {t("There seems to be nothing here", {
            ns: "Global",
          })}
        </Box>
      );

    case "gallery":
      if (loading) return <LoadingIndicator />;
      return (
        <Stack
          ref={sizeMeasureRef}
          direction="column"
          spacing={1}
          flex={1}
          overflow="auto"
          minHeight={0}
        >
          <Box overflow="auto" flex={1} minHeight={0}>
            <Grid container spacing={cardsSpacing}>
              {items.map((templateType, index) => {
                return (
                  <Grid key={templateType.id} item {...cardsColumns}>
                    <MediaCardItem
                      key={templateType.id}
                      selected={templateType.id === selectedTemplateType?.id}
                      onClick={() => {
                        if (onTemplateTypeClick) {
                          onTemplateTypeClick(templateType);
                        } else {
                          setSelectedTemplateType?.(
                            selectedTemplateType?.id === templateType.id
                              ? null
                              : templateType
                          );
                        }
                      }}
                      title={templateType.title}
                      description={templateType.description}
                      ActionButton={
                        <ButtonCircleWrapper>
                          <IconButton
                            onClick={event => {
                              event.stopPropagation();
                              setInspectedTemplateType(templateType);
                            }}
                          >
                            <InfoOutlinedIcon color="secondary" />
                          </IconButton>
                        </ButtonCircleWrapper>
                      }
                    />
                  </Grid>
                );
              })}
            </Grid>
          </Box>
          <TablePagination
            component="div"
            count={totalCount}
            page={paginationModel.page}
            onPageChange={(event, newPage) => {
              setPaginationModel({
                pageSize: paginationModel.pageSize,
                page: newPage,
              });
            }}
            rowsPerPage={paginationModel.pageSize}
            onRowsPerPageChange={event => {
              setPaginationModel({
                pageSize: parseInt(event.target.value, 10),
                page: 0,
              });
            }}
            labelRowsPerPage={t("Items per page:", { ns: "Global" })}
          />
        </Stack>
      );

    case "table":
      return (
        <DataGrid<TemplateTypesSearchModal_TemplateTypeFragment>
          density="compact"
          loading={loading}
          hideFooter={totalCount === 0}
          onRowClick={({ row: templateType }, event) => {
            if (onTemplateTypeClick) {
              onTemplateTypeClick(templateType);
            } else {
              setSelectedTemplateType?.(
                selectedTemplateType?.id === templateType.id
                  ? null
                  : templateType
              );
            }
          }}
          localeText={{
            noRowsLabel: t("There are no items to display", {
              ns: "Global",
            }),
            MuiTablePagination: {
              labelRowsPerPage: t("Rows per page", {
                ns: "Global",
              }),
            },
          }}
          columns={columns}
          rows={items}
          paginationModel={paginationModel}
          onPaginationModelChange={newPaginationModel => {
            setPaginationModel(newPaginationModel);
          }}
          rowSelectionModel={
            selectedTemplateType ? [selectedTemplateType.id] : []
          }
          disableColumnFilter
          pageSizeOptions={RESULTS_PER_PAGE_OPTIONS}
          rowCount={totalCount}
        />
      );
  }

  return null;
}

function TemplateTypesSearchModalListHeader({
  searchTerm,
  setSearchTerm,
  handleSearchValueChange,
  activeView,
  setActiveView,
}: {
  searchTerm: string;
  setSearchTerm: React.Dispatch<React.SetStateAction<string>>;
  handleSearchValueChange?: (value: string) => void;
  activeView: CollectionView;
  setActiveView: (newValue: CollectionView) => void;
}) {
  const { t } = useTranslate(["Global"]);

  return (
    <>
      <ListHeader
        elementsOrder="separate-rows"
        SwitchViewButton={
          <SwitchCollectionViewButton
            allowedViews={ALLOWED_VIEWS}
            activeView={activeView}
            setActiveView={setActiveView}
          />
        }
        SearchField={
          <Stack direction={"row"} flex={1} spacing={1}>
            <DebouncedSearchInput
              defaultValue={searchTerm}
              placeholder={t("Search", {
                ns: "Global",
              })}
              onChangeSearchTerm={setSearchTerm}
              onImmediateChangeSearchTerm={handleSearchValueChange}
              autoFocus
              style={{ flexGrow: 1 }}
            />
          </Stack>
        }
      />
    </>
  );
}
