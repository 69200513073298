import { assertNever, RequiredNotNull } from "@msys/common";
import { findIndex, merge, omit } from "lodash";
import omitDeep from "omit-deep-lodash";
import {
  DefineItemProps2Entry,
  DefineItemProps2EntryBool,
  DefineItemProps2EntryBoolComputed,
  DefineItemProps2EntryNumber,
  DefineItemProps2EntryNumberArray,
  DefineItemProps2EntryNumberArrayComputed,
  DefineItemProps2EntryNumberComputed,
  DefineItemProps2EntryText,
  DefineItemProps2EntryTextArray,
  DefineItemProps2EntryTextArrayComputed,
  DefineItemProps2EntryTextComputed,
  EnterItemProps2ValueEntry,
  Props2,
  Props2Bool,
  Props2BoolComputed,
  Props2NonComputed,
  Props2Number,
  Props2NumberArray,
  Props2NumberArrayComputed,
  Props2NumberComputed,
  Props2Text,
  Props2TextArray,
  Props2TextArrayComputed,
  Props2TextComputed,
} from "../../../clients/graphqlTypes";

export type PropType = "TEXT" | "NUMBER" | "BOOLEAN";
export const PROP_VALUES: PropType[] = ["TEXT", "NUMBER", "BOOLEAN"];

export function isNonComputedProp(prop: Props2): prop is Props2NonComputed {
  return !isComputedProp(prop);
}

export function isComputedProp(
  prop: Props2
): prop is Exclude<Props2, Props2NonComputed> {
  switch (prop.__typename) {
    case "Props2Bool":
    case "Props2Text":
    case "Props2TextArray":
    case "Props2NumberArray":
    case "Props2Number": {
      return false;
    }
    case "Props2TextComputed":
    case "Props2NumberComputed":
    case "Props2TextArrayComputed":
    case "Props2NumberArrayComputed":
    case "Props2BoolComputed": {
      return true;
    }
    default: {
      assertNever(prop);
    }
  }
}

export function isPropMissingValue(prop: Props2) {
  switch (prop.__typename) {
    case "Props2TextComputed":
    case "Props2NumberComputed":
    case "Props2TextArrayComputed":
    case "Props2NumberArrayComputed":
    case "Props2BoolComputed": {
      return false;
    }
    case "Props2Bool":
    case "Props2Text":
    case "Props2Number":
    case "Props2TextArray":
    case "Props2NumberArray": {
      return getPropValue(prop) === null;
    }
    default: {
      assertNever(prop);
    }
  }
}

export function isPropHavingRangeDefined(prop: Props2) {
  switch (prop.__typename) {
    case "Props2TextComputed":
    case "Props2NumberComputed":
    case "Props2TextArrayComputed":
    case "Props2NumberArrayComputed":
    case "Props2BoolComputed":
    case "Props2Bool":
    case "Props2Text":
    case "Props2TextArray": {
      return false;
    }
    case "Props2Number":
    case "Props2NumberArray": {
      return (
        (prop.range?.min ?? null) !== null || (prop.range?.max ?? null) !== null
      );
    }
    default: {
      assertNever(prop);
    }
  }
}

export function getPropValue(prop: Props2) {
  switch (prop.__typename) {
    case "Props2Bool": {
      return prop.valueBool;
    }
    case "Props2Number": {
      return prop.valueNumber;
    }
    case "Props2NumberArray": {
      return prop.valueNumberArray;
    }
    case "Props2Text": {
      return prop.valueText;
    }
    case "Props2TextArray": {
      return prop.valueTextArray;
    }
    case "Props2BoolComputed": {
      return prop.valueBoolComputed;
    }
    case "Props2NumberComputed": {
      return prop.valueNumberComputed;
    }
    case "Props2NumberArrayComputed": {
      return prop.valueNumberArrayComputed;
    }
    case "Props2TextComputed": {
      return prop.valueTextComputed;
    }
    case "Props2TextArrayComputed": {
      return prop.valueTextArrayComputed;
    }
    default: {
      assertNever(prop);
    }
  }
}

export function getPropDisplayedValue(
  prop: Props2,
  getBoolLabel: (key: boolean) => string,
  getNumberLabel: (key: number) => string,
  notSetSymbol: string,
  unit: string
) {
  switch (prop.__typename) {
    case "Props2Bool": {
      return prop.valueBool !== null && prop.valueBool !== undefined
        ? getBoolLabel(prop.valueBool)
        : notSetSymbol;
    }
    case "Props2Number": {
      return prop.valueNumber !== null && prop.valueNumber !== undefined
        ? `${getNumberLabel(prop.valueNumber)}${unit}`
        : `${notSetSymbol}${notSetSymbol ? unit : ""}`;
    }
    case "Props2NumberArray": {
      return prop.valueNumberArray && prop.valueNumberArray.length > 0
        ? prop.valueNumberArray
            .map(v => `${getNumberLabel(v)}${unit}`)
            .join(", ")
        : `${notSetSymbol}${notSetSymbol ? unit : ""}`;
    }
    case "Props2Text": {
      return prop.valueText !== null &&
        prop.valueText !== undefined &&
        prop.valueText.trim()
        ? prop.valueText.trim()
        : notSetSymbol;
    }
    case "Props2TextArray": {
      return prop.valueTextArray && prop.valueTextArray.length > 0
        ? prop.valueTextArray.map(v => v.trim()).join(", ")
        : notSetSymbol;
    }
    case "Props2BoolComputed": {
      return !prop.missingValue &&
        prop.valueBoolComputed !== null &&
        prop.valueBoolComputed !== undefined
        ? getBoolLabel(prop.valueBoolComputed)
        : notSetSymbol;
    }
    case "Props2NumberComputed": {
      return !prop.missingValue &&
        prop.valueNumberComputed !== null &&
        prop.valueNumberComputed !== undefined
        ? `${getNumberLabel(prop.valueNumberComputed)}${unit}`
        : `${notSetSymbol}${notSetSymbol ? unit : ""}`;
    }
    case "Props2NumberArrayComputed": {
      return !prop.missingValue &&
        prop.valueNumberArrayComputed &&
        prop.valueNumberArrayComputed.length > 0
        ? prop.valueNumberArrayComputed
            .map(v => `${getNumberLabel(v)}${unit}`)
            .join(", ")
        : `${notSetSymbol}${notSetSymbol ? unit : ""}`;
    }
    case "Props2TextComputed": {
      return !prop.missingValue &&
        prop.valueTextComputed !== null &&
        prop.valueTextComputed !== undefined &&
        prop.valueTextComputed.trim()
        ? prop.valueTextComputed.trim()
        : notSetSymbol;
    }
    case "Props2TextArrayComputed": {
      return !prop.missingValue &&
        prop.valueTextArrayComputed &&
        prop.valueTextArrayComputed.length > 0
        ? prop.valueTextArrayComputed.map(v => v.trim()).join(", ")
        : notSetSymbol;
    }
    default: {
      assertNever(prop);
    }
  }
}

export function getPropertyUnit(prop: Props2) {
  switch (prop.__typename) {
    case "Props2Bool":
    case "Props2Text":
    case "Props2TextArray":
    case "Props2BoolComputed":
    case "Props2TextComputed":
    case "Props2TextArrayComputed":
      return null;
    case "Props2Number":
    case "Props2NumberArray":
    case "Props2NumberComputed":
    case "Props2NumberArrayComputed":
      return prop.unit;
    default: {
      assertNever(prop);
    }
  }
}

export function getPropertyDisplayedUnit(
  prop: Props2,
  getUnitLabel: (key: string) => string
) {
  switch (prop.__typename) {
    case "Props2Bool":
    case "Props2Text":
    case "Props2TextArray":
    case "Props2BoolComputed":
    case "Props2TextComputed":
    case "Props2TextArrayComputed":
      return "";
    case "Props2Number":
    case "Props2NumberArray":
    case "Props2NumberComputed":
    case "Props2NumberArrayComputed": {
      const label = prop.unit ? getUnitLabel(prop.unit) : "";
      if (label.length > 3) return ` ${label}`;
      return label;
    }
    default: {
      assertNever(prop);
    }
  }
}

export function getPropValueWithUnit(
  prop: Props2,
  getUnitLabel: (key: string) => string,
  getBoolLabel: (key: boolean) => string,
  getNumberLabel: (key: number) => string,
  notSetSymbol: string
) {
  const unit = getPropertyDisplayedUnit(prop, getUnitLabel);
  return getPropDisplayedValue(
    prop,
    getBoolLabel,
    getNumberLabel,
    notSetSymbol,
    unit
  );
}

export function stringArrayToNumberArray(stringArray: string[]): number[] {
  const numberArray = stringArray.map(parseFloat);
  if (numberArray.some(isNaN)) throw new Error("Failed to parse float");

  return numberArray;
}

export function propToDefineInput(
  property: Props2Bool
): RequiredNotNull<Pick<DefineItemProps2Entry, "bool">>;
export function propToDefineInput(
  property: Props2BoolComputed
): RequiredNotNull<Pick<DefineItemProps2Entry, "boolComputed">>;
export function propToDefineInput(
  property: Props2Number
): RequiredNotNull<Pick<DefineItemProps2Entry, "number">>;
export function propToDefineInput(
  property: Props2NumberComputed
): RequiredNotNull<Pick<DefineItemProps2Entry, "numberComputed">>;
export function propToDefineInput(
  property: Props2NumberArray
): RequiredNotNull<Pick<DefineItemProps2Entry, "numberArray">>;
export function propToDefineInput(
  property: Props2NumberArrayComputed
): RequiredNotNull<Pick<DefineItemProps2Entry, "numberArrayComputed">>;
export function propToDefineInput(
  property: Props2Text
): RequiredNotNull<Pick<DefineItemProps2Entry, "text">>;
export function propToDefineInput(
  property: Props2TextComputed
): RequiredNotNull<Pick<DefineItemProps2Entry, "textComputed">>;
export function propToDefineInput(
  property: Props2TextArray
): RequiredNotNull<Pick<DefineItemProps2Entry, "textArray">>;
export function propToDefineInput(
  property: Props2TextArrayComputed
): RequiredNotNull<Pick<DefineItemProps2Entry, "textArrayComputed">>;
export function propToDefineInput(
  property: Props2NonComputed
):
  | RequiredNotNull<Pick<DefineItemProps2Entry, "bool">>
  | RequiredNotNull<Pick<DefineItemProps2Entry, "number">>
  | RequiredNotNull<Pick<DefineItemProps2Entry, "numberArray">>
  | RequiredNotNull<Pick<DefineItemProps2Entry, "text">>
  | RequiredNotNull<Pick<DefineItemProps2Entry, "textArray">>;
export function propToDefineInput(
  property: Exclude<Props2, Props2NonComputed>
):
  | RequiredNotNull<Pick<DefineItemProps2Entry, "boolComputed">>
  | RequiredNotNull<Pick<DefineItemProps2Entry, "numberComputed">>
  | RequiredNotNull<Pick<DefineItemProps2Entry, "numberArrayComputed">>
  | RequiredNotNull<Pick<DefineItemProps2Entry, "textComputed">>
  | RequiredNotNull<Pick<DefineItemProps2Entry, "textArrayComputed">>;
export function propToDefineInput(property: Props2): DefineItemProps2Entry;
export function propToDefineInput(property: Props2): DefineItemProps2Entry {
  switch (property.__typename) {
    case "Props2Bool": {
      const bool = omitDeep(property, [
        "__typename",
      ]) as unknown as DefineItemProps2EntryBool;
      return { bool };
    }
    case "Props2Number": {
      const number = omitDeep(property, [
        "__typename",
      ]) as unknown as DefineItemProps2EntryNumber;
      if (number.allowedValuesNumber)
        number.allowedValuesNumber = number.allowedValuesNumber.map(v =>
          v.media ? { ...v, media: omit(v.media, "id") } : v
        );
      return { number };
    }
    case "Props2NumberArray": {
      const numberArray = omitDeep(property, [
        "__typename",
      ]) as unknown as DefineItemProps2EntryNumberArray;
      if (numberArray.allowedValuesNumber)
        numberArray.allowedValuesNumber = numberArray.allowedValuesNumber.map(
          v => (v.media ? { ...v, media: omit(v.media, "id") } : v)
        );
      return { numberArray };
    }
    case "Props2Text": {
      const text = omitDeep(property, [
        "__typename",
      ]) as unknown as DefineItemProps2EntryText;
      if (text.allowedValuesText)
        text.allowedValuesText = text.allowedValuesText.map(v =>
          v.media ? { ...v, media: omit(v.media, "id") } : v
        );
      return { text };
    }
    case "Props2TextArray": {
      const textArray = omitDeep(property, [
        "__typename",
      ]) as unknown as DefineItemProps2EntryTextArray;
      if (textArray.allowedValuesText)
        textArray.allowedValuesText = textArray.allowedValuesText.map(v =>
          v.media ? { ...v, media: omit(v.media, "id") } : v
        );
      return { textArray };
    }
    case "Props2BoolComputed":
    case "Props2NumberComputed":
    case "Props2NumberArrayComputed":
    case "Props2TextComputed":
    case "Props2TextArrayComputed":
      return computedPropToDefineInput(property);
    default: {
      assertNever(property);
    }
  }
}

export function computedPropToDefineInput(
  property: Exclude<Props2, Props2NonComputed>,
  newExpression?: string
):
  | RequiredNotNull<Pick<DefineItemProps2Entry, "boolComputed">>
  | RequiredNotNull<Pick<DefineItemProps2Entry, "numberComputed">>
  | RequiredNotNull<Pick<DefineItemProps2Entry, "numberArrayComputed">>
  | RequiredNotNull<Pick<DefineItemProps2Entry, "textComputed">>
  | RequiredNotNull<Pick<DefineItemProps2Entry, "textArrayComputed">> {
  switch (property.__typename) {
    case "Props2BoolComputed": {
      return {
        boolComputed: merge(
          omit(property, ["__typename", "valueBoolComputed", "missingValue"]),
          { expr: newExpression }
        ) as unknown as DefineItemProps2EntryBoolComputed,
      };
    }

    case "Props2NumberComputed": {
      return {
        numberComputed: merge(
          omit(property, ["__typename", "valueNumberComputed", "missingValue"]),
          { expr: newExpression }
        ) as unknown as DefineItemProps2EntryNumberComputed,
      };
    }

    case "Props2NumberArrayComputed": {
      return {
        numberArrayComputed: merge(
          omit(property, [
            "__typename",
            "valueNumberArrayComputed",
            "missingValue",
          ]),
          { expr: newExpression }
        ) as unknown as DefineItemProps2EntryNumberArrayComputed,
      };
    }

    case "Props2TextComputed": {
      return {
        textComputed: merge(
          omit(property, ["__typename", "valueTextComputed", "missingValue"]),
          { expr: newExpression }
        ) as unknown as DefineItemProps2EntryTextComputed,
      };
    }

    case "Props2TextArrayComputed": {
      return {
        textArrayComputed: merge(
          omit(property, [
            "__typename",
            "valueTextArrayComputed",
            "missingValue",
          ]),
          { expr: newExpression }
        ) as unknown as DefineItemProps2EntryTextArrayComputed,
      };
    }

    default: {
      assertNever(property);
    }
  }
}

export function getPropInputEntryKey(entry: DefineItemProps2Entry) {
  if (entry.bool) return entry.bool.key;
  if (entry.boolComputed) return entry.boolComputed.key;
  if (entry.number) return entry.number.key;
  if (entry.numberComputed) return entry.numberComputed.key;
  if (entry.numberArray) return entry.numberArray.key;
  if (entry.numberArrayComputed) return entry.numberArrayComputed.key;
  if (entry.text) return entry.text.key;
  if (entry.textComputed) return entry.textComputed.key;
  if (entry.textArray) return entry.textArray.key;
  if (entry.textArrayComputed) return entry.textArrayComputed.key;
  throw new Error("Unexpected entry type: " + JSON.stringify(entry));
}

export function getPropInputGroup(entry: DefineItemProps2Entry) {
  if (entry.bool) return entry.bool.group;
  if (entry.boolComputed) return entry.boolComputed.group;
  if (entry.number) return entry.number.group;
  if (entry.numberComputed) return entry.numberComputed.group;
  if (entry.numberArray) return entry.numberArray.group;
  if (entry.numberArrayComputed) return entry.numberArrayComputed.group;
  if (entry.text) return entry.text.group;
  if (entry.textComputed) return entry.textComputed.group;
  if (entry.textArray) return entry.textArray.group;
  if (entry.textArrayComputed) return entry.textArrayComputed.group;
  throw new Error("Unexpected entry type: " + JSON.stringify(entry));
}

export function convertToComputedInput(
  property: Props2Bool,
  expression: string
): RequiredNotNull<Pick<DefineItemProps2Entry, "boolComputed">>;
export function convertToComputedInput(
  property: Props2Number,
  expression: string
): RequiredNotNull<Pick<DefineItemProps2Entry, "numberComputed">>;
export function convertToComputedInput(
  property: Props2NumberArray,
  expression: string
): RequiredNotNull<Pick<DefineItemProps2Entry, "numberArrayComputed">>;
export function convertToComputedInput(
  property: Props2Text,
  expression: string
): RequiredNotNull<Pick<DefineItemProps2Entry, "textComputed">>;
export function convertToComputedInput(
  property: Props2TextArray,
  expression: string
): RequiredNotNull<Pick<DefineItemProps2Entry, "textArrayComputed">>;
export function convertToComputedInput(
  property: Props2NonComputed,
  expression: string
):
  | RequiredNotNull<Pick<DefineItemProps2Entry, "boolComputed">>
  | RequiredNotNull<Pick<DefineItemProps2Entry, "numberComputed">>
  | RequiredNotNull<Pick<DefineItemProps2Entry, "numberArrayComputed">>
  | RequiredNotNull<Pick<DefineItemProps2Entry, "textComputed">>
  | RequiredNotNull<Pick<DefineItemProps2Entry, "textArrayComputed">>;
export function convertToComputedInput(
  property: Props2NonComputed,
  expression: string
):
  | RequiredNotNull<Pick<DefineItemProps2Entry, "boolComputed">>
  | RequiredNotNull<Pick<DefineItemProps2Entry, "numberComputed">>
  | RequiredNotNull<Pick<DefineItemProps2Entry, "numberArrayComputed">>
  | RequiredNotNull<Pick<DefineItemProps2Entry, "textComputed">>
  | RequiredNotNull<Pick<DefineItemProps2Entry, "textArrayComputed">> {
  const entry = {
    key: property.key,
    label: property.label,
    group: property.group,
    expr: expression,
    clientVisibility: property.clientVisibility,
  };

  switch (property.__typename) {
    case "Props2Bool":
      return { boolComputed: entry };
    case "Props2Number":
      return {
        numberComputed: { ...entry, unit: property.unit },
      };
    case "Props2NumberArray":
      return {
        numberArrayComputed: { ...entry, unit: property.unit },
      };
    case "Props2Text":
      return {
        textComputed: entry,
      };
    case "Props2TextArray":
      return {
        textArrayComputed: entry,
      };
    default:
      assertNever(property);
  }
}

export function convertToNonComputedInput(
  property: Props2BoolComputed
): RequiredNotNull<Pick<DefineItemProps2Entry, "bool">>;
export function convertToNonComputedInput(
  property: Props2NumberComputed
): RequiredNotNull<Pick<DefineItemProps2Entry, "number">>;
export function convertToNonComputedInput(
  property: Props2NumberArrayComputed
): RequiredNotNull<Pick<DefineItemProps2Entry, "numberArray">>;
export function convertToNonComputedInput(
  property: Props2TextComputed
): RequiredNotNull<Pick<DefineItemProps2Entry, "text">>;
export function convertToNonComputedInput(
  property: Props2TextArrayComputed
): RequiredNotNull<Pick<DefineItemProps2Entry, "text">>;
export function convertToNonComputedInput(
  property: Exclude<Props2, Props2NonComputed>
):
  | RequiredNotNull<Pick<DefineItemProps2Entry, "bool">>
  | RequiredNotNull<Pick<DefineItemProps2Entry, "number">>
  | RequiredNotNull<Pick<DefineItemProps2Entry, "numberArray">>
  | RequiredNotNull<Pick<DefineItemProps2Entry, "text">>
  | RequiredNotNull<Pick<DefineItemProps2Entry, "textArray">>;
export function convertToNonComputedInput(
  property: Exclude<Props2, Props2NonComputed>
):
  | RequiredNotNull<Pick<DefineItemProps2Entry, "bool">>
  | RequiredNotNull<Pick<DefineItemProps2Entry, "number">>
  | RequiredNotNull<Pick<DefineItemProps2Entry, "numberArray">>
  | RequiredNotNull<Pick<DefineItemProps2Entry, "text">>
  | RequiredNotNull<Pick<DefineItemProps2Entry, "textArray">> {
  const entry = {
    key: property.key,
    label: property.label,
    group: property.group,
    essential: false,
    clientVisibility: property.clientVisibility,
    prompt: "",
    askWhom: [],
    askWhen: [],
  };

  switch (property.__typename) {
    case "Props2BoolComputed":
      return {
        bool: {
          ...entry,
          valueBool: property.missingValue ? null : property.valueBoolComputed,
        },
      };
    case "Props2NumberComputed":
      return {
        number: {
          ...entry,
          allowedValuesNumber: [],
          valueNumber: property.missingValue
            ? null
            : property.valueNumberComputed,
          unit: property.unit,
        },
      };
    case "Props2NumberArrayComputed":
      return {
        numberArray: {
          ...entry,
          allowedValuesNumber: [],
          valueNumberArray: property.missingValue
            ? null
            : property.valueNumberArrayComputed,
          unit: property.unit,
        },
      };
    case "Props2TextComputed":
      return {
        text: {
          ...entry,
          allowedValuesText: [],
          valueText: property.missingValue ? null : property.valueTextComputed,
        },
      };
    case "Props2TextArrayComputed":
      return {
        textArray: {
          ...entry,
          allowedValuesText: [],
          valueTextArray: property.missingValue
            ? null
            : property.valueTextArrayComputed,
        },
      };
    default:
      assertNever(property);
  }
}

export function getEnhancedPropertyLabel(
  property: Props2,
  includeRequired: boolean = true
) {
  return `${property.label} ${getPropertyLabelTypeSuffix(property)} ${
    includeRequired ? getPropertyLabelRequiredSuffix(property) : ""
  }`.trim();
}

function getPropertyLabelTypeSuffix(property: Props2) {
  switch (property.__typename) {
    case "Props2Bool":
    case "Props2BoolComputed":
      return "(t/f)";
    case "Props2Number":
    case "Props2NumberComputed":
    case "Props2NumberArray":
    case "Props2NumberArrayComputed":
      return "(#)";
    case "Props2Text":
    case "Props2TextComputed":
    case "Props2TextArray":
    case "Props2TextArrayComputed":
      return "(Aa)";
    default:
      assertNever(property);
  }
}

function getPropertyLabelRequiredSuffix(property: Props2) {
  switch (property.__typename) {
    case "Props2BoolComputed":
    case "Props2NumberComputed":
    case "Props2NumberArrayComputed":
    case "Props2TextComputed":
    case "Props2TextArrayComputed":
      return "";
    case "Props2Bool":
    case "Props2Number":
    case "Props2NumberArray":
    case "Props2Text":
    case "Props2TextArray":
      // return property.essential ? "*" : "";
      return property.askWhom.length > 0 ? "*" : "";
    default:
      assertNever(property);
  }
}

export function getPropertyType(prop: Props2): PropType {
  switch (prop.__typename) {
    case "Props2Bool":
    case "Props2BoolComputed":
      return "BOOLEAN";
    case "Props2Number":
    case "Props2NumberComputed":
    case "Props2NumberArray":
    case "Props2NumberArrayComputed":
      return "NUMBER";
    case "Props2Text":
    case "Props2TextComputed":
    case "Props2TextArray":
    case "Props2TextArrayComputed":
      return "TEXT";
    default:
      console.log(prop);
      assertNever(prop);
  }
}

export function isBooleanType(
  prop: Props2
): prop is Props2Bool | Props2BoolComputed {
  switch (prop.__typename) {
    case "Props2Bool":
    case "Props2BoolComputed":
      return true;
    case "Props2Number":
    case "Props2NumberComputed":
    case "Props2NumberArray":
    case "Props2NumberArrayComputed":
    case "Props2Text":
    case "Props2TextComputed":
    case "Props2TextArray":
    case "Props2TextArrayComputed":
      return false;
    default:
      assertNever(prop);
  }
}

export function isNumberType(
  prop: Props2
): prop is
  | Props2Number
  | Props2NumberComputed
  | Props2NumberArray
  | Props2NumberArrayComputed {
  switch (prop.__typename) {
    case "Props2Number":
    case "Props2NumberComputed":
    case "Props2NumberArray":
    case "Props2NumberArrayComputed":
      return true;
    case "Props2Bool":
    case "Props2BoolComputed":
    case "Props2Text":
    case "Props2TextComputed":
    case "Props2TextArray":
    case "Props2TextArrayComputed":
      return false;
    default:
      assertNever(prop);
  }
}

export function isTextType(
  prop: Props2
): prop is
  | Props2Text
  | Props2TextComputed
  | Props2TextArray
  | Props2TextArrayComputed {
  switch (prop.__typename) {
    case "Props2Text":
    case "Props2TextComputed":
    case "Props2TextArray":
    case "Props2TextArrayComputed":
      return true;
    case "Props2Bool":
    case "Props2BoolComputed":
    case "Props2Number":
    case "Props2NumberComputed":
    case "Props2NumberArray":
    case "Props2NumberArrayComputed":
      return false;
    default:
      assertNever(prop);
  }
}

export function isArrayType(
  prop: Props2
): prop is
  | Props2NumberArray
  | Props2NumberArrayComputed
  | Props2TextArray
  | Props2TextArrayComputed {
  switch (prop.__typename) {
    case "Props2NumberArray":
    case "Props2NumberArrayComputed":
    case "Props2TextArray":
    case "Props2TextArrayComputed":
      return true;
    case "Props2Bool":
    case "Props2BoolComputed":
    case "Props2Number":
    case "Props2NumberComputed":
    case "Props2Text":
    case "Props2TextComputed":
      return false;
    default:
      assertNever(prop);
  }
}

export function isNotArrayType(
  prop: Props2
): prop is
  | Props2Bool
  | Props2BoolComputed
  | Props2Number
  | Props2NumberComputed
  | Props2Text
  | Props2TextComputed {
  return !isArrayType(prop);
}

export function nonComputedPropToEntry(
  prop: Props2NonComputed
): EnterItemProps2ValueEntry {
  switch (prop.__typename) {
    case "Props2Bool":
      return { bool: { key: prop.key, valueBool: prop.valueBool } };
    case "Props2Text": {
      const value = prop.valueText?.length ? prop.valueText : null;
      return {
        text: {
          key: prop.key,
          valueText:
            value !== null && prop.allowedValuesText.length > 0
              ? prop.allowedValuesText.find(
                  ({ allowedText }) => allowedText === value
                )?.allowedText ?? null
              : value,
        },
      };
    }
    case "Props2Number": {
      const value = prop.valueNumber;
      return {
        number: {
          key: prop.key,
          valueNumber:
            value !== null && prop.allowedValuesNumber.length > 0
              ? prop.allowedValuesNumber.find(
                  ({ allowedNumber }) => allowedNumber === value
                )?.allowedNumber ?? null
              : value,
        },
      };
    }
    case "Props2TextArray": {
      const value = prop.valueTextArray;
      return {
        textArray: {
          key: prop.key,
          valueTextArray:
            value?.length && prop.allowedValuesText.length > 0
              ? value.filter(v =>
                  prop.allowedValuesText.some(
                    ({ allowedText }) => allowedText === v
                  )
                )
              : value,
        },
      };
    }
    case "Props2NumberArray": {
      const value = prop.valueNumberArray;
      return {
        numberArray: {
          key: prop.key,
          valueNumberArray:
            value?.length && prop.allowedValuesNumber.length > 0
              ? value.filter(v =>
                  prop.allowedValuesNumber.some(
                    ({ allowedNumber }) => allowedNumber === v
                  )
                )
              : value,
        },
      };
    }
    default:
      assertNever(prop);
  }
}

export type Props2Group = { key: string; properties: Props2[] };

export function groupProperties(properties: Props2[]): Props2Group[] {
  return properties.reduce((acc, prop) => {
    const groupIndex = findIndex(acc, g => g.key === prop.group);
    if (groupIndex >= 0) {
      acc[groupIndex].properties.push(prop);
    } else {
      acc.push({ key: prop.group, properties: [prop] });
    }
    return acc;
  }, [] as Props2Group[]);
}

export function ungroupProperties(groups: Props2Group[]): Props2[] {
  return groups.map(g => g.properties).flat(1);
}
